import styled from "styled-components";
import { colores, leerColor} from "../../../../constants"

const Item = styled.div`
  max-width: 100%;
  margin:0px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  border-left: ${props => props.select? `solid 2px rgb(109, 199, 249)` : "none" } ;
  border-top: ${props => props.select? `solid 1px #FFF` : "solid 1px #DDD" } ;
  background-color: ${props => props.select? leerColor(colores.celesteNot) : leerColor(colores.blanco) };
  transition: all 0.3s ease-in-out;
  font-size: 12px;
  &:hover {
    cursor:pointer;
  }
  
  & .badge {
    background-color : ${leerColor(colores.celestePasos)};
    margin: 3px;
    margin-top: -5px;
    padding: 5px;
    border-radius: 3px;
  }

  & .notificacion {
    background-color : ${leerColor(colores.naranjaEstadosSuscripcion)};
    color: ${leerColor(colores.blanco)};
    font-weight: 600;
  }

  & p {
    color: ${leerColor(colores.celeste)};
  }
`

export {Item}