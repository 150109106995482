// Components
import Sidebar from "../../../components/Sidebar";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import { Grid, Col } from "../../../components/Grid";
import FilterSection from "../../../components/FiltersSection";
import TableInfo from "../../../components/TableInfo";
import ModalHistorial from "../../../components/Modals/SolicitudEmision/ModalHistorial";
import TitlePageSection from "../../../components/TitlePageSection";
import NewTable from "../../../components/NewTable"
// Custom Hooks
import useEmisiones from "../../../hooks/useEmisiones";
import useFiltersNew from "../../../hooks/useFiltersNew";
import useHistorialNew from "../../../hooks/useHistorialNew";
import { useDescarga } from "../../../hooks/useDescarga";
import { useTable } from "../../../hooks/useTable";
import { useActionButton } from "../../../hooks/useActionButton";
// Filters
import { filtersEmisiones } from "../../../utils/filtersPages";
import { filterEmisionesInitialValues } from "../../../utils/filtersPages";
// Table
import { emisionesTable } from "../../../utils/tableData";
import ModalLoading from "../../../components/Modals/General/ModalLoading";
import NewFiltersSection from "../../../components/NewFiltersSection";
import { Container } from "./styled";
import { useState } from "react";
import NewButton from "../../../components/NewButton";
import { FiArrowRight, FiFilter, FiX } from "react-icons/fi";
import SpinnerLoading from "../../../components/SpinnerLoading";
import { useEffect } from "react";

const EmisionesRefactor = () => {
  const [filtersApplied, setFiltersApplied] = useState([]);

  // Modales
  const { historial, isModalOpen, handleModal, handleModalHistorial, solicitudData } = useHistorialNew()
  const { modal, descargarSolicitud } = useDescarga()

  const { inputValues, params, handleSearch, handleChange, handleChangeExternal, clearSearch } = useFiltersNew(filterEmisionesInitialValues)

  const { order, handleTableOrder } = useTable()

  const { data, loading, pagination } = useEmisiones(params, order)
  
  const filters = filtersEmisiones(data)
  // Botones
  const { isActionButtonOpen, handleToggle } = useActionButton()

  const tableData = emisionesTable(handleModalHistorial, descargarSolicitud, isActionButtonOpen, handleToggle)

  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    if (params) {
      let filterArr = [];
      if (params.includes("&")) {
        const filters = params.split("&");
        const filtersDos = filters.map((item) => item.split("="));
        filterArr.push(filtersDos);
        setFiltersApplied(filtersDos);
      } else {
        const filters = params.split("=");
        const arr = [filters[0], filters[1]]
        filterArr.push(arr);
        setFiltersApplied([arr]);
      }
    }
  }, [params]);

  const removeFilter = (e, filterName) => {
    handleChangeExternal(e, filterName, '')
  }

  return (
    <>
      <Sidebar active="emisiones" />
      
      <ModalLoading isModalOpen={modal.isModalOpen} setIsModalOpen={modal.setIsModalOpen} message={modal.modalMessage} />

      <ModalHistorial isModalOpen={isModalOpen} setIsModalOpen={handleModal} historial={historial} solicitudData={solicitudData} />

      <Wrapper>
        <Inner>
          <Container>
            <Grid colGap={21} rowGap={21} narrow={true}>
              
              <Col desktop={12}>
                <TitlePageSection title="Emisiones" description="Lista de todas las solicitudes de emisión emitidas">
                <NewButton
                    backgroundColor={false}
                    textColor={true}
                    borderColor={true}
                    onClick={() => setShowFilters(!showFilters)}
                  >
                    <FiFilter /> Filtros
                    {params?.split("=").length > 1 ? (
                      <span className="filtros-aplicados">
                        {params?.split("=").length - 1}
                      </span>
                    ) : null}
                  </NewButton>
                </TitlePageSection>
              </Col>
              {showFilters ?
              <Col desktop={12}>
                <NewFiltersSection section="solicitudes emitidas" inputs={filters} values={inputValues} params={params} handleChange={handleChange} handleSearch={handleSearch} clearSearch={clearSearch} />
              </Col>
              : null}
            </Grid>

            <hr />

            <Grid colGap={21} rowGap={21} narrow={true}>
              <Col desktop={2}>
                <div className="estadistica-container">
                  <span>Nuevas solicitudes</span>
                  {data.estadisticas?.nuevas >= 0 ? (
                    <>
                      <h3>{data.estadisticas.nuevas}</h3>
                      <hr />
                      <span
                        className="link"
                        onClick={(e) =>
                          handleChangeExternal(e, "tipo_solicitud", 1)
                        }
                      >
                        Ver nuevas <FiArrowRight />
                      </span>
                    </>
                  ) : (
                    <SpinnerLoading />
                  )}
                </div>
              </Col>
              <Col desktop={2}>
                <div className="estadistica-container">
                  <span>Anulaciones</span>
                  {data.estadisticas?.anuladas >= 0 ? (
                    <>
                      <h3>{data.estadisticas.anuladas}</h3>
                      <hr />
                      <span
                        className="link"
                        onClick={(e) =>
                          handleChangeExternal(e, "tipo_solicitud", 2)
                        }
                      >
                        Ver anuladas <FiArrowRight />
                      </span>
                    </>
                  ) : (
                    <SpinnerLoading />
                  )}
                </div>
              </Col>
              <Col desktop={2}>
                <div className="estadistica-container">
                  <span>Endosos</span>
                  {data.estadisticas?.endosos >= 0 ? (
                    <>
                      <h3>{data.estadisticas.endosos}</h3>
                      <hr />
                      <span
                        className="link"
                        onClick={(e) =>
                          handleChangeExternal(e, "tipo_solicitud", 3)
                        }
                      >
                        Ver endosos <FiArrowRight />
                      </span>
                    </>
                  ) : (
                    <SpinnerLoading />
                  )}
                </div>
              </Col>
              <Col desktop={2}>
                <div className="estadistica-container">
                  <span>Mails sin entregar</span>
                  {data.estadisticas?.no_entregados >= 0 ? (
                    <>
                      <h3>{data.estadisticas.no_entregados}</h3>
                      <hr />
                      <span
                        className="link"
                        onClick={(e) =>
                          handleChangeExternal(e, "estado_mail_poliza", 3)
                        }
                      >
                        Ver solicitudes <FiArrowRight />
                      </span>
                    </>
                  ) : (
                    <SpinnerLoading />
                  )}
                </div>
              </Col>
            </Grid>

            {filtersApplied.length > 0 && params && data.solicitudes
              ? 
              <div className="filtros-aplicados-container">
                <span>{pagination.dataLength} resultados encontrados con los siguientes filtros: </span>
                <div>
                  {filtersApplied.map((item) => {
                      const tiposSolicitud = { 1: "Nueva solicitud", 2: "Anulación por reemplazo", 3: "Endoso" }
                      let nombreFiltro = item[0] 
                      let valorFiltro = item[0] === "tipo_solicitud" ? tiposSolicitud[item[1]] : item[1]
                      valorFiltro = item[0] === "estado_mail_poliza" ? "No entregados" : valorFiltro
                      nombreFiltro = item[0] === "estado_mail_poliza" ? "Estado de mail de solicitudes emitidas" : nombreFiltro
                      return (
                        <span className="filtro-aplicado" onClick={(e) => removeFilter(e, item[0])}>
                          {nombreFiltro.charAt(0).toUpperCase() + nombreFiltro.slice(1).replaceAll("_", " ")}: {valorFiltro} <FiX/>
                        </span>
                      );
                  })}
                </div>
              </div>
              : null}

            <NewTable columns={tableData} data={data.solicitudes} loading={loading} order={handleTableOrder} orderParams={order} pagination={pagination} />
          </Container>
        </Inner>
      </Wrapper>
    </>
  );
};

export default EmisionesRefactor
