import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const ModalIdle = styled.div`
  padding: 50px;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;

  h3{
    margin:0 0 20px 0;
    font-family: sans-serif;
    font-weight: 400;
    font-size: 24px;
    color: ${leerColor(colores.grisModales)};
  }
`;
