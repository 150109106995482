import React from "react";
import PropTypes from "prop-types";
import { DivDescargar } from "./styled";
import Download from "../../assets/iconos/download.svg";
import Button from "../Button";
import { FiFile, FiStar, FiLink2 } from "react-icons/fi";

function ButtonDescargar({ children, nPoliza, premio, polizaPDF, backgroundColor, borderColor, textColor }) {

  /*
   <p><span className="icon"><FiFile /></span>Número de póliza: <span>{nPoliza}</span></p>
        <p><span className="icon"><FiStar /></span>Premio: <span>{premio}</span></p>
        <p><span className="icon"><FiLink2 /><span>Póliza: Póliza</span></span></p>
  */

  // https://apptest.lasegunda.com.ar/pdf/poliza_1056692.pdf

  return (
      <DivDescargar
        data-backgroundcolor={backgroundColor}
        data-cordercolor={borderColor}
        data-textcolor={textColor}
      >
        <p><span className="icon"><FiFile /></span>Número de póliza: <span>838992</span></p>
        <p><span className="icon"><FiStar /></span>Premio: <span>4869.43</span></p>
        <p><span className="icon"><FiLink2 /><span>Póliza: Póliza</span></span></p>
        {/*
        <Button
          backgroundColor={false}
          borderColor={false}
          textColor={true}
          direction={"after"}
          href={polizaPDF}
          download={true}
          target={"_blank"}
        >
          Ver archivo
        </Button>
        */}
      </DivDescargar>
  );
}

// ButtonDescargar.propTypes = {
//   children: PropTypes.string.isRequired
// };

export default ButtonDescargar;
