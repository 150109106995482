import React, { useState } from "react";
import PropTypes from "prop-types";
import { InputDiv, Input } from "./styled";
import Upload from '../../assets/iconos/upload.svg';

function InputFileButton({ type, name, required, setValuesStepFive, files, setFiles, text }) {
  
  function onFileUpload(event) {
    event.preventDefault();

    if(event.target.files[0]){
      const { name } = event.target;
      if(setValuesStepFive){
        setValuesStepFive(prev => ({...prev, [name]: event.target.files[0], }))
      }
      setFiles(prev => ({...prev, [name]: {image: event.target.files[0], name: event.target.files[0].name }}))
    }
  }

  return (
    <InputDiv>
      <label>
        <Input 
          type={type}
          name={name}
          id={name}
          multiple={false}
          required={required}
          accept={'.png, .jpg, .jpeg, image/jpeg, image/png, .pdf, .mp4'}
          onChange={onFileUpload}
        />
        <span>
          {text ? text : 'Adjuntar archivo'}
          <img src={Upload}/>
        </span>
      </label>
    </InputDiv>
  );
}

// InputFile.propTypes = {
//   children: PropTypes.string.isRequired
// };

export default InputFileButton;
