import React, { useEffect, useState } from "react";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import TitlePageSection from "../../../components/TitlePageSection";
import Sidebar from "../../../components/Sidebar";
import NewButton from "../../../components/NewButton";
import { Container } from "./styled";
import axiosRequest from "../../../utils/axiosConfig";
import NewTabs from "../../../components/NewTabs";
import { useTabs } from "../../../hooks/useTabs";
import Subtitle from "../../../components/Subtitle";
import { FiArrowLeft, FiUser } from "react-icons/fi";
import { Col, Grid } from "../../../components/Grid";
import NewInputBasic from "../../../components/NewInputBasic"
import { useForm } from "../../../hooks/useForm";
import ErrorInput from "../../../components/ErrorInput";
import { crearGrupoReglas } from "../../../utils/formRules";
import { crearGrupoForm } from "../../../utils/formInitialValues";
import { useLoading } from "../../../hooks/useLoading";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const PremiosCrearGrupo = () => { 
  const [vendedores, setVendedores] = useState([]);
  const [listaVendedores, setListaVendedores] = useState([])
  const [vendedoresSeleccionados, setVendedoresSeleccionados] = useState([])
    const { loading, setLoading } = useLoading()
  const { inputValues, handleChange, handleChangeExternal, formErrors, handleValidation, resetForm } = useForm(crearGrupo, crearGrupoReglas, crearGrupoForm)

  useEffect(() => {
    const getData = async () => {
      try {
        const vendedores = await axiosRequest.get("/premios/vendedores");
        setVendedores(vendedores.data);
        setListaVendedores(vendedores.data);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  const seleccionarVendedor = (vendedor) => {
    const seleccionado = vendedoresSeleccionados.find(ven => ven === vendedor)
    if(seleccionado){
        const removerVendedor = vendedoresSeleccionados.filter(ven => ven !== vendedor)
        handleChangeExternal("integrantes", removerVendedor)
        setVendedoresSeleccionados(removerVendedor)
    } else {
        handleChangeExternal("integrantes", [...vendedoresSeleccionados, vendedor])
        setVendedoresSeleccionados(prev => [...prev, vendedor])
    }
  }

  const handleChangeVendedor = (e) => {
    const { value } = e.target
    if(value){
        const filtrarVendedores = listaVendedores.filter(vendedor => vendedor.nombre.toLowerCase().startsWith(value.toLowerCase()))
        setVendedores(filtrarVendedores)
    } else {
        setVendedores(listaVendedores)
    }
  }

  async function crearGrupo (){
    setLoading(true)
    try { 
        const data = {
            integrantes: vendedoresSeleccionados,
            nombre: inputValues.nombre
        }
        const grupo = await axiosRequest.post('/premios/crear_grupo', data)
        if(grupo.status === 200){
            setLoading(false)
            setVendedoresSeleccionados([])
            resetForm()
        }
    } catch (error) {
        console.log(error)
    }
  }

  return (
    <div>
      <div>
        <Sidebar />
        <Wrapper>
          <Inner>
            <Container>
              <div>
            <Link to="/Premios" className="back-button">
                  <FiArrowLeft />
                  Volver a Premios
                </Link>
              <TitlePageSection
                title="Crear Grupo"
                description="Armá grupos de vendedores para asignar premios"
              >
              </TitlePageSection>
              </div>
              <hr />
              <Subtitle
                icon={<FiUser />}
                title={"Datos del grupo"}
                description="Elegí el nombre del grupo"
                disableDivider={true}
              />
              <Grid colGap={21} rowGap={21} narrow={true}>
                <Col desktop={3}>
                <NewInputBasic
                    type={"text"}
                    name={"nombre"}
                    labelName={"Nombre del grupo"}
                    placeholder={"Nombre del grupo"}
                    onChange={handleChange}
                    value={inputValues.nombre}
                />
                {formErrors.nombre && <ErrorInput>{formErrors.nombre}</ErrorInput>}
                </Col>
              </Grid>
              <hr />
              <Subtitle
                icon={<FiUser />}
                title={"Miembros del grupo"}
                description="Seleccioná los vendedores que formarán parte del grupo"
                disableDivider={true}
              />
              <Grid colGap={21} rowGap={21} narrow={true}>
                <Col desktop={12}>
                <NewInputBasic
                    type={"text"}
                    name={"nombre"}
                    labelName={"Buscar vendedor"}
                    placeholder={"Nombre del vendedor"}
                    onChange={handleChangeVendedor}
                />
                </Col>
                {vendedores.length > 0
                  ? vendedores.map((vendedor) => {
                      const seleccionado = vendedoresSeleccionados.find(ven => ven === vendedor.numero)
                      return (
                        <Col desktop={3}>
                            <div className={`vendedor-container ${seleccionado ? 'seleccionado' : ''}`} onClick={() => seleccionarVendedor(vendedor.numero)}>
                                <span className="icon"><FiUser /></span>
                                <span>{vendedor.nombre}</span>
                            </div>
                        </Col>
                      )
                    })
                  : null}

                {formErrors.integrantes && <Col desktop={12}><ErrorInput>{formErrors.integrantes}</ErrorInput></Col>}

              </Grid>
              <hr />
              <div className="buttons-container">
                <NewButton backgroundColor={true} onClick={handleValidation} disabled={loading}>{loading ? "Creando grupo..." : "Crear grupo"}</NewButton>
                <Link to="/Premios"><NewButton borderColor={true} textColor={true}><FiArrowLeft /> Volver a Premios</NewButton></Link>
              </div>
            </Container>
          </Inner>
        </Wrapper>
      </div>
    </div>
  );
};

export default PremiosCrearGrupo;
