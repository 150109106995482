import Modal from "../../../Modal";
import { Container } from "./styled";
import Titulo from "../../../Titulo";
import { Grid, Col } from "../../../Grid";
import Button from "../../../Button";
import dayjs from "dayjs";

const ModalBorrarSiniestro = ({ isModalOpen, handleModal, deleteSiniestro, siniestro }) => {

  const user = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={handleModal}
    >
      <Container>
        <h2>¿Desea borrar el siniestro?</h2>
        {siniestro ?
        <Grid colGap={21} rowGap={8}>
          <Col desktop={4}>
            <h4>Asegurado</h4>
            <span className="data">{siniestro.asegurado}</span>
          </Col>
          <Col desktop={4}>
            <h4>Compañia</h4>
            <span className="data">{siniestro.compania}</span>
          </Col>
          <Col desktop={4}>
            <h4>Estado</h4>
            <span className="data">{siniestro.estado}</span>
          </Col>
          <Col desktop={4}>
            <h4>Fecha</h4>
            <span className="data">{dayjs(siniestro.fecha).format("DD-MM-YYYY")}</span>
          </Col>
          <Col desktop={4}>
            <h4>Numero</h4>
            <span className="data">{siniestro.num_siniestro}</span>
          </Col>
          <Col desktop={4}>
            <h4>Póliza</h4>
            <span className="data">{siniestro.poliza}</span>
          </Col>
          <Col desktop={4}>
            <h4>Prioridad</h4>
            <span className="data">{siniestro.prioridad}</span>
          </Col>
          <Col desktop={4}>
            <h4>Ramo</h4>
            <span className="data">{siniestro.ramo}</span>
          </Col>
          <Col desktop={4}>
            <h4>Tipo</h4>
            <span className="data">{siniestro.tipo_siniestro}</span>
          </Col>
        </Grid>
        : null}
        <div className={"buttonsCont"}>
          <Button
            backgroundColor={false}
            borderColor={true}
            textColor={true}
            icon={false}
            onClick={handleModal}
            disabled={user.id_rol === 8}
          >
            {" "}
            Cancelar{" "}
          </Button>
          <Button
            backgroundColor={true}
            borderColor={false}
            textColor={false}
            icon={false}
            onClick={() => deleteSiniestro(siniestro)}
            disabled={user.id_rol === 8}
          >
            Borrar Siniestro
          </Button>
        </div>
      </Container>
    </Modal>
  );
};

export default ModalBorrarSiniestro;
