import styled from "styled-components";
import { breakpoints } from "../../../../constants/media";
import { colores, leerColor} from "../../../../constants"

const Container = styled.div`
  width: 100%;
  margin: 8px 0px 8px 0px;
  padding: 0px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) =>
    props.enviado == true ? "flex-end" : "flex-start"};
  align-items: flex-start;
  align-content: flex-start;

  #mensaje-container {
    width: fit-content;
    max-width: 650px;
    margin: ${(props) =>
      props.enviado ? "0px 0px 0px 200px" : "0px 200px 0px 0px"};
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: ${(props) =>
      props.enviado? "flex-end" : "flex-start"};
    align-content: ${(props) =>
      props.enviado? "flex-end" : "flex-start"};
    background-color: ${(props) =>
      props.enviado? leerColor(colores.celesteNot) : leerColor(colores.grisNot)};
    border-radius: ${(props) =>
      props.enviado? "16px 0px 16px 16px" : "0px 16px 16px 16px"};
  }

  #mensaje-container h4 {
    color: ${leerColor(colores.celeste)};
    width: auto;
    margin: 16px 16px 0px 16px;
    font-size: 13px;
  }

  #mensaje-container p {
    width: auto;
    margin: 16px 16px 0px 16px;
    text-align: left;
    font-size: 13.5px;
    font-weight: 500;
  }

  #mensaje-container p:nth-child(3) {
    margin: 8px 16px 8px 16px;
    font-size: 12px;
    font-weight: 600;
    color: #999;
  }

  // Archivo Container
  #archivo-container {
    width: fit-content;
    margin: 8px 16px 0px 16px;
    display: flex;
    justify-content: ${(props) =>
      props.enviado? "flex-end" : "flex-start"};
    align-items: center;
    align-content: center;
  }

  // Arhchivo Container Texto
  #archivo-container p {
    width: auto;
    margin: 8px 16px 0px 0px;
    padding: 4px 12px 4px 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    background-color: var(--blanco);
    color: var(--negro);
    border-radius: 8px;
    border: 0.5px solid var(--grisClaro);
  }

  // Archivo Container Texto Ícono
  #archivo-container p span {
    margin: 4px 6px 0px 0px;
    font-size: 18px;
  }

  // Botón Descargar
  #archivo-container a {
    margin: 10px 0px 0px 0px;
    transition: 0.2s all;
  }

  // Botónm Descargar Hover
  #archivo-container a:hover {
    color: var(--azul60);
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    #mensaje-container {
      margin: ${(props) =>
        props.enviado == true ? "0px 0px 0px 40px" : "0px 40px 0px 0px"};
    }
  }
`;

export default Container;
