// Components
import Sidebar from "../../../components/Sidebar";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import { Grid, Col } from "../../../components/Grid";
import FilterSection from "../../../components/FiltersSection";
import TableInfo from "../../../components/TableInfo";
import ModalHistorial from "../../../components/Modals/SolicitudEmision/ModalHistorial";
import ModalCrearUsuario from "../../../components/Modals/Usuarios/ModalCrearUsuario";
import TitlePageSection from "../../../components/TitlePageSection";
import Button from "../../../components/Button";
// Custom Hooks
import useSolicitudEmision from "../../../hooks/useSolicitudEmision";
import useFiltersNew from "../../../hooks/useFiltersNew";
import useHistorialNew from "../../../hooks/useHistorialNew";
import { useDescarga } from "../../../hooks/useDescarga";
import { useAsignacion } from "../../../hooks/useAsignacion";
import { useTable } from "../../../hooks/useTable";
import { useActionButton } from "../../../hooks/useActionButton";
// Filters
import { filtersUsuarios } from "../../../utils/filtersPages";
import { filterUsuariosInitialValues } from "../../../utils/filtersPages";
// Table
import { usuariosTable } from "../../../utils/tableData";
import ModalLoading from "../../../components/Modals/General/ModalLoading";
// Icons
import { BiUserCheck } from "react-icons/bi"
import { HiPlus } from "react-icons/hi";
import { useUsuarios } from "../../../hooks/useUsuarios";
import useModal from "../../../hooks/useModal";
import ModalSuccess from "../../../components/ModalSuccess";
import ModalNuevaClave from "../../../components/Modals/Usuarios/ModalNuevaClave";

const UsuariosRefactor = () => {

  // Modales
  const crearUsuario = useModal()
  const nuevaClaveModal = useModal()
  const modalExito = useModal()

  // Filtros
  const { inputValues, params, handleSearch, handleChange, clearSearch } = useFiltersNew(filterUsuariosInitialValues)
  // Orden 
  const { order, handleTableOrder } = useTable()
  // Usuarios
  const { data, loading, pagination, form, formClave, usuarios } = useUsuarios(params, order, crearUsuario.handleModal, modalExito, nuevaClaveModal)
  // Filtros
  const filters = filtersUsuarios(data)

  // Información para la tabla
  const tableData = usuariosTable(usuarios.editarUsuario, usuarios.abrirModalClave)

  return (
    <>

      <ModalSuccess
        isSuccessPopUpOpen={modalExito.isModalOpen}
        setIsSuccessPopUpOpen={modalExito.handleModal}
        popUpMessage={modalExito.modalMessage}
      />

      <ModalNuevaClave isModalOpen={nuevaClaveModal.isModalOpen} setIsModalOpen={nuevaClaveModal.handleModal} form={formClave} usuario={usuarios.usuarioSeleccionado} />

      <ModalCrearUsuario isModalOpen={crearUsuario.isModalOpen} setIsModalOpen={crearUsuario.handleModal} data={data} form={form} loading={usuarios.loading} editMode={usuarios.editMode} loadingText={usuarios.loadingText} />

      <Sidebar active="usuarios" />
      
      <Wrapper>
        <Inner>
          <Grid colGap={21} rowGap={21} narrow={true}>
            
            <Col desktop={12}>
              <TitlePageSection title="Usuarios">
                <Button backgroundColor={true} onClick={usuarios.abrirModalCrearUsuario}><span><HiPlus /></span>Crear usuario</Button>
              </TitlePageSection>
            </Col>

            <Col desktop={12}>
              <FilterSection section="usuarios" inputs={filters} values={inputValues} params={params} handleChange={handleChange} handleSearch={handleSearch} clearSearch={clearSearch} />
            </Col>
          </Grid>

          <TableInfo columns={tableData} data={data.usuarios} loading={loading} order={handleTableOrder} orderParams={order} pagination={pagination} />
        </Inner>
      </Wrapper>
    </>
  );
};

export default UsuariosRefactor
