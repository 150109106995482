import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { Mensaje, Attachment, Tab } from "./styled";
import { InputBasic, Button } from "../Index";
import { motion } from "framer-motion"
import url from '../../utils/url'
import docVideo from '../../assets/images/docVideo.png'
import docImage from '../../assets/images/doc_cuadrado.png'
import axios from "axios";

function MessagesHistory({ icon, className, direction, persona, fechaHora, mensaje, isLink, showReenviarButton, enviarMail, setDestinatarioMail, getMailAttachments, attachments, showAttachmentsFiles, setAttachmentsList, historialEmailId }) {

  // Estado para reenviar mensaje // 
  const [reenviarMailInputs, setReenviarMailInputs] = useState(false);
  const [showMailAttachments, setShowMailAttachments] = useState(false)
  const [link, setLink] = useState('')
  const [linkText, setLinkText] = useState('Cargando archivo...')
  const [sendingEmails, setSendingEmails] = useState([])

  useEffect(async () => {
    if(isLink){
      let lastValueText = mensaje.indexOf(':')
      let startValueLink = mensaje.indexOf('http')
      const newLink = await getFile(mensaje.substring(startValueLink))
      setLink(newLink)
      setLinkText(mensaje.substring(0, lastValueText))
    }
  }, [])

  const getFile = async (url) => {
    const config = {
        headers: { 'keystorage': 'e152f0a0-5918-5169-a04d-ae4ea1123b69' },
        responseType: 'blob',
    }
    try {
        // obtiene el documento como un blob
        const file = await axios.get(url, config)
        let src;
        var isChrome = navigator.userAgent.indexOf("Chrome") != -1;
        if ( isChrome ){
            src = window.webkitURL.createObjectURL(file.data);
        } else {
            src = window.URL.createObjectURL(file.data);
        }
        return src;

    } catch (error) {
        console.log(error)
    }
  }

  const handleChange = e => {
    let expReg= /^\w+([\.\+\-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/i;
    let pass = e.target.value.split(",");
    let emailData = [];
    pass.forEach( email => {
        emailData.push(email.trim())
    })
    emailData.forEach( e => {
      expReg.test(e) ? setDestinatarioMail(emailData) : setSendingEmails([])
    })
  }

  const showReenviarInputs = () => {
    if(reenviarMailInputs){
      setReenviarMailInputs(false)
    } else {
      setReenviarMailInputs(true)
      setShowMailAttachments(false)
    }
  }

  const showAttachments = () => {
    if(showMailAttachments){
      setShowMailAttachments(false)
    } else {
      setShowMailAttachments(true)
      setReenviarMailInputs(false)
      getMailAttachments()
    }
  }

  const showFile = async (attachment) => {
    const fileExtension = attachment.split('.')
    const extension = fileExtension[fileExtension.length - 1]
    if(extension === "pdf" || extension === "docx" || extension === "xlsx"){
      return docImage
    } else if(extension === "mp4"){
      return docVideo
    } else {
      return `${url}/${attachment.replace(/\\/g, "/").substr(11)}`
    }
  }

  return (
    <Mensaje
      icon={icon}
      className={className}
      direction={direction}
      reenviarMail={showReenviarButton}
      showAttachmentsFiles={showAttachmentsFiles}
    >
      <motion.div initial={{opacity:0}} transition={{duration: 0.5}} animate={{opacity: 1,}}>
      {persona && <h6>{persona}</h6>}
      <span>{fechaHora}</span>
      {isLink ?
        <div>
          <p><a href={link} target="_blank">{linkText}.</a></p>
        </div>
      : 
        <p>{mensaje}</p>
      }
      <div className={'reenviarMail'}>

        <div className="linksContainer">
          {showReenviarButton && !mensaje.startsWith("Reenvio") && <a onClick={showReenviarInputs} className={showMailAttachments ? 'inactive' : 'active'}>Reenviar mail</a>}
          <a onClick={showAttachments} className={reenviarMailInputs ? 'inactive' : 'active'}>Ver archivos</a>
        </div>

        <div className={reenviarMailInputs ? 'inputsReenviarMailVisible' : 'inputsReenviarMailInvisible'} >
          <InputBasic type={"text"} name={"reenviar_mail"} color={"normal"} placeholder={"Reenviar a..."} onChange={handleChange} />
          <span>En caso de reenviar a múltiples mails, recordá separarlos con coma.</span>
          <Button backgroundColor={true} borderColor={false} textColor={false} icon={false} onClick={enviarMail}>Reenviar</Button>
        </div>

        <div className={showMailAttachments ? 'mailAttachmentsVisible' : 'inputsReenviarMailInvisible'} >
          {attachments[historialEmailId] ? 
            attachments[historialEmailId].map(attachment => {
              return(
                <a href={attachment.link} target="_blank"><Attachment src={attachment.image} alt={attachment.link} /></a>
              )
            })
          : 
            <div className={'lds-ring'}><div></div><div></div><div></div><div></div></div>
          }
        </div>

      </div>
      </motion.div>
    </Mensaje>
  );
}

export default MessagesHistory;
