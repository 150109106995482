import React, { useState, useEffect } from "react";
import { Wrapper, InputBasic, InputSelect, InputTextarea, InputFileRec, InputFileButton, InputSelectAPI, InputCalendar, Notification, Menu, Button, Subtitulo, Titulo, PopUp, ButtonDescargar, MessagesHistory, InfoBoard, CustomerProfile, Chat, TabsInfo, Modal, CarouselSlider } from "../components/Index";

import ArrowLeft from "../assets/iconos/arrow-left.svg";
import ArrowRight from "../assets/iconos/arrow-right.svg";
import Download from "../assets/iconos/download.svg";
import Check from "../assets/iconos/tic.svg";
import Warning from "../assets/iconos/warning.svg";
import Cancel from "../assets/iconos/cancel.svg";
import CheckEmit from "../assets/iconos/checkEmit.svg";
import CancelReminder from "../assets/iconos/cancelReminder.svg";
import CalendarPicker from "../assets/iconos/calendarPicker.svg";


const AllComponents = () => {
  return (
    <div className="App">
      <Wrapper>
        <Menu />
        <Notification notificationCampanita={true}></Notification>
        <Titulo textColor={true}>Cargar solicitud de Seguro</Titulo>
        <Subtitulo>Datos del cliente</Subtitulo>
        <Button
          backgroundColor={true}
          borderColor={false}
          textColor={false}
          icon={false}
        >
          Boton Principal
        </Button>
        <Button
          backgroundColor={false}
          borderColor={true}
          textColor={true}
          icon={false}
        >
          Atr&aacute;s
        </Button>
        <Button
          backgroundColor={false}
          borderColor={true}
          textColor={true}
          icon={ArrowLeft}
          direction={"before"}
        >
          Volver
        </Button>
        <Button
          backgroundColor={false}
          borderColor={true}
          textColor={true}
          icon={ArrowRight}
          direction={"after"}
        >
          Ver adjuntos
        </Button>
        <Button
          backgroundColor={false}
          borderColor={true}
          textColor={true}
          icon={Download}
          direction={"after"}
        >
          Descargar
        </Button>
        <InputBasic
          type={"text"}
          name={"Nombre"}
          labelName={"Nombre"}
          placeholder={"Nombre"}
          onChange={null}
        />
        <InputSelect name={"Compania"} labelName={"Compañia"} onChange={null} />
        <InputSelectAPI
          name={"Compania"}
          labelName={"Compañia"}
          onChange={null}
        />
        <InputCalendar
          type={"date"}
          name={"Programar"}
          labelName={"Programar"}
          onChange={null}
          readOnly={false}
          calendarPicker={CalendarPicker}
          cancelReminder={CancelReminder}
        />
        <InputTextarea
          type={"text"}
          name={"Observaciones"}
          labelName={"Observaciones"}
          placeholder={"Observaciones"}
          onChange={null}
        />
        <InputFileRec
          type={"file"}
          name={"Nombre"}
          required={false}
        />
        <InputFileButton
          type={"file"}
          name={"Archivos"}
          required={false}
        />
        <PopUp
          icono={Check}
          backgroundColor={true}
          mensaje={"Poliza cargada"}
        />
        <PopUp
          icono={Warning}
          backgroundColor={false}
          mensaje={"Hubo un problema"}
        />
        <ButtonDescargar
          backgroundColor={false}
          borderColor={true}
          textColor={true}
          nPoliza={"02558445"}
          premio={"$120.000"}
        />
        <MessagesHistory 
          className={"falta"} 
          icon={false}
          direction={'right'}
          persona={'Arias'}
          fechaHora={'08.04 - 12:56 pm'}
          mensaje={'Falta documentación'}
        />
        <MessagesHistory 
          className={"rechazada"} 
          icon={Cancel}
          direction={'right'}
          persona={'Arias'}
          fechaHora={'08.04 - 12:56 pm'}
          mensaje={'Solicitud rechazada'}
        />
        <MessagesHistory 
          className={"emitida"} 
          icon={CheckEmit}
          direction={'right'}
          persona={'Arias'}
          fechaHora={'08.04 - 12:56 pm'}
          mensaje={'Solicitud emitida'}
        />
        <MessagesHistory 
          className={"enviada"} 
          icon={false}
          direction={'left'}
          persona={'Vendedor Vega'}
          fechaHora={'08.04 - 01:25 pm'}
          mensaje={'Solicitud de emisión enviada'}
        />
        <InfoBoard borderTop={true} invisible={false} />
        <CustomerProfile />
        <Chat />
        <TabsInfo hayTitulo={true} hayBotones={true} />
        <Modal>
          <Titulo>Titulo del Modal</Titulo>
        </Modal>
      </Wrapper>
    </div>
  );
};

export default AllComponents;
