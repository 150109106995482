import React, { useState, useEffect } from "react";
import { InputSelect, InputCalendar, ErrorInput, Subtitulo, Grid, Col, Button, Modal } from "../../Index";
import NewInputBasic from "../../NewInputBasic"
import NewInputSelect from "../../NewInputSelect"
import NewInputCalendar from "../../NewInputCalendar";
import { useSolicitudContext } from "../../../context/SolicitudContext";
import { useAsegurado } from "../../../hooks/useAsegurado";
import useModal from "../../../hooks/useModal"
import { useParams, useLocation } from "react-router-dom";

import { generos, condicion_fiscal } from "../../../constants/data"
import { BuscarCliente, Icon, RemoveContainer, ModalClienteEncontrado, Container } from './styled';

import { ImSearch } from "react-icons/im";
import { FiTrash2, FiUser } from "react-icons/fi";

import dayjs from "dayjs"
import axios from "axios";
import useAutocomplete from "../../../hooks/useAutocomplete";
import { MdCleaningServices } from "react-icons/md";
import Subtitle from "../../Subtitle";
import NewButton from "../../NewButton";
import NewTitle from "../../NewTitle";
import InputFile from "../../InputFile";

const AseguradoForm = () => {

    const { id } = useParams()
    const location = useLocation();
    const tipoSolicitud = new URLSearchParams(location.search).get("tipoSolicitud");

    const { asegurado } = useSolicitudContext()
    const { values, formErrors, handleChange, handleChangeExternal, resetForm, completeForm, data, completeField } = asegurado
    const { provincias, localidades, profesiones, nacionalidades, estadosCivil } = data

    const { isModalOpen, handleModal } = useModal()

    // Búsqueda de asegurado
    const { buscarAsegurado, handleChangeDniAsegurado, removerDatosAsegurado, dniEncontrado, dniAsegurado } = useAsegurado(values.dni_asegurado, completeForm, handleModal, id)

    const [codigosPostales, setCodigosPostales] = useState([])

    const autocomplete = useAutocomplete(codigosPostales, completeField)

    const cleanAutocomplete = (value) => {
        completeField('codigo_postal_asegurado', value)
        autocomplete.setAutocomplete([])
    }

    useEffect(() => {
        const obtenerCodigoPostal = async () => {
            if(values.provincia_asegurado && values.localidad_asegurado){
                try {
                    setCodigosPostales([])
                    const code = await axios.get(`https://get-arg-cp-api.vercel.app/postal_code?provincia=${values.provincia_asegurado}&&localidad=${values.localidad_asegurado}`)
                    if(code.data.type !== "array") {
                        setCodigosPostales([code.data.postal_code])
                    } else {
                        setCodigosPostales(code.data.postal_code)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        }
        obtenerCodigoPostal()
    }, [values.provincia_asegurado, values.localidad_asegurado])

    const tipoDePersona = [
        {
            label: "Persona Física",
            value: "Persona Física",
            key: 1,
        },
        {
            label: "Persona Jurídica",
            value: "Persona Jurídica",
            key: 2,
        },
    ]

    return (
        <Container>
            {isModalOpen ? 
                <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal} >
                    <ModalClienteEncontrado>
                    <div className="header-container">
                    <NewTitle>Datos del cliente cargados</NewTitle>
                    <p>El DNI <strong>{values.dni_asegurado}</strong> ya existe en la base de datos. El formulario se autocompleto con los datos existentes del cliente.</p>
                    </div>
                    <hr />
                    <div className={"actionButtonsCont"}>
                        <div className={'buttonsCont'}>
                            <NewButton backgroundColor={true} borderColor={false} textColor={false} icon={false} onClick={handleModal}> Aceptar</NewButton>
                        </div>
                    </div>
                    </ModalClienteEncontrado>  
                </Modal>
                : 
                null
            }

            <Grid colGap={21} rowGap={21} narrow={true}>
                <Col desktop={12}>
                <Subtitle
                    title="Asegurado"
                    description="Completa los datos del asegurado"
                    icon={<FiUser />}
                />
                </Col>
            </Grid>

            {tipoSolicitud == 1 || tipoSolicitud == 2 ?
            <Grid colGap={21} rowGap={21} narrow={true}>       
                <Col desktop={4}>
                    <NewInputBasic type={"number"} name={"dni_asegurado"} labelName={"DNI"} color={formErrors.dni_asegurado ? "error" : "normal"} placeholder={"19556234"} onChange={handleChange} value={values.dni_asegurado} disabled={false} />
                    {formErrors.dni_asegurado && <ErrorInput>{formErrors.dni_asegurado}</ErrorInput>}
                 </Col>
                 <Col desktop={4}>
                    <NewInputBasic type={"number"} name={"cuit_asegurado"} labelName={"CUIT / CUIL"} color={formErrors.cuit_asegurado ? "error" : "normal"} placeholder={"20195562341"} onChange={handleChange} value={values.cuit_asegurado} disabled={false} />
                    {formErrors.cuit_asegurado && <ErrorInput>{formErrors.cuit_asegurado}</ErrorInput>}
                </Col>
                <Col desktop={4}>
                    <NewInputBasic type={"text"} name={"nombre_asegurado"} labelName={"Nombre"} color={formErrors.nombre ? "error" : "normal"} placeholder={"Nombre"} onChange={handleChange} value={values.nombre_asegurado} disabled={false} />
                    {formErrors.nombre_asegurado && <ErrorInput>{formErrors.nombre_asegurado}</ErrorInput>}
                </Col>
                <Col desktop={4}>
                    <NewInputBasic type={"text"} name={"apellido_asegurado"} labelName={"Apellido"} color={formErrors.apellido_asegurado ? "error" : "normal"} placeholder={"Apellido"} onChange={handleChange} value={values.apellido_asegurado} disabled={false} />
                    {formErrors.apellido_asegurado && <ErrorInput>{formErrors.apellido_asegurado}</ErrorInput>}
                </Col>
                <Col desktop={4}>
                    <NewInputCalendar type={"date"} name={"nacimiento_asegurado"} labelName={"Fecha de nacimiento"} color={formErrors.nacimiento_asegurado ? "error" : "normal"} onChange={handleChange} readOnly={false} value={values.nacimiento_asegurado} disabled={false} /> 
                    {formErrors.nacimiento_asegurado && <ErrorInput>{formErrors.nacimiento_asegurado}</ErrorInput>}
                </Col>
                <Col desktop={4}>
                    <NewInputSelect name={"genero_asegurado"} labelName={"Género"} color={formErrors.genero_asegurado ? "error" : "normal"} placeholder={"Género"} onChange={handleChange} options={generos} value={values.genero_asegurado} disabled={false} />
                    {formErrors.genero_asegurado && <ErrorInput>{formErrors.genero_asegurado}</ErrorInput>}
                </Col>
                <Col desktop={3}>
                    <NewInputSelect name={"tipo_persona_asegurada"} labelName={"Tipo de persona"} color={formErrors.tipo_persona_asegurado ? "error" : "normal"} placeholder={"Tipo de persona"} onChange={handleChange} options={tipoDePersona} value={values.tipo_persona_asegurada} />
                    {formErrors.tipo_persona_asegurada && <ErrorInput>{formErrors.tipo_persona_asegurada}</ErrorInput>}
                </Col>
                <Col desktop={3}>
                    <NewInputSelect name={"condicion_fiscal_asegurado"} labelName={"Código Fiscal"} color={formErrors.condicion_fiscal_asegurado ? "error" : "normal"} placeholder={"Código Fiscal"} onChange={handleChange} options={condicion_fiscal} value={values.condicion_fiscal_asegurado} />
                    {formErrors.condicion_fiscal_asegurado && <ErrorInput>{formErrors.condicion_fiscal_asegurado}</ErrorInput>}
                </Col>
                <Col desktop={3}>
                    <NewInputSelect name={"profesion_asegurado"} labelName={"Profesión"} color={formErrors.profesion_asegurado ? "error" : "normal"} placeholder={"Profesión"} onChange={handleChange} options={profesiones} value={values.profesion_asegurado} />
                    {formErrors.profesion_asegurado && <ErrorInput>{formErrors.profesion_asegurado}</ErrorInput>}
                </Col>
                <Col desktop={3}>
                    <NewInputSelect name={"estado_civil_asegurado"} labelName={"Estado Cívil"} color={formErrors.estado_civil_asegurado ? "error" : "normal"} placeholder={"Estado Cívil"} onChange={handleChange} options={estadosCivil} value={values.estado_civil_asegurado} />
                    {formErrors.estado_civil_asegurado && <ErrorInput>{formErrors.estado_civil_asegurado}</ErrorInput>}
                </Col>
                <Col desktop={4}>
                    <div><NewInputSelect name={"nacionalidad_asegurado"} labelName={"Nacionalidad"} color={formErrors.nacionalidad_asegurado ? "error" : "normal"} placeholder={"Nacionalidad"} onChange={handleChange} options={nacionalidades} value={values.nacionalidad_asegurado} disabled={false} />
                    {formErrors.nacionalidad_asegurado && <ErrorInput>{formErrors.nacionalidad_asegurado}</ErrorInput>}</div>
                </Col>
                <Col desktop={4}>
                    <div><NewInputSelect name={"lugar_nacimiento_asegurado"} labelName={"Lugar de nacimiento"} color={formErrors.lugar_nacimiento_asegurado ? "error" : "normal"} placeholder={"Lugar de nacimiento"} onChange={handleChange} options={nacionalidades} value={values.lugar_nacimiento_asegurado} disabled={false} />
                    {formErrors.lugar_nacimiento_asegurado && <ErrorInput>{formErrors.lugar_nacimiento_asegurado}</ErrorInput>}</div>
                </Col>
                <Col desktop={4}>
                    <NewInputSelect name={"provincia_asegurado"} labelName={"Provincia"} color={formErrors.provincia_asegurado ? "error" : "normal"} placeholder={"Provincia"}  onChange={handleChange} options={provincias} value={values.provincia_asegurado} />
                    {formErrors.provincia_asegurado && <ErrorInput>{formErrors.provincia_asegurado}</ErrorInput>}
                </Col>
                <Col desktop={4}>
                    <NewInputSelect name={"localidad_asegurado"} labelName={"Localidad"} color={formErrors.localidad_asegurado ? "error" : "normal"} placeholder={"Localidad"} onChange={handleChange} options={localidades} value={values.localidad_asegurado} />
                    {formErrors.localidad_asegurado && <ErrorInput>{formErrors.localidad_asegurado}</ErrorInput>}
                </Col>

                <Col desktop={2}>
                        <NewInputBasic type={"text"} name={"codigo_postal_asegurado"} labelName={"Código Postal"} color={formErrors.codigo_postal_asegurado ? "error" : "normal"} placeholder={"1678"} value={values.codigo_postal_asegurado} 
                            onChange={(e) => {
                                handleChange(e);
                                autocomplete.handleChangeAutocomplete(e.target.value);
                            }}
                            onKeyDown={autocomplete.changeAutocompleteSuggestion}
                            autocomplete={autocomplete.autocomplete}
                            setAutocomplete={(value) => cleanAutocomplete(value)}
                            autocompleteActive={autocomplete.autocompleteActiveSuggestionIndex}
                            cleanAutocomplete={autocomplete.setAutocomplete}
                        />
                        {formErrors.codigo_postal_asegurado && <ErrorInput>{formErrors.codigo_postal_asegurado}</ErrorInput>}
                </Col>
                <Col desktop={4}>
                    <NewInputBasic type={"text"} name={"direccion_calle_asegurado"} labelName={"Calle"} color={formErrors.direccion_calle_asegurado ? "error" : "normal"} placeholder={"Av. Rivadavia"} onChange={handleChange} value={values.direccion_calle_asegurado} />
                    {formErrors.direccion_calle_asegurado && <ErrorInput>{formErrors.direccion_calle_asegurado}</ErrorInput>}
                </Col>
                <Col desktop={2}>
                    <NewInputBasic type={"number"} name={"direccion_numero_asegurado"} labelName={"Número"} color={formErrors.direccion_numero_asegurado ? "error" : "normal"} placeholder={"2727"} onChange={handleChange} value={values.direccion_numero_asegurado} />
                    {formErrors.direccion_numero_asegurado && <ErrorInput>{formErrors.direccion_numero_asegurado}</ErrorInput>}
                </Col>
                <Col desktop={2}>
                    <NewInputBasic type={"number"} name={"direccion_piso_asegurado"} labelName={"Piso"} color={formErrors.direccion_piso_asegurado ? "error" : "normal"} placeholder={"4"} onChange={handleChange} value={values.direccion_piso_asegurado} />
                    {formErrors.direccion_piso_asegurado && <ErrorInput>{formErrors.direccion_piso_asegurado}</ErrorInput>}
                </Col>
                <Col desktop={2}>
                    <NewInputBasic type={"text"} name={"direccion_dpto_asegurado"} labelName={"Depto"} color={formErrors.direccion_dpto_asegurado ? "error" : "normal"} placeholder={"B"} onChange={handleChange} value={values.direccion_dpto_asegurado} />
                    {formErrors.direccion_dpto_asegurado && <ErrorInput>{formErrors.direccion_dpto_asegurado}</ErrorInput>}
                </Col>
                <Col desktop={4}>
                    <NewInputBasic type={"text"} name={"email_asegurado"} labelName={"Correo Electrónico"} color={formErrors.email_asegurado ? "error" : "normal"} placeholder={"info@segurobici.com.ar"} onChange={handleChange} value={values.email_asegurado} />
                    {formErrors.email_asegurado && <ErrorInput>{formErrors.email_asegurado}</ErrorInput>}
                </Col>
                <Col desktop={2}>
                    <NewInputBasic type={"number"} name={"codigo_area_asegurado"} labelName={"Cod. Area"} color={formErrors.codigo_area_asegurado ? "error" : "normal"} placeholder={"11"} onChange={handleChange} value={values.codigo_area_asegurado} />
                    {formErrors.codigo_area_asegurado && <ErrorInput>{formErrors.codigo_area_asegurado}</ErrorInput>}
                </Col>
                <Col desktop={2}>
                    <NewInputBasic type={"number"} name={"telefono_asegurado"} labelName={"Teléfono"} color={formErrors.telefono_asegurado ? "error" : "normal"} placeholder={"41256389"} onChange={handleChange} value={values.telefono_asegurado} />
                    {formErrors.telefono_asegurado && <ErrorInput>{formErrors.telefono_asegurado}</ErrorInput>}
                </Col>
                {values.tipo_persona_asegurada === "Persona Jurídica" ?
                <Col desktop={4}>
                <InputFile
                  name={"archivo_estatuto_empresa_asegurado"}
                  labelName={"Estatuto empresa"}
                  required={false}
                  onChange={handleChangeExternal}
                  file={values.archivo_estatuto_empresa_asegurado}
                  text={"Adjuntar estatuto empresa"}
                  accept={".pdf, .jpg, .png"}
                />
                {formErrors.archivo_estatuto_empresa_asegurado && (
                  <ErrorInput>{formErrors.archivo_estatuto_empresa_asegurado}</ErrorInput>
                )}
              </Col>
              : null}
            </Grid>
            : null}

            {tipoSolicitud == 3 ?
            <Grid colGap={21} rowGap={21} narrow={true}>
                <Col desktop={4}>
                    <NewInputBasic type={"number"} name={"dni_asegurado"} labelName={"DNI"} color={formErrors.dni_asegurado ? "error" : "normal"} placeholder={"19556234"} onChange={handleChange} value={values.dni_asegurado} disabled={false} />
                    {formErrors.dni_asegurado && <ErrorInput>{formErrors.dni_asegurado}</ErrorInput>}
                 </Col>
                <Col desktop={4}>
                    <NewInputBasic type={"text"} name={"nombre_asegurado"} labelName={"Nombre"} color={formErrors.nombre ? "error" : "normal"} placeholder={"Nombre"} onChange={handleChange} value={values.nombre_asegurado} disabled={false} />
                    {formErrors.nombre_asegurado && <ErrorInput>{formErrors.nombre_asegurado}</ErrorInput>}
                </Col>
                <Col desktop={4}>
                    <NewInputBasic type={"text"} name={"apellido_asegurado"} labelName={"Apellido"} color={formErrors.apellido_asegurado ? "error" : "normal"} placeholder={"Apellido"} onChange={handleChange} value={values.apellido_asegurado} disabled={false} />
                    {formErrors.apellido_asegurado && <ErrorInput>{formErrors.apellido_asegurado}</ErrorInput>}
                </Col>
                <Col desktop={4}>
                    <NewInputBasic type={"text"} name={"email_asegurado"} labelName={"Correo Electrónico"} color={formErrors.email_asegurado ? "error" : "normal"} placeholder={"info@segurobici.com.ar"} onChange={handleChange} value={values.email_asegurado} />
                    {formErrors.email_asegurado && <ErrorInput>{formErrors.email_asegurado}</ErrorInput>}
                </Col>
                <Col desktop={2}>
                    <NewInputBasic type={"number"} name={"codigo_area_asegurado"} labelName={"Cod. Area"} color={formErrors.codigo_area_asegurado ? "error" : "normal"} placeholder={"11"} onChange={handleChange} value={values.codigo_area_asegurado} />
                    {formErrors.codigo_area_asegurado && <ErrorInput>{formErrors.codigo_area_asegurado}</ErrorInput>}
                </Col>
                <Col desktop={2}>
                    <NewInputBasic type={"number"} name={"telefono_asegurado"} labelName={"Teléfono"} color={formErrors.telefono_asegurado ? "error" : "normal"} placeholder={"41256389"} onChange={handleChange} value={values.telefono_asegurado} />
                    {formErrors.telefono_asegurado && <ErrorInput>{formErrors.telefono_asegurado}</ErrorInput>}
                </Col>
            </Grid>
            : null}
        </Container>
    )
}

export default AseguradoForm

/*

                {values.tipo_persona_asegurada === "Persona Jurídica" ?
                <Col desktop={2}>
                    <NewInputBasic type={"number"} name={"cuit_empresa_segurado"} labelName={"CUIT"} color={formErrors.cuit_empresa_segurado ? "error" : "normal"} placeholder={"20195562341"} onChange={handleChange} value={values.cuit_empresa_segurado} disabled={false} />
                    {formErrors.cuit_empresa_segurado && <ErrorInput>{formErrors.cuit_empresa_segurado}</ErrorInput>}
                </Col>
                : null}

            {!id &&
            
                <BuscarCliente>
                    <Grid colGap={21} rowGap={21} narrow={true}>
                        <Col desktop={3}>
                            <NewInputBasic type={"text"} name={"buscar_cliente"} labelName={"Buscar cliente por DNI"} placeholder={"Ingresar DNI"} maxLength={8} onChange={handleChangeDniAsegurado} value={dniAsegurado ? dniAsegurado : ""} />
                        </Col>
                        <Col desktop={6}>
                            <div className="container-botones">
                                <Button backgroundColor={true} borderColor={false} textColor={false} onClick={() => buscarAsegurado(dniAsegurado, completeForm)}><span><ImSearch></ImSearch></span>Buscar</Button>
                                {dniEncontrado && <Button backgroundColor={false} borderColor={true} textColor={true} onClick={() => removerDatosAsegurado(resetForm)}><span><MdCleaningServices /></span>Limpiar búsqueda</Button>}
                            </div>
                        </Col>
                    </Grid>
                </BuscarCliente>
            }

*/