import React, { useEffect, useState } from "react";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import TitlePageSection from "../../../components/TitlePageSection";
import Sidebar from "../../../components/Sidebar";
import NewButton from "../../../components/NewButton";
import { Container } from "./styled";
import axiosRequest from "../../../utils/axiosConfig";
import NewTabs from "../../../components/NewTabs";
import { useTabs } from "../../../hooks/useTabs";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import SpinnerLoading from "../../../components/SpinnerLoading";
import { Col, Grid } from "../../../components/Grid";
import {
  FiArrowRight,
  FiArrowUpRight,
  FiAward,
  FiBarChart,
  FiDollarSign,
  FiEdit,
  FiRefreshCcw,
  FiStar,
  FiUser,
} from "react-icons/fi";
import NewTable from "../../../components/NewTable";
import { useLoading } from "../../../hooks/useLoading";
import Subtitle from "../../../components/Subtitle";
import { PREMIOS_LABELS } from "../../../constants/labels";
import NewInputSelect from "../../../components/NewInputSelect";
import { meses, anios } from "../../../constants/data";
import { MdStar } from "react-icons/md";
import PieChart from "../../../components/PieChart";
import ModalCargarObjetivosPremios from "../../../components/Modals/Premios/ModalCargarObjetivos";
import useModal from "../../../hooks/useModal";
import { objetivosVendedores } from "../../../utils/tableData";
import { useTable } from "../../../hooks/useTable";
import BarChart from "../../../components/BarChart";
import IconButton from "../../../components/IconButton";
import { BiEdit } from "react-icons/bi";
import dayjs from "dayjs";
import LineChart from "../../../components/LineChart";
import formatSelectData from "../../../utils/formatSelectData";

const Premios = () => {
  const [vendedores, setVendedores] = useState([]);
  const [premios, setPremios] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const { handleTab, tabSelected } = useTabs();
  const { loading, setLoading } = useLoading();
  const loadingObjetivos = useLoading()
  const [mes, setMes] = useState(new Date().getMonth());
  const [anio, setAnio] = useState(new Date().getFullYear());
  const [total, setTotal] = useState(null);
  const [dataPie, setDataPie] = useState({});
  const [objetivos, setObjetivos] = useState({})
  const { order, handleTableOrder } = useTable();
  const [prima, setPrima] = useState([])
  const [operaciones, setOperaciones] = useState([])
  const [totalPremios, setTotalPremios] = useState(null)
  const [totalPremiosAnual, setTotalPremiosAnual] = useState([])
  const [ordenVendededores, setOrdenVendedores] = useState("Nombre")
  const [dataPrimaDiariaProducto, setDataPrimaDiariaProducto] = useState([])
  const [dataOperacionesDiariasProducto, setDataOperacionesDiariasProducto] = useState([])
  const [productos, setProductos] = useState([])
  const [productoSeleccionado, setProductoSeleccionado] = useState(1)

  useEffect(() => {
    const getProductos = async () => {
      try {
        const productos = await axiosRequest.get('/premios/productos')
        const productosList = formatSelectData(productos.data, 'numero', 'descripcion', 'numero')
        setProductos(productosList)
      } catch (error) {
        console.log(error)
      }
    }
    getProductos()
  }, [])

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      loadingObjetivos.setLoading(true)
      if (mes && productos.length > 0) {
        try {
          setObjetivos([])
          const calculo = await axiosRequest.get(
            `/premios/calculo/${parseInt(mes) + 1}`
          );
          const grupos = await axiosRequest.get(
            `/premios/grupos/${parseInt(mes) + 1}`
          );

          const totalPremiosMes = await axiosRequest.get(`/premios/total`)
          console.log(totalPremiosMes)

          const total = await axiosRequest.get(
            `/premios/vendedores_total/${parseInt(mes) + 1}/${productoSeleccionado}`
          );

          console.log(total)

          const diasMes = new Date("2024", parseInt(mes), 0).getDate();
          const labelsDiasMes = Array.from({ length: diasMes + 1}).map((_, index) => {
            const day = index + 1 < 10 ? `0${index + 1}` : index
            const month = parseInt(mes) + 1 < 10 ? `0${parseInt(mes) + 1}` : mes
            return `${day}/${month}`
          })

          const objetivos = await axiosRequest.get(`/premios/objetivos/${parseInt(mes) + 1}`)
          const historialAnual = await axiosRequest.get(`/premios/historial_anual`)

          const labels = [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
          ];

          const productoLabel = productos.find(item => item.value == productoSeleccionado)?.label

          const productosEmitidosPrima = labelsDiasMes.map((label, index) => {
            const find = total.data.productos_emitidos.find((item) => {
              return parseInt(dayjs(item.fechaucac).format("D")) === index + 1;
            });
            if (find) {
              return find.prima;
            } else {
              return 0;
            }
          });
          const productosEmitidosOperaciones = labelsDiasMes.map((label, index) => {
            const find = total.data.productos_emitidos.find((item) => {
              return parseInt(dayjs(item.fechaucac).format("D")) === index + 1;
            });
            if (find) {
              return find.operaciones;
            } else {
              return 0;
            }
          });

          const emisionesPrima = labelsDiasMes.map((label, index) => {
            const find = total.data.emisiones.find((item) => {
              return parseInt(dayjs(item.fechaucac).format("D")) === index + 1;
            });
            if (find) {
              return find.prima;
            } else {
              return 0;
            }
          });
          const emisionesOperaciones = labelsDiasMes.map((label, index) => {
            const find = total.data.emisiones.find((item) => {
              return parseInt(dayjs(item.fechaucac).format("D")) === index + 1;
            });
            if (find) {
              return find.operaciones;
            } else {
              return 0;
            }
          });


          const dataProductosOperaciones = {
            labels: labelsDiasMes,
            datasets: [
              {
                label: productoLabel,
                data: productosEmitidosOperaciones,
                fill: false,
                borderColor: "rgb(0, 255, 179)",
                tension: 0.001,
              },
              {
                label: "Operaciones",
                data: emisionesOperaciones,
                fill: false,
                borderColor: "rgb(26, 86, 255)",
                tension: 0.001,
              },
            ],
          };

          const dataProductosEmitidos = {
            labels: labelsDiasMes,
            datasets: [
              {
                label: productoLabel,
                data: productosEmitidosPrima,
                fill: false,
                borderColor: "rgb(0, 255, 179)",
                tension: 0.001,
              },
              {
                label: "Prima",
                data: emisionesPrima,
                fill: false,
                borderColor: "rgb(26, 86, 255)",
                tension: 0.001,
              },
            ],
          };

          const dataTotalPremios = {
            labels,
            datasets: [
              {
                label: "Total a pagar",
                data: totalPremiosMes.data.map((item) => Math.round(parseInt(item))),
                backgroundColor: "#1A56FF",
              },
            ],
          };
          const dataPrima = {
            labels,
            datasets: [
              {
                label: "Objetivo prima",
                data: historialAnual.data.map((item) => item.objetivo_prima),
                backgroundColor: "#c4d4ff",
              },
              {
                label: "Prima obtenida",
                data: historialAnual.data.map((item) => Math.round(item.prima)),
                backgroundColor: "#1A56FF",
              },
            ],
          };
          const dataOperaciones = {
            labels,
            datasets: [
              {
                label: "Objetivo operaciones",
                data: historialAnual.data.map((item) => item.objetivo_operaciones),
                backgroundColor: "#c4d4ff",
              },
              {
                label: "Operaciones realizadas",
                data: historialAnual.data.map((item) => item.operaciones),
                backgroundColor: "#1A56FF",
              },
            ],
          };

          console.log(objetivos)

          const dataPie = {
            labels: total.data.forma_pago.map((item) => item.forma_pago),
            datasets: [
              {
                label: "Operaciones emitidas: ",
                data: total.data.forma_pago.map((item) => item.operaciones),
                backgroundColor: [
                  "rgb(26, 86, 255)",
                  "rgb(116, 255, 227)",
                  "rgb(125, 188, 255)",
                ],
                hoverOffset: 4,
              },
            ],
          };

          console.log(calculo.data)
          const totalPremios = calculo.data.reduce((total, item) => { return total += item.total}, 0)

          setDataOperacionesDiariasProducto(dataProductosOperaciones)
          setDataPrimaDiariaProducto(dataProductosEmitidos)
          setTotalPremios(totalPremios)
          setTotalPremiosAnual(dataTotalPremios)
          setDataPie(dataPie);
          setPrima(dataPrima);
          setOperaciones(dataOperaciones);
          console.log(calculo.data)
          setVendedores(calculo.data);
          setTotal(total.data);
          setGrupos(grupos.data);
          setObjetivos(objetivos.data)
          setLoading(false);
          loadingObjetivos.setLoading(false)
        } catch (error) {
          console.log(error);
        }
      }
    };
    getData();
  }, [mes, productoSeleccionado, productos]);

  const tabs = [
    {
      name: "Vendedores",
    },
    {
      name: "Grupos",
    },
    {
      name: "Estadísticas",
    },
    {
      name: "Objetivos",
    },
  ];

  const formatter = new Intl.NumberFormat("es-ES", {});

  const actualizarCalculoMes = async () => {
    setLoading(true);
    try {
      const calculo = await axiosRequest.get(
        `/premios/actualizar_calculo/${parseInt(mes) + 1}`
      );
      setVendedores(calculo.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const objetivosModal = useModal();
  const tableData = objetivosVendedores()

  const refreshObjetivos = async () => {
    try {
      loadingObjetivos.setLoading(true)
      setObjetivos([])
      const objetivos = await axiosRequest.get(`/premios/objetivos/${parseInt(mes) + 1}`)
      setObjetivos(objetivos.data)
      loadingObjetivos.setLoading(false)
    } catch (error) {
      console.log(error)
      loadingObjetivos.setLoading(false)
    }
  }

  const reordenarVendedores = (orden) => {
    if(orden === "Nombre"){
      const ordenar = vendedores.sort((a, b) => a.vendedor.localeCompare(b.vendedor));
      setVendedores(ordenar)
    }
    if(orden === "Total"){
      const ordenar = vendedores.sort((a, b) => b.total - a.total)
      setVendedores(ordenar)
    }
    if(orden === "Grupo"){
      const sinGrupo = [...vendedores].filter(vendedor => !vendedor.id_grupo)
      const ordenar = [...vendedores].filter(vendedor => vendedor.id_grupo).sort((a, b) => a.nombre_grupo.localeCompare(b.nombre_grupo));
      setVendedores([...ordenar, ...sinGrupo])
    }
    setOrdenVendedores(orden)
  }

  return (
    <div>
      {objetivosModal.isModalOpen ? (
          <ModalCargarObjetivosPremios
            modal={objetivosModal}
            refreshData={refreshObjetivos}
          />
        ) : null}
      <Sidebar />
      <Wrapper>
        <Inner>
          <Container>
            <TitlePageSection
              title="Premios"
              description="Consultá y crea premios para los vendedores"
            >
                <NewButton backgroundColor={true} onClick={() => objetivosModal.setIsModalOpen(true)}
                >Cargar objetivos</NewButton>
              <Link to="/CrearGrupo">
                <NewButton backgroundColor={false} textColor={true} borderColor={true}>Crear grupo</NewButton>
              </Link>
            </TitlePageSection>
            {!loading ? (
              <NewTabs
                tabs={tabs}
                tabSelected={tabSelected}
                handleTab={handleTab}
              />
            ) : null}
            {tabSelected === 0 ? (
              <>
                <Grid colGap={21} rowGap={21} narrow={true}>
                  <Col desktop={2}>
                    <NewInputSelect
                      name={"mes"}
                      labelName={"Mes"}
                      onChange={(e) => setMes(e.target.value)}
                      placeholder={"Mes"}
                      options={meses}
                      value={mes}
                    />
                  </Col>
                  {/*
                  <Col desktop={2}>
                    <NewInputSelect
                      name={"anio"}
                      labelName={"Año"}
                      onChange={(e) => setAnio(e.target.value)}
                      placeholder={"Año"}
                      options={anios}
                      value={anio}
                    />
                  </Col>
                  */}
                </Grid>
                <hr />

                {!loading ? (
                  <>
                  <Subtitle
                    icon={<FiDollarSign />}
                    title={`Total a pagar en ${meses
                      .find((m) => m.value == mes)
                      ?.label.toLocaleLowerCase()}`}
                    description={
                      "Valor a pagar de la sumatoria de todos los premios obtenidos por los vendedores"
                    }
                    disableDivider={true}
                  />
                  {totalPremios ?
                  <Grid colGap={21} rowGap={21} narrow={true}>
                  <Col desktop={3}>
                   <div className={`total-container total`}>
                      <span>Total a pagar</span>
                      <h2>${formatter.format(totalPremios)}</h2>
                    </div>
                  </Col>
                  </Grid>: null}
                  <hr />
                    <div className="actualizacion-container">
                      <div className="premios-botones-container">
                        <h4>
                          Resultados obtenidos para el mes de{" "}
                          {meses
                            .find((m) => m.value == mes)
                            ?.label.toLowerCase()}
                        </h4>
                        <p>
                          La actualización automática del cálculo de premios del
                          mes se realiza todos los días a la medianoche.
                        </p>
                      </div>
                      <div className="buttons-cont">
                      <NewButton
                        borderColor={true}
                        textColor={true}
                        onClick={actualizarCalculoMes}
                      >
                        <FiRefreshCcw />
                        Actualizar
                      </NewButton>
                      </div>
                    </div>
                    <Grid colGap={21} rowGap={21} narrow={true}>
                    <Col desktop={2}>
                    <NewInputSelect
                        name={"orden"}
                        labelName={"Ordenar por"}
                        onChange={(e) => reordenarVendedores(e.target.value)}
                        placeholder={"Ordenar por"}
                        options={[{ name: "grupo", label: "Grupo", key: 1 }, { name: "alfabeticamente", label: "Nombre", key: 2 }, { name: "total", label: "Total", key: 3 }]}
                        value={ordenVendededores}
                    />
                    </Col>
                    </Grid>

                    <Grid colGap={21} rowGap={21} narrow={true}>
                      {vendedores.map((vendedor) => {
                        const grupo = grupos.find(item => item.id_vendedores_grupo === vendedor.id_grupo)
                        return (
                          <Col desktop={12}>
                            <div className="vendedor-container-2">
                              <div className="vendedor-info-container">
                                <div className="info-cont">
                                  
                                  <span className="userIcon">
                                    <FiUser />
                                  </span>
                                  {grupo ?
                                  <Link
                                  to={{
                                    pathname: `/Premios/Vendedores/Grupos/${grupo.id_vendedores_grupo}`,
                                    state: grupo,
                                  }}
                                >
                                  <span className="grupo">
                                    {vendedor.nombre_grupo}
                                  </span></Link>
                                  : null}
                                  <h3>{vendedor.vendedor}</h3>

                                  <span>
                                    Total: ${formatter.format(vendedor.total)}
                                  </span>
                                </div>
                                <div className="info-cont">
                                  <Link
                                    to={{
                                      pathname: `/Premio/Vendedor/${vendedor.id_vendedor}`,
                                      state: {
                                        nombre: vendedor.vendedor,
                                        data: vendedor,
                                        mes: mes,
                                      },
                                    }}
                                  >
                                    <NewButton backgroundColor={true}>
                                      Ver <FiArrowRight />
                                    </NewButton>
                                  </Link>
                                </div>
                              </div>
                              <div className="premio-valores-container-2">
                                <div className="grid-premios">
                                  {vendedor.premios.map((item) => {
                                    if (
                                      item !== "vendedor" &&
                                      item !== "id_vendedor" &&
                                      item !== "total"
                                    ) {
                                      return (
                                        <div className="premio-valor">
                                          <span className="premio-label">
                                            {item.nombre}
                                          </span>
                                          <span>
                                            ${formatter.format(item.premio)}
                                          </span>
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                    </Grid>
                  </>
                ) : (
                  <SpinnerLoading text={"Calculando premios..."} />
                )}
              </>
            ) : null}

            {tabSelected === 1 ? (
              <Grid colGap={21} rowGap={21} narrow={true}>
                {grupos.length > 0 &&
                  grupos.map((grupo) => {
                    return (
                      <Col desktop={4}>
                        <div className="grupo-container">
                          <div className="grupo-titulo-container">
                            <h3>{grupo.nombre}</h3>
                            <div className="grupo-titulo-botones">
                            <Link
                              to={{
                                pathname: `/Premios/Vendedores/Grupos/${grupo.id_vendedores_grupo}`,
                                state: grupo,
                              }}
                            ><IconButton className="icon" icon={<FiArrowUpRight />} tooltipText={"Ver grupo"}/></Link>
                            <Link
                              to={{
                                pathname: `/Premios/EditarGrupo/${grupo.id_vendedores_grupo}`,
                                state: grupo,
                              }}
                            ><IconButton className="icon" icon={<FiEdit />} tooltipText={"Editar grupo"}/></Link>
                            </div>
                          </div>
                          <div className="integrantes-container">
                            {grupo.integrantes.map((integrante, index) => {
                              console.log(integrante)
                              const vendedorData = vendedores.find(item => item.id_vendedor === integrante.id_vendedor)
                              return (
                                <Link
                                to={{
                                  pathname: `/Premio/Vendedor/${integrante.id_vendedor}`,
                                  state: {
                                    nombre: vendedorData.vendedor,
                                    data: vendedorData,
                                    mes: mes,
                                  },
                                }}
                              >
                                <div className="integrante">
                                  <div className="integrante-data-container">
                                    <span className="userIcon">
                                      <FiUser />
                                    </span>
                                    <div className="integrante-data">
                                      <span className="nombre">
                                        {integrante.nombre}
                                      </span>
                                      <div>
                                        <span className="integrante-prima-actual">
                                          Prima actual: $
                                          {formatter.format(integrante.prima)}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                </Link>
                              );
                            })}
                          </div>
                        </div>
                        {/*
                        <div className="buttons-container">
                          <NewButton backgroundColor={false} textColor={true} borderColor={true}>Editar</NewButton>
                          <Link
                              to={{
                                pathname: `/Premios/Vendedores/Grupos/${grupo.id_vendedores_grupo}`,
                                state: grupo,
                              }}
                            ><NewButton backgroundColor={true}>Ver</NewButton></Link>
                        </div>
                        */}
                      </Col>
                    );
                  })}
              </Grid>
            ) : null}

            {tabSelected === 2 ? (
              <>
                <Grid colGap={21} rowGap={21} narrow={true}>
                  <Col desktop={2}>
                    <NewInputSelect
                      name={"mes"}
                      labelName={"Mes"}
                      onChange={(e) => setMes(e.target.value)}
                      placeholder={"Mes"}
                      options={meses}
                      value={mes}
                    />
                  </Col>
                  {/*
                  <Col desktop={2}>
                    <NewInputSelect
                      name={"anio"}
                      labelName={"Año"}
                      onChange={(e) => setAnio(e.target.value)}
                      placeholder={"Año"}
                      options={anios}
                      value={anio}
                    />
                  </Col>
                  */}
                </Grid>
                <hr />
                <Subtitle
                  icon={<FiStar />}
                  title={`Estadísticas totales de ${meses
                    .find((item) => item.value == mes)
                    ?.label.toLowerCase()}`}
                  description={
                    "Prima total obtenida por todos los vendedores y cantidad total de operaciones realizadas"
                  }
                  disableDivider={true}
                />
                {!loading ? (
                  <Grid colGap={21} rowGap={21} narrow={true}>
                    {total ? (
                      <>
                        <Col desktop={3}>
                          <div className={`total-container`}>
                            <span>Prima total del mes</span>
                            <h2>
                              ${formatter.format(Math.round(total.total.prima))}
                            </h2>
                          </div>
                        </Col>
                        <Col desktop={3}>
                          <div className={`total-container`}>
                            <span>Cantidad de operacione del mes</span>
                            <h2>{total.total.operaciones}</h2>
                          </div>
                        </Col>
                        <Col desktop={12}>
                          <div className={`total-container pie`}>
                            <PieChart data={dataPie} />
                          </div>
                        </Col>
                      </>
                    ) : null}
                  </Grid>
                ) : (
                  <SpinnerLoading text={"Cargando estadísticas del mes"} />
                )}

                <hr />
                <Subtitle
                  icon={<FiStar />}
                  title={`Prima y operaciones diarias de ${productos.find(item => item.value == productoSeleccionado)?.label.toLowerCase()} en ${meses
                    .find((item) => item.value == mes)
                    ?.label.toLowerCase()}`}
                  description={
                    "Prima obtenida de forma diaria para el producto seleccionado durante el mes"
                  }
                  disableDivider={true}
                />      
                {!loading ? (
                  <Grid colGap={21} rowGap={21} narrow={true}>
                    {total ? (
                      <>
                        <Col desktop={2}>
                          <NewInputSelect
                            name={"producto"}
                            labelName={"Producto"}
                            onChange={(e) => setProductoSeleccionado(e.target.value)}
                            placeholder={"Producto"}
                            options={productos}
                            value={productoSeleccionado}
                          />
                        </Col>
                        <Col desktop={12}>
                          <LineChart data={dataPrimaDiariaProducto} options={{
                            responsive: true,
                            scales: {
                              x: {
                                stacked: false,
                              },
                              y: {
                                stacked: false,
                              },
                            },
                            plugins: {
                              title: {
                                display: true,
                                text: 'Prima diaria',
                              },
                            },
                          }}/>
                        </Col>
                      </>
                    ) : null}
                  </Grid>
                ) : (
                  <SpinnerLoading text={"Cargando estadísticas del mes"} />
                )}
                                {!loading ? (
                  <Grid colGap={21} rowGap={21} narrow={true}>
                    {total ? (
                      <>
                        <Col desktop={12}>
                          <LineChart data={dataOperacionesDiariasProducto} options={{
                            responsive: true,
                            scales: {
                              x: {
                                stacked: false,
                              },
                              y: {
                                stacked: false,
                              },
                            },
                            plugins: {
                              title: {
                                display: true,
                                text: 'Operaciones emitidas por día',
                              },
                            },
                          }}/>
                        </Col>
                      </>
                    ) : null}
                  </Grid>
                ) : (
                  <SpinnerLoading text={"Cargando estadísticas del mes"} />
                )}
                
                {prima ? (
                    <>
                      <hr />
                      <Subtitle
                        icon={<FiBarChart />}
                        title={"Prima anual"}
                        description={`Evolución de la prima obtenida a lo largo del año`}
                        disableDivider={true}
                      />
                      <Grid colGap={21} rowGap={21} narrow={true}>
                        <Col desktop={12}>
                          <BarChart data={prima} />
                        </Col>
                      </Grid>
                    </>
                  ) : null}
                  <hr />
                  {operaciones ? (
                    <>
                      <Subtitle
                        icon={<FiBarChart />}
                        title={"Operaciones anuales"}
                        description={`Evolución de la cantidad de operaciones realizadas a lo largo del año`}
                        disableDivider={true}
                      />
                      <Grid colGap={21} rowGap={21} narrow={true}>
                        <Col desktop={12}>
                          <BarChart data={operaciones} />
                        </Col>
                      </Grid>
                    </>
                  ) : null}
                  <hr />
                  {totalPremiosAnual ? (
                    <>
                      <Subtitle
                        icon={<FiBarChart />}
                        title={"Total a pagar de premios"}
                        description={`Evolución del total de premios a pagar a vendedores por mes.`}
                        disableDivider={true}
                      />
                      <Grid colGap={21} rowGap={21} narrow={true}>
                        <Col desktop={12}>
                          <BarChart data={totalPremiosAnual} />
                        </Col>
                      </Grid>
                    </>
                  ) : null}
              </>
            ) : null}

            {tabSelected === 3 ? (
              <>
                <Grid colGap={21} rowGap={21} narrow={true}>
                  <Col desktop={2}>
                    <NewInputSelect
                      name={"mes"}
                      labelName={"Mes"}
                      onChange={(e) => setMes(e.target.value)}
                      placeholder={"Mes"}
                      options={meses}
                      value={mes}
                    />
                  </Col>
                </Grid>
                <NewTable
                  columns={tableData}
                  data={objetivos}
                  loading={loadingObjetivos.loading}
                  order={handleTableOrder}
                  orderParams={order}
                />
              </>
            ) : null}
          </Container>
        </Inner>
      </Wrapper>
    </div>
  );
};

export default Premios;

