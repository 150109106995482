import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ChatInfo, Info, Subtitulo, Perfil, Conversacion, MensajeRecibido, MensajeEnviado, BtnAdjQue } from "./styled";
import { Titulo, InputTextarea, Button } from "../Index";

import ArrowLeft from "../../assets/iconos/arrow-left.svg";
import Transfer from "../../assets/iconos/transfer.svg";

function Chat({ children }) {

  const [items, setItems] = useState([]);

  useEffect(() => {
    async function getCharacters() {
      const response = await fetch("https://swapi.dev/api/people");
      const body = await response.json();
      setItems(body.results.map(({ name, height, mass, hair_color, skin_color, eye_color, birth_year, gender }) => (
        { 
          name: name, 
          value: name,
          height: height,
          mass: mass,
          hair_color: hair_color,
          skin_color: skin_color,
          eye_color: eye_color,
          birth_year: birth_year,
          gender: gender
        }
      )));
    }
    getCharacters();
  }, []);

  return (
    <>
      {items.slice(0, 1).map((item, index) => (
        <ChatInfo key={index}>
          <Info className>
            <Titulo textColor={false}>Consulta - {item.name}</Titulo>
            <Perfil href={'#'}>
              <svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5 0C7.3952 0 4.86951 2.52562 4.86951 5.63045C4.86951 8.73527 7.39513 11.2609 10.5 11.2609C13.6048 11.2609 16.1304 8.7352 16.1304 5.63045C16.1304 2.5257 13.6048 0 10.5 0ZM10.5 9.65215C8.2832 9.65215 6.47825 7.8472 6.47825 5.63045C6.47825 3.4137 8.2832 1.60874 10.5 1.60874C12.7167 1.60874 14.5217 3.4137 14.5217 5.63045C14.5217 7.8472 12.7168 9.65215 10.5 9.65215Z" fill="#3289B9"/>
                <path d="M10.4999 12.8696C4.73428 12.8696 0.043335 17.5606 0.043335 23.3262C0.043335 23.7702 0.403707 24.1305 0.847706 24.1305C1.29171 24.1305 1.65208 23.7702 1.65208 23.3262C1.65208 18.447 5.6207 14.4784 10.4999 14.4784C15.379 14.4784 19.3476 18.447 19.3476 23.3262C19.3476 23.7702 19.708 24.1305 20.152 24.1305C20.596 24.1305 20.9564 23.7702 20.9564 23.3262C20.9564 17.5606 16.2654 12.8696 10.4999 12.8696Z" fill="#3289B9"/>
              </svg>
            </Perfil>
            <Subtitulo>Asunto: {item.eye_color}</Subtitulo>
            <Subtitulo className={'pendiente'} icon={Transfer}>Estado actual: {item.eye_color}</Subtitulo>
          </Info>

          <Conversacion>
            <MensajeRecibido icon={false}>
              {/* HORA */}
              <span>{item.height}</span>
              {/* MENSAJE */}
              <p>{item.name}</p>
            </MensajeRecibido>
            <MensajeEnviado icon={false}>
              {/* HORA */}
              <span>{item.height}</span>
              {/* MENSAJE */}
              <p>{item.name}</p>
            </MensajeEnviado>
          </Conversacion>
          <InputTextarea
            type={"text"}
            name={"Observaciones"}
            labelName={false}
            placeholder={"Escribir observaciones..."}
            onChange={null}
          />
          <div className={'buttons'}>
            <Button
              backgroundColor={false}
              borderColor={true}
              textColor={true}
              icon={ArrowLeft}
              direction={"before"}
            >
              Volver
            </Button>
            <div className={'btnAdjQueEnviar'}>
              <BtnAdjQue onClick={null}>
                <svg width="27" height="30" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.1325 3.19968C20.4313 4.96362 20.8673 8.26983 19.1028 10.5694L10.9579 21.1839C9.51457 23.065 6.80921 23.4211 4.92817 21.9777C3.04712 20.5344 2.69096 17.829 4.13433 15.948L11.1156 6.84981C12.2384 5.38654 14.3421 5.10955 15.8054 6.23238C17.2687 7.35522 17.5457 9.45896 16.4229 10.9222L11.1869 17.7458C11.0264 17.9551 10.7262 17.9946 10.5169 17.8341C10.3077 17.6735 10.2682 17.3733 10.4287 17.1641L15.6647 10.3404C16.4663 9.29568 16.2692 7.79283 15.2236 6.99059C14.1781 6.18832 12.6755 6.38684 11.8738 7.4316L4.89253 16.5298C3.76972 17.993 4.04666 20.0968 5.50997 21.2196C6.97328 22.3424 9.07697 22.0654 10.1998 20.6021L18.3446 9.98763C19.788 8.10658 19.4318 5.40122 17.5508 3.95785C15.6697 2.51447 12.9643 2.87064 11.521 4.75168L5.12148 13.0917C4.96091 13.3009 4.66074 13.3404 4.45148 13.1799C4.24222 13.0193 4.2027 12.7191 4.36327 12.5099L10.7628 4.16989C12.5273 1.87036 15.8337 1.43574 18.1325 3.19968Z" fill="#3289B9"/>
                </svg>
              </BtnAdjQue>
              <BtnAdjQue onClick={null}>
                <svg width="30" height="30" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.5 0C5.60762 0 0 5.60762 0 12.5C0 19.3923 5.60762 25 12.5 25C19.3923 25 25 19.3923 25 12.5C25 5.60762 19.3924 0 12.5 0ZM12.5 23.913C6.2065 23.913 1.08694 18.7934 1.08694 12.5C1.08694 6.2065 6.2065 1.08694 12.5 1.08694C18.7934 1.08694 23.913 6.2065 23.913 12.5C23.913 18.7934 18.7934 23.913 12.5 23.913Z" fill="#3289B9"/>
                  <path d="M12.4999 5.43481C10.4021 5.43481 8.69556 7.14132 8.69556 9.23918V10.3261C8.69556 10.6261 8.93905 10.8696 9.23905 10.8696C9.53905 10.8696 9.78254 10.6261 9.78254 10.3261V9.23918C9.78254 7.74132 11.001 6.52175 12.4999 6.52175C13.9988 6.52175 15.2173 7.74132 15.2173 9.23913V10.0619C15.2173 10.788 14.9347 11.4706 14.4206 11.9848L13.0706 13.3348C12.3521 14.0544 11.9564 15.0087 11.9564 16.025V16.8478C11.9564 17.1478 12.1999 17.3913 12.4999 17.3913C12.7999 17.3913 13.0434 17.1478 13.0434 16.8478V16.025C13.0434 15.2989 13.326 14.6163 13.8401 14.1022L15.1901 12.7522C15.9086 12.0326 16.3043 11.0783 16.3043 10.062V9.23918C16.3043 7.14132 14.5978 5.43481 12.4999 5.43481Z" fill="#3289B9"/>
                  <path d="M12.5 18.4783C11.9 18.4783 11.4131 18.9663 11.4131 19.5652C11.4131 20.1641 11.9 20.6521 12.5 20.6521C13.1 20.6521 13.587 20.1641 13.587 19.5652C13.587 18.9663 13.1 18.4783 12.5 18.4783Z" fill="#3289B9"/>
                </svg>
              </BtnAdjQue>
              
              <Button
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                icon={false}
              >
                Enviar
              </Button>
            </div>
            
          </div>
        </ChatInfo>
      ))}
    </>
  )
}

// InfoBoard.propTypes = {
//   children: PropTypes.string.isRequired
// };

export default Chat;
