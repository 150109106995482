import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { MenuDiv, StyledBurger, StyledMenu } from "./styled";
import SolicitudesEmision from "../../assets/iconos/solicitudesEmision.svg";
import Emisiones from "../../assets/iconos/emisiones.svg";
import Clientes from "../../assets/iconos/clientes.svg";
import Estadisticas from "../../assets/iconos/estadisticas.svg";
import Consultas from "../../assets/iconos/consultas.svg";
import Siniestros from "../../assets/iconos/siniestros.svg";
import CerrarSesion from "../../assets/iconos/cerrarSesion.svg";
import axios from "axios";

function Menu( { active, user } ) {
  const node = useRef();
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState('')

  useEffect(() => {
    const getUserName = async () =>{
      if(user){
        const userName = await axios.get(`https://gys-client.vercel.app/usuario_operador/${user.user.id_usuario}`)
        setUsername(userName.data.nombre_operador)
      }
    }
    getUserName()
  }, [user])

  const Burger = ({ open, setOpen }) => {
    return (
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
    );
  };

  const MenuSlide = ({ open }) => {
    return (
      <StyledMenu open={open}>
        <h2>Hola {username}!</h2>
        <nav>
          <ul>
            <li>
              <a href="/SolicitudEmision" className={'solicitudEmision'} active={'solicitudEmision'}>
                <figure>
                  <img src={SolicitudesEmision} alt={"Solicitudes Emision"} />
                </figure>
                <span>Solicitudes de emisi&oacute;n</span>
              </a>
            </li>
            <li>
              <a href="/Emisiones" className={'emisiones'} active={'emisiones'}>
                <figure>
                  <img src={Emisiones} alt={"Solicitudes Emision"} />
                </figure>
                <span>Emisiones</span>
              </a>
            </li>
            <li>
              <a href="/" className={'clientes'} active={'clientes'}>
                <figure>
                  <img src={Clientes} alt={"Clientes"} />
                </figure>
                <span>Clientes</span>
              </a>
            </li>
            <li>
              <a href="/Estadisticas" className={'estadisticas'} active={'estadisticas'}>
                <figure>
                  <img src={Estadisticas} alt={"Estadisticas"} />
                </figure>
                <span>Estad&iacute;sticas</span>
              </a>
            </li>
            <li>
              <a href="/Consultas" className={'consultas'} active={'consultas'}>
                <figure>
                  <img src={Consultas} alt={"Consultas"} />
                </figure>
                <span>Consultas</span>
              </a>
            </li>
            <li>
              <a href="/" className={'siniestros'} active={'siniestros'}>
                <figure>
                  <img src={Siniestros} alt={"Siniestros"} />
                </figure>
                <span>Siniestros</span>
              </a>
            </li>
            <li>
              <a href="/">
                <figure>
                  <img src={CerrarSesion} alt={"Cerrar Sesion"} />
                </figure>
                <span>Cerrar Sesi&oacute;n</span>
              </a>
            </li>
          </ul>
        </nav>
      </StyledMenu>
    );
  };

  /*
  <MenuDiv ref={node} active={active}>
    </MenuDiv>
    */

  return (
    <MenuDiv ref={node} active={active}>
      <Burger open={open} setOpen={setOpen} />
      <MenuSlide open={open} active={active}/>
    </MenuDiv>
  );
}

// Menu.propTypes = {
//   children: PropTypes.string.isRequired
// };

export default Menu;
