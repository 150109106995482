import { useState, useEffect, useRef } from "react"
import { getEstadosEmision, getOperadores, getSolicitudes, getTipoSolicitudes, getVendedores } from "../services/Emision"
import formatSelectData from "../utils/formatSelectData"
import { useLoading } from "./useLoading"
import usePagination from "./usePagination"
import { useAuthContext } from "../context/AuthContext"

export default function useSolicitudEmision(filterParams, orderParams) {

  // User Data
  const { authState } = useAuthContext()
  const { userInfo } = authState
  const userParams = userInfo.id_rol === 6 ? `user=${userInfo.nombre_operador}` : ``

  const [solicitudes, setSolicitudes] = useState([])
  const [solicitudesLength, setSolicitudesLength] = useState(0)
  const [operadores, setOperadores] = useState('')
  const [operadoresIds, setOperadoresIds] = useState([])
  const [vendedores, setVendedores] = useState('');
  const [estados, setEstados] = useState('');
  const [tiposSolicitudes, setTiposSolicitudes] = useState([])

  const [estadisticas, setEstadisticas] = useState({})

  // Paginación
  const pagination = usePagination(solicitudesLength)

  // Filter ref
  const filtrosRef = useRef(null)

  const { loading, setLoading } = useLoading(true)

  const refreshSolicitudesEmision = async () => {
    setLoading(true)
    setSolicitudes([])
    let paginacion = filtrosRef.current !== filterParams ? 1 : pagination.currentPage
    if(filtrosRef.current !== filterParams){
      filtrosRef.current = filterParams
      pagination.selectPage(1)
    }
    try {
      const solicitudes = await getSolicitudes(filterParams, orderParams, paginacion, userParams)
      const solicitudesLength = await getSolicitudes(filterParams, orderParams, null, userParams)
      setSolicitudes(solicitudes)
      setSolicitudesLength(solicitudesLength.length)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    refreshSolicitudesEmision()
  }, [filterParams, orderParams, pagination.currentPage])

  const obtenerEstadisticas = (solicitudes) => {
    const data = solicitudes.reduce((total, item) => {
      const keys = Object.keys(total).length
      if(keys == 0){
        const obj = {
          pendientes: 0,
          rechazadas: 0,
          prioritarias: 0,
          anuladas: 0,
          endosos: 0,
          nuevas: 0
        }
        if(item.id_estado == 1) obj.pendientes += 1
        if(item.id_estado == 3) obj.rechazadas += 1
        if(item.prioridad == 1) obj.prioritarias += 1
        if(item.id_tipo_solicitud == 1) obj.nuevas += 1
        if(item.id_tipo_solicitud == 2) obj.anuladas += 1
        if(item.id_tipo_solicitud == 3) obj.endosos += 1

        return obj
      } else {
        if(item.id_estado == 1) total.pendientes += 1
        if(item.id_estado == 3) total.rechazadas += 1
        if(item.prioridad == 1) total.prioritarias += 1
        if(item.id_tipo_solicitud == 1) total.nuevas += 1
        if(item.id_tipo_solicitud == 2) total.anuladas += 1
        if(item.id_tipo_solicitud == 3) total.endosos += 1

        return total
      }
    }, {})
    return data
  }

  useEffect(() => {
    const getInitialData = async () => {
        try {
            const solicitudes = await getSolicitudes(null, null, 1, userParams)
            const solicitudesLength = await getSolicitudes(null, null, null, userParams)
            const estadisticas = obtenerEstadisticas(solicitudesLength)
            const vendedores = await getVendedores()
            const tiposSolicitudes = await getTipoSolicitudes()
            const estados = await getEstadosEmision()
            const operadores = await getOperadores()
            setSolicitudes(solicitudes)
            setSolicitudesLength(solicitudesLength.length)
            setEstadisticas(Object.keys(estadisticas).length === 0 ? {
              pendientes: 0,
              rechazadas: 0,
              prioritarias: 0,
              anuladas: 0,
              endosos: 0,
              nuevas: 0
            } : estadisticas)
            setVendedores(vendedores)
            setEstados(estados)
            setOperadores(operadores.operadores)
            setOperadoresIds(operadores.operadoresIds)
            setTiposSolicitudes(tiposSolicitudes)
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }
    getInitialData()
  }, [])

  return {  
    data: {
        solicitudes: solicitudes, 
        vendedores: vendedores,
        operadores: operadores,
        operadoresIds: operadoresIds,
        estados: estados,
        estadisticas,
        tiposSolicitudes
    },
    refreshSolicitudesEmision,
    loading,
    pagination
  }

}
