import SeguroBiciLogo from "../assets/iconos/segurobici.svg";
import SeguroMotosLogo from "../assets/iconos/seguromotos.svg";
import SeguroMascotasLogo from "../assets/iconos/seguromascotas.svg";

export const landingsTabs = [
    {
      name: "Seguro Bici",
      image: SeguroBiciLogo,
      id_landing: 1,
      initialProduct: 1
    },
    {
      name: "Seguro Motos",
      image: SeguroMotosLogo,
      id_landing: 2,
      initialProduct: 5
    }
];