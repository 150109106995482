function formaterObject(CSVData, filtro) {
    //recibe un arreglo de strings y devuelve un arreglo de objetos con formato
    let arregloObjetos = [];

    // lee la cabecera
    const firstLineBreak = CSVData.indexOf("\n");
    // Separa las líneas del CSV -> array de strings
    try {

        const csvContent = CSVData.slice(firstLineBreak).trim().split("\n");

        if (csvContent[0].includes(':')) {
            // carga para un csv de prudencia
            csvContent.forEach(string => {
                if (string.indexOf('::') === -1 && string.length !== 0) {
                    const limiteInf = string.indexOf('-')
                    const limiteSup = string.indexOf('/')
                    const poliza = string.substring(limiteInf + 1, limiteSup);
                    let objetoNuevo = {
                        poliza: parseInt(poliza), //filtro
                        importe: 0,
                    }
                    arregloObjetos.push(objetoNuevo);
                }
            })
        } else {

            // carga de un csv normal
            csvContent.forEach((string, index) => {
                // los campos no pueden estar vacios y tener un minimo de 3 caracteres
                if (string.indexOf(';;') === -1 && string.length >= 3) {
                    const arregloTemporal = string.split(';');
                    // verifica los campos de cada fila
                    let objetoNuevo = verifyFields(arregloTemporal, filtro)
                    arregloObjetos.push(objetoNuevo);
                }
            });
        }
        return arregloObjetos;
    } catch (error) {
        console.log(error)
        return []
    }
}

const verifyFields = (rowCsv, filtro) => {
    // si se encuentra un error señala el indice y el campo
    // si pasamos un filtro 
    if (filtro) {
        const { type, template } = filtro
        let rowVerified = {}
        if (type === 'cobranza') {
            // validacion de campos solo para templates de cobranzas
            switch (template) {
                // poliza anulada
                case "1":
                // saldo a favor 
                case "3": rowVerified = validarCampos(type, rowCsv, ['poliza']); break;
                // baja debito automatico
                case "2": rowVerified = validarCampos(type, rowCsv, ['poliza', 'periodo_adeudado']); break;
                // error en la tarjeta de credito
                case "4": rowVerified = validarCampos(type, rowCsv, ['poliza', 'periodo_adeudado' , 'motivo_rechazo']); break;
                // poliza proxima a vencer
                case "5": rowVerified = validarCampos(type, rowCsv, ['poliza', 'importe', 'periodo_adeudado', 'fecha_vencimiento']); break;
                default:
                    break;
            }
        }
        return rowVerified

    } else {
        let importe = rowCsv[1] ? parseFloat(rowCsv[1]): 0;

        return {
            poliza: parseInt(rowCsv[0]), //filtro
            importe,
        }
    }
}

const validarCampos = (type, rowCsv, campos) => {
    let rowGenerada = {}
    try {
        for (const [index, field] of campos.entries()) {
            if (field === 'poliza') rowGenerada[field] = parseInt(rowCsv[index]) || 'ERROR'
            if (field === 'periodo_adeudado') rowGenerada[field] = rowCsv[index].includes('-') ? rowCsv[index] : 'ERROR'
            if (field === 'motivo_rechazo') rowGenerada[field] = typeof rowCsv[index] === 'string' ? rowCsv[index] : 'ERROR'
            if (field === 'importe') rowGenerada[field] = parseFloat(rowCsv[index]) || 'ERROR'
            if (field === 'fecha_vencimiento') rowGenerada[field] =  rowCsv[index].includes('-') ? rowCsv[index] : 'ERROR'
            // verificar ramo en csv
            if (field === 'ramo') rowGenerada[field] =  rowCsv[index].includes('-') ? rowCsv[index] : 'ERROR'
        }
    } catch (error) {
        console.log(error)
    }
    return rowGenerada;
}

export default formaterObject;