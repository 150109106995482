import React, { useState, useEffect } from "react";
import { Modal, Inner, Menu, Wrapper, Chat } from '../components/Index';

import { ModalEmisionEmitir, ShowModal} from "./styled.js"

const ModalChat = () => {

  return (
    <div className="App">
    <Menu />
    <Wrapper>
        <Inner>
            <ModalEmisionEmitir>
                <ShowModal>
                    <Modal>
                        <Chat />
                    </Modal>
                </ShowModal>
            </ModalEmisionEmitir>
        </Inner>
    </Wrapper>
    </div>
  );
};

export default ModalChat;