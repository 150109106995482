import React, { useState, useEffect } from "react";
import { Button, Modal, Inner, Menu, Wrapper } from '../components/Index';
import { ModalRecordatorio, SuccessTitle, TickWrapper, ShowModal, ModalConfirmacion } from "./styled.js"

const ModalDerivacionOk = () => {

  return (
    <div className="App">
    <Menu />
    <Wrapper>
        <Inner>
            <ShowModal>
                <ModalConfirmacion>
                    <Modal>
                        <SuccessTitle>Consulta Derivada</SuccessTitle>
                        <TickWrapper>
                        <svg width="65" height="50" viewBox="0 0 47 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M45.6195 0.678049C44.7155 -0.226016 43.2499 -0.226016 42.3457 0.678049L14.6121 28.4119L3.95178 17.7516C3.04781 16.8475 1.58221 16.8476 0.678049 17.7516C-0.226016 18.6556 -0.226016 20.1212 0.678049 21.0252L12.9753 33.3223C13.879 34.2262 15.3457 34.2256 16.249 33.3223L45.6195 3.95178C46.5235 3.04781 46.5234 1.58212 45.6195 0.678049Z" fill="#3D9F54"/>
                        </svg>
                        </TickWrapper>
                        <Button backgroundColor={true} borderColor={false} textColor={false} icon={false}> Aceptar </Button>  
                    </Modal>
                </ModalConfirmacion>
            </ShowModal>
        </Inner>
    </Wrapper>
    </div>
  );
};

export default ModalDerivacionOk;