import React, { useState, useRef } from "react";
import { IoClose } from "react-icons/io5";
// Librerias
import disableScroll from "disable-scroll";
import { CSSTransition } from "react-transition-group";
// Estilos
import Container from "./styled";
import { TabsContainer, Tab } from "../../../pages/styled";
import { ItemConsulta } from "./ItemConsulta";
import SpinnerLoading from "../../SpinnerLoading";

export default function Menu({selected, setSelected, consultas, setFilter, loading}) {
  // pestañas del menu
  const [selectedTab, setSelectedTab] = useState('abiertas')


  return (
    <Container>
      {/* Card Container */}
      <div>
          {/* Título */}
          {
            loading?
            <div> 
              <SpinnerLoading text={"Obteniendo Consultas"}></SpinnerLoading>
            </div>
            :
            <>
              <TabsContainer>
              <Tab 
                selected={selectedTab === "abiertas" ? true : false} onClick={()=> setSelectedTab('abiertas')} 
                padding={true} 
                small={true}
              >Abiertas
              </Tab>
              <Tab 
                selected={selectedTab === "cerradas" ? true : false} onClick={()=> setSelectedTab('cerradas')} 
                padding={true} 
                small={true}
              >
              Cerradas
              </Tab>
              <Tab 
                selected={selectedTab === "filtrar" ? true : false} onClick={()=> setSelectedTab('filtrar')} 
                padding={true} 
                small={true}
              >
              Filtrar
              </Tab>
            </TabsContainer>
            {
              selectedTab==='abiertas'?
                consultas.map( c => (
                  <ItemConsulta 
                    key={c.id}
                    selected={selected}
                    setSelected={setSelected}
                    consulta={c}
                  />
                ))
              :null
            }
            </>
          }
            
      </div>
    </Container>
  );
}
