import React, { useState, useEffect } from "react";
import { InputTextarea, InputFileButton, ErrorInput, Subtitulo, Grid, Col, InputBasic, Modal, FileLoaded } from "../../Index";

import { useSolicitudContext } from "../../../context/SolicitudContext";
import Subtitle from "../../Subtitle";
import { FiEye } from "react-icons/fi";
import NewInputTextarea from "../../NewInputTextarea";
import Switch from "../../Switch"
import { ContainerSwitch } from "./styled";
import { HiSparkles } from "react-icons/hi";

const ObservacionForm = () => {

    const { observacion } = useSolicitudContext()
    const { values, formErrors, handleChange, handleChangeCheckbox } = observacion

    return (
        <Grid colGap={21} rowGap={21} narrow={true}>
            <Col desktop={12}>
                <Subtitle title="Observaciones" description="Dejá comentarios adicionales a la solicitud en caso de ser necesario" icon={<FiEye/>}/>
            </Col>
            <Col desktop={12}>
                <NewInputTextarea type={"text"} name={"observaciones"} labelName={"Observaciones"} placeholder={"Comentarios adicionales sobre la solicitud"} maxLength={"1000"} onChange={handleChange} value={values.observaciones}/>
            </Col>
            <Col desktop={12}>
                <ContainerSwitch>
                    <label><HiSparkles />Alta prioridad por uso urgente del bien</label>
                    <Switch checked={values.prioridad} name="prioridad" onChange={handleChangeCheckbox} />
                </ContainerSwitch>
            </Col>
        </Grid>
    )
}

export default ObservacionForm