import React, { useState, useEffect } from "react";
import {
  Button,
  Subtitulo,
  Titulo,
  InfoBoard,
  Grid,
  Col,
  Inner,
  InputBasic,
  InputSelect,
  ProgressBar,
  Menu,
  Wrapper,
  NewProgressBar,
  BurgerMenu,
  NavMenu,
} from "../components/Index";
import { CargaSolicitud } from "../components/ProgressBar/styled";
import axios from "axios";
import ChatContainer from "../components/ChatConsultas/Container/chat-container";

const Consultas = () => {
  // datos sesion
  const user = JSON.parse(localStorage.getItem('userInfo'));

  // menu 
  const [open, setOpen] = useState(false)

  return (
    <div className="App">
      <BurgerMenu open={open} setOpen={setOpen} />
      <NavMenu open={open} setOpen={setOpen} user={user} active={"Usuarios"} />
      <Wrapper>
        <Inner>
          <Grid colGap={21} rowGap={21} narrow={true}>
            <Col desktop={12}>
              <Titulo textColor={false}>Consultas Portal de Clientes</Titulo>
            </Col>
            <Col desktop={12}>
              <ChatContainer />
            </Col>
          </Grid>
          </Inner>
      </Wrapper>
    </div>
  );
};

export default Consultas;
