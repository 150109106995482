import { Tab, Tabs, TabList } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { TabDiv } from "./styled";

function TabsInfo( { tabs, tabSelected, handleTab } ) {
  
  return (
    <TabDiv>
      <Tabs selectedTabClassName={"selectedTab"}>
        <TabList className={"tabList"}>
          {tabs.map((tab, index) => {
            return (
              <Tab selected={tabSelected === index} onClick={()=> handleTab(index)} key={index}>
                {tab?.image ? <img src={tab.image} alt={`Logo de ${tab.name}`} /> : tab.name}
              </Tab>
            )
          })}
        </TabList>
      </Tabs>
    </TabDiv>
  );
  
}


export default TabsInfo;
