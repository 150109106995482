import React, { useState, useEffect } from "react";
import { Button, Titulo, Modal, Inner, InputTextarea, InputFileButton, Menu, Wrapper } from '../components/Index';

import ArrowLeft from "../assets/iconos/arrow-left.svg";
import { ModalEmisionEmitir, ShowModal } from "./styled.js"

const ModalDerivacion = () => {

  return (
    <div className="App">
    <Menu />
    <Wrapper>
        <Inner>
            <ModalEmisionEmitir>
                <ShowModal>
                <Modal>
                    <Titulo>Emisión Doe Jane - L2 Bici Gold</Titulo>
                    <h3>Se enviará el siguiente mail a siniestros@seguroweb.com.ar</h3>

                    <InputTextarea placeholder={'Escriba un mensaje...'} />
                    <div className={'actionButtonsCont'}>
                        <InputFileButton type={"file"} name={"Archivos"} required={false} />
                        <div className={'buttonsCont'}>
                            <Button backgroundColor={false} borderColor={true} textColor={true} icon={ArrowLeft} direction={"before"}> Volver </Button>
                            <Button backgroundColor={true} borderColor={false} textColor={false} icon={false}> Enviar </Button>
                        </div>  
                    </div>          
                </Modal>
                </ShowModal>
            </ModalEmisionEmitir>
        </Inner>
    </Wrapper>
    </div>
  );
};

export default ModalDerivacion;