import React from "react";
// Iconos
import {
  HiOutlineTicket,
  HiOutlineFolder,
  HiOutlineFolderOpen,
  HiBadgeCheck,
} from "react-icons/hi";
import {
  RiShieldFill,
  RiCustomerServiceFill,
  RiCalendar2Fill,
  RiHotelFill,
  RiDiscussFill,
  RiTimeLine,
} from "react-icons/ri";
import { MdKeyboardArrowUp } from "react-icons/md";
import { IoClose } from "react-icons/io5";
// Librerias
import { Collapse } from "react-collapse";
// Estilos
import Container from "./styled";

export default function CardMobile({
  numero,
  seguro,
  estado,
  bien,
  poliza,
  sector,
  operador,
  isDropdownOpen,
  handleToggleDropdownMobile,
  children,
}) {
  return (
    <Container>
      {/* Card Container Mobile */}
      <div id="card-container-mobile" className="opacidad03">
        <button
          id={isDropdownOpen == true ? "dropdown-abierto" : "dropdown-cerrado"}
        >
          {/* Título */}
          <h3>
            <span>
              <HiOutlineTicket></HiOutlineTicket>
            </span>
            <strong>#{numero}&nbsp;-&nbsp;</strong>
            {seguro}
          </h3>
          <button
            id={
              isDropdownOpen == true
                ? "ver-mas-boton-abierto"
                : "ver-mas-boton-cerrado"
            }
            onClick={handleToggleDropdownMobile}
          >
            <MdKeyboardArrowUp />
          </button>
        </button>
        <Collapse isOpened={isDropdownOpen}>{children}</Collapse>
      </div>
    </Container>
  );
}
