import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: calc(75% - 32px);
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;

  #mensajes-input-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative !important;
  }

  #mensajes-container {
    width: calc(100% - 66px);
    height: calc(78vh - 140px);
    margin: 30.5px 0px 0px 32px;
    padding: 32px 32px 105px 32px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    border: 1px solid var(--grisClaro);
    border-radius: 16px 0px 0px 16px;
    background-color: var(--blanco);
    box-shadow: 0px 5px 30px rgba(9, 30, 66, 0.05);
    overflow-y: scroll;
    scroll-behavior: smooth;
  }

  #input-mensaje-container {
    width: calc(100% - 47px);
    height: 96px;
    margin: 0px 14px 0px 0px;
    padding: 0px 0px 0px 0px;
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background: rgba(255, 255, 255, 0.65);
    backdrop-filter: saturate(180%) blur(20px);
    border-radius: 0px 0px 0px 16px;
    border: 1px solid var(--grisClaro);
    border-right: 0px;
  }

  #input-mensaje {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 32px 0px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  #input-mensaje input {
    width: calc(100% - 94px);
    height: 48px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: inline-block;
    padding: 0px 16px 0px 16px;
    margin: 0px 0px 0px 0px;
    outline: 0;
    border: 0px solid #AAA;
    font-size: 0.75em;
    font-style: normal;
    font-weight: normal;
    color: var(--gris);
    position: relative;
    transition: all 0.25s ease;
    z-index: 60;
    background-color: #EEE;
    box-shadow: 0;
    border-radius: 8px;
  }

  #input-mensaje-botones {
    width: fit-content;
    margin: 0px 0px 0px 32px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
  }

  #boton-archivo {
    margin: 8px 0px 0px 0px;
    font-size: 22px;
    color: var(--gris);
    line-height: normal;
    transition: 0.2s all;
    cursor: pointer;
  }

  #boton-archivo:hover {
    color: var(--azul60);
  }

  #input-mensaje-botones input {
    display: none;
  }

  #enviar-boton {
    margin: 8px 0px 0px 16px;
    padding: 0px 0px 0px 0px;
    font-size: 24px;
    color: var(--azul60);
    line-height: normal;
    transition: 0.2s all;
    cursor: pointer;
    transition: 0.2s all;
  }

  #enviar-boton:hover {
    color: var(--negro);
  }

  #archivo-cargado {
    width: calc(100% - 162.14px);
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }

  #archivo-cargado p {
    width: auto;
    margin: 0px 16px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-weight: 600;
    font-size: 14px;
    line-height: normal;
    text-align: left;
  }

  #archivo-cargado p span {
    margin: 4px 6px 0px 0px;
    font-size: 18px;
  }

  #archivo-cargado a {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-weight: 500;
    color: var(--rojo);
    line-height: normal;
    cursor: pointer;
    transition: 0.2s all;
  }

  #archivo-cargado a span {
    margin: 4px 6px 0px 0px;
    font-size: 18px;
  }

  #archivo-cargado a:hover {
    color: var(--gris);
  }

  & figure {
   display: block;
   width:100%;
   padding: 30px;
   text-align: center;
  }
  & figure img {
    width: 80px;
  }
  & figure .invertido {
    transform: scaleX(-1);
    width: 100px;
    opacity: 0.3;
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    width: calc(65% - 32px);
  }

  // 1024px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet1024}) {
    width: 100% !important;

    #mensajes-container {
      margin: 30.5px 0px 0px 0px;
      height: calc(100vh - 470px);
    }

    #input-mensaje-container {
      width: calc(100% - 15px);
    }
  }

  // 570px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile570}) {
    #mensajes-container {
      width: calc(100% - 32px);
      margin: 16px 0px 0px 0px;
      height: calc(100vh - 310px);
      padding: 16px 16px 105px 16px;
    }

    #input-mensaje {
      padding: 0px 16px 0px 16px;
    }

    #input-mensaje-botones {
      margin: 0px 0px 0px 12px;
    }

    #enviar-boton {
      margin: 7px 0px 0px 12px;
    }
  }
`;

export default Container;
