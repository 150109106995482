import React, { useEffect, useState } from "react";
import Titulo from "../Titulo";
import Mensajes from "../Mensajes";
import { Link as Boton, useLocation } from "react-router-dom";
import Menu from "../Menu";
import { Container } from "./styled";
import axiosRequest from "../../../utils/axiosConfig";
import socket from "../../../config/socket_client"
import urlPortal from "../../../constants/urlPortal";


export default function ChatContainer() {
  // cuando se monta el container trae las consultas abiertas
  const [consultas, setConsultas] = useState(null);

  // detalles de la consulta
  const [detalleConsulta, setDetalleConsulta] = useState(null);

  // mensajes de la consulta
  const [historialMensajes, setHistorialMensajes] = useState(null);

  // consulta seleccionada
  const [selectedConsultaId, setSelectedConsultaId] = useState(null);

  // estado de la consulta 
  const [estadoConsulta, setEstadoConsulta] = useState("Abierta")

  // filtro para las consultas
  const [filter, setFilter] = useState('')

  // loading
  const [loading, setLoading] = useState({
    consultas: false,
    detalleConsulta: false,
    mensajes: false
  })

  const getConsultas = async () => {
    setLoading({ consultas: true })
    try {
      const result = await axiosRequest.get(`${urlPortal}/crm/consultas?${filter}`);
      setConsultas(result.data)
    } catch (error) {
      console.log('erro al traerconsultas: ', error)
    }
    finally {
      setLoading({ consultas: false })
    }
  }

  const getDetalleConsulta = async (id) => {
    setLoading({ detalleConsulta: true });
    setHistorialMensajes(null);
    try {
      const result = await axiosRequest.get(`${urlPortal}/crm/consulta/${id}`)
      // detalles de la consulta
      setDetalleConsulta(result.data);
    } catch (error) {
      // manejar el error
      console.log('erro al traerconsultas: ', error)
      setDetalleConsulta(null)
    } finally {
      setLoading({ detalleConsulta: false })
    }
  }

  const getMensajesByConsulta = async (id) => {
    setLoading({ mensajes: true })
    try {
      const result = await axiosRequest.get(`${urlPortal}/crm/chat/historial/${id}`);
      // detalles de la consulta
      setHistorialMensajes(result.data)
    } catch (error) {
      // manejar el error
      console.log('erro al traerconsultas: ', error)
      setHistorialMensajes(null)
    } finally {
      setLoading({ mensajes: false })
    }
  }


  const verifySocketConection = () => {
    try {
      socket.emit('conect_crm', 'Conexion establecida')
    } catch (error) {
      throw new Error('error al intentar conectar al socket')
    }
  }

  socket.off('notify_crm');
  socket.on('notify_crm', (data)=> {
    data.Tipo = {tipo: "siniestros"}
    setConsultas( prev => [data, ...prev])
  })

  // trae las consultas cuando renderiza el componente
  useEffect(() => {
    try {
      verifySocketConection();
      getConsultas() // obtiene las consultas
    } catch (error) {
        console.log('error al setear socket')
    }
  }, [])

  // trea los detalles de la consulta cuando cambia la consulta seleccionada
  useEffect(() => {
    if (selectedConsultaId !== null){
      getDetalleConsulta(selectedConsultaId);
    }
  }, [selectedConsultaId])

  // carga los mensajes cuando hay una consulta con detalles

  useEffect(()=>{
    if( detalleConsulta !== null ){
      getMensajesByConsulta(detalleConsulta.id)
      const consultaabierta = consultas.map(c => c.id === selectedConsultaId? {...c, notificacion: false}:c)
      setConsultas(consultaabierta)
    }
  },[detalleConsulta])

  // trae los datos del chat
  return (
    <Container>
      <div id="max-width-container-chat">
        <Menu
          consultas={consultas || []}
          setFilter={setFilter}
          selected={selectedConsultaId}
          setSelected={setSelectedConsultaId}
          loading={loading.consultas}
        />
        {/* Si no selecciona una consulta muestra un cartel */}
        <Titulo
          detalleConsulta={detalleConsulta}
          numero="7801"
          seguro="SeguroBici"
          estado="Abierta"
          bien="Bicicleta TopMega"
          sector="Cobranzas"
          operador="Lionel Messi"
          poliza="1437853"
          detalle={detalleConsulta}
          loading={loading.detalleConsulta}
          consultas={consultas}
        ></Titulo>
        <Mensajes
          mensajes={historialMensajes}
          detalle={detalleConsulta}
          setDetalle={setDetalleConsulta}
          operador="Lionel Messi"
          cliente="Facundo Brahim"
          loading={loading.mensajes}
          consultas={consultas}
          setConsultas={setConsultas}
        ></Mensajes>

      </div>
    </Container>
  );
}
