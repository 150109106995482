import { Grid, Col } from "../../Grid";
import Button from "../../Button";
import InputBasic from "../../InputBasic";
import { useForm } from "../../../hooks/useForm";
import { useEffect, useState } from "react";
import ErrorInput from "../../ErrorInput";
import axiosRequest from "../../../utils/axiosConfig";
import useButtonMessage from "../../../hooks/useButtonMessage";
import { cotizadorLimitesReglas } from "../../../utils/formRules";
import SpinnerLoading from "../../SpinnerLoading";
import { Container } from "./styled"
import Subtitulo from "../../Subtitulo";
import { formatNumber } from "../../../utils/formatter";

const CotizadorForm = ({ productos }) => {
  const [initialValues, setInitialValues] = useState({});
  const { inputValues, handleChange, handleValidation, formErrors } = useForm(
    handleSubmit,
    cotizadorLimitesReglas,
    initialValues
  );
  const { buttonText, handleButtonText } =
    useButtonMessage("Actualizar valores");

  useEffect(() => {
    const initialValues = productos.reduce((total, item) => {
      return {
        ...total,
        [item.descripcion.toLowerCase().replace(" ", "_")]:
          item.limite_cotizador,
      };
    }, {});
    setInitialValues(initialValues);
  }, [productos]);

  async function handleSubmit() {
    handleButtonText("Actualizando...");
    let data = [];
    try {
      productos.forEach((producto) => {
        if (
          inputValues[producto.descripcion.toLowerCase().replace(" ", "_")] !==
          producto.limite_cotizador
        ) {
          data.push({
            id_landing_producto: producto.id_landing_producto,
            limite_cotizador:
              inputValues[producto.descripcion.toLowerCase().replace(" ", "_")],
          });
        }
      });
      const actualizar = await axiosRequest.put(
        "/limite_cotizador_producto",
        data
      );
      if (actualizar) {
        handleButtonText("¡Valores actualizados correctamente!");
        setTimeout(() => {
          handleButtonText("Actualizar valores");
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Container>
      <Grid colGap={21} rowGap={40} narrow={true}>
        {productos.length > 0 ? (
          <>
            {productos.map((producto) => {
              return (
                <Col desktop={3} key={producto.id}>
                <div className="limite-container">
                    <Subtitulo>{producto.descripcion}</Subtitulo>
                    <hr className="divider"/>
                    <span><strong>Valor actual:</strong> ${formatNumber.format(producto.limite_cotizador)}</span>
                    <hr className="divider"/>
                  <InputBasic
                    labelName={producto.descripcion}
                    placeholder={`Valor de ${producto.descripcion}`}
                    name={producto.descripcion.toLowerCase().replace(" ", "_")}
                    value={
                      inputValues[
                        producto.descripcion.toLowerCase().replace(" ", "_")
                      ]
                    }
                    onChange={handleChange}
                  />
                  {formErrors[
                    inputValues[
                      producto.descripcion.toLowerCase().replace(" ", "_")
                    ]
                  ] && (
                    <ErrorInput>
                      {
                        formErrors[
                          inputValues[
                            producto.descripcion.toLowerCase().replace(" ", "_")
                          ]
                        ]
                      }
                    </ErrorInput>
                  )}
                  </div>
                </Col>
              );
            })}
            <Col desktop={12}>
              <Button backgroundColor={true} onClick={handleValidation}>
                {buttonText}
              </Button>
            </Col>
          </>
        ) : 
          <Col desktop={12}>
            <SpinnerLoading text="Cargando valores del cotizador" />
          </Col>
        }
      </Grid>
    </Container>
  );
};

export default CotizadorForm;
