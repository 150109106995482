import React, { useState, useEffect } from "react";
import {
  InputBasic,
  ErrorInput,
  Subtitulo,
  Grid,
  Col,
  InputCheckbox,
  InputSelect,
  InputTextarea,
} from "../../Index";
import InputFile from "../../InputFile";
import { useSolicitudContext } from "../../../context/SolicitudContext";
import { Container, StepContainer } from "../../Step3/styled";
import { getAclaraciones } from "../../../services/Emision";
import {
  SELECT_OPTIONS_CERRADURAS,
  SELECT_OPTIONS_VENTANAS,
  SELECT_OPTIONS_CLARABOYA_BANDOLERA,
  SELECT_OPTIONS_TECHOS,
  SELECT_OPTIONS_RC,
  SELECT_OPTIONS_MASCOTAS,
  SELECT_OPTIONS_TIPO_TRANSPORTE,
  SELECT_OPTIONS_VIA,
  SELECT_OPTIONS_TIPO_EMBARCACION,
  SELECT_OPTIONS_TIPO_VEHICULO,
  SELECT_OPTIONS_MATERIAL_CUADRO,
  SELECT_OPTIONS_USO,
} from "../../../constants/emision";
import NewInputBasic from "../../NewInputBasic";
import NewInputSelect from "../../NewInputSelect";
import NewInputTextarea from "../../NewInputTextarea";
import Subtitle from "../../Subtitle";

import { FiAlertCircle, FiBox, FiEye, FiFile, FiFilm, FiImage } from "react-icons/fi";
import NewInputCalendar from "../../NewInputCalendar";
import AutomotoresForm from "./AutomotoresForm";
import { AclaracionAutoMoto, AclaracionInput, EjemploAutoMoto, ComprimirVideo, AclaracionLimiteArchivo } from "./styled";

const BienAseguradoForm = () => {
  const { bienAsegurado, seguro } = useSolicitudContext();
  const {
    values,
    formErrors,
    handleChange,
    handleChangeExternal,
    handleChangeCheckbox,
    data,
  } = bienAsegurado;
  const { campos } = data;
  const { values: seguroValues } = seguro

  const [aclaraciones, setAclaraciones] = useState([]);


  useEffect(() => {
    const getFields = async () => {
      try {
        const aclaraciones = await getAclaraciones();
        setAclaraciones(aclaraciones);
      } catch (error) {
        console.log("Hubo un error", error);
      }
    };
    getFields();
  }, []);

  const imagenesEjemplo = {
    archivo_lado_derecho: "https://files.gyssrl.ar/api/v1/storage/image/cab92a5d-2c98-49de-b124-431f7571e9cf",
    archivo_lado_izquierdo: "https://files.gyssrl.ar/api/v1/storage/image/6edb88ec-2f1c-4079-bc62-6df8dce70fbd",
    archivo_frente: "https://files.gyssrl.ar/api/v1/storage/image/9faf1fac-ce8b-4100-a508-a4a821d09d68",
    archivo_trasera: "https://files.gyssrl.ar/api/v1/storage/image/f97ff938-acd9-4e15-9e55-b056615f2a2d",
  }

  return (
    <StepContainer>
      <Grid colGap={21} rowGap={21} narrow={true}>
        <Col desktop={12}>
          <Subtitle
            title="Bien asegurado"
            description="Completa los datos del bien asegurado"
            icon={<FiBox />}
          />
        </Col>
      </Grid>

      {seguroValues.producto_cobertura_asegurada == "3" ?
        <AutomotoresForm />
        :
        <>
          {campos.camposCheckbox.length > 0 ?
          <>
          <Container>
          <Grid colGap={21} rowGap={21} narrow={true}>
              {campos.camposCheckbox.map((campo) => {
                return (
                  <Col desktop={4}>
                    <InputCheckbox
                      className={"inputCheckbox"}
                      type={campo.tipo_campo}
                      name={campo.name_campo}
                      labelName={campo.label_campo}
                      color={formErrors[campo.name_campo] ? "error" : "normal"}
                      placeholder={campo.placeholder_campo}
                      onChange={(event) => handleChangeCheckbox(event, campo)}
                      checked={values[campo.name_campo]}
                    />
                  </Col>
                );
              })}
            </Grid>
          </Container>
    
          <hr />
          </>
          : null}
    
          {campos.campos ?
          <Container>
    
          <Grid colGap={21} rowGap={21} narrow={true}>
            {campos.campos.map((campo) => {
              switch (campo.tipo_campo) {
                case "text":
                case "number":
                  return (
                    <Col desktop={4}>
                      <NewInputBasic
                        type={campo.tipo_campo}
                        name={campo.name_campo}
                        labelName={campo.label_campo}
                        color={formErrors[campo.name_campo] ? "error" : "normal"}
                        placeholder={campo.placeholder_campo}
                        onChange={handleChange}
                        value={values[campo.name_campo]}
                        disabled={false}
                      />
                      {formErrors[campo.name_campo] && (
                        <ErrorInput>{formErrors[campo.name_campo]}</ErrorInput>
                      )}
                    </Col>
                  );
                case "select":
                  let options = [];
                  switch (campo.name_campo) {
                    case "techos":
                      options = SELECT_OPTIONS_TECHOS;
                      break;
                    case "ventanas_laterales":
                    case "ventanas_frente":
                    case "ventanas_fondo":
                      options = SELECT_OPTIONS_VENTANAS;
                      break;
                    case "claraboya_bandolera_frente":
                    case "claraboya_bandolera_fondo":
                    case "claraboya_bandolera_techo":
                    case "claraboya_bandolera_lateral":
                      options = SELECT_OPTIONS_CLARABOYA_BANDOLERA;
                      break;
                    case "cerraduras_laterales":
                    case "cerraduras_frente":
                    case "cerraduras_fondo":
                      options = SELECT_OPTIONS_CERRADURAS;
                      break;
                    case "tipo_rc":
                      options = SELECT_OPTIONS_RC
                      break
                    case "mascota":
                      options = SELECT_OPTIONS_MASCOTAS
                      break
                    case "tipo_transporte":
                      options = SELECT_OPTIONS_TIPO_TRANSPORTE
                      break
                    case "via":
                      options = SELECT_OPTIONS_VIA
                      break
                    case "tipo_embarcacion":
                      options = SELECT_OPTIONS_TIPO_EMBARCACION
                      break
                    case "tipo_vehiculo":
                      options = SELECT_OPTIONS_TIPO_VEHICULO
                      break
                    case "material_cuadro":
                      options = SELECT_OPTIONS_MATERIAL_CUADRO
                      break
                    case "uso":
                      options = SELECT_OPTIONS_USO
                      break
                    default:
                      break;
                  }
                  return (
                    <Col desktop={4}>
                      <NewInputSelect
                        name={campo.name_campo}
                        labelName={campo.label_campo}
                        color={formErrors[campo.name_campo] ? "error" : "normal"}
                        placeholder={campo.placeholder_campo}
                        onChange={handleChange}
                        value={values[campo.name_campo]}
                        options={options}
                        disabled={false}
                      />
                      {formErrors[campo.name_campo] && (
                        <ErrorInput>{formErrors[campo.name_campo]}</ErrorInput>
                      )}
                    </Col>
                  );
                case "date":
                    return (
                      <Col desktop={4}>
                        <NewInputCalendar
                          type={campo.tipo_campo}
                          name={campo.name_campo}
                          labelName={campo.label_campo}
                          color={formErrors[campo.name_campo] ? "error" : "normal"}
                          placeholder={campo.placeholder_campo}
                          onChange={handleChange}
                          value={values[campo.name_campo]}
                          disabled={false}
                        />
                        {formErrors[campo.name_campo] && (
                          <ErrorInput>{formErrors[campo.name_campo]}</ErrorInput>
                        )}
                      </Col>
                    );
                default:
                  break;
              }
            })}
          </Grid>
          </Container>
          : null}

      {campos.campos.some(item => item.tipo_campo === "textarea") ?
      <>
      <hr />
      <Container>
        <Grid colGap={21} rowGap={21} narrow={true}>
          {campos.campos
            .filter((item) => item.tipo_campo === "textarea")
            .map((campo) => {
              const aclaracion = aclaraciones.find(item => item.id_campo_cobertura === campo.id_campo)
              return (
                <Col desktop={12}>
                  <NewInputTextarea
                    type={campo.tipo_campo}
                    name={campo.name_campo}
                    labelName={campo.label_campo}
                    color={formErrors[campo.name_campo] ? "error" : "normal"}
                    placeholder={campo.placeholder_campo}
                    onChange={handleChange}
                    value={values[campo.name_campo]}
                    disabled={false}
                  />
                  {aclaracion ? <AclaracionInput><FiAlertCircle />{aclaracion.descripcion_aclaracion}</AclaracionInput> : null}
                  {formErrors[campo.name_campo] && (
                    <ErrorInput>{formErrors[campo.name_campo]}</ErrorInput>
                  )}
                </Col>
              );
            })}
        </Grid>
      </Container>
      </> : null}
    
          
          {campos.camposFile.length > 0 ?
          <>
          {campos.campos.length > 0 ? <hr /> : null}
    
          <Container>
          <Grid colGap={21} rowGap={21} narrow={true}>
              {seguroValues.producto_cobertura_asegurada == "5" ?
              <Col desktop={12}>
                <AclaracionAutoMoto><span className="icon"><FiAlertCircle /></span>Recordá que el cartel con la fecha debe estar colocado de modo que no obstruya la visión de las luces, espejos o patente, lo mismo con el costado del vehículo.
                </AclaracionAutoMoto>
              </Col>
              : null}
              <Col desktop={12}>
                <AclaracionLimiteArchivo>
                  <div className="icon-container">
                    <span className="icon"><FiFile /></span>
                  </div>
                  <div>
                  <h4>Subida de archivos</h4>
                  <span>El tamaño límite de subida de archivos es de 5MB. En caso de que el archivo supere el límite, <a href="https://www.freeconvert.com/es/video-compressor" target="_blank">reducí su tamaño usando este link.</a></span>
                  </div>
                </AclaracionLimiteArchivo>
              </Col>
              {campos.camposFile.map((campo) => {
                const aclaracion = aclaraciones.find(
                  (aclaracion) => aclaracion.id_campo_cobertura === campo.id_campo
                );
                return (
                  <Col desktop={4}>
                    <InputFile
                      type={campo.tipo_campo}
                      name={campo.name_campo}
                      labelName={campo.label_campo}
                      required={false}
                      onChange={handleChangeExternal}
                      file={values[campo.name_campo]}
                      aclaracion={aclaracion}
                      text={"Adjuntar" + " " + campo.label_campo}
                      accept={campo.extensiones_campo}
                    />
                    {formErrors[campo.name_campo] && (
                      <ErrorInput>{formErrors[campo.name_campo]}</ErrorInput>
                    )}
                    {campo.name_campo === "archivo_video" ? <ComprimirVideo href="https://www.freeconvert.com/es/video-compressor" target="_blank"><FiFilm />Reducir tamaño del video si pesa 5MB o más</ComprimirVideo> : null}
                    {imagenesEjemplo[campo.name_campo] && <EjemploAutoMoto href={imagenesEjemplo[campo.name_campo]} target="_blank"><FiEye />Ver ejemplo</EjemploAutoMoto>}
                  </Col>
                );
              })}
            </Grid>
          </Container>
          </>
          : null}
      
        </>
      }

    </StepContainer>
  );
};

export default BienAseguradoForm;
