import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../constants";

export const Container = styled.div`
  .notificacion-container {
    display: flex;
    gap: 2px;
    align-items: center;
  }

  .blob {
    background: ${leerColor(colores.verde)};
    border-radius: 50%;
    margin: 10px;
    height: 10px;
    width: 10px;

    box-shadow: 0 0 0 0 ${leerColor(colores.verde)};
    transform: scale(1);
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(61, 159, 84, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(61, 159, 84, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(61, 159, 84, 0);
    }
  }

  .notificacion {
    font-size: 14px;
    color: ${leerColor(colores.verde)};
    cursor: pointer;
    font-weight: 500;

    :hover {
        opacity: 0.8;
    }
  }
`