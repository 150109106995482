import React, { useEffect, useState } from "react";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import TitlePageSection from "../../../components/TitlePageSection";
import Sidebar from "../../../components/Sidebar";
import { Container } from "./styled";
import axiosRequest from "../../../utils/axiosConfig";
import { Col, Grid } from "../../../components/Grid";
import { useParams, useLocation } from "react-router-dom";
import EditarPremioForm from "../../../components/PremiosForm/EditarPremioForm";
import { useTabs } from "../../../hooks/useTabs";
import NewTabs from "../../../components/NewTabs";
import { PREMIOS_LABELS } from "../../../constants/labels";
import NewInputSelect from "../../../components/NewInputSelect";
import NewInputBasic from "../../../components/NewInputBasic";
import Subtitle from "../../../components/Subtitle";
import { FiArrowLeft, FiArrowUpRight, FiBarChart, FiDollarSign, FiFile, FiStar, FiTarget, FiUser } from "react-icons/fi";
import { useForm } from "../../../hooks/useForm";
import ErrorInput from "../../../components/ErrorInput";
import { crearGrupoForm } from "../../../utils/formInitialValues";
import { crearGrupoReglas } from "../../../utils/formRules";
import { meses, anios } from "../../../constants/data";
import NewButton from "../../../components/NewButton";
import useModal from "../../../hooks/useModal";
import ModalObjetivos from "../../../components/Modals/Vendedor/Objetivos";
import axios from "axios";
import BarChart from "../../../components/BarChart";
import { useLoading } from "../../../hooks/useLoading";
import SpinnerLoading from "../../../components/SpinnerLoading";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import PieChart from "../../../components/PieChart";
import dayjs from "dayjs";
import LineChart from "../../../components/LineChart";
import Subtitulo from "../../../components/Subtitulo";
import formatSelectData from "../../../utils/formatSelectData";

const Vendedor = () => {

  const vendedor = useParams();
  const location = useLocation();
  const mesActual = new Date().getMonth();
  const objetivosModal = useModal();
  const userNombre = JSON.parse(localStorage.getItem("userInfo"))?.nombre_operador
  const userRol = JSON.parse(localStorage.getItem("userInfo"))?.id_rol;

  const vendedorNombre = location.state?.nombre ? location.state.nombre : userNombre
  const [premios, setPremios] = useState([]);
  const [premiosMes, setPremiosMes] = useState(location.state?.data ? location.state.data : []);
  const { handleTab, tabSelected } = useTabs();
  const { inputValues, handleChange, handleValidation, formErrors } = useForm(
    null,
    crearGrupoReglas,
    crearGrupoForm
  );
  const [mes, setMes] = useState(location.state?.mes ? location.state.mes : new Date().getMonth());
  const [anio, setAnio] = useState(new Date().getFullYear());
  const [objetivos, setObjetivos] = useState(null);
  const [data, setData] = useState(null);
  const [diasHabiles, setDiasHabiles] = useState(null);
  const [prima, setPrima] = useState(null);
  const [operaciones, setOperaciones] = useState(null);
  const { loading, setLoading } = useLoading();
  const [formaPago, setFormaPago] = useState([])
  const [rendimientoDiario, setRendimientoDiario] = useState({})
  const [grupo, setGrupo] = useState([])
  const [grupoData, setGrupoData] = useState(null)
  const [productos, setProductos] = useState([])
  const [productoSeleccionado, setProductoSeleccionado] = useState(1)

  useEffect(() => {
    const getProductos = async () => {
      try {
        const productos = await axiosRequest.get('/premios/productos')
        const productosList = formatSelectData(productos.data, 'numero', 'descripcion', 'numero')
        setProductos(productosList)
      } catch (error) {
        console.log(error)
      }
    }
    getProductos()
  }, [])

  useEffect(() => {
    const getVendedor = async () => {
      try {
        const rendimientoDiario = await axiosRequest.get(
          `/premios/vendedor_rendimiento_diario/${vendedor.id}/${mes}/${productoSeleccionado}`
        );
        console.log(rendimientoDiario.data)

        const month =
        parseInt(mes) + 1 > 9 ? parseInt(mes) + 1 : `0${parseInt(mes) + 1}`;
        const feriados = await axios.get(
          "https://api.argentinadatos.com/v1/feriados/2024"
        );
        const feriadosMes = feriados.data.filter((item) =>
          item.fecha.split("-")[1].includes(month)
        );

        const diasMes = new Date("2024", parseInt(mes) + 1, 0).getDate();
        const labels = Array.from({ length: diasMes + 1}).map((_, index) => {
          const day = index + 1 < 10 ? `0${index + 1}` : index
          const month = parseInt(mes) + 1 < 10 ? `0${parseInt(mes) + 1}` : mes
          return `${day}/${month}`
        })

        console.log(labels)

        const days = labels.map((label, index) => {
          const find = rendimientoDiario.data.operacionesPrimaPorDia.find((item) => {
            return parseInt(dayjs(item.fechaucac).format("D")) === index + 1;
          });
          if (find) {
            return find.operaciones;
          } else {
            return 0;
          }
        });
        const productosEmitidosPrima = labels.map((label, index) => {
          const find = rendimientoDiario.data.productoPorDiaEmitido.find((item) => {
            return parseInt(dayjs(item.fechaucac).format("D")) === index + 1;
          });
          if (find) {
            return find.prima;
          } else {
            return 0;
          }
        });

        const productosEmitidosOperaciones = labels.map((label, index) => {
          const find = rendimientoDiario.data.productoPorDiaEmitido.find((item) => {
            return parseInt(dayjs(item.fechaucac).format("D")) === index + 1;
          });
          if (find) {
            return find.operaciones;
          } else {
            return 0;
          }
        });

        const prima = labels.map((label, index) => {
          const find = rendimientoDiario.data.operacionesPrimaPorDia.find((item) => {
            return parseInt(dayjs(item.fechaucac).format("D")) === index + 1;
          });
          if (find) {
            return find.prima;
          } else {
            return 0;
          }
        });

        const productosContratadosPrima = labels.map((label, index) => {
          const find = rendimientoDiario.data.productoPorDiaContratado.find((item) => {
            return parseInt(dayjs(item.fechauc).format("D")) === index + 1;
          });
          if (find) {
            return find.prima;
          } else {
            return 0;
          }
        });

        const productosContratadosOperaciones = labels.map((label, index) => {
          const find = rendimientoDiario.data.productoPorDiaContratado.find((item) => {
            return parseInt(dayjs(item.fechauc).format("D")) === index + 1;
          });
          if (find) {
            return find.operaciones;
          } else {
            return 0;
          }
        });
      
        const operacionesContratado = labels.map((label, index) => {
          const find = rendimientoDiario.data.operacionesPrimaPorDiaContratado.find((item) => {
            return parseInt(dayjs(item.fechauc).format("D")) === index + 1;
          });
          if (find) {
            return find.operaciones;
          } else {
            return 0;
          }
        });
      
        const primaContratado = labels.map((label, index) => {
          const find = rendimientoDiario.data.operacionesPrimaPorDiaContratado.find((item) => {
            return parseInt(dayjs(item.fechauc).format("D")) === index + 1;
          });
          if (find) {
            return find.prima;
          } else {
            return 0;
          }
        });
      
        const objetivoOperacionesPorDia = labels.map((label, index) => {
          console.log(label)
          const dia = label.split("/")[0]
          let day = new Date("2024", parseInt(mes), dia).getDay();
          if (day != 0 && day != 6) {
              return rendimientoDiario.data.objetivoOperacionesPorDia
            } else {
              return 0
            }
        });
      
        const objetivoPrimaPorDia = labels.map((label, index) => {
          const dia = label.split("/")[0]
          let day = new Date("2024", parseInt(mes), dia).getDay();
          if (day != 0 && day != 6) {
              return rendimientoDiario.data.objetivoPrimaPorDia
            } else {
              return 0
            }
        });
      
        const options = {
          responsive: true,
          scales: {
            x: {
              stacked: false,
            },
            y: {
              stacked: false,
            },
          },
          plugins: {
            title: {
              display: true,
              text: 'Operaciones emitidas por día',
            },
          },
        };
      
        const options2 = {
          responsive: true,
          scales: {
            x: {
              stacked: false,
            },
            y: {
              stacked: false,
            },
          },
          plugins: {
            title: {
              display: true,
              text: 'Prima emitida por día',
            },
          },
        };
      
        const optionsOperacionesContratadas = {
          responsive: true,
          scales: {
            x: {
              stacked: false,
            },
            y: {
              stacked: false,
            },
          },
          plugins: {
            title: {
              display: true,
              text: 'Operaciones contratadas por día',
            },
          },
        };
      
        const optionsPrimaContratada = {
          responsive: true,
          scales: {
            x: {
              stacked: false,
            },
            y: {
              stacked: false,
            },
          },
          plugins: {
            title: {
              display: true,
              text: 'Prima contratada por día',
            },
          },
        };

        const productoLabel = productos.find(item => item.value == productoSeleccionado)?.label
      
        const dataPrimaContratado = {
          labels: labels,
          datasets: [
            {
              label: "Prima contratada",
              data: primaContratado,
              fill: false,
              borderColor: "rgb(26, 86, 255)",
              tension: 0.001,
            },
            {
              label: productoLabel,
              data: productosContratadosPrima,
              fill: false,
              borderColor: "rgb(0, 255, 179)",
              tension: 0.001,
            },
            {
              label: "Prima esperada",
              data: objetivoPrimaPorDia,
              fill: false,
              borderColor: "rgb(210, 27, 118)",
              tension: 0.001,
            },
          ],
        };
      
        const dataOperacionesContratado = {
          labels: labels,
          datasets: [
            {
              label: "Operaciones contratadas",
              data: operacionesContratado,
              fill: false,
              borderColor: "rgb(26, 86, 255)",
              tension: 0.001,
            },
            {
              label: productoLabel,
              data: productosContratadosOperaciones,
              fill: false,
              borderColor: "rgb(0, 255, 179)",
              tension: 0.001,
            },
            {
              label: "Operaciones esperadas",
              data: objetivoOperacionesPorDia,
              fill: false,
              borderColor: "rgb(210, 27, 118)",
              tension: 0.001,
            },
          ],
        };

      
        const data = {
          labels: labels,
          datasets: [
            {
              label: "Operaciones por día",
              data: days,
              fill: false,
              borderColor: "rgb(26, 86, 255)",
              tension: 0.001,
            },
            {
              label: productoLabel,
              data: productosEmitidosOperaciones,
              fill: false,
              borderColor: "rgb(0, 255, 179)",
              tension: 0.001,
            },
            {
              label: "Operaciones esperadas",
              data: objetivoOperacionesPorDia,
              fill: false,
              borderColor: "rgb(210, 27, 118)",
              tension: 0.001,
            },
          ],
        };

        const data2 = {
          labels: labels,
          datasets: [
            {
              label: "Prima por día",
              data: prima,
              fill: false,
              borderColor: "rgb(26, 86, 255)",
              tension: 0.001,
            },
            {
              label: productoLabel,
              data: productosEmitidosPrima,
              fill: false,
              borderColor: "rgb(0, 255, 179)",
              tension: 0.001,
            },
            {
              label: "Prima esperada",
              data: objetivoPrimaPorDia,
              fill: false,
              borderColor: "rgb(210, 27, 118)",
              tension: 0.001,
            },
          ],
        };
      
        setRendimientoDiario({ data, data2, dataPrimaContratado, dataOperacionesContratado, options, options2, optionsOperacionesContratadas, optionsPrimaContratada })
      } catch (error) {
        console.log(error);
      }
  
  };
  getVendedor();
  }, [mes, productoSeleccionado, productos])

  useEffect(() => {
    const getVendedor = async () => {
        try {
          const formaPago = await axiosRequest.get(
            `/premios/vendedor_forma_pago/${mes}/${vendedor.id}`
          );
          console.log(formaPago)
          const dataPie = {
            labels: formaPago.data.forma_pago.map((item) => item.forma_pago),
            datasets: [
              {
                label: "Operaciones emitidas: ",
                data: formaPago.data.forma_pago.map((item) => item.operaciones),
                backgroundColor: [
                  "rgb(26, 86, 255)",
                  "rgb(116, 255, 227)",
                  "rgb(125, 188, 255)",
                ],
                hoverOffset: 4,
              },
            ],
          };
          setFormaPago(dataPie);
        } catch (error) {
          console.log(error);
        }
    
    };
    getVendedor();
  }, [userRol, mes])

  useEffect(() => {
    const getVendedor = async () => {
      if (!location.state) {
        try {
          setPremios([]);
          const data = await axiosRequest.get(
            `/premios/historial/vendedor/${vendedor.id}/${mes}`
          );
          const premios = await axiosRequest.get(
            `/premios/vendedor/${vendedor.id}/${mes}`
          );
          setPremiosMes(data.data);
          setPremios(premios.data);
        } catch (error) {
          console.log(error);
        }
      }
    };
    getVendedor();
  }, [])

  useEffect(() => {
    const getVendedor = async () => {
      if(vendedor){
        try {
          setGrupo([])
          const grupo = await axiosRequest.get(
            `/premios/grupo/${vendedor.id}/${parseInt(mes) + 1}`
          );
          const grupos = await axiosRequest.get(
            `/premios/grupos/${parseInt(mes) + 1}`
          );
          if(grupo.data){
            const grupoData = grupos.data.find(item => item.id_vendedores_grupo == grupo.data.id_grupo)
            setGrupoData(grupoData)
            setGrupo(grupo.data);
          }

        } catch (error) {
          console.log(error);
        }
      }
    };
    getVendedor();
  }, [vendedor, mes])

  useEffect(() => {
    const getVendedor = async () => {
      if (vendedor) {
        try {
          setLoading(true);
          setObjetivos(null)
          const data = await axiosRequest.get(
            `/premios/vendedor/${vendedor.id}/${mes}`
          );
          const objetivos = await axiosRequest.get(
            `/premios/vendedor/objetivos/${vendedor.id}/${mes}`
          );
          const vendedorData = await axiosRequest.get(
            `/premios/vendedordata/${vendedor.id}/${mes}`
          );
          const prima = await axiosRequest.get(`/premios/prima/${vendedor.id}`);

          const labels = [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
          ];
          const dataPrima = {
            labels,
            datasets: [
              {
                label: "Objetivo prima",
                data: prima.data.map((item) => item.objetivo_prima),
                backgroundColor: "#c4d4ff",
              },
              {
                label: "Prima obtenida",
                data: prima.data.map((item) => Math.round(item.prima)),
                backgroundColor: "#1A56FF",
              },
            ],
          };
          const dataOperaciones = {
            labels,
            datasets: [
              {
                label: "Objetivo operaciones",
                data: prima.data.map((item) => item.objetivo_operaciones),
                backgroundColor: "#c4d4ff",
              },
              {
                label: "Operaciones realizadas",
                data: prima.data.map((item) => item.operaciones),
                backgroundColor: "#1A56FF",
              },
            ],
          };

          setPrima(dataPrima);
          setOperaciones(dataOperaciones);
          setPremios(data.data);
          setObjetivos(objetivos.data);
          setData(vendedorData.data);
          setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    };
    getVendedor();
  }, [vendedor, mes]);

  useEffect(() => {
    const getVendedor = async () => {
      if (mes) {
        try {
          setPremiosMes([])
          setPremios([]);
          const data = await axiosRequest.get(
            `/premios/historial/vendedor/${vendedor.id}/${mes}`
          );
          const premios = await axiosRequest.get(
            `/premios/vendedor/${vendedor.id}/${mes}`
          );
          setPremiosMes(data.data);
          setPremios(premios.data);
        } catch (error) {
          console.log(error);
        }
      }
    };
    getVendedor();
  }, [mes]);

  const refreshData = async () => {
    if (vendedor) {
      try {
        setObjetivos(null)
        setLoading(true)
        const objetivos = await axiosRequest.get(
          `/premios/vendedor/objetivos/${vendedor.id}/${mesActual}`
        );
        setObjetivos(objetivos.data);
        setLoading(false)
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const getDiasHabiles = async () => {
      try {
        setDiasHabiles(null);
        const month =
          parseInt(mes) + 1 > 9 ? parseInt(mes) + 1 : `0${parseInt(mes) + 1}`;
        const feriados = await axios.get(
          "https://api.argentinadatos.com/v1/feriados/2024"
        );
        const feriadosMes = feriados.data.filter((item) =>
          item.fecha.split("-")[1].includes(month)
        );
        let diasHabiles = 0;
        let diasHabilesTranscurridos = 0;

        const diasMes = new Date("2024", parseInt(mes) + 1, 0).getDate();
        for (let i = 1; i <= diasMes; i++) {
          let day = new Date("2024", parseInt(mes), i).getDay();
          let today = new Date().getDate();
          if (day != 0 && day != 6) {
            diasHabiles++;

            if (today >= i) {
              diasHabilesTranscurridos++;
            }
          }
        }

        const actualMonth = new Date().getMonth();

        if (actualMonth != mes) {
          diasHabilesTranscurridos = diasHabiles;
        }
        diasHabiles -= feriadosMes.length;
        diasHabilesTranscurridos -= feriadosMes.length;
        setDiasHabiles({ diasHabiles, diasHabilesTranscurridos });
      } catch (error) {
        console.log(error);
      }
    };
    getDiasHabiles();
  }, [mes]);

  const tabsOptions = [
    {
      name: "Detalle",
    },
    {
      name: "Rendimiento anual",
    },
    {
      name: "Rendimiento diario",
    },
    {
      name: "Edición de premios",
    },
  ];
  let tabs = []
  if(userRol === 10 || userRol === 18){
    const filter = tabsOptions.filter(item => item.name !== "Edición de premios")
    tabs = filter
  } else {
    tabs = tabsOptions
  }

  const formatter = new Intl.NumberFormat("es-ES", {});

  return (
    <div>
      <div>
        {objetivosModal.isModalOpen ? (
          <ModalObjetivos
            modal={objetivosModal}
            vendedor={location.state.data}
            objetivos={objetivos}
            refreshData={refreshData}
          />
        ) : null}
        <Sidebar />
        <Wrapper>
          <Inner>
            <Container>
              <div>
                {userRol !== 10 && userRol !== 18 ?
                <Link to="/Premios" className="back-button">
                  <FiArrowLeft />
                  Volver a lista de vendedores
                </Link>
                : null}
                <TitlePageSection
                  title={vendedorNombre}
                  description="Lista de premios ganados y estadísticas de rendimiento general"
                >
                  {userRol !== 10 && userRol !== 18 ?
                  <NewButton
                    backgroundColor={true}
                    onClick={() => objetivosModal.setIsModalOpen(true)}
                  >
                    Actualizar objetivos
                  </NewButton>
                  : null}
                </TitlePageSection>
              </div>
              <NewTabs
                tabs={tabs}
                tabSelected={tabSelected}
                handleTab={handleTab}
              />
              {tabSelected !== 1 ?
              <Grid colGap={21} rowGap={21} narrow={true}>
                <Col desktop={2}>
                  <NewInputSelect
                    name={"mes"}
                    labelName={"Mes"}
                    onChange={(e) => setMes(e.target.value)}
                    placeholder={"Mes"}
                    options={meses}
                    value={mes}
                  />
                </Col>
                {/*
                    <Col desktop={2}>
                      <NewInputSelect
                        name={"anio"}
                        labelName={"Año"}
                        onChange={(e) => setAnio(e.target.value)}
                        placeholder={"Año"}
                        options={anios}
                        value={anio}
                      />
                    </Col>
                    */}
              </Grid>
              : null}
              {tabSelected === 0 ? (
                <>
                  <hr />
                  <Subtitle
                    icon={<FiStar />}
                    title={`Premios del mes de ${meses
                      .find((m) => m.value == mes)
                      ?.label.toLowerCase()}`}
                    description={"Valores obtenidos para todos los premios. Hacé click en cada uno para conocer más detalles."}
                    disableDivider={true}
                  />
                  <div className="tope-premios">
                  <p>El tope de la suma de premios es <strong>$400.000</strong>. El tope de la suma de premios para quienes cumplan sus objetivos y prima es <strong>$450.000</strong>.</p>
                  </div>
                  {premiosMes?.premios?.length > 0 ?
                  <Grid colGap={21} rowGap={21} narrow={true}>
                    {premiosMes.premios.map((premio) => {
                      if (premio !== "vendedor" && premio !== "id_vendedor") {
                        return (
                          <Col desktop={3}>
                            <Link
                              to={{
                                pathname: `/Premio/Vendedor/${vendedor.id}/${premio.id_premio}`,
                                state: {
                                  premio: { nombre: premio.nombre, valor: formatter.format(premio.premio)},
                                  data: premios.find(prem => prem.id_vendedor_premio == premio.id_premio),
                                  mes: mes,
                                  vendedor: vendedorNombre
                                },
                              }}
                            >
                              <div
                                className={`premio-container ${
                                  premio === "total" && "total"
                                }`}
                              >
                                <span>{premio.nombre}</span>
                                <h2>${formatter.format(premio.premio)}</h2>
                              </div>
                            </Link>
                          </Col>
                        );
                      }
                    })}
                    <Col desktop={3}>
                      <div className={`premio-container total`}>
                        <span>Total</span>
                        <h2>${formatter.format(premiosMes.total)}</h2>
                      </div>
                    </Col>
                  </Grid>
                  : <SpinnerLoading />}



              {grupo?.integrantes?.length > 0 ?
              <>
                            <hr />
              <Subtitle
                icon={<FiUser />}
                title={"Grupo"}
                description={"Prima de los integrantes del grupo"}
                disableDivider={true}
              />
              <Grid colGap={21} rowGap={21} narrow={true}>
                {grupo.integrantes.length > 0 ? 
                <>
                {grupo.integrantes.map((item, index) => {
                  console.log(item)
                      return (
                        <Col desktop={3}>
                          <div
                            className={`vendedor-container ${item.id_vendedor != vendedor.id && 'no-seleccionado' }`}
                          >
                            <h2>#{index + 1}</h2>
                            <span className="vendedor-nombre">
                              {item.nombre}
                            </span>
                            <span>${formatter.format(Math.round(item.prima))}</span>
                          </div>
                        </Col>
                      );
                    })}
                    {userRol !== 10 && userRol !== 18 && grupoData ?
                    <Col desktop={12}>
                    <Link
                              to={{
                                pathname: `/Premios/Vendedores/Grupos/${grupo.id_grupo}`,
                                state: grupoData,
                              }}
                            ><NewButton backgroundColor={true}>Ver grupo <FiArrowUpRight /></NewButton></Link>
                    </Col>
                    : null}
                    </>
                  : <SpinnerLoading />}
              </Grid>
              </>
              : null}
              
                  <hr />
                  <Subtitle
                    icon={<FiTarget />}
                    title={`Objetivos del mes de ${meses
                      .find((m) => m.value == mes)
                      ?.label.toLowerCase()}`}
                    description={"Operaciones y prima a realizar"}
                    disableDivider={true}
                  />
                  {objetivos && 
                    <Grid colGap={21} rowGap={21} narrow={true}>
                      <Col desktop={3}>
                        <div className="premio-container">
                          <span>Objetivo operaciones</span>
                          <h2>{objetivos.operaciones}</h2>
                        </div>
                      </Col>
                      <Col desktop={3}>
                        <div className="premio-container">
                          <span>Objetivo prima</span>
                          <h2>${formatter.format(objetivos.prima)}</h2>
                        </div>
                      </Col>
                      {userRol !== 10 && userRol !== 18 ?
                       <Col desktop={12}>
                  <NewButton
                    backgroundColor={true}
                    onClick={() => objetivosModal.setIsModalOpen(true)}
                  >
                    Actualizar objetivos
                  </NewButton>
                  </Col>
                  : null}
                     
                    </Grid>
                  }
                  {!objetivos && loading && <SpinnerLoading />}
                  {!objetivos && !loading && 
                  <div className="sin-solicitudes-container">
                  <span className="icon-sin-solicitudes">
                    <FiTarget />
                  </span>
                  <Subtitulo>No hay objetivos cargados</Subtitulo>
                  <span className="description">
                    No se encontraron objetivos establecidos para el mes de {meses
                      .find((m) => m.value == mes)
                      ?.label.toLowerCase()}
                  </span>
                  {userRol !== 10 && userRol !== 18 ?
                  <NewButton
                    backgroundColor={true}
                    onClick={() => objetivosModal.setIsModalOpen(true)}
                  >
                    Crear objetivos
                  </NewButton>
                  : null}
                </div>
                  }

                  <hr />
                  <Subtitle
                    icon={<FiBarChart />}
                    title={`Estadísticas del mes de ${meses
                      .find((m) => m.value == mes)
                      ?.label.toLocaleLowerCase()}`}
                    description={`Datos sobre el desempeño de ${vendedorNombre}`}
                    disableDivider={true}
                  />
                  {data && diasHabiles ? (
                    <>
                    <Grid colGap={21} rowGap={21} narrow={true}>
                      <Col desktop={3}>
                        <div className="premio-container">
                          <span>Operaciones realizadas</span>
                          <h2>{data.operaciones}</h2>
                        </div>
                      </Col>
                      {objetivos?.operaciones ?
                      <Col desktop={3}>
                        <div className="premio-container">
                          <span>Porcentaje operaciones</span>
                          <h2>
                            {Math.round(
                              (100 * data.operaciones) / objetivos.operaciones
                            )}
                            %
                          </h2>
                        </div>
                      </Col>
                      : null}
                      <Col desktop={3}>
                        <div className="premio-container">
                          <span>Prima obtenida</span>
                          <h2>
                            ${formatter.format(Math.round(data.totalPrima))}
                          </h2>
                        </div>
                      </Col>
                      {objetivos?.prima ? 
                      <Col desktop={3}>
                        <div className="premio-container">
                          <span>Porcentaje prima</span>
                          <h2>
                            {Math.round(
                              (100 * data.totalPrima) / objetivos.prima
                            )}
                            %
                          </h2>
                        </div>
                      </Col>
                      : null}
                      <Col desktop={3}>
                        <div className="premio-container">
                          <span>Dias hábiles</span>
                          <h2>{diasHabiles.diasHabiles}</h2>
                        </div>
                      </Col>
                      <Col desktop={3}>
                        <div className="premio-container">
                          <span>Dias hábiles transcurridos</span>
                          <h2>{diasHabiles.diasHabilesTranscurridos}</h2>
                        </div>
                      </Col>
                    </Grid>
                    <Grid colGap={21} rowGap={21} narrow={true}>
                      <Col desktop={12}>
                      <div className={`total-container pie`}>
                        <PieChart data={formaPago} />
                      </div>
                    </Col> 
                    </Grid>
                    </>
                  ) : (
                    <SpinnerLoading />
                  )}
                </>
              ) : null}
              {tabSelected === 3 ? (
                <>
                <hr />
                <Grid colGap={21} rowGap={21} narrow={true}>
                  {premios.length > 0 ? (
                    premios.map((prem, index) => {
                      console.log(premios);
                      return (
                        <Col desktop={12}>
                          <EditarPremioForm
                            prem={prem}
                            vendedorId={vendedor.id}
                            mes={mes}
                          />
                        </Col>
                      );
                    })
                  ) : (
                    <SpinnerLoading
                      text={"Cargando información de premios..."}
                    />
                  )}
                </Grid>
                </>
              ) : null}

              {tabSelected === 1 ? (
                <>
                  {prima ? (
                    <>
                      <Subtitle
                        icon={<FiBarChart />}
                        title={"Prima"}
                        description={`Evolución de la prima obtenido por ${vendedorNombre} a lo largo del año`}
                        disableDivider={true}
                      />
                      <Grid colGap={21} rowGap={21} narrow={true}>
                        <Col desktop={12}>
                          <BarChart data={prima} />
                        </Col>
                      </Grid>
                    </>
                  ) : null}
                  <hr />
                  {operaciones ? (
                    <>
                      <Subtitle
                        icon={<FiBarChart />}
                        title={"Operaciones"}
                        description={`Evolución de la cantidad de operaciones realizadas por ${vendedorNombre} a lo largo del año`}
                        disableDivider={true}
                      />
                      <Grid colGap={21} rowGap={21} narrow={true}>
                        <Col desktop={12}>
                          <BarChart data={operaciones} />
                        </Col>
                      </Grid>
                    </>
                  ) : null}
                </>
              ) : null}

              {tabSelected === 2 ? 
                <>
                {rendimientoDiario ?
                <>
                <hr />
                <Subtitle
                    icon={<FiBarChart />}
                    title={`Prima`}
                    description={`Evolución de prima contratadas y emitidas por día`}
                    disableDivider={true}
                  />
                <Grid colGap={21} rowGap={21} narrow={true}>

                <Col desktop={2}>
                  <NewInputSelect
                    name={"producto"}
                    labelName={"Producto"}
                    onChange={(e) => setProductoSeleccionado(e.target.value)}
                    placeholder={"Producto"}
                    options={productos}
                    value={productoSeleccionado}
                  />
                </Col>
                <Col desktop={12}>
                    <LineChart data={rendimientoDiario.data2} options={rendimientoDiario.options2} />
                </Col>
                <Col desktop={12}>
                    <LineChart data={rendimientoDiario.dataPrimaContratado} options={rendimientoDiario.optionsPrimaContratada} />
                </Col>
                </Grid>
                <hr />
                <Subtitle
                    icon={<FiBarChart />}
                    title={`Operaciones`}
                    description={`Evolución de operaciones contratadas y emitidas por día`}
                    disableDivider={true}
                  />
                <Grid colGap={21} rowGap={21} narrow={true}>
                <Col desktop={12}>
                    <LineChart data={rendimientoDiario.data} options={rendimientoDiario.options}/>
                </Col>
                <Col desktop={12}>
                    <LineChart data={rendimientoDiario.dataOperacionesContratado} options={rendimientoDiario.optionsOperacionesContratadas} />
                </Col>
              </Grid>
              </>
              : null}
                </>
              : null}
            </Container>
          </Inner>
        </Wrapper>
      </div>
    </div>
  );
};

export default Vendedor;
