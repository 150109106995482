export const INITIAL_SINIESTROS_STATE = {
    siniestros: [],
    siniestrosLength: null,
    estados: [],
    tipos: [],
    companias: [],
    ramos: [],
    prioridades: [],
    productos: [],
    estadisticas: {}
}

export const siniestrosReducer = (state, action) => {
    switch(action.type) {
        case 'INITIAL_DATA':
            return action.payload
        case 'REFRESH_SINIESTROS':
            return { 
                ...state, 
                siniestros: action.payload.siniestros,
                siniestrosLength: action.payload.siniestrosLength
            }
        default: 
            return state
    }
}