import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../../constants";

export const ContainerBotones = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
`;

export const Aclaracion = styled.p`
    margin: 0;
    background-color: #f3fbff;
    color: ${leerColor(colores.celeste)};
    padding: 20px;
    margin-bottom: 15px;
    font-weight: 500;

    span {
      color: ${leerColor(colores.celeste)};
      margin-right: 10px;
      font-weight: 800;
      font-size: 18px;
    }

    code {
        font-weight: 600;
        font-size: 14px;
        background-color: ${leerColor(colores.celeste)};
        color: #f3fbff;
        padding: 2px 4px;
        border-radius: 2px;
    }
`;