import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../../constants";

export const Container = styled.div`
  width: 900px;
  padding: 40px 70px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 450px;
  // 
  background: #fff;
  font-family: 'DM Sans', sans-serif;
  border-radius: 8px;

  .icon {
    color: ${leerColor(colores.blanco)}
  }

  hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    margin: 20px 0 20px 0;
  }

  h1{ 
    margin-top:0;
  }

  h3{
    margin: 0;
    //padding-bottom: 20px;
    font-weight: 500;
    font-size: 16px;
    color: ${leerColor(colores.gris)};
  }

  h2{
    color: ${leerColor(colores.celeste)};
    margin: 10px 0;
  }

  h4{
    font-weight:600;
    color: ${leerColor(colores.azul)};
    margin:10px 0;
  }

  .fedpat-warning {
    margin: 0;
    background-color: #f3fbff;
    color: ${leerColor(colores.celeste)};
    font-weight:600;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;

    span {
      color: ${leerColor(colores.celeste)};
      margin-right: 10px;
    }
  }

  span{
    font-size:16px;
    font-weight: 500;
    //color: ${leerColor(colores.gris)};
    color: rgb(82, 82, 82);
  }

  .url-example{
    color: ${leerColor(colores.grisModales)};
    display: block;
    margin-top: 8px;
    font-size: 14px;
  }

  .preview-container-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .preview-url{
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px 0;
    border:1px dashed ${leerColor(colores.gris)};

    div{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding:40px;

      img{
        width: 64px;
        margin-bottom: 10px;
      }
    }

    span{
      color: ${leerColor(colores.grisModales)};
    }

    /*
    .error{
       
      color: ${leerColor(colores.rojo)};
      display: block;
      margin-top: 8px;

    }
    */

    img{
      width: 100%;
      cursor: pointer;
      transition:0.5s;

      :hover{
        opacity: 0.7;
      }
    }
  }

  .remove-margin-bottom{
    margin-bottom:0;
  }

  .animation-container{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    h3{
      color: ${leerColor(colores.celeste)};
      margin: 24px 0 8px 0;
    }

    span{
      font-weight: 400;
    }

    svg{
      width:100%;
      margin:15px 0;
    }
  }

  #Cursor{
    animation: cursor 2.2s ease-in-out infinite;
  }

  @keyframes cursor {
    0%{
      transform: translate(0px, 0px);
    }
    80%{
      transform: translate(-405px, -130px);
    }
    100%{
      transform: translate(-405px, -130px);
    }
  }

  #CheckboxShape{
    animation: checkbox 2.2s ease-in-out infinite;
  }

  @keyframes checkbox {
    0%{
      fill: #fff;
    }
    75%{
      fill: #fff;
    }
    80%{
      fill: #0075ff;
    }
    100%{
      fill: #0075ff;
    }
  }

  .error{
     
    color: ${leerColor(colores.rojo)};
    display:block;
    margin-top: 8px;
    font-size: 14px;
  }

  .actionButtonsCont {
    display: flex;
    justify-content: space-between;
    margin-top:20px;
  }

  .buttonsCont{
    display: flex;
    place-content: flex-end;
    //margin-top: 48px;
    margin-top:10px;
    width:100%;
  }

  .buttonsContSiniestro{
    display: flex;
    place-content: flex-end;
    margin-top: 30px;
  }

  .buttonsCont a, .buttonsContSiniestro a {
    margin-left: 25px;
  }

  /* ScrollBar */
  &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #e6e6e6;
      border-radius: 10px;
    }
    
    &::-webkit-scrollbar-thumb {
      background: ${leerColor(colores.gris)}; 
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${leerColor(colores.grisEstados)};
    }

  .operadorCont{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:20px 0 40px 0;
    //border-top: 1px solid ${leerColor(colores.celeste)};

    a{
      margin-left:20px;
    }
    
    span{
      display:inline-block;
      margin-right:10px;
    }

    div{
      //display: flex;
      //align-items: center;
    }
  }
  
  @media (min-width: ${breakpoints.mobileGrande}) {

  }
  @media (min-width: ${breakpoints.tablet}) {
    
  }
  @media (min-width: ${breakpoints.laptop}) {

  }
  @media (min-width: ${breakpoints.laptopL}) {
    max-height: 600px;
  }
  @media (min-width: ${breakpoints.notebook}) {
    max-height: 600px;
  }
`;