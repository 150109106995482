import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Subtitulo,
  Titulo,
  InfoBoard,
  Grid,
  Col,
  Inner,
  InputBasic,
  InputSelect,
  InputTextarea,
  ProgressBar,
  Menu,
  Wrapper,
  Modal,
  NewProgressBar,
  BurgerMenu, 
  NavMenu
} from "../components/Index";
import { SolEmisionTitleContainer } from './styled'
import axiosRequest from "../utils/axiosConfig";
import Filters from "../components/Filters"
import { anios, meses } from "../constants/data";

const WhatsApp = () => {
		// Información del usuario logueado 
		const user = JSON.parse(localStorage.getItem('userInfo'))
		// State que abre y cierra el menú de navegación
		const [open, setOpen] = useState(false)
		// State que almacena la data que se muestra en la tabla
		const [data, setData] = useState([])
		// State que guarda los params que se van a utilizar para filtrar en el backend
		const [filterParams, setFilterParams] = useState('')
		// State para definir si existen resultados o no (si está en true muestra el loading)
		const [hayBusquedas, setHayBusquedas] = useState(false)
		
		// Headers de la tabla
		const headers = ["Id", "Nombre", "Año", "Mes", "Temas"]
		
		// Objeto donde se almacenan los que se tipea / selecciona en los filtros de la página.
		const values = {
		   mes: '',
		   anio: ''
		}
		
		// Array de objetos con los inputs que funcionan como filtros.
		const inputs = [
		   {
		     name: "anio",
		     labelName: "Año",
		     options: anios,
		     component: InputSelect 
		   },
		   {
		     name: "mes",
		     labelName: "Mes",
		     options: meses,
		     component: InputSelect 
		   }
		]
		
        const sendMessage = async () => {
            try {
                const data = { "messaging_product": "whatsapp", "to": "541135779373", "type": "text", "text": { 
                    "preview_url": false,
                    "body": "Coldplay 26 de Octubre"
                } }
                const message = await axiosRequest.post('/whatsapp', data)
                console.log(message)
            } catch (error){
                console.log(error)
            }
        }
		
		return (
		        <div>
		            <BurgerMenu open={open} setOpen={setOpen}/>
		            <NavMenu open={open} setOpen={setOpen} user={user} active={"whatsapp"} />
		
		            <Wrapper>
		                <Inner>
		                <Grid colGap={21} rowGap={21} narrow={true}>
		                    <Col desktop={12}>
		                        <SolEmisionTitleContainer>
		                            <Titulo textColor={false}>WhatsApp</Titulo>
                                    <Button
                                        backgroundColor={true}
                                        borderColor={false}
                                        textColor={false}
                                        icon={false}
                                    >
                                        Agendar contacto
                                    </Button>
		                        </SolEmisionTitleContainer>
		                    </Col>        
		                </Grid>
                        <Grid colGap={21} rowGap={21} narrow={true}>
		                    <Col desktop={3}>
		                        <SolEmisionTitleContainer>
		                            <Titulo textColor={false}>Contactos</Titulo>
		                        </SolEmisionTitleContainer>
		                    </Col> 
                            <Col desktop={9}>
		                        <SolEmisionTitleContainer>
		                            <Titulo textColor={false}>Mensaje</Titulo>
		                        </SolEmisionTitleContainer>
		                    </Col>        
		                </Grid>

		                </Inner>
                        <Button
                            backgroundColor={true}
                            borderColor={false}
                            textColor={false}
                            icon={false}
                            onClick={sendMessage}
                        >
                            Enviar mensaje
                        </Button>
		            </Wrapper>
		        </div>
		 )
}

export default WhatsApp