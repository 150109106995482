import { useEffect, useState } from "react";
import { actualizarSolicitud, getMotivosRechazo, postMensajeHistorial } from "../services/Emision";
import dayjs from "dayjs"
import { useSocket } from "../context/SocketProvider";
import axiosRequest from "../utils/axiosConfig";

export const useRechazo = (solicitud) => {

  const socket = useSocket()
  const userRol = JSON.parse(localStorage.getItem('userInfo'))
  const [motivos, setMotivos] = useState([])
  const [popUpMessage, setPopUpMessage] = useState("")

  const postearRechazo = async (inputValues, handleModalRechazo, setIsModalExitoOpen, getSolicitud) => {
    try {
      console.log(solicitud)
      // Cambia el estado de la solicitud a "Rechazado (ID 3)", guarda el estado actual de la solicitud, agrega el motivo según lo seleccionado y completa la fecha de rechazo. 
      let data = solicitud;
      let prevEstado = solicitud.id_estado;
      data.id_estado = 3;
      data.id_estado_previo = prevEstado
      data.id_motivo = inputValues.motivo;
      data.rechazo_solicitud = dayjs(new Date()).format('DD/MM/YYYY HH:mm:ss')
      data.ingreso_solicitud = dayjs(data.ingreso_solicitud).isValid() ? dayjs(data.ingreso_solicitud).format('DD/MM/YYYY HH:mm:ss') : data.ingreso_solicitud

      let update = await actualizarSolicitud(solicitud.id, data)

      if(update){
        // Agrega el cambio de estado al historial de solicitudes.
        const dataSolicitudHistorial = {
          id_solicitud: solicitud.id,
          id_area: 1,
          id_operador: userRol.id_operador,
          id_accion_historial: 3,
          fecha_solicitud_historial: dayjs(new Date()).format('DD/MM/YYYY HH:mm:ss'),
          descripcion_solicitud_historial: 'Solicitud Rechazada.',
          eprevio_solicitud_historial: prevEstado,
          estado_solicitud_historial: 1,
          email_solicitudes_historial: 0,
          id_historial_email: null
        }
        postMensajeHistorial(dataSolicitudHistorial)
      }

      let motivoRechazoMensaje = null
    
      // Agrega el comentario adicional
      if(inputValues.motivo){
        motivoRechazoMensaje = motivos.find(motivo => {
          return inputValues.motivo === motivo.value
        })

        const motivoRechazo = {
          id_solicitud: solicitud.id,
          id_area: 1,
          id_operador: userRol.id_operador,
          id_accion_historial: 4,
          fecha_solicitud_historial: dayjs(new Date()).format('DD/MM/YYYY HH:mm:ss'),
          descripcion_solicitud_historial: `Motivo de rechazo: ${motivoRechazoMensaje.label}`,
          eprevio_solicitud_historial: prevEstado,
          estado_solicitud_historial: 1,
          email_solicitudes_historial: 0,
          id_historial_email: null
        }
        postMensajeHistorial(motivoRechazo)
      }

      // Agrega el comentario adicional
      if(inputValues.mensaje){
        const dataSolicitudHistorial = {
          id_solicitud: solicitud.id,
          id_area: 1,
          id_operador: userRol.id_operador,
          id_accion_historial: 4,
          fecha_solicitud_historial: dayjs(new Date()).format('DD/MM/YYYY HH:mm:ss'),
          descripcion_solicitud_historial: inputValues.mensaje,
          eprevio_solicitud_historial: prevEstado,
          estado_solicitud_historial: 1,
          email_solicitudes_historial: 0,
          id_historial_email: null
        }
        postMensajeHistorial(dataSolicitudHistorial)
      }

      const operadores = await axiosRequest.get('operadores')
      const usuario = operadores.data.find(operador => operador.id_operador === solicitud.vendedor_solicitud)
      
      const notificacion = {
        url_pagina: `/Emision/${solicitud.id}`,
        nombre_icono: "emision",
        nombre_pagina: "Historial Solicitudes",
        notificacion: `La solicitud ID ${solicitud.id} fue rechazada. Revisar historial de la solicitud para más información. - Motivo de rechazo: ${motivoRechazoMensaje.label}`,
        time: dayjs(new Date()).format("HH:mm"),
        id_usuario: usuario.id_usuario,
      }
      socket.emit('notificacion-crm-emision-rechazo', notificacion)
      await axiosRequest.post('/notificacion/crear', notificacion)

      handleModalRechazo();
      setPopUpMessage('Solicitud rechazada correctamente')
      setIsModalExitoOpen(true);
      getSolicitud()
      setTimeout(() => {
        setIsModalExitoOpen(false)
      }, 1200);
       
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const getMotivos = async () => {
      try {
        const motivos = await getMotivosRechazo()
        setMotivos(motivos)
      } catch (error) {
        console.log(error)
      }
    };
    getMotivos();
  }, []);

  return {
    motivos,
    postearRechazo,
    popUpMessage
  };
};
