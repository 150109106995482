import { Grid, Col } from "../../../components/Grid"
import Inner from "../../../components/Inner"
import Sidebar from "../../../components/Sidebar"
import TableInfo from "../../../components/TableInfo"
import Wrapper from "../../../components/Wrapper"
import TitlePageSection from "../../../components/TitlePageSection"
import FilterSection from "../../../components/FiltersSection"
import { Container } from "./styled"
import NotificacionPagina from "../../../components/NotificacionPagina"
// Custom Hooks
import useFiltersNew from "../../../hooks/useFiltersNew";
import { useTable } from "../../../hooks/useTable"
import { autogestionTable } from "../../../utils/tableData"
import { useAutogestion } from "../../../hooks/useAutogestion"
import { useSocket } from "../../../context/SocketProvider"
// Filters
import { filtersAutogestion } from "../../../utils/filtersPages";
import { filterAutogestionInitialValues } from "../../../utils/filtersPages";
import useModal from "../../../hooks/useModal"
import ModalPago from "../../../components/Modals/Autogestion/ModalPago"
import { useNotificacionesPage } from "../../../hooks/useNotificaciones"

const AutogestionRefactor = () => {

    const pagoModal = useModal()

    const { order, handleTableOrder } = useTable()

    // Filtros
    const { inputValues, params, handleSearch, handleChange, clearSearch } = useFiltersNew(filterAutogestionInitialValues)

    // Autogestion
    const { data, loading, pagination, abrirModalPago, modalLoading } = useAutogestion(params, order, pagoModal.handleModal)

    // Notificaciones
    const { notificaciones, resetNotificaciones, mensaje } = useNotificacionesPage(['autogestión'], data.refreshContrataciones)

    const filters = filtersAutogestion()

    const tableData = autogestionTable(abrirModalPago)

    return (
        <Container>

        <ModalPago isModalOpen={pagoModal.isModalOpen} setIsModalOpen={pagoModal.handleModal} data={data.pago} loading={modalLoading} />

        <Sidebar active="autogestion" />
          
        <Wrapper>
          <Inner>
            <Grid colGap={21} rowGap={21} narrow={true}>
              
              <Col desktop={12}>
                <TitlePageSection title="Autogestión" />
              </Col>
  
              <Col desktop={12}>
                <FilterSection section="contrataciones hechas a través de las landings" inputs={filters} values={inputValues} params={params} handleChange={handleChange} handleSearch={handleSearch} clearSearch={clearSearch} />
              </Col>

              <Col desktop={12}>
                {notificaciones.length > 0 &&
                  <NotificacionPagina mensaje={mensaje} resetNotificaciones={resetNotificaciones} />
                }
              </Col>
            </Grid>
  
            <TableInfo columns={tableData} data={data.contrataciones} loading={loading} order={handleTableOrder} orderParams={order} pagination={pagination} />
  
          </Inner>
        </Wrapper>
      </Container>
    )
}

export default AutogestionRefactor