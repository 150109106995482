import styled, { css } from 'styled-components';
import { breakpoints, leerColor, colores } from '../../constants';

export const InputDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #fff;

  label{
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    color: #202020;
    font-weight: 500;
  }

  textarea {
    box-sizing: border-box;
    width: 100%;
    height: 200px;
    background: #ffffff;
    border: 1.7px solid #eaeaea;
    border-radius: 8px;
    padding: 16px 20px;
    color: #6d6d6d;
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
    resize: none;

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:focus-visible{
      outline: none;
      border-color: ${leerColor(colores.celeste)};
    }
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield !important;
  }

  textarea:focus{
    border: 1.7px solid ${leerColor(colores.azulRebranding)};
    outline: none;
    z-index: 59 !important;
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1; /* Firefox */
    }
  }
`
export const Icon = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  position: absolute;
  top: 8px;
  right: 18px;

  .image{
    width: 13px !important;
    padding-bottom: 8px;
    margin-left: 5px;
    color: ${leerColor(colores.celeste)}
  }

  .tooltip{
    display: none;
    position: absolute;
    background-color: rgba(30, 30, 30, 0.5);
    color: #fff;
    top: -280px;
    right: 0;
    padding: 8px;
    font-size: 14px;
    text-align: center;
     
    font-weight: 500;
    border-radius: 5px;
    width: 500px;

    ::after {
      content: "";
      display: block;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
      top: 100%;
      left: 50%;
      position: absolute;
    }
  }

  :hover{
      .tooltip{
        display:block;
      }
  }
`

export const Input = styled.textarea` 
  /*
   
  height: ${(props) => (props.height ? props.height : `200px`)};
  font-weight: 400;
  font-size: 16px;
  padding: 12px 20px;
  //border: 1px solid ${leerColor(colores.gris)};
  border: ${(props) => (props.color === "error" ? `1px solid ${leerColor(colores.rojo)}` : `1px solid ${leerColor(colores.gris)}`)};
  resize: none;
  &::placeholder{
    color: ${leerColor(colores.gris)};
  }
  &:focus-visible{
    outline: none;
    border-color: ${leerColor(colores.celeste)};
  }
  @media (min-width: ${breakpoints.mobileGrande}) {

  }
  @media (min-width: ${breakpoints.tablet}) {
    
  }
  @media (min-width: ${breakpoints.laptop}) {

  }
  @media (min-width: ${breakpoints.laptopL}) {
    
  }
  @media (min-width: ${breakpoints.notebook}) {

  }
  */
`;
