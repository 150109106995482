import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { SelectDiv, Select } from "./styled";
import { Step } from "react-stepz";

function InputSelectAPI({ labelName, name, onChange, options, disabled, value, color, size }) {

  const [items, setItems] = useState([]);

  // Se guarda en el state "items" toda la data que viene en la prop "options"
  useEffect(() => {
    if(options){
      setItems(options)
      const check_selected = options.filter(option => option?.selected)
      if(check_selected.length !== 0){
        check_selected.forEach(option => { option.step(prev => ({...prev, [name]: option.value, }))})
      }
    }
  }, [options])

  return (
    <SelectDiv size={size}>
      <select 
        name={name}
        id={name}
        color={color}
        onChange={onChange}
        disabled={disabled}
        value={value}
        size={size}
      >
        <option value={''} disabled defaultValue={''}> </option>
        {items.map((item, index) => (
          <option
            key={index + 1}
            value={item.value}
          >
            {item.label}
          </option>
        ))}
      </select>
      <label
        className={
          value == null || value == ""
            ? "label-down"
            : "label-top"
        }
        htmlFor={name}
        color={color}
        size={size}
      >
        {labelName}
      </label>
      <i></i>
    </SelectDiv>
  );
}

export default InputSelectAPI;
