import React from "react";
import { Container, Tab } from "./styled";

const NewTabs = ({ tabs, tabSelected, handleTab }) => {
  return (
    <Container>
      <div className={"tabsContainer"}>
        {tabs.map((tab, index) => {
            return (
              <Tab className={tabSelected === index ? "tabSelected" : ""} onClick={()=> handleTab(index, tab.name)} key={index}>
                {tab?.image ? <img src={tab.image} alt={`Logo de ${tab.name}`} /> : tab.name}
              </Tab>
            )
          })}
      </div>
    </Container>
  );
};

export default NewTabs;
