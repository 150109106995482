import { Grid, Col } from "../../../components/Grid"
import InputBasic from "../../../components/InputBasic"
import Button from "../../../components/Button"
import { IlustracionContainer, LoginContainer, ContainerLogin, Container } from "./styled"
import { useLogin } from "../../../hooks/useLogin"
import Titulo from "../../../components/Titulo"
import EdificioGyS from '../../../assets/images/login-1.svg'
import ErrorInput from "../../../components/ErrorInput"
// Icons
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"

const LoginRefactor = () => {

    const { form, passwordType, handlePasswordType } = useLogin()
    const { inputValues, handleChange, formErrors, handleValidation } = form

    return (
        <Container>
            <IlustracionContainer>
                <img className={'ilustracion'} src={EdificioGyS} alt={"Ilustracion Login"} />
            </IlustracionContainer>
            <LoginContainer>
                <ContainerLogin>
                    <img src="https://files.gyssrl.ar/api/v1/storage/image/ebb231c0-05d8-454e-bd9a-bcba17a8061c" alt={"Gestion y Servicios"} />
                    <div>
                        <Titulo>¡Bienvenido al CRM!</Titulo>
                        <p>Usá tus credenciales para iniciar sesión.</p>
                    </div>
                    <Grid colGap={21} rowGap={21} narrow={true}>
                        <Col desktop={12}>
                            <InputBasic
                                type={"text"}
                                name={"usuario"}
                                labelName={"Usuario"}
                                placeholder={"usuario@seguroweb.com.ar"}
                                onChange={handleChange}
                                value={inputValues.usuario}
                            />
                            {formErrors.usuario && <ErrorInput>{formErrors.usuario}</ErrorInput>}
                        </Col>
                        <Col desktop={12}>
                            <InputBasic
                                type={passwordType}
                                name={"password"}
                                labelName={"Contraseña"}
                                placeholder={"Ingresa tu contraseña"}
                                onChange={handleChange}
                                value={inputValues.password}
                                iconImage={passwordType === "password" ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                                iconCallback={handlePasswordType}
                            />
                            {formErrors.password && <ErrorInput>{formErrors.password}</ErrorInput>}
                        </Col>
                    </Grid>
                    <Button
                        backgroundColor={true}
                        borderColor={false}
                        textColor={false}
                        icon={false}
                        onClick={handleValidation}
                    >
                        Iniciar Sesión
                    </Button>
                </ContainerLogin>
            </LoginContainer>
        </Container>
    )
}

export default LoginRefactor