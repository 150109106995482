import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  background: #00000010;
  backdrop-filter: blur(10px);
  animation-name: opacidad;
  animation-duration: 0.1s;
  animation-iteration-count: initial;
  animation-timing-function: ease-out;
  z-index: 200;
  //background-color: red;

  @keyframes opacidad {
    from {
      opacity: 0%;
    }
    50% {
      opacity: 50%;
    }
    to {
      opacity: 100%;
    }
  }

  .carousel {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  &.closeCarousel {
    display: none;
  }

  .hide {
    display: none;
  }

  #boton-cerrar-container {
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 30px;
    color: ${leerColor(colores.negro)};
    font-size: 25px;
    transition: 0.2s all;
  }

  #boton-cerrar-container:hover {
    transform: scale(1.15);
  }
  
  span.info{
    font-size: 10px;
    font-weight: 600;
    background-color: #edfff2;
    color: ${leerColor(colores.verde)};
    border-radius: 20px;
    padding: 6px 12px;
  }
`;

export const Box = styled.div`
  min-width: 800px;
  height: auto;
  max-height: 90vh;
  //min-height: calc(671px - 17px - 17px);
  //top: 100px;
  //top:300px;
  //left: 0px;
  //right: 0px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  padding: 17px;
  //margin-top: calc(100vh - 85vh - 100px);
  margin: 0 auto;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;
  //background: #fff;
  background: ${props => (!props.background ? "" : "#fff")};
  border-radius: 6px;
  animation-name: zoom;
  animation-duration: 0.15s;
  animation-iteration-count: initial;
  animation-timing-function: ease-out;

  @keyframes zoom {
    from {
      opacity: 0%;
      transform: scale(0.7) translate(-50%, -50%);
    }
    50% {
      opacity: 40%;
    }
    to {
      opacity: 100%;
      transform: scale(1) translate(-50%, -50%);
    }
  }

  > h3 {
     
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    color: ${leerColor(colores.grisModales)};
  }

  > p {
     
    margin: 0;
    font-weight: 400;
    font-size: 18px;
    color: ${leerColor(colores.grisModales)};
  }
`;
