import React from "react";
import Modal from "../../../Modal";
import { ModalEmision, RechazoContainer } from "./styled";
import Button from "../../../Button";
import Titulo from "../../../Titulo";
import InputFile from "../../../InputFile";
import { Col, Grid } from "../../../Grid";
import { useForm } from "../../../../hooks/useForm";
import ErrorInput from "../../../ErrorInput";
import { objetivosReglas, polizaReglas } from "../../../../utils/formRules";
import { objetivosForm, polizaForm } from "../../../../utils/formInitialValues";
import {
  actualizarOperacion,
  actualizarSolicitud,
  postMensajeHistorial,
  postSolicitudPoliza,
} from "../../../../services/Emision";
import InputBasic from "../../../InputBasic";
import dayjs from "dayjs";
import sendFiles from "../../../../utils/uploadFile";
import { useLoading } from "../../../../hooks/useLoading";
import SpinnerLoading from "../../../SpinnerLoading";
import ModalSuccess from "../../../ModalSuccess";
import useModal from "../../../../hooks/useModal";
import { useSocket } from "../../../../context/SocketProvider";
import axiosRequest from "../../../../utils/axiosConfig";
import NewButton from "../../../NewButton";
import NewTitle from "../../../NewTitle";
import NewInputBasic from "../../../NewInputBasic";
import { useEffect } from "react";

const ModalObjetivos = ({ modal, vendedor, objetivos, refreshData }) => {
  const { isModalOpen, handleModal } = modal;
  const {
    inputValues,
    handleChange,
    formErrors,
    handleValidation,
    completeForm
  } = useForm(handleSubmit, objetivosReglas, objetivosForm);

  const { loading, setLoading, loadingText, changeLoadingText } =
    useLoading(false);
  const exitoModal = useModal();

  useEffect(() => {
    if(objetivos){
      const { operaciones, prima } = objetivos
      completeForm({operaciones, prima})
    }
  }, [objetivos])

  async function handleSubmit() {
    setLoading(true);
    changeLoadingText("Creando objetivos...");
    try {
      if(objetivos){
        const update = await axiosRequest.put(`/premios/vendedor_objetivos/${vendedor.id_vendedor}`, inputValues)
        if(update.status === 200){
          refreshData()
          handleModal();
          exitoModal.setIsModalOpen(true);
          setTimeout(() => {
            exitoModal.setIsModalOpen(false);
          }, 1200);
          setLoading(false);
        }
      } else {
        const crear = await axiosRequest.post(`/premios/vendedor/objetivos/${vendedor.id_vendedor}`, inputValues)
        if(crear.status === 200){
          handleModal();
          exitoModal.setIsModalOpen(true);
          setTimeout(() => {
            exitoModal.setIsModalOpen(false);
          }, 1200);
          setLoading(false);
        }
      }

    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <div>
      <ModalSuccess
        isSuccessPopUpOpen={exitoModal.isModalOpen}
        setIsSuccessPopUpOpen={exitoModal.handleModal}
        popUpMessage={"¡Objetivos cargados!"}
      />
      <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal}>
        <RechazoContainer>
              <div className="header-container">
                <NewTitle>Objetivos</NewTitle>
                <p>
                  Completá los objetivos de {vendedor.vendedor} para el mes de junio
                </p>
              </div>
              <hr />
              <div className="data-container">
                <Grid colGap={21} rowGap={21} narrow={true}>
                  <Col desktop={6}>
                    <NewInputBasic
                      type="number"
                      labelName="Operaciones"
                      name="operaciones"
                      placeholder="100"
                      onChange={handleChange}
                      value={inputValues.operaciones}
                    />
                    {formErrors.operaciones && (
                      <ErrorInput>{formErrors.operaciones}</ErrorInput>
                    )}
                  </Col>
                  <Col desktop={6}>
                    <NewInputBasic
                      type="number"
                      labelName="Prima"
                      name="prima"
                      placeholder="1000000"
                      onChange={handleChange}
                      value={inputValues.prima}
                    />
                    {formErrors.prima && (
                      <ErrorInput>{formErrors.prima}</ErrorInput>
                    )}
                  </Col>
                </Grid>
              </div>
              <hr />
              <div className={"actionButtonsCont"}>
                <div className="buttonsCont">
                  <NewButton
                    backgroundColor={false}
                    textColor={true}
                    borderColor={true}
                    onClick={handleModal}
                  >
                    Cancelar
                  </NewButton>
                  <NewButton backgroundColor={true} onClick={handleValidation} disabled={loading}>
                    {loading ? `${objetivos ? "Actualizando" : "Creando"} objetivos...` : `${objetivos ? "Actualizar" : "Crear"} objetivos`}
                  </NewButton>
                </div>
              </div>

        </RechazoContainer>
      </Modal>
    </div>
  );
};

export default ModalObjetivos;
