import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Container = styled.div`
  .tabsContainer {
    display: flex;
    gap: 20px;
    font-size: 14px;
    border-bottom: 1px solid #eaeaea;
  }

  .tab {
    color: #6d6d6d;
    cursor: pointer;
    padding-bottom: 10px;
  }

  .tabSelected {
    color: #1a56ff;
    font-weight: 500;
    border-bottom: 1px solid #1a56ff;
  }
`;

export const Tab = styled.div`
  color: #6d6d6d;
  cursor: pointer;
  padding-bottom: 10px;

  .tabSelected {
    color: #1a56ff;
    font-weight: 500;
    border-bottom: 1px solid #1a56ff;
  }
`;
