import React, { useRef, useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import IdleTimer from 'react-idle-timer';
import Modal from '../Modal'
import Button from '../Button'
import { useHistory } from 'react-router-dom';
import { ModalIdle } from "./styled";

const Index = () => {

    const [reDirect, setReDirect] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState()
    const history = useHistory(); // Hook de router

    const IdleTimerRef = useRef(null);

    const onIdle = () => {
        const user = localStorage.getItem("userInfo")
        if(user){
            localStorage.clear();
            setIsModalOpen(true)
            setReDirect(true);
        }
    }

    const redirectToLogin = () => {
        setIsModalOpen(false)
        history.push("/");
    }

    return (
        <div>
 
            <IdleTimer
                ref={IdleTimerRef}
                timeout={60 * 1000 * 60}
                onIdle={onIdle}
            />

            {reDirect ? 
                <Modal isModalOpen={isModalOpen} hideCross={true} >
                    <ModalIdle>
                        <h3>El tiempo de sesión expiró</h3>
                        <Button backgroundColor={true} borderColor={false} textColor={false} onClick={redirectToLogin}>Volver a iniciar sesión</Button>
                    </ModalIdle>
                </Modal>
            : 
                null
            }

        </div>
    );
}

export default Index;