import Modal from "../../../Modal";
import { ModalHistorialContainer } from "../../../InfoBoard/styled"
import Titulo from "../../../Titulo";
import { Grid, Col } from "../../../Grid";
import Button from "../../../Button";
import Subtitulo from "../../../Subtitulo";
import InputBasic from "../../../InputBasic";
import InputSelect from "../../../InputSelect";
import InputCalendar from "../../../InputCalendar";
import InputCheckbox from "../../../InputCheckbox";
import ErrorInput from "../../../ErrorInput";
import SpinnerLoading from "../../../SpinnerLoading";
import { ContainerBotones, Aclaracion } from "./styled";
// Icons
import { ImWarning } from "react-icons/im";

const ModalCrearUsuario = ({ isModalOpen, setIsModalOpen, data, form, loading, editMode, loadingText }) => {

    const { estados, roles } = data
    const { inputValues, handleChange, handleChangeCheckbox, formErrors, handleValidation } = form 

    return (
        <div>
            <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
                <ModalHistorialContainer>
                  <Titulo>{!editMode ? "Crear Usuario" : "Editar Usuario"}</Titulo>
                  {!loading ?
                  <Grid colGap={21} rowGap={21} narrow={true}>
                                        <Col desktop={12}>
                                            <Subtitulo>Información de operador</Subtitulo>
                                        </Col>
                                        <Col desktop={4}>
                                            <InputBasic
                                                type={"text"}
                                                name={"nombre_operador"}
                                                labelName={"Nombre"}
                                                id={"nombre_operador"}
                                                placeholder={"Nombre"}
                                                onChange={handleChange}
                                                color={formErrors.nombre_operador?'error':''}
                                                value={inputValues.nombre_operador}
                                                required
                                            />
                                            {formErrors.nombre_operador && <ErrorInput>{formErrors.nombre_operador}</ErrorInput>}
                                        </Col>
                                        <Col desktop={4}>
                                            <InputBasic
                                                type={"text"}
                                                name={"apellido_operador"}
                                                labelName={"Apellido"}
                                                id={"apellido_operador"}
                                                placeholder={"Apellido"}
                                                onChange={handleChange}
                                                value={inputValues.apellido_operador}
                                                required
                                            />
                                            {formErrors.apellido_operador && <ErrorInput>{formErrors.apellido_operador}</ErrorInput>}
                                        </Col>
                                        <Col desktop={4}>
                                            <InputBasic
                                                type={"text"}
                                                name={"email_operador"}
                                                labelName={"Email"}
                                                id={"email_operador"}
                                                placeholder={"Email"}
                                                onChange={handleChange}
                                                color={formErrors.email_operador?'error':''}
                                                value={inputValues.email_operador}
                                                required
                                            />
                                            {formErrors.email_operador && <ErrorInput>{formErrors.email_operador}</ErrorInput>}
                                        </Col>
                                        <Col desktop={4}>
                                            <InputCalendar
                                                type={"date"}
                                                name={"nacimiento_operador"}
                                                labelName={"Fecha de Nacimiento"}
                                                id={"usuario_usuario"}
                                                placeholder={"Nombre"}
                                                onChange={handleChange}
                                                value={inputValues.nacimiento_operador}
                                                color={formErrors.nacimiento_operador?'error':''}
                                                required
                                            />
                                            {formErrors.nacimiento_operador && <ErrorInput>{formErrors.nacimiento_operador}</ErrorInput>}
                                        </Col>
                                        <Col desktop={4}>
                                            <InputBasic
                                                type={"text"}
                                                name={"telefono_operador"}
                                                labelName={"Telefono"}
                                                id={"telefono_operador"}
                                                placeholder={"123456"}
                                                onChange={handleChange}
                                                value={inputValues.telefono_operador}
                                                color={formErrors.telefono_operador?'error':''}
                                                required
                                            />
                                            {formErrors.telefono_operador && <ErrorInput>{formErrors.telefono_operador}</ErrorInput>}
                                        </Col>
                                        <Col desktop={4}>
                                            <InputBasic
                                                type={"text"}
                                                name={"telefono_b_operador"}
                                                labelName={"Telefono 2"}
                                                id={"telefono_b_operador"}
                                                placeholder={"123456"}
                                                onChange={handleChange}
                                                value={inputValues.telefono_b_operador}
                                                required
                                            />
                                            {formErrors.telefono_b_operador && <ErrorInput>{formErrors.telefono_b_operador}</ErrorInput>}
                                        </Col>
                                        <Col desktop={4}>
                                            <InputBasic
                                                type={"text"}
                                                name={"dni_operador"}
                                                labelName={"Documento"}
                                                id={"dni_operador"}
                                                placeholder={"123456"}
                                                onChange={handleChange}
                                                value={inputValues.dni_operador}
                                                color={formErrors.dni_operador?'error':''}
                                                required
                                            />
                                            {formErrors.dni_operador && <ErrorInput>{formErrors.dni_operador}</ErrorInput>}
                                        </Col>
                                        <Col desktop={4}>
                                            <InputBasic
                                                type={"text"}
                                                name={"cuit_operador"}
                                                labelName={"CUIT"}
                                                id={"cuit_operador"}
                                                placeholder={""}
                                                onChange={handleChange}
                                                value={inputValues.cuit_operador}
                                                color={formErrors.cuit_operador?'error':''}
                                                required
                                            />
                                            {formErrors.cuit_operador && <ErrorInput>{formErrors.cuit_operador}</ErrorInput>}
                                        </Col>
                                        <Col desktop={4}>
                                            <InputBasic
                                                type={"text"}
                                                name={"direccion_calle_operador"}
                                                labelName={"Direccion"}
                                                id={"direccion_calle_operador"}
                                                placeholder={""}
                                                onChange={handleChange}
                                                value={inputValues.direccion_calle_operador}
                                                required
                                            />
                                            {formErrors.direccion_calle_operador && <ErrorInput>{formErrors.direccion_calle_operador}</ErrorInput>}
                                        </Col>
                                        <Col desktop={4}>
                                            <InputBasic
                                                type={"text"}
                                                name={"direccion_numero_operador"}
                                                labelName={"Número"}
                                                id={"direccion_numero_operador"}
                                                placeholder={""}
                                                onChange={handleChange}
                                                value={inputValues.direccion_numero_operador}
                                                required
                                            />
                                            {formErrors.direccion_numero_operador && <ErrorInput>{formErrors.direccion_numero_operador}</ErrorInput>}
                                        </Col>
                                        <Col desktop={4}>
                                            <InputBasic
                                                type={"text"}
                                                name={"direccion_piso_operador"}
                                                labelName={"Piso"}
                                                id={"direccion_piso_operador"}
                                                placeholder={""}
                                                onChange={handleChange}
                                                value={inputValues.direccion_piso_operador}
                                                required
                                            />
                                            {formErrors.direccion_piso_operador && <ErrorInput>{formErrors.direccion_piso_operador}</ErrorInput>}
                                        </Col>
                                        <Col desktop={4}>
                                            <InputBasic
                                                type={"text"}
                                                name={"direccion_dpto_operador"}
                                                labelName={"Departamento"}
                                                id={"direccion_dpto_operador"}
                                                placeholder={""}
                                                onChange={handleChange}
                                                value={inputValues.direccion_dpto_operador}
                                                required
                                            />
                                            {formErrors.direccion_dpto_operador && <ErrorInput>{formErrors.direccion_dpto_operador}</ErrorInput>}
                                        </Col>
                                        <Col desktop={4}>
                                            <InputBasic
                                                type={"text"}
                                                name={"codigo_postal_operador"}
                                                labelName={"Código Postal"}
                                                id={"codigo_postal_operador"}
                                                placeholder={""}
                                                onChange={handleChange}
                                                value={inputValues.codigo_postal_operador}
                                                required
                                            />
                                            {formErrors.codigo_postal_operador && <ErrorInput>{formErrors.codigo_postal_operador}</ErrorInput>}
                                        </Col>
                                        <Col desktop={4}>
                                            <InputBasic
                                                type={"text"}
                                                name={"provincia_operador"}
                                                labelName={"Provincia"}
                                                id={"provincia_operador"}
                                                placeholder={""}
                                                onChange={handleChange}
                                                value={inputValues.provincia_operador}
                                                required
                                            />
                                            {formErrors.provincia_operador && <ErrorInput>{formErrors.provincia_operador}</ErrorInput>}
                                        </Col>
                                        <Col desktop={4}>
                                            <InputBasic
                                                type={"text"}
                                                name={"localidad_operador"}
                                                labelName={"Localidad"}
                                                id={"localidad_operador"}
                                                placeholder={""}
                                                onChange={handleChange}
                                                value={inputValues.localidad_operador}
                                                required
                                            />
                                            {formErrors.localidad_operador && <ErrorInput>{formErrors.localidad_operador}</ErrorInput>}
                                        </Col>
                                        <Col desktop={4}>
                                            <InputSelect
                                                type={"text"}
                                                name={"condicion_operador"}
                                                labelName={"Condición"}
                                                id={"condicion_operador"}
                                                placeholder={""}
                                                onChange={handleChange}
                                                value={inputValues.condicion_operador}
                                                disabled
                                            />
                                            {formErrors.condicion_operador && <ErrorInput>{formErrors.condicion_operador}</ErrorInput>}
                                        </Col>
                                    
                               
                                                <Col desktop={4}>
                                                    <InputBasic
                                                        type={"text"}
                                                        name={"clave_usuario"}
                                                        labelName={"Contraseña"}
                                                        id={"clave_usuario"}
                                                        onChange={handleChange}
                                                        value={inputValues.clave_usuario}
                                                        color={formErrors.clave_usuario?'error':''}
                                                        required
                                                    />
                                                    {formErrors.clave_usuario && <ErrorInput>{formErrors.clave_usuario}</ErrorInput>}
                                                </Col>
                                            
                                   

                                        <Col desktop={12}>
                                            <Subtitulo>Información de usuario</Subtitulo>
                                        </Col>
                                        <Col desktop={4}>
                                                <InputBasic
                                                    type={"text"}
                                                    name={"usuario_usuario"}
                                                    labelName={"Cuenta"}
                                                    id={"usuario_usuario"}
                                                    placeholder={"ejemplo@seguroweb.com.ar"}
                                                    onChange={handleChange}
                                                    color={formErrors.usuario_usuario?'error':''}
                                                    value={inputValues.usuario_usuario}
                                                />
                                                {formErrors.usuario_usuario && <ErrorInput>{formErrors.usuario_usuario}</ErrorInput>}
                                            </Col>
                                            <Col desktop={4}>
                                                <InputBasic
                                                    type={"text"}
                                                    name={"interno_usuario"}
                                                    labelName={"Interno Usuario"}
                                                    id={"interno_usuario"}
                                                    placeholder={"interno"}
                                                    onChange={handleChange}
                                                    value={inputValues.interno_usuario}
                                                />
                                                {formErrors.interno_usuario && <ErrorInput>{formErrors.interno_usuario}</ErrorInput>}
                                            </Col>
                                            <Col desktop={4}>
                                                <InputBasic
                                                    type={"text"}
                                                    name={"whatsapp_usuario"}
                                                    labelName={"Whatsapp"}
                                                    id={"whatsapp_usuario"}
                                                    placeholder={"21313123"}
                                                    onChange={handleChange}
                                                    value={inputValues.whatsapp_usuario}
                                                />
                                                {formErrors.whatsapp_usuario && <ErrorInput>{formErrors.whatsapp_usuario}</ErrorInput>}
                                            </Col>
                                            <Col desktop={4}>
                                                <InputSelect
                                                    type={"text"}
                                                    name={"estado_usuario"}
                                                    labelName={"Estado"}
                                                    id={"estado_usuario"}
                                                    onChange={handleChange}
                                                    options={estados}
                                                    value={inputValues.estado_usuario}
                                                    color={formErrors.estado_usuario?'error':''}
                                                />
                                                {formErrors.estado_usuario && <ErrorInput>{formErrors.estado_usuario}</ErrorInput>}
                                            </Col>
                                            <Col desktop={4}>
                                                <InputSelect
                                                    type={"text"}
                                                    name={"id_rol"}
                                                    labelName={"Rol"}
                                                    id={"id_rol"}
                                                    onChange={handleChange}
                                                    options={roles}
                                                    value={inputValues.id_rol}
                                                    color={formErrors.id_rol?'error':''}
                                                />
                                                {formErrors.id_rol && <ErrorInput>{formErrors.id_rol}</ErrorInput>}
                                            </Col>

                                            {(parseInt(inputValues.id_rol) === 10 || parseInt(inputValues.id_rol) === 13 || parseInt(inputValues.id_rol) === 18) &&
                                                <>
                                                <Col desktop={4}>
                                                    <InputCheckbox
                                                        name={"siniestros_habilitado_usuario"}
                                                        labelName={"Habilitar vista de siniestros"}
                                                        id={"siniestros_habilitado_usuario"}
                                                        onChange={handleChangeCheckbox}
                                                        value={inputValues.siniestros_habilitado_usuario}
                                                        checked={inputValues.siniestros_habilitado_usuario}
                                                        color={formErrors.siniestros_habilitado_usuario?'error':''}
                                                    />
                                                    {formErrors.siniestros_habilitado_usuario && <ErrorInput>{formErrors.siniestros_habilitado_usuario}</ErrorInput>}
                                                </Col>

                                                {inputValues.siniestros_habilitado_usuario && (parseInt(inputValues.id_rol) === 10 || parseInt(inputValues.id_rol) === 18) ? 
                                                    <>
                                                        <Col desktop={12}>
                                                            <h4 className="subtitulo">Tipos de siniestro que puede recibir el vendedor</h4>
                                                        </Col>
                                                        {!editMode && 
                                                            <Col desktop={12}>
                                                                <Aclaracion><span><ImWarning></ImWarning></span>Asegurate que el usuario con la cuenta <code>{inputValues.usuario_usuario}</code> esté creado en la tabla <code>sw_vendedores</code> en la base de datos "Novedades" de Siteground antes de asignar siniestros.</Aclaracion>
                                                            </Col>
                                                        }
                                                        <Col desktop={2}>
                                                            <InputCheckbox
                                                                name={"total"}
                                                                labelName={"Totales"}
                                                                id={"total"}
                                                                onChange={handleChangeCheckbox}
                                                                value={inputValues.total}
                                                                checked={inputValues.total}
                                                                color={formErrors.total?'error':''}
                                                            />
                                                        </Col>
                                                        <Col desktop={2}>
                                                            <InputCheckbox
                                                                name={"parcial"}
                                                                labelName={"Parciales"}
                                                                id={"parcial"}
                                                                onChange={handleChangeCheckbox}
                                                                value={inputValues.parcial}
                                                                checked={inputValues.parcial}
                                                                color={formErrors.parcial?'error':''}
                                                            />
                                                        </Col>
                                                    </>
                                                    : null
                                                }
                                                </>
                                            }

                                        <Col desktop={12}>
                                            {editMode ?
                                                <>
                                                <ContainerBotones>
                                                    <Button
                                                        backgroundColor={false}
                                                        borderColor={true}
                                                        textColor={true}
                                                        onClick={setIsModalOpen}
                                                        target={"_blank"}
                                                    >
                                                        Cancelar
                                                    </Button>
                                                    <Button
                                                        backgroundColor={true}
                                                        borderColor={false}
                                                        textColor={false}
                                                        onClick={handleValidation}
                                                        target={"_blank"}
                                                    >
                                                        Guardar cambios
                                                    </Button>
                                                </ContainerBotones>
                                                </>
                                            :
                                                <ContainerBotones>
                                                    <Button
                                                        backgroundColor={false}
                                                        borderColor={true}
                                                        textColor={true}
                                                        onClick={setIsModalOpen}
                                                    >
                                                        Cancelar
                                                    </Button>
                                                    <Button
                                                        backgroundColor={true}
                                                        borderColor={false}
                                                        textColor={false}
                                                        onClick={handleValidation}
                                                        target={"_blank"}
                                                    >
                                                        Crear Usuario
                                                    </Button>
                                                </ContainerBotones>
                                            }
                                        </Col>
                                    </Grid> 
                    : 
                        <Grid>
                            <Col desktop={12}>
                                <div className='loading-container'>
                                    <SpinnerLoading text={loadingText} />
                                </div>
                            </Col>
                        </Grid>
                    }
                </ModalHistorialContainer>
            </Modal>
        </div>
    )
}

export default ModalCrearUsuario