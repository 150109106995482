import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";
import { useNotificaciones } from "../../context/NotificacionesProvider";
import { FiBell } from "react-icons/fi";
import { PAGES_ICONS } from "../../constants/pagesIcons";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FiArrowRight } from "react-icons/fi";
import dayjs from "dayjs";
import NewTabs from "../NewTabs"
import {useTabs} from "../../hooks/useTabs"

const Notificaciones = () => {
  const history = useHistory();
  const [isNotificacionesOpen, setIsNotificacionesOpen] = useState();
  const { notificaciones, notificacionesLeidas, abrirNotificacion, marcarComoLeidas } = useNotificaciones();
  const { tabSelected, handleTab } = useTabs()

  const handleNotificacionOpened = (notificacion, index) => {
    abrirNotificacion(index, notificacion);
    if(notificacion.url_pagina.includes("?")){
      const query = notificacion.url_pagina.split("?")
      history.push({
        pathname: `/Siniestros`,
        search: `?${query[1]}`
      });
    } else {
      history.push({ pathname: notificacion.url_pagina });
    }
  };

  const tabs = [
    {
      name: "Sin leer"
    },
    {
      name: "Historial"
    }
  ]

  return (
    <>
      <span
        className="notificaciones-icono"
        onClick={() => setIsNotificacionesOpen(!isNotificacionesOpen)}
      >
        <FiBell color="#001040" />
        {notificaciones.length > 0 && (
          <span className="notificaciones-contador">
            {notificaciones.length}
          </span>
        )}
      </span>
      {isNotificacionesOpen ? (
        <div className="notificaciones-container">
          <div className="notificaciones-titulo-container">
            <h3>Notificaciones</h3>
            <span onClick={marcarComoLeidas}>Marcar como leídas</span>
          </div>
          <div className="tabs-container">
            <NewTabs tabs={tabs} tabSelected={tabSelected} handleTab={handleTab} />
          </div>

          {tabSelected === 0 ? 
            notificaciones.length > 0 ? notificaciones.map((notificacion, index) => {
              let notif = null
              if(notificacion.nombre_icono === "emision"){
                notif = notificacion.notificacion.split("-")
              }
              return (
                <div
                  className="notificacion-item"
                  onClick={() =>
                    handleNotificacionOpened(notificacion, index)
                  }
                >
                  <div className="notificacion-contenido-container">
                    <span className="pagina-icono">
                      {PAGES_ICONS[notificacion.nombre_icono]}
                    </span>
                    <div className="notificacion-contenido">
                      <div className="notificacion-titulo">
                        <h4>{notificacion.nombre_pagina}</h4>
                        <span className="notificacion-hora">
                          {dayjs(notificacion.dia).format("DD/MM HH:mm")}hs
                        </span>
                      </div>
                      {notif ? notif.map(item => {
                        return (
                          <div className="notif-container">
                            {item.split(":").map((data, index) => <span className={index === 0 && "notif-item"}>{data}{index === 0 && ":"}</span>)}
                          </div>
                        )
                      })
                      :
                      <span className="notif">{notificacion.notificacion}</span>
                      }
                      <Link to={notificacion.url_pagina}>
                        Ir a {notificacion.nombre_pagina}
                        <span className="notificacion-icono">
                          <FiArrowRight />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })
            : 
            <div className="empty-state-notificaciones">
              <span className="empty-state-notificaciones-icono">
                <FiBell />
              </span>
              <h4>No hay notificaciones</h4>
              <span className="empty-descripcion">No existen nuevas notificaciones para mostrar</span>
            </div>
          : null}

          {tabSelected === 1 ?
          notificacionesLeidas.length > 0 ?notificacionesLeidas.map((notificacion, index) => {
            let notif = null
            let fecha = null
            if(notificacion.nombre_icono === "emision"){
              notif = notificacion.notificacion.split("-")
            }
            if(index !== 0){
              
              const fechaActual = dayjs(notificacion.dia).format("DD/MM/YYYY")
              const fechaAnterior = dayjs(notificacionesLeidas[index - 1].dia).format("DD/MM/YYYY")

              console.log(fechaActual)
              console.log(fechaAnterior)

              if(fechaActual !== fechaAnterior){
                console.log("HEY YOU")
                fecha = fechaActual
              }
            }
            return (
              <>
              {fecha ? 
                <div className="fecha-container">
                  <span>{fecha}</span>
                </div>
              : null}
              <div
                className="notificacion-item"
              >

                <div className="notificacion-contenido-container">
                  <span className="pagina-icono">
                    {PAGES_ICONS[notificacion.nombre_icono]}
                  </span>
                  <div className="notificacion-contenido">
                    <div className="notificacion-titulo">
                      <h4>{notificacion.nombre_pagina}</h4>
                      <span className="notificacion-hora">
                        {dayjs(notificacion.dia).format("DD/MM HH:mm")}hs
                      </span>
                    </div>
                    {notif ? notif.map(item => {
                      return (
                        <div className="notif-container">
                          {item.split(":").map((data, index) => <span className={index === 0 && "notif-item"}>{data}{index === 0 && ":"}</span>)}
                        </div>
                      )
                    })
                    :
                    <span className="notif">{notificacion.notificacion}</span>
                    }
                    <Link to={notificacion.url_pagina}>
                      Ir a {notificacion.nombre_pagina}
                      <span className="notificacion-icono">
                        <FiArrowRight />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              </>
            );
          })
          : 
          <div className="empty-state-notificaciones">
          <span className="empty-state-notificaciones-icono">
            <FiBell />
          </span>
          <h4>No hay notificaciones</h4>
          <span className="empty-descripcion">Recordá que las notificaciones se borran después de 30 días</span>
        </div>
          : null}
        </div>
      ) : null}
    </>
  );
}

export default Notificaciones;

/*

<div className="empty-state-notificaciones">
              <span className="empty-state-notificaciones-icono">
                <FiBell />
              </span>
              <h4>No hay notificaciones</h4>
              <span className="empty-descripcion">Recordá que las notificaciones se borran después de 30 días</span>
            </div>
*/