export const landingsUrls = [
    {
      'label': 'Seguro Bici',
      'url': process.env.REACT_APP_ENV === "development" || process.env.REACT_APP_ENV === "staging" ? 'https://dev.segurobici.com.ar' : 'https://segurobici.com.ar'
    }, 
    {
      'label': 'Seguro Auto',
      'url': process.env.REACT_APP_ENV === "development" || process.env.REACT_APP_ENV === "staging" ? 'https://dev.auto.seguroweb.com.ar' : 'http://www.auto.seguroweb.com.ar/'
    },
    {
      'label': 'Seguro Web',
      'url': process.env.REACT_APP_ENV === "development" || process.env.REACT_APP_ENV === "staging" ? 'https://dev.seguroweb.com.ar' : 'https://seguroweb.com.ar'
    }
];

export const meses = [
  {
    'label': 'Enero',
    'value': 0,
    'key': 1
  }, 
  {
      'label': 'Febrero',
      'value': 1,
      'key': 2
  },
  {
    'label': 'Marzo',
    'value': 2,
    'key': 3
  },
  {
    'label': 'Abril',
    'value': 3,
    'key': 4
  },
  {
    'label': 'Mayo',
    'value': 4,
    'key': 5
  },
  {
    'label': 'Junio',
    'value': 5,
    'key': 6
  },
  {
    'label': 'Julio',
    'value': 6,
    'key': 7
  },
  {
    'label': 'Agosto',
    'value': 7,
    'key': 8
  },
  {
    'label': 'Septiembre',
    'value': 8,
    'key': 9
  },
  {
    'label': 'Octubre',
    'value': 9,
    'key': 10
  },
  {
    'label': 'Noviembre',
    'value': 10,
    'key': 11
  },
  {
    'label': 'Diciembre',
    'value': 11,
    'key': 12
  }
];

export const anios = [
  {
    'label': '2024',
    'value': '2024',
    'key': 4
  },
  {
    'label': '2023',
    'value': '2023',
    'key': 3
  }, 
  {
      'label': '2022',
      'value': '2022',
      'key': 1
  }, 
  {
      'label': '2021',
      'value': '2021',
      'key': 2
  }, 
]

export const condicion_fiscal = [
  {
    'label': 'Consumidor final',
    'value': 'Consumidor final',
    'key': 1
  }, 
  {
    'label': 'IVA responsable inscripto',
    'value': 'IVA responsable inscripto',
    'key': 2
  },
  {
    'label': 'IVA responsable inscripto agente de perc',
    'value': 'IVA responsable inscripto agente de perc',
    'key': 3
  },
  {
    'label': 'IVA sujeto exento',
    'value': 'IVA sujeto exento',
    'key': 4
  },
  {
    'label': 'Responsable monotributo',
    'value': 'Responsable monotributo',
    'key': 5
  },
  {
    'label': 'Sujeto no categorizado',
    'value': 'Sujeto no categorizado',
    'key': 6
  }
];

export const generos = [
  {
    'label': 'Femenino',
    'value': 'Femenino',
    'key': 1
  }, 
  {
    'label': 'Masculino',
    'value': 'Masculino',
    'key': 2
  }
]

export const prioridades = [
  {
    'label': 'Alta',
    'value': 'Alta',
    'key': 1
  }, 
  {
    'label': 'Baja',
    'value': 'Baja',
    'key': 2
  },
  {
    'label': 'Media',
    'value': 'Media',
    'key': 3
  }
]

export const IMAGE_FILE_TYPE = "image";
