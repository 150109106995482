import axios from "axios";
import * as React from "react";
import { AiOutlineFile } from "react-icons/ai";
import { FaDownload } from "react-icons/fa";
import Container from "./styled";

export default function Mensaje({ enviado, mensaje, archivo, hora, nombre }) {

  const MsgFile = ({ url }) => {
    const [archivo, setArchivo] = React.useState({
      linkBlob: null,
      nameFile: mensaje,
      error: null
    })
    const getFile = async () => {
      setArchivo({nameFile: `cargando... ${mensaje}`, error:'cargando'})
      try {
        const config = {
          headers: {
            'keystorage': 'e152f0a0-5918-5169-a04d-ae4ea1123b69'
          },
          responseType: 'blob'
        }

        const res = await axios.get(url, config)
        let link = document.createElement('a');
        link.setAttribute("target", "_blank")
        var isChrome = navigator.userAgent.indexOf("Chrome") != -1;
        if ( isChrome ){
           link.href = window.webkitURL.createObjectURL(res.data);
        } else {
            link.href = window.URL.createObjectURL(res.data);
        }
        //link.download = "archivo"
        link.text = "cargando"
        link.setAttribute("alt","archivo")
        link.setAttribute("rel", "noopener noreferrer")
        link.click();
        // URL.revokeObjectURL(link.href);
      } catch (error) {
        console.log('error', error)
      } finally {
        setArchivo({nameFile: mensaje, error:null})
      }
    }
    


    return (<p>
      <span>
        <AiOutlineFile></AiOutlineFile>
      </span>
      <a onClick={getFile}  rel="noreferrer">
        {archivo.nameFile}
      
      </a>
      <a >
        <FaDownload></FaDownload>
      </a>
    </p>)
  }


  return (
    <Container enviado={enviado}>
      <div id="mensaje-container">
        {nombre && <h4 id="nombre">{nombre}</h4>}
        {archivo == null ? (
          <p>{mensaje}</p>
        ) : (
          <div id="archivo-container">
            <MsgFile url={archivo}></MsgFile>
          </div>
        )}
        <p className="hora">{hora}</p>
      </div>
    </Container>
  );
}
