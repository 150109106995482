import { useEmision } from "../../../hooks/useEmision";
import { DivApp, EmisionData, ModalContainer, ModalContainerEmision } from "../../styled.js";
import {
  Button,
  Subtitulo,
  Titulo,
  Grid,
  Col,
  TabsInfo,
} from "../../../components/Index";
import ArrowLeft from "../../../assets/iconos/arrow-left.svg";
import {
  aseguradoLabels,
  seguroLabels,
  pagoLabels,
  anulacionLabels,
  endosoLabels,
} from "../../../constants/emision";
import EmisionSeccion from "../../../components/EmisionSeccion";
import Sidebar from "../../../components/Sidebar";
import useModal from "../../../hooks/useModal";
import ModalRechazo from "../../../components/Modals/Emision/ModalRechazo";
import ModalRevertir from "../../../components/Modals/Emision/ModalRevertir";
import ModalEmisionAPI from "../../../components/Modals/Emision/ModalEmisionAPI";
import ModalSuccess from "../../../components/ModalSuccess";
import { useHistory } from "react-router-dom";
import { useLoading } from "../../../hooks/useLoading";
import SpinnerLoading from "../../../components/SpinnerLoading";
import CarouselSlider from "../../../components/CarouselSlider";
import Estado from "../../../components/Estado";
import { ContainerEstados, ContainerFile, EmptyDocumentacion, OperationNumber, Prioridad } from "./styled";
import {
  FiImage,
  FiFileText,
  FiUser,
  FiShield,
  FiDollarSign,
  FiBox,
  FiArrowRight,
  FiArrowLeft,
  FiSlash,
  FiFilePlus,
  FiFile,
  FiEdit,
  FiX,
} from "react-icons/fi";
import docImage from "../../../assets/images/doc_cuadrado.png";
import docVideo from "../../../assets/images/docVideo.png";
import ImgGys from "../../../components/ImgGys/Index";

import { useState } from "react";
import ModalError from "../../../components/Modals/General/ModalError";
import DocumentGys from "../../../components/DocumentGys";
import { useTabs } from "../../../hooks/useTabs";
import ModalEmisionManual from "../../../components/Modals/Emision/ModalEmisionManual/index.js";
import NewButton from "../../../components/NewButton/index.jsx";
import Subtitle from "../../../components/Subtitle/index.jsx";
import NewTabs from "../../../components/NewTabs/index.jsx";
import NewTitle from "../../../components/NewTitle/index.jsx";
import NewHistorial from "../../../components/NewHistorial/index.jsx";
import { useEffect } from "react";
import useFiles from "../../../hooks/useFiles.js";
import ImgGys2 from "../../../components/ImgGys2/index.js";
import { HiSparkles } from "react-icons/hi";
import { Link } from "react-router-dom/cjs/react-router-dom.min.js";
import axiosRequest from "../../../utils/axiosConfig.js";
import { postMensajeHistorial } from "../../../services/Emision.js";
import dayjs from "dayjs";
import { HiOutlineMailOpen } from "react-icons/hi";
import { TbMailbox } from "react-icons/tb";

const EmisionRefactor = () => {
  const { tabSelected, tabName, handleTab } = useTabs();
  // Hook de router
  const history = useHistory();

  const userInfo = JSON.parse(localStorage.getItem('userInfo'))

  // Modales
  const rechazo = useModal();
  const revertir = useModal();
  const emitirAPI = useModal();
  const exito = useModal();
  const error = useModal();
  const emisionManual = useModal();

  const { solicitudEmision, getSolicitud, loading } = useEmision();
  const {
    asegurado,
    seguroInfo,
    pago,
    bienAseguradoInfo,
    observacion,
    solicitud,
    poliza,
    anulacion,
    endoso,
    seguro,
    certificado
  } = solicitudEmision;
  
  const [openCarousel, setOpenCarousel] = useState(false);
  const [documentacion, setDocumentacion] = useState([]);
  const [emailRecibido, setEmailRecibido] = useState(false)
  const [emailAbierto, setEmailAbierto] = useState(false)
  const [emailCertificadoRecibido, setEmailCertificadoRecibido] = useState(false)
  const [emailCertificadoAbierto, setEmailCertificadoAbierto] = useState(false)
  const [emailStatus, setEmailStatus] = useState({ 
    entregado: false,
    abierto: false,
    noEntregado: false
  })
  const [emailStatusCertificado, setEmailStatusCertificado] = useState({ 
    entregado: false,
    abierto: false,
    noEntregado: false
  })
 
  useEffect(() => {
    const getDocumentacion = () => {
         console.log(endoso)
        let documentacion = bienAseguradoInfo
          ? bienAseguradoInfo.filter((bien) => {
              if (bien.item_bien_asegurado.startsWith("archivo")) {
                return { file: bien.descripcion_bien_asegurado };
              }
            })
          : null;
        
        let endosoArchivos = []
        if(endoso){
          for (const key in endoso) {
            console.log(endoso[key])
            if(key.startsWith("archivo") && endoso[key] !== null){
              endosoArchivos.push({ label_bien_asegurado: key.replace("_", " "), descripcion_bien_asegurado: endoso[key]})
            }
          }
        }

        let dataToShow = []

        
        if(asegurado.archivo_estatuto_empresa_asegurado){
          dataToShow.push({ label_bien_asegurado: "Estatuto empresa", descripcion_bien_asegurado: asegurado.archivo_estatuto_empresa_asegurado})
        }
        if(documentacion) {
          dataToShow = [...dataToShow, ...documentacion]
        }
        if(endoso){
          dataToShow = [...dataToShow, ...endosoArchivos]
        }
        setDocumentacion(dataToShow);
      
    };
    getDocumentacion();
  }, [bienAseguradoInfo, anulacion, endoso]);

  useEffect(() => {
    const getStatus = async () => {
      console.log(poliza)
      if(poliza){
        try {
          const id_mailgun = poliza.poliza_id_mail
          const estado_mail = await axiosRequest(`/mails_emision/${asegurado.email_asegurado}/${id_mailgun}`)
          console.log(poliza.estado_mail_poliza)
          console.log(estado_mail.data.estado_email)
          if(poliza.estado_mail_poliza !== estado_mail.data.estado_email){
            switch(estado_mail.data.estado_email){
              case 1:
                const entregado = {
                  id_solicitud_poliza: poliza.id_solicitud_poliza,
                  estado_email_poliza: estado_mail.data.estado_email
                }
                await axiosRequest.put(`/solicitudes_polizas/estado_email`, entregado)
                const entregadoMensaje = {
                  id_solicitud: solicitud.id,
                  id_area: 1,
                  id_operador: userInfo.id_operador,
                  id_accion_historial: 3,
                  fecha_solicitud_historial: dayjs(new Date()).format(
                    "DD/MM/YYYY HH:mm:ss"
                  ),
                  descripcion_solicitud_historial:
                    `El cliente recibió el email de la solicitud emitida.`,
                  eprevio_solicitud_historial: solicitud.id_estado_previo,
                  estado_solicitud_historial: 1,
                  email_solicitudes_historial: 0,
                  id_historial_email: null,
                };
                postMensajeHistorial(entregadoMensaje);
  
                setEmailStatus(prev => ({...prev, entregado: true }))
                break
              case 2:
                const abierto = {
                  id_solicitud_poliza: poliza.id_solicitud_poliza,
                  estado_email_poliza: estado_mail.data.estado_email
                }
                await axiosRequest.put(`/solicitudes_polizas/estado_email`, abierto)
                const abiertoMensaje = {
                  id_solicitud: solicitud.id,
                  id_area: 1,
                  id_operador: userInfo.id_operador,
                  id_accion_historial: 3,
                  fecha_solicitud_historial: dayjs(new Date()).format(
                    "DD/MM/YYYY HH:mm:ss"
                  ),
                  descripcion_solicitud_historial:
                    `Email de póliza emitida abierto por el cliente.`,
                  eprevio_solicitud_historial: solicitud.id_estado_previo,
                  estado_solicitud_historial: 1,
                  email_solicitudes_historial: 0,
                  id_historial_email: null,
                };
                postMensajeHistorial(abiertoMensaje);
  
                setEmailStatus(prev => ({...prev, abierto: true, entregado: true }))
                break
              case 3:
                console.log("KE")
                console.log(estado_mail)
                const noEntregado = {
                  id_solicitud_poliza: poliza.id_solicitud_poliza,
                  estado_email_poliza: estado_mail.data.estado_email
                }
                await axiosRequest.put(`/solicitudes_polizas/estado_email`, noEntregado)

                let mensaje = null
                const NO_STORAGE_CODE = 452
                const MAIL_DOESNT_EXIST_CODE = 550
                if(estado_mail.data.codigo === NO_STORAGE_CODE){
                  mensaje = 'El cliente no pudo recibir el email con la póliza emitida. El correo electrónico indicado en la solicitud no tiene espacio disponible en su bandeja de entrada.'
                } else if(estado_mail.data.codigo === MAIL_DOESNT_EXIST_CODE){
                  mensaje = 'El cliente no pudo recibir el email con la póliza emitida. El correo electrónico de la solicitud es inexistente.'
                } else {
                  mensaje = 'El cliente no pudo recibir el email con la póliza emitida. Error al entregar el email.'
                }

                const noEntregadoMensaje = {
                  id_solicitud: solicitud.id,
                  id_area: 1,
                  id_operador: userInfo.id_operador,
                  id_accion_historial: 3,
                  fecha_solicitud_historial: dayjs(new Date()).format(
                    "DD/MM/YYYY HH:mm:ss"
                  ),
                  descripcion_solicitud_historial: mensaje,
                  eprevio_solicitud_historial: solicitud.id_estado_previo,
                  estado_solicitud_historial: 1,
                  email_solicitudes_historial: 0,
                  id_historial_email: null,
                };
                postMensajeHistorial(noEntregadoMensaje);
  
                setEmailStatus(prev => ({...prev, noEntregado: true }))
                break
              default: 
                break
            }
          } else {
            if(estado_mail.data.estado_email === 1){
              setEmailStatus(prev => ({...prev, entregado: true }))
            }
            if(estado_mail.data.estado_email === 2){
              setEmailStatus(prev => ({...prev, abierto: true, entregado: true }))
            }
            if(estado_mail.data.estado_email === 3){
              setEmailStatus(prev => ({...prev, noEntregado: true }))
            }
          }

          /*
          if(!poliza?.poliza_enviada_mail){
            const statusDelivered = await axiosRequest(`/mails_entregados_emision/${asegurado.email_asegurado}`)
            const mailPolizaEntregado = statusDelivered.data.items.find(item => {
              const deliveryStatus = item["delivery-status"].code
              const successCode = 250
              const mensaje = item.message.headers
              return mensaje["message-id"] === id_mailgun && deliveryStatus === successCode
            })
            if(mailPolizaEntregado){
              const updatePoliza = {
                id_solicitud_poliza: poliza.id_solicitud_poliza,
                poliza_enviada_mail: 1
              }
              await axiosRequest.put(`/solicitudes_polizas/mail_entregado`, updatePoliza)
  
              const solicitudMessage = {
                id_solicitud: solicitud.id,
                id_area: 1,
                id_operador: userInfo.id_operador,
                id_accion_historial: 3,
                fecha_solicitud_historial: dayjs(new Date()).format(
                  "DD/MM/YYYY HH:mm:ss"
                ),
                descripcion_solicitud_historial:
                  `El cliente recibió el email de la solicitud emitida.`,
                eprevio_solicitud_historial: solicitud.id_estado_previo,
                estado_solicitud_historial: 1,
                email_solicitudes_historial: 0,
                id_historial_email: null,
              };
              postMensajeHistorial(solicitudMessage);

              setEmailRecibido(true)
            }
          } else if(poliza?.poliza_enviada_mail){
            setEmailRecibido(true)
          }

          if(!poliza?.poliza_abierta_mail){
            const statusOpened = await axiosRequest(`/mails_abiertos_emision/${asegurado.email_asegurado}/${id_mailgun}`)
            console.log(statusOpened)
            if(statusOpened.data?.items?.length > 0){
              const updatePoliza = {
                id_solicitud_poliza: poliza.id_solicitud_poliza,
                poliza_abierta_mail: 1
              }
              await axiosRequest.put(`/solicitudes_polizas/mail_abierto`, updatePoliza)
  
              const solicitudMessage = {
                id_solicitud: solicitud.id,
                id_area: 1,
                id_operador: userInfo.id_operador,
                id_accion_historial: 3,
                fecha_solicitud_historial: dayjs(new Date()).format(
                  "DD/MM/YYYY HH:mm:ss"
                ),
                descripcion_solicitud_historial:
                  `Email de póliza emitida abierto por el cliente.`,
                eprevio_solicitud_historial: solicitud.id_estado_previo,
                estado_solicitud_historial: 1,
                email_solicitudes_historial: 0,
                id_historial_email: null,
              };
              postMensajeHistorial(solicitudMessage);

              setEmailAbierto(true)

            }
          } else if(poliza?.poliza_abierta_mail){
            setEmailAbierto(true)
          }
          */
          
        } catch (error) {
          console.log(error)
        }
      }
    }
    getStatus()
  }, [poliza])

  useEffect(() => {
    const getStatus = async () => {
      if(certificado){
        try {
          console.log("CERTIFICADO")
          console.log(certificado)
          const id_mailgun = certificado.certificado_id_mail
          const estado_mail = await axiosRequest(`/mails_emision/${asegurado.email_asegurado}/${id_mailgun}`)
          console.log(certificado.estado_mail_certificado)
          console.log(estado_mail.data.estado_email)
          if(certificado.estado_mail_certificado !== estado_mail.data.estado_email){
            switch(estado_mail.data.estado_email){
              case 1:
                const entregado = {
                  id_solicitud_certificado: certificado.id_solicitud_certificado,
                  estado_email_certificado: estado_mail.data.estado_email
                }
                await axiosRequest.put(`/solicitudes_certificados/estado_email`, entregado)
                const entregadoMensaje = {
                  id_solicitud: solicitud.id,
                  id_area: 1,
                  id_operador: userInfo.id_operador,
                  id_accion_historial: 3,
                  fecha_solicitud_historial: dayjs(new Date()).format(
                    "DD/MM/YYYY HH:mm:ss"
                  ),
                  descripcion_solicitud_historial:
                    `El cliente recibió el email con el certificado emitido.`,
                  eprevio_solicitud_historial: solicitud.id_estado_previo,
                  estado_solicitud_historial: 1,
                  email_solicitudes_historial: 0,
                  id_historial_email: null,
                };
                postMensajeHistorial(entregadoMensaje);
  
                setEmailStatusCertificado(prev => ({...prev, entregado: true }))
                break
              case 2:
                const abierto = {
                  id_solicitud_certificado: certificado.id_solicitud_certificado,
                  estado_email_certificado: estado_mail.data.estado_email
                }
                await axiosRequest.put(`/solicitudes_certificados/estado_email`, abierto)
                const abiertoMensaje = {
                  id_solicitud: solicitud.id,
                  id_area: 1,
                  id_operador: userInfo.id_operador,
                  id_accion_historial: 3,
                  fecha_solicitud_historial: dayjs(new Date()).format(
                    "DD/MM/YYYY HH:mm:ss"
                  ),
                  descripcion_solicitud_historial:
                    `Email de certificado emitido abierto por el cliente.`,
                  eprevio_solicitud_historial: solicitud.id_estado_previo,
                  estado_solicitud_historial: 1,
                  email_solicitudes_historial: 0,
                  id_historial_email: null,
                };
                postMensajeHistorial(abiertoMensaje);
  
                setEmailStatusCertificado(prev => ({...prev, abierto: true, entregado: true }))
                break
              case 3:
                console.log("KE")
                console.log(estado_mail)
                const noEntregado = {
                  id_solicitud_certificado: certificado.id_solicitud_certificado,
                  estado_email_certificado: estado_mail.data.estado_email
                }
                await axiosRequest.put(`/solicitudes_certificados/estado_email`, noEntregado)

                let mensaje = null
                const NO_STORAGE_CODE = 452
                const MAIL_DOESNT_EXIST_CODE = 550
                if(estado_mail.data.codigo === NO_STORAGE_CODE){
                  mensaje = 'El cliente no pudo recibir el email con el certificado emitido. El correo electrónico indicado en la solicitud no tiene espacio disponible en su bandeja de entrada.'
                } else if(estado_mail.data.codigo === MAIL_DOESNT_EXIST_CODE){
                  mensaje = 'El cliente no pudo recibir el email con el certificado emitido. El correo electrónico de la solicitud es inexistente.'
                } else {
                  mensaje = 'El cliente no pudo recibir el email con el certificado emitido. Error al entregar el email.'
                }

                const noEntregadoMensaje = {
                  id_solicitud: solicitud.id,
                  id_area: 1,
                  id_operador: userInfo.id_operador,
                  id_accion_historial: 3,
                  fecha_solicitud_historial: dayjs(new Date()).format(
                    "DD/MM/YYYY HH:mm:ss"
                  ),
                  descripcion_solicitud_historial: mensaje,
                  eprevio_solicitud_historial: solicitud.id_estado_previo,
                  estado_solicitud_historial: 1,
                  email_solicitudes_historial: 0,
                  id_historial_email: null,
                };
                postMensajeHistorial(noEntregadoMensaje);
  
                setEmailStatusCertificado(prev => ({...prev, noEntregado: true }))
                break
              default: 
                break
            }
          } else {
            if(estado_mail.data.estado_email === 1){
              setEmailStatusCertificado(prev => ({...prev, entregado: true }))
            }
            if(estado_mail.data.estado_email === 2){
              setEmailStatusCertificado(prev => ({...prev, abierto: true, entregado: true }))
            }
            if(estado_mail.data.estado_email === 3){
              setEmailStatusCertificado(prev => ({...prev, noEntregado: true }))
            }
          }

          /*
          if(!poliza?.poliza_enviada_mail){
            const statusDelivered = await axiosRequest(`/mails_entregados_emision/${asegurado.email_asegurado}`)
            const mailPolizaEntregado = statusDelivered.data.items.find(item => {
              const deliveryStatus = item["delivery-status"].code
              const successCode = 250
              const mensaje = item.message.headers
              return mensaje["message-id"] === id_mailgun && deliveryStatus === successCode
            })
            if(mailPolizaEntregado){
              const updatePoliza = {
                id_solicitud_poliza: poliza.id_solicitud_poliza,
                poliza_enviada_mail: 1
              }
              await axiosRequest.put(`/solicitudes_polizas/mail_entregado`, updatePoliza)
  
              const solicitudMessage = {
                id_solicitud: solicitud.id,
                id_area: 1,
                id_operador: userInfo.id_operador,
                id_accion_historial: 3,
                fecha_solicitud_historial: dayjs(new Date()).format(
                  "DD/MM/YYYY HH:mm:ss"
                ),
                descripcion_solicitud_historial:
                  `El cliente recibió el email de la solicitud emitida.`,
                eprevio_solicitud_historial: solicitud.id_estado_previo,
                estado_solicitud_historial: 1,
                email_solicitudes_historial: 0,
                id_historial_email: null,
              };
              postMensajeHistorial(solicitudMessage);

              setEmailRecibido(true)
            }
          } else if(poliza?.poliza_enviada_mail){
            setEmailRecibido(true)
          }

          if(!poliza?.poliza_abierta_mail){
            const statusOpened = await axiosRequest(`/mails_abiertos_emision/${asegurado.email_asegurado}/${id_mailgun}`)
            console.log(statusOpened)
            if(statusOpened.data?.items?.length > 0){
              const updatePoliza = {
                id_solicitud_poliza: poliza.id_solicitud_poliza,
                poliza_abierta_mail: 1
              }
              await axiosRequest.put(`/solicitudes_polizas/mail_abierto`, updatePoliza)
  
              const solicitudMessage = {
                id_solicitud: solicitud.id,
                id_area: 1,
                id_operador: userInfo.id_operador,
                id_accion_historial: 3,
                fecha_solicitud_historial: dayjs(new Date()).format(
                  "DD/MM/YYYY HH:mm:ss"
                ),
                descripcion_solicitud_historial:
                  `Email de póliza emitida abierto por el cliente.`,
                eprevio_solicitud_historial: solicitud.id_estado_previo,
                estado_solicitud_historial: 1,
                email_solicitudes_historial: 0,
                id_historial_email: null,
              };
              postMensajeHistorial(solicitudMessage);

              setEmailAbierto(true)

            }
          } else if(poliza?.poliza_abierta_mail){
            setEmailAbierto(true)
          }
          */
          
        } catch (error) {
          console.log(error)
        }
      }
    }
    getStatus()
  }, [certificado])

  const handleBack = () => history.goBack();

  const tabs = [
    {
      name: "Solicitud",
    },
    {
      name: "Historial",
    },
    {
      name: "Documentación",
    },
  ];
  if(poliza){
    tabs.push({ name: "Póliza Emitida"})
  }
  console.log(certificado)
  if(certificado) {
    tabs.push({ name: "Certificado de cobertura" })
  }

  console.log(emailStatus)
  console.log(emailStatusCertificado)

  return (
    <DivApp className="App">
      <Sidebar />
      <ModalContainerEmision>
        <div>
          <ModalError
            isModalOpen={error.isModalOpen}
            setIsModalOpen={error.setIsModalOpen}
            message={error.modalMessage}
          />

          {/* Modales */}
          {revertir.isModalOpen ? (
            <ModalRevertir
              isModalOpen={revertir.isModalOpen}
              handleModal={revertir.handleModal}
              solicitud={solicitud}
              successModal={exito.handleModal}
              errorModal={error}
              getSolicitud={getSolicitud}
            />
          ) : null}

          {rechazo.isModalOpen ? (
            <ModalRechazo
              isModalOpen={rechazo.isModalOpen}
              handleModal={rechazo.handleModal}
              asegurado={asegurado}
              seguro={seguroInfo}
              solicitud={solicitud}
              successModal={exito.handleModal}
              errorModal={error}
              getSolicitud={getSolicitud}
            />
          ) : null}

          {emisionManual.isModalOpen && (
            <ModalEmisionManual
              modal={emisionManual}
              solicitud={solicitud}
              getSolicitud={getSolicitud}
              asegurado={asegurado}
              seguro={seguroInfo}
              producto={seguro?.producto_cobertura_asegurada}
            />
          )}

          {!loading ? (
            <>
              <div>
                {solicitud.prioridad == 1 ?
                <Col desktop={12}>
                  <Prioridad>Prioridad <HiSparkles /></Prioridad>
                </Col>
                : null}
                <Col desktop={12}>
                  <ContainerEstados>
                    <span className="header-estado">
                      Operación: {observacion?.operacion}
                    </span>
                    <span>
                      ・ Estado de la solicitud:{" "}
                      <Estado
                        estado={solicitud.estado}
                        operador={solicitud.operador_solicitud}
                        previo={solicitud.id_estado_previo}
                      />
                    </span>
                    {solicitud.id_estado_previo === 3 &&
                      solicitud.id_estado === 1 && (
                        <span>
                          ・ Estado previo: <Estado estado="Rechazado" />
                        </span>
                      )}
                  </ContainerEstados>
                </Col>
                <Col desktop={12}>
                  <NewTitle textColor={false}>
                    {solicitud.tipo_solicitud} - {asegurado?.nombre_asegurado}{" "}
                    {asegurado?.apellido_asegurado} -{" "}
                    {seguroInfo?.cia_cobertura_asegurada}{" "}
                    {seguroInfo?.producto_cobertura_asegurada}
                  </NewTitle>
                </Col>
              </div>

              <NewTabs
                tabs={tabs}
                tabSelected={tabSelected}
                handleTab={handleTab}
              />

              {emailStatus ?
                <Col desktop={12}>
                  <div className="poliza-mail-container">
                    {emailStatus.entregado ? <div><span className="icon"><TbMailbox /></span>Email de solicitud emitida recibido por el cliente.</div> : null}
                    {emailStatus.abierto ? <div><span className="icon"><HiOutlineMailOpen /></span> El cliente abrió el email con su póliza.</div> : null}
                    {emailStatus.noEntregado ? <div className="no-entregado-mensaje"><span className="icon no-entregado"><FiX /></span> Email de póliza no entregado. Revisar historial de la solicitud.</div> : null}

                    {emailStatusCertificado.entregado ? <div><span className="icon"><TbMailbox /></span>Email del certificado emitido recibido por el cliente.</div> : null}
                    {emailStatusCertificado.abierto ? <div><span className="icon"><HiOutlineMailOpen /></span> El cliente abrió el email con su certificado.</div> : null}
                    {emailStatusCertificado.noEntregado ? <div className="no-entregado-mensaje"><span className="icon no-entregado"><FiX /></span> Email de certificado no entregado. Revisar historial de la solicitud.</div> : null}
                  </div>
                </Col>
                : null}

              {tabName === "Certificado de cobertura" && certificado ? <DocumentGys url={certificado} /> : null}

              {tabName === "Póliza Emitida" && poliza ? <DocumentGys url={poliza?.url_poliza} /> : null}

              {tabSelected === 2 ? 
                documentacion.length > 0 ?
                  <>
                <Grid>
                  {documentacion.map((archivo, index) => {
                    console.log(archivo)
                    console.log(archivo)
                    console.log(archivo.descripcion_bien_asegurado)
                    let file = null;
                    if (archivo.descripcion_bien_asegurado.includes("image")) {
                      file = (
                        <ImgGys
                          url={archivo.descripcion_bien_asegurado}
                          index={index}
                          descarga={false}
                        />
                      );
                    } else if (
                      archivo.descripcion_bien_asegurado.includes("video")
                    ) {
                      file = (
                        <img
                          src={docVideo}
                          alt={"documento"}
                          descarga={false}
                        />
                      );
                    } else {
                      file = (
                        <ImgGys2
                          url={archivo.descripcion_bien_asegurado}
                          index={index}
                          descarga={false}
                        />
                      );
                    }
                    return (
                      <Col desktop={3}>
                        <ContainerFile>
                          <label>{archivo.label_bien_asegurado}</label>
                          {file}
                        </ContainerFile>
                      </Col>
                    );
                  })}
                </Grid>
                </>
                : 
                <EmptyDocumentacion>
                  <span className="icon"><FiFile /></span>
                  <h3>No hay archivos disponibles</h3>
                  <span className="descripcion">La solicitud no tiene documentación cargada</span>
                </EmptyDocumentacion>
             : null}

              {tabSelected === 1 ? (
                <NewHistorial solicitud={solicitudEmision} />
              ) : null}

              {tabSelected === 0 ? (
                <>
                  {solicitud.id_tipo_solicitud !== 3 ? (
                    <>
                      {solicitud.id_tipo_solicitud == 2 ? (
                        <EmisionSeccion
                          title="Datos anulación"
                          data={anulacion}
                          label={anulacionLabels}
                          icon={<FiSlash />}
                        />
                      ) : null}
                      <EmisionSeccion
                        title="Datos del Cliente"
                        data={asegurado}
                        label={aseguradoLabels}
                        icon={<FiUser />}
                      />
                      <EmisionSeccion
                        title="Datos del Seguro"
                        data={seguroInfo}
                        label={seguroLabels}
                        icon={<FiShield />}
                      />

                      <Grid colGap={21} rowGap={21} narrow={true}>
                        <Col desktop={12} spaced={true}>
                          <Subtitle
                            title={"Datos del Bien Asegurado"}
                            icon={<FiBox />}
                          />
                        </Col>
                        {bienAseguradoInfo.length > 0 &&
                          bienAseguradoInfo.map((bien, index) => {
                            console.log(bien);
                            const isString = typeof(bien.descripcion_bien_asegurado) === "string"
                            const isLink = isString ? bien.descripcion_bien_asegurado.includes("http") : null
                            const linebreak = isString ? bien.descripcion_bien_asegurado.includes("\n") : null
                            const hasLineBreak = isString ? bien.descripcion_bien_asegurado.split("\n") : null
                            const column = linebreak ? 12 : 3
                            if (
                              !bien.item_bien_asegurado.startsWith("archivo")
                            ) {
                              return (
                                <Col desktop={column} key={bien.id_bien_asegurado}>
                                  <EmisionData key={bien.id_bien_asegurado}>
                                    <h6>{bien.label_bien_asegurado}</h6>
                                      {hasLineBreak && linebreak ? hasLineBreak.map(item => <p>{item}</p>) : null}
                                      {isLink ? <a href={bien.item_bien_asegurado} target="_blank" rel="noreferrer"><div className="icon"><span><FiFileText /></span></div></a> : null}
                                      {!isLink && !linebreak ? <p>{bien.item_bien_asegurado.startsWith("check") ? bien.descripcion_bien_asegurado == 1 ? "Sí" : "No" : bien.descripcion_bien_asegurado}</p> : null}
                                  </EmisionData>
                                </Col>
                              );
                            }
                          })}

                        {bienAseguradoInfo.filter((bien) =>
                          bien.item_bien_asegurado.startsWith("archivo")
                        ).length > 0 ? (
                          <Col desktop={12} alignBottom={true}>
                            <NewButton
                              backgroundColor={false}
                              borderColor={true}
                              textColor={true}
                              //icon={ArrowRight}
                              direction={"after"}
                              onClick={() => setOpenCarousel(!openCarousel)}
                            >
                              <span>
                                <FiImage />
                              </span>
                              Ver adjuntos
                            </NewButton>
                          </Col>
                        ) : null}
                      </Grid>

                      <EmisionSeccion
                        title="Forma de Pago"
                        data={pago}
                        label={pagoLabels}
                        icon={<FiDollarSign />}
                      />
                    </>
                  ) : (
                    <>
                                          <EmisionSeccion
                        title="Datos del Cliente"
                        data={asegurado}
                        label={aseguradoLabels}
                        icon={<FiUser />}
                      />
                    <EmisionSeccion
                      title="Datos endoso"
                      data={endoso}
                      label={endosoLabels}
                      icon={<FiFilePlus />}
                    />
                    </>
                  )}
                </>
              ) : null}

              <Grid colGap={21} rowGap={21} narrow={true}>
                <Col desktop={12}>
                  <EmisionData apiDisabled={false}>
                    <div className={"actionButtonsCont"}>
                      <NewButton
                        backgroundColor={false}
                        borderColor={true}
                        textColor={true}
                        direction={"before"}
                        onClick={handleBack}
                      >
                        <FiArrowLeft /> Volver
                      </NewButton>
                      {userInfo.id_rol === 10 && parseInt(solicitud.id_estado) === 3 ?
                      <Link
                      to={{
                        pathname: `/EditarSolicitudEmision/${solicitud.id}`,
                        state: { data: solicitud.id },
                        search: `?tipoSolicitud=${solicitud.id_tipo_solicitud}`,
                      }}
                    >
                      <NewButton
                        backgroundColor={true}
                      >
                        <FiEdit /> Editar Solicitud
                      </NewButton>
                      </Link>
                      : null
                      }

                      {userInfo.id_rol !== 10 && userInfo.id_rol !== 18 ?
                      <div className={"buttonsCont"}>
                        {parseInt(solicitud.id_estado) !== 2 && parseInt(solicitud.id_estado) !== 2 && 
                        parseInt(solicitud.id_estado) !== 4 ? (
                          <NewButton
                            backgroundColor={
                              solicitud.operador_solicitud === 66 ? true : false
                            }
                            borderColor={
                              solicitud.operador_solicitud === 66 ? false : true
                            }
                            textColor={
                              solicitud.operador_solicitud === 66 ? false : true
                            }
                            icon={false}
                            onClick={rechazo.handleModal}
                          >
                            Rechazar
                          </NewButton>
                        ) : null}

                        {(parseInt(solicitud.id_estado) == 1 || parseInt(solicitud.id_estado) == 4) &&
                        solicitud.operador_solicitud !== 66 ? (
                          <NewButton
                            backgroundColor={true}
                            borderColor={false}
                            textColor={false}
                            icon={false}
                            onClick={emisionManual.handleModal}
                          >
                            Emitir solicitud
                          </NewButton>
                        ) : null}

                        {solicitud.id_estado == "3" ? (
                          <NewButton
                            backgroundColor={true}
                            borderColor={false}
                            textColor={false}
                            icon={false}
                            onClick={revertir.handleModal}
                          >
                            Revertir{" "}
                          </NewButton>
                        ) : null}
                      </div>
                      : null}

                      
                    </div>
                  </EmisionData>
                </Col>
              </Grid>

              {openCarousel ? (
                <CarouselSlider
                  items={bienAseguradoInfo.filter((bien) =>
                    bien.item_bien_asegurado.startsWith("archivo")
                  )}
                  openCarousel={openCarousel}
                  setOpenCarousel={setOpenCarousel}
                />
              ) : null}
            </>
          ) : (
            <SpinnerLoading text="Cargando solicitud..." />
          )}
        </div>
      </ModalContainerEmision>
    </DivApp>
  );
};

export default EmisionRefactor;

/*
                        {solicitud.id_estado == "1" &&
                        solicitud.operador_solicitud !== 66 ? (
                          <NewButton
                            backgroundColor={true}
                            borderColor={false}
                            textColor={false}
                            icon={false}
                            onClick={emitirAPI.handleModal}
                          >
                            Emitir por API
                          </NewButton>
                        ) : null}

                                  {emitirAPI.isModalOpen && (
            <ModalEmisionAPI
              isModalOpen={emitirAPI.isModalOpen}
              handleModal={emitirAPI.handleModal}
              asegurado={asegurado}
              seguro={seguroInfo}
              bienAsegurado={bienAseguradoInfo}
              pago={pago}
              solicitud={solicitud}
              errorModal={error}
              getSolicitud={getSolicitud}
            />
          )}

*/
