import React, { useState, useEffect } from "react";
import { Button, ButtonDescargar, Subtitulo, Titulo, Modal, Grid, Col, Inner, InputBasic, InputFileRec, InputTextarea, InputFileButton, ProgressBar, Menu, Wrapper, NewProgressBar, MessagesHistory, InputSelect } from '../components/Index';

import ArrowLeft from "../assets/iconos/arrow-left.svg";
import ArrowRight from "../assets/iconos/arrow-right.svg";
import Download from "../assets/iconos/download.svg";
import Check from "../assets/iconos/tic.svg";
import Warning from "../assets/iconos/warning.svg";
import Cancel from "../assets/iconos/cancel.svg";
import CheckEmit from "../assets/iconos/checkEmit.svg";
import CancelReminder from "../assets/iconos/cancelReminder.svg";
import CalendarPicker from "../assets/iconos/calendarPicker.svg";

import axios from 'axios'

import { AllModals, ModalSolicitud, ModalRechazoContainer, ModalExito, ModalRevertirContainer, ModalEmision, ModalProcesoExitoso, ModalEmisionCargar, ModalRecordatorio, ModalEmisionEmitir, ModalEnviarEmail} from "./styled.js"

const Modals = () => {

    const [data, setData] = useState('');
    const [motivos, setMotivos] = useState('')

    useEffect(() => {
        const getData = async () => {
            try{
                const motivo = await axios.get(`http://localhost:5000/motivos`);
                console.log(motivo.data)

                const newMotivos = motivo.data.map(motivo => {
                    return {
                        'label': motivo.nombre_motivo,
                        'value': motivo.id_motivo,
                        'key': motivo.id_motivo
                    }
                })
                setMotivos(newMotivos);
                
            } catch (error){
                console.log('Hubo un error', error)
            }  
        };
        getData();
    }, []);

    

  return (
    <div className="App">
    <Menu />
    <Wrapper>
        <Inner>
          <Titulo textColor={true}>Modales</Titulo>
          <AllModals>
            <ModalRevertirContainer>
                <Modal>
                    <h3>¿Querés revertir el rechazo de la solicitud?</h3>
                    <div className={'buttonsCont'}>
                        <Button backgroundColor={false} borderColor={true} textColor={true} icon={false}> Cancelar </Button> 
                        <Button backgroundColor={true} borderColor={false} textColor={false} icon={false}> Revertir </Button>
                    </div>
                </Modal>
            </ModalRevertirContainer>

            <ModalEmision>
                <Modal>
                    <Titulo>Emisión Doe Jane - Prudencia Bici Gold</Titulo>
                    <div className={"inputsCont"}>
                        <InputFileRec type={"file"} name={"Nombre"} required={false} />
                        <InputFileRec type={"file"} name={"Nombre"} required={false} />
                    </div>
                    <div className={"buttonsCont"}>
                        <Button backgroundColor={false} borderColor={true} textColor={true} icon={false}> Cancelar </Button> 
                        <Button backgroundColor={true} borderColor={false} textColor={false} icon={false}> Cargar </Button>  
                    </div>
                    
                </Modal>
            </ModalEmision>

            <ModalEmisionEmitir>
                <Modal>
                    <Titulo>Emisión Doe Jane - L2 Bici Gold</Titulo>
                    <h3>Se enviará el siguiente mail</h3>

                    <InputTextarea placeholder={'Facundo: Te envio para emitir. Gracias'} />
                    <div className={'actionButtonsCont'}>
                        <InputFileButton type={"file"} name={"Archivos"} required={false} />
                        <div className={'buttonsCont'}>
                            <Button backgroundColor={false} borderColor={true} textColor={true} icon={false}> Cancelar </Button> 
                            <Button backgroundColor={true} borderColor={false} textColor={false} icon={false}> Emitir </Button>
                        </div>  
                    </div>          
                </Modal>
            </ModalEmisionEmitir>

            <ModalEmisionCargar>
                <Modal>
                    <Titulo>Emisión Doe Jane - L2 Bici Gold</Titulo>
                    <InputFileRec type={"file"} name={"Nombre"} required={false} />
                    <div className={'buttonsCont'}>
                        <Button backgroundColor={false} borderColor={true} textColor={true} icon={false}> No cargar </Button>
                        <Button backgroundColor={true} borderColor={false} textColor={false} icon={false}> Cargar </Button>
                    </div>  
                </Modal>
            </ModalEmisionCargar>
            
            <ModalRecordatorio>
                <Modal>
                    <p>Recordá que la foto de la bici es completa del lado derecho, que tenga un cartel con fecha (la misma tiene validez de 48hs a la emision, es decir, si envias a emitir hoy la foto puede tener la fecha de antes de ayer)</p>

                    <Button backgroundColor={true} borderColor={false} textColor={false} icon={false}> Aceptar </Button>  
                </Modal>
            </ModalRecordatorio>

            <ModalRechazoContainer>
                <Modal>
                    <Titulo>Rechazar Emisión Doe Jane -<br></br> Prudencia Bici Gold</Titulo>
                    <div className = {'selectCont'}>
                        <InputSelect name={"motivos"} options={motivos} labelName={"Motivo: "} onChange={null} />
                    </div>

                    <InputTextarea placeholder={'Data adicional del operador..'} ></InputTextarea>        
                    <InputFileButton type={"file"} name={"Archivos"} required={false} /> 
                    <div className={'buttonsCont'}>
                        <Button backgroundColor={false} borderColor={true} textColor={true} icon={false}> Cancelar </Button> 
                        <Button backgroundColor={true} borderColor={false} textColor={false} icon={false}> Rechazar </Button>   
                    </div>
                </Modal>
            </ModalRechazoContainer>

            <ModalExito>
                <Modal>
                    <Titulo>Proceso Exitoso!</Titulo>
                    <h3>Emisión Doe Jane - Prudencia Delivery Gold</h3>
                    <Subtitulo>Descargar Pólizas</Subtitulo>
                    <div className={'buttonsCont'}>
                        <ButtonDescargar backgroundColor={false} borderColor={true} textColor={true} nPoliza={"02558445"} premio={"$120.000"} />
                        <ButtonDescargar backgroundColor={false} borderColor={true} textColor={true} nPoliza={"02558445"} premio={"$120.000"} />
                    </div>
                    <div className={'premioCont'}>
                        <InputBasic  placeholder={"Premio vendedor"} labelName={"Premio vendedor"} minLength={2} />
                    </div>
                    <div className={'buttonCont'}>
                        <Button backgroundColor={true} borderColor={false} textColor={false} icon={false}> Aceptar </Button> 
                    </div>     
                </Modal>
            </ModalExito>

            <ModalProcesoExitoso>
                <Modal>
                    <Titulo>Proceso Exitoso!</Titulo>
                    <h3>Emisión Doe Jane - Prudencia Delivery Gold</h3>
                    <div className={'premioCont'}>
                        <InputBasic placeholder={"Premio vendedor"} labelName={"Premio Vendedor"}  minLength={2} />
                    </div>
                    <div className={'buttonCont'}>
                        <Button backgroundColor={true} borderColor={false} textColor={false} icon={false}> Aceptar </Button> 
                    </div>
                </Modal>
            </ModalProcesoExitoso>

            <ModalSolicitud>
                <Modal>
                    <Titulo>Solicitud Doe Jane - Prudencia Bici</Titulo>
                    <h3>Estado actual: <strong className={'pendSuscripcion'}>Pend. Suscripción</strong></h3>
                    <MessagesHistory className={'enviada'} icon={false} direction={'left'} persona={'Vendedor Vega'} fechaHora={'08.04 - 01:25 pm'} mensaje={'Solicitud de emisión enviada'}></MessagesHistory>

                    <MessagesHistory className={'rechazada'} icon={Cancel} direction={'right'} persona={'Arias'} fechaHora={'08.04 - 01:25 pm'} mensaje={'Solicitud Rechazada'}></MessagesHistory>

                    <MessagesHistory className={'falta'} icon={false} direction={'right'} fechaHora={'08.04 - 01:25 pm'} mensaje={'Falta documentación'}></MessagesHistory>

                    <MessagesHistory className={'enviada'} icon={false} direction={'left'} persona={'Vendedor Vega'} fechaHora={'08.04 - 01:25 pm'} mensaje={'Solicitud de emisión enviada'}></MessagesHistory> 

                    <MessagesHistory className={'falta'} icon={false} direction={'left'} fechaHora={'08.04 - 01:25 pm'} mensaje={'Documentación corregida'}></MessagesHistory>

                    <MessagesHistory className={'emitida'} icon={CheckEmit} direction={'right'} persona={'Arias'} fechaHora={'08.04 - 01:25 pm'} mensaje={'Solicitud Emitida'}></MessagesHistory>
                </Modal>
            </ModalSolicitud>

            <ModalEnviarEmail>
                <Modal>
                    <div className={'lds-ring'}><div></div><div></div><div></div><div></div></div>
                    <h3>Enviando email ...<br></br>Por favor aguarde un momento</h3>
                </Modal>
            </ModalEnviarEmail>
            
        </AllModals>
        </Inner>
    </Wrapper>
    </div>
  );
};

export default Modals;