import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Container } from "./styled";
import { CgCloseO } from "react-icons/cg";

function Modal({ children, setIsModalOpen, isModalOpen, hideCross }) {
  return (
    <Container className={!isModalOpen ? "closeCarousel" : ""}>
      <div id="boton-cerrar-container" onClick={() => setIsModalOpen(!isModalOpen)}>
        <CgCloseO></CgCloseO>
      </div>
      <Box>{children}</Box>
    </Container>
  );
}

Modal.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Modal;
