import { useState, useEffect } from "react";
import { Grid, Col } from "../Grid";
import InputBasic from "../InputBasic";
import SpinnerLoading from "../SpinnerLoading";
import Button from "../Button";
import Subtitulo from "../Subtitulo";
import NewInputBasic from "../NewInputBasic";
import {
  FiFileText,
  FiUser,
  FiBox,
  FiAtSign,
  FiCalendar,
  FiPhone,
  FiMapPin,
  FiArchive,
  FiFilePlus,
  FiFileMinus,
  FiSlash,
  FiPlus,
  FiFile,
  FiSearch,
} from "react-icons/fi";
import { MdCleaningServices } from "react-icons/md";
import { HiOutlineIdentification } from "react-icons/hi";
import { useForm } from "../../hooks/useForm";
import { datosOperacionSolicitudReglas } from "../../utils/formRules";
import { operacionSolicitudForm } from "../../utils/formInitialValues";
import axiosRequest from "../../utils/axiosConfig";
import dayjs from "dayjs";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { ImSearch } from "react-icons/im";
import { useLoading } from "../../hooks/useLoading";
import { Container, ContainerInfo, Icon } from "./styled";
import NewButton from "../NewButton";
import Subtitle from "../Subtitle";
import OperacionCard from "../OperacionCard";
import ErrorInput from "../ErrorInput";

const Operacion = () => {
  const history = useHistory();

  const location = useLocation();
  const id_operacion = new URLSearchParams(location.search).get("operacion");

  const [tipoEmisionSeleccionado, setTipoEmisionSeleccionado] = useState(null)
  const [operacionData, setOperacionData] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const { loading, setLoading } = useLoading();
  const { inputValues, handleChange, handleChangeExternal, formErrors, handleValidation } = useForm(
    handleSubmit,
    datosOperacionSolicitudReglas,
    operacionSolicitudForm
  );

  const [tiposSolicitudes, setTiposSolicitudes] = useState([])

  useEffect(() => {
    const getTiposSolicitudes = async () => {
      try {
        const tiposSolicitudes = await axiosRequest.get('/tipos_solicitudes')
        setTiposSolicitudes(tiposSolicitudes.data)
      } catch (error) {
        console.log(error)
      }
    }
    getTiposSolicitudes()
  }, [])

  const buscarOperacion = async () => {
    setNotFound(false);
    setOperacionData(null);
    setLoading(true);
    try {
      const data = {
        operacionId: inputValues.num_operacion,
      };
      const operacion = await axiosRequest.post("/operaciones", data);
      if (operacion.data) {
        setOperacionData(operacion.data);
        setLoading(false);
      }
      setNotFound(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const iniciarCargaSolicitud = async () =>
    history.push(
      `/CargarSolicitudEmision?operacion=${inputValues.num_operacion}&tipoSolicitud=${tipoEmisionSeleccionado}`
    );

  const ICONOS = [
    {
      nombre: "nueva",
      icono: <FiPlus />
    },
    {
      nombre: "anulación",
      icono: <FiSlash />
    },
    {
      nombre: "endoso",
      icono: <FiFilePlus />
    }
  ]

  function handleSubmit () {
    iniciarCargaSolicitud()
  }

  const seleccionarSolicitud = (id_tipo_solicitud) => {
    handleChangeExternal("tipo_solicitud", id_tipo_solicitud)
    setTipoEmisionSeleccionado(id_tipo_solicitud)
  }

  return (
    <Container>

      {tiposSolicitudes.length > 0 ?
      <>
      <Grid colGap={21} rowGap={1} narrow={true}>
        <Col desktop={12}>
          <Subtitle icon={<FiFile />} title="Tipo de emisión" description="Elegí el tipo de solicitud de emisión a crear" disableDivider={true} />
        </Col>
        {tiposSolicitudes.length > 0 ? 
          tiposSolicitudes.map(tipo => {
            return (
              <Col desktop={4}>
              <div className={`tipo-solicitud ${tipoEmisionSeleccionado === tipo.id_tipo_solicitud && "selected"} ${tipoEmisionSeleccionado !== null && tipoEmisionSeleccionado !== tipo.id_tipo_solicitud && "not-selected"}`} onClick={() => seleccionarSolicitud(tipo.id_tipo_solicitud)}>
                <span className="icon">
                  {ICONOS.find(icono => icono.nombre === tipo.icono)?.icono}
                </span>
                <span className="titulo">{tipo.nombre}</span>
                <span className="descripcion">{tipo.descripcion}</span>
              </div>{" "}
              </Col>
            )
          })
        : null}
        {formErrors.tipo_solicitud ? <Col desktop={12}><ErrorInput>{formErrors.tipo_solicitud}</ErrorInput></Col> : null}
      </Grid>

      <hr className="divider"/>

      <Grid colGap={21} rowGap={1} narrow={true} className="down">
        <Col desktop={12}>
          <Subtitle icon={<FiSearch />} title="Número de operación" description="El número se obtiene desde el CRM" disableDivider={true} />
        </Col>
        <Col desktop={3}>
          <NewInputBasic
            type={"number"}
            name={"num_operacion"}
            labelName={"Operación"}
            placeholder={"Número de operación"}
            minlength={5}
            onChange={handleChange}
            value={inputValues.num_operacion}
            disabled={false}
          />
        </Col>
        <Col desktop={6}>
          <div className="container-botones">
            <NewButton
              backgroundColor={true}
              textColor={false}
              borderColor={true}
              onClick={buscarOperacion}
            >
              <span>
                <ImSearch />
              </span>{" "}
              Buscar
            </NewButton>
            {operacionData && (
              <NewButton
                backgroundColor={false}
                borderColor={true}
                textColor={true}
                onClick={() => setOperacionData(null)}
              >
                <span>
                  <MdCleaningServices />
                </span>
                Limpiar búsqueda
              </NewButton>
            )}
          </div>
        </Col>
      </Grid>
      <p className="recordatorio">
        Recordá que la operación debe estar en estado <strong>contratado</strong> para iniciar la carga
        de una solicitud
      </p>
      <hr className="divider" />

      {operacionData ? (
        <>
          <Grid colGap={21} rowGap={21} narrow={true}>
            <Col desktop={12}>
              <Subtitle
                title="Operación encontrada"
                icon={<FiFileText />}
                disableDivider={true}
              />
            </Col>
          </Grid>

          <div className="cont">
            <OperacionCard
              operacion={operacionData}
              handleValidation={handleValidation}
            />
          </div>
        </>
        
      ) : (
        <ContainerInfo>
          {!loading ? (
            <>
              <Icon>
                <FiFileText />
              </Icon>
              <h3>
                {notFound
                  ? `No se encontró ninguna operación con ese número`
                  : "Buscá una operación"}
              </h3>
              <p>
                Ingresá el número de operación para iniciar el proceso de carga
                de solicitud de seguro.
              </p>
            </>
          ) : (
            <SpinnerLoading text="Buscando operación..." />
          )}
        </ContainerInfo>
      )}
      </>
      : <SpinnerLoading />}

    </Container>
  );
};

export default Operacion;

/*
<Grid colGap={21} rowGap={21} narrow={true}>
            <Col desktop={3}>
              <div className="data-container">
                <span className="data-icon">
                  <FiFileText />
                </span>
                <h4>Número de Operación</h4>
                <span>{operacionData.numero}</span>
              </div>
            </Col>
            <Col desktop={3}>
              <div className="data-container">
                <span className="data-icon">
                  <FiUser />
                </span>

                <h4>Nombre</h4>
                <span>{operacionData.nombre}</span>
              </div>
            </Col>
            <Col desktop={3}>
              <div className="data-container">
                <span className="data-icon">
                  <HiOutlineIdentification />
                </span>
                <h4>DNI</h4>
                <span>{operacionData.dni}</span>
              </div>
            </Col>
            <Col desktop={3}>
              <div className="data-container">
                <span className="data-icon">
                  <FiMapPin />
                </span>
                <h4>Provincia</h4>
                <span>{operacionData.provincia}</span>
              </div>
            </Col>
            <Col desktop={3}>
              <div className="data-container">
                <span className="data-icon">
                  <FiAtSign />
                </span>
                <h4>Email</h4>
                <span>{operacionData.email}</span>
              </div>
            </Col>
            <Col desktop={3}>
              <div className="data-container">
                <span className="data-icon">
                  <FiBox />
                </span>
                <h4>Producto</h4>
                <span>{operacionData.producto}</span>
              </div>
            </Col>
            <Col desktop={3}>
              <div className="data-container">
                <span className="data-icon">
                  <FiCalendar />
                </span>
                <h4>Fecha</h4>
                <span>{dayjs(operacionData.fecha).format("DD/MM/YYYY")}</span>
              </div>
            </Col>
            <Col desktop={3}>
              <div className="data-container">
                <span className="data-icon">
                  <FiPhone />
                </span>
                <h4>Teléfono</h4>
                <span>{operacionData.telefono}</span>
              </div>
            </Col>
          </Grid>
*/