// Componentes 
import Sidebar from "../../../components/Sidebar"
import TitlePageSection from "../../../components/TitlePageSection"
import FilterSection from "../../../components/FiltersSection"
import TableInfo from "../../../components/TableInfo"
import Button from "../../../components/Button"
import Wrapper from "../../../components/Wrapper"
import Inner from "../../../components/Inner"
import { Grid, Col } from "../../../components/Grid"
import NewTable from "../../../components/NewTable"
// ** Modales ** 
import ModalCargarSiniestro from "../../../components/Modals/Siniestros/ModalCargarSiniestro"
import ModalEditarSiniestro from "../../../components/Modals/Siniestros/ModalEditarSiniestro"
import ModalBorrarSiniestro from "../../../components/Modals/Siniestros/ModalBorrarSiniestro"
import ModalCargarSiniestroCSV from "../../../components/Modals/Siniestros/ModalCargarSiniestroCSV"
import ModalSuccess from "../../../components/ModalSuccess"
import ModalError from "../../../components/ModalError"
// Custom Hooks
import useSiniestros from "../../../hooks/useSiniestros"
import useModal from "../../../hooks/useModal"
import useFiltersNew from "../../../hooks/useFiltersNew"
import { useTable } from "../../../hooks/useTable";
// Utils
import { siniestrosTable } from "../../../utils/tableData"
import { filtersSiniestros } from "../../../utils/filtersPages"
import { filterSiniestrosInitialValues } from "../../../utils/filtersPages"
// Iconos
import { HiPlus } from "react-icons/hi";
import { useNotificacionesPage } from "../../../hooks/useNotificaciones"
import NotificacionPagina from "../../../components/NotificacionPagina"
import NewButton from "../../../components/NewButton"
import { FiArrowRight, FiFilter } from "react-icons/fi"
import { useState } from "react"
import NewFiltersSection from "../../../components/NewFiltersSection"
// Estilos
import { Container } from "./styled"
import SpinnerLoading from "../../../components/SpinnerLoading"

const SiniestrosRefactor = () => {

    // Modales
    const confirmacionModal = useModal()
    const errorModal = useModal()

    // Storage
    const user = JSON.parse(localStorage.getItem("userInfo"));
    
    // Custom Hooks
    const { inputValues, params, handleSearch, handleChange, handleChangeExternal, clearSearch } = useFiltersNew(filterSiniestrosInitialValues)

    // Orden 
    const { order, handleTableOrder } = useTable()
    const { data, loading, pagination, operaciones, siniestroSeleccionado, borrarSiniestro, editarSiniestro, cargarSiniestro, cargarSiniestroCSV, refreshSiniestros } = useSiniestros(params, order, confirmacionModal)

    // Notificaciones
    const { notificaciones, resetNotificaciones, mensaje } = useNotificacionesPage(['siniestros'], refreshSiniestros)

    // Tabla
    const tableData = siniestrosTable(editarSiniestro.abrirModal, borrarSiniestro.handleModal, operaciones.abrirModalBorrarSiniestro, user)

    // Filtros
    const filters = filtersSiniestros(data)
    const [showFilters, setShowFilters] = useState(false);


    return (
        <div>
            
            <ModalSuccess 
              isSuccessPopUpOpen={confirmacionModal.isModalOpen}
              setIsSuccessPopUpOpen={confirmacionModal.handleModal}
              popUpMessage={confirmacionModal.modalMessage} 
            />

            <ModalError isSuccessPopUpOpen={errorModal.isModalOpen} />   
             
            <ModalCargarSiniestro isModalOpen={cargarSiniestro.modal.isModalOpen} handleModal={cargarSiniestro.modal.handleModal} tipos={data.tipos} estados={data.estados} postSiniestro={operaciones.postearSiniestro} loading={cargarSiniestro.loading} allProductos={data.productos} />

            <ModalCargarSiniestroCSV isModalOpen={cargarSiniestroCSV.isModalOpen} handleModal={cargarSiniestroCSV.handleModal} csvFile={cargarSiniestroCSV.csvFile} setCsvFile={cargarSiniestroCSV.setCsvFile} postSiniestrosLoading={cargarSiniestroCSV.postSiniestrosLoading} cargarCSV={cargarSiniestroCSV.cargarCsv} />

            {editarSiniestro.modal.isModalOpen && <ModalEditarSiniestro isModalOpen={editarSiniestro.modal.isModalOpen} handleModal={editarSiniestro.modal.handleModal} siniestroSeleccionado={siniestroSeleccionado} estados={data.estados} updateSiniestro={operaciones.actualizarSiniestro} loading={editarSiniestro.loading} productos={data.productos} />}
            
            <ModalBorrarSiniestro isModalOpen={borrarSiniestro.isModalOpen} handleModal={borrarSiniestro.handleModal} deleteSiniestro={operaciones.borrarSiniestro} siniestro={siniestroSeleccionado} />
 
            <Sidebar active="siniestros" />
  
              <Wrapper>
              <Inner>
                <Container>
                <Grid colGap={21} rowGap={21} narrow={true}>
                <Col desktop={12}>
                <TitlePageSection
                  title="Siniestros"
                  description={
                    "Lista y carga de siniestros"
                  }
                >
                  <NewButton
                    backgroundColor={false}
                    textColor={true}
                    borderColor={true}
                    onClick={() => setShowFilters(!showFilters)}
                  >
                    <FiFilter /> Filtros
                    {params?.split("=").length > 1 ? (
                      <span className="filtros-aplicados">
                        {params?.split("=").length - 1}
                      </span>
                    ) : null}
                  </NewButton>
                  <NewButton backgroundColor={true} borderColor={false} textColor={false} icon={false} onClick={cargarSiniestro.modal.handleModal} disabled={user.id_rol === 8}>
                            <span><HiPlus></HiPlus></span>Carga Manual
                  </NewButton>
                  <NewButton backgroundColor={true} borderColor={false} textColor={false} icon={false} onClick={cargarSiniestroCSV.handleModal} disabled={user.id_rol === 8}>
                            <span><HiPlus></HiPlus></span>Carga CSV
                          </NewButton>
                </TitlePageSection>
              </Col>
                </Grid>
                  {/*
                  <Col desktop={12}>
                    <TitlePageSection title="Siniestros">
                      {user.id_rol != 18 && !user.siniestros_habilitado_usuario && 
                        <div className="buttonsContainer">
                          <Button backgroundColor={true} borderColor={false} textColor={false} icon={false} onClick={cargarSiniestro.modal.handleModal} disabled={user.id_rol === 8}>
                            <span><HiPlus></HiPlus></span>Carga Manual
                          </Button>
                          <Button backgroundColor={true} borderColor={false} textColor={false} icon={false} onClick={cargarSiniestroCSV.handleModal} disabled={user.id_rol === 8}>
                            <span><HiPlus></HiPlus></span>Carga CSV
                          </Button>
                        </div>
                      }
                    </TitlePageSection>
                  </Col>
                  */}

                  {/*
                  <Col desktop={12}>
                    <FilterSection section="siniestros" inputs={filters} values={inputValues} params={params} handleChange={handleChange} handleSearch={handleSearch} clearSearch={clearSearch} />
                  </Col>
                  */}

              {showFilters ? (
              <Grid colGap={21} rowGap={21} narrow={true}>
                <Col desktop={12}>
                  <NewFiltersSection
                    section="solicitudes"
                    inputs={filters}
                    values={inputValues}
                    params={params}
                    handleChange={handleChange}
                    handleSearch={handleSearch}
                    clearSearch={clearSearch}
                  />
                </Col>
                {notificaciones.length > 0 && (
                  <Col desktop={12}>
                    <NotificacionPagina
                      mensaje={mensaje}
                      resetNotificaciones={resetNotificaciones}
                    />
                  </Col>
                )}
              </Grid>
            ) : null}

<hr />

<Grid colGap={21} rowGap={21} narrow={true}>
              <Col desktop={2}>
                <div className="estadistica-container">
                  <span>Siniestros</span>
                  {data.estadisticas?.siniestros >= 0 ? (
                    <>
                      <h3>{data.estadisticas.siniestros}</h3>
                      <hr />
                      <span
                        className="link"
                        onClick={(e) =>
                          handleChangeExternal(e, "siniestros", 1)
                        }
                      >
                        Ver siniestros <FiArrowRight />
                      </span>
                    </>
                  ) : (
                    <SpinnerLoading />
                  )}
                </div>
              </Col>
              <Col desktop={2}>
                <div className="estadistica-container">
                  <span>Conformes</span>
                  {data.estadisticas?.cerrado_positivo_conforme >= 0 ? (
                    <>
                      <h3>{data.estadisticas.cerrado_positivo_conforme}</h3>
                      <hr />
                      <span
                        className="link"
                        onClick={(e) =>
                          handleChangeExternal(e, "tipo_solicitud", 2)
                        }
                      >
                        Ver conformes <FiArrowRight />
                      </span>
                    </>
                  ) : (
                    <SpinnerLoading />
                  )}
                </div>
              </Col>
              <Col desktop={2}>
                <div className="estadistica-container">
                  <span>Parciales</span>
                  {data.estadisticas?.cerrado_positivo_conforme_parcial >= 0 ? (
                    <>
                      <h3>{data.estadisticas.cerrado_positivo_conforme_parcial}</h3>
                      <hr />
                      <span
                        className="link"
                        onClick={(e) =>
                          handleChangeExternal(e, "tipo_solicitud", 3)
                        }
                      >
                        Ver parciales<FiArrowRight />
                      </span>
                    </>
                  ) : (
                    <SpinnerLoading />
                  )}
                </div>
              </Col>
              <Col desktop={2}>
                <div className="estadistica-container">
                  <span>Disconformes</span>
                  {data.estadisticas?.cerrado_positivo_disconforme >= 0 ? (
                    <>
                      <h3>{data.estadisticas.cerrado_positivo_disconforme}</h3>
                      <hr />
                      <span
                        className="link"
                        onClick={(e) =>
                          handleChangeExternal(
                            e,
                            "estado",
                            "Pendiente inspección"
                          )
                        }
                      >
                        Ver disconform. <FiArrowRight />
                      </span>
                    </>
                  ) : (
                    <SpinnerLoading />
                  )}
                </div>
              </Col>
              <Col desktop={2}>
                <div className="estadistica-container">
                  <span>Negativos</span>
                  {data.estadisticas?.cerrado_negativo >= 0 ? (
                    <>
                      <h3>{data.estadisticas.cerrado_negativo}</h3>
                      <hr />
                      <span
                        className="link"
                        onClick={(e) =>
                          handleChangeExternal(e, "estado", "Rechazado")
                        }
                      >
                        Ver negativos <FiArrowRight />
                      </span>
                    </>
                  ) : (
                    <SpinnerLoading />
                  )}
                </div>
              </Col>
            </Grid>

                  {/*
                  <Col desktop={12}>
                    {notificaciones.length > 0 &&
                      <NotificacionPagina mensaje={mensaje} resetNotificaciones={resetNotificaciones} />
                    }
                  </Col>
                  */}

                {/*<TableInfo columns={tableData} data={data.siniestros} loading={loading} order={handleTableOrder} orderParams={order} pagination={pagination} />*/}

                <NewTable columns={tableData} data={data.siniestros} loading={loading} order={handleTableOrder} orderParams={order} pagination={pagination} />
                </Container>
              </Inner>
          </Wrapper>
        </div>
    )
}

export default SiniestrosRefactor