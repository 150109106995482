import { FiStar } from "react-icons/fi";
import { Col, Grid } from "../../Grid";
import Subtitle from "../../Subtitle";
import ErrorInput from "../../ErrorInput";
import NewButton from "../../NewButton";
import { useForm } from "../../../hooks/useForm";
import NewInputBasic from "../../NewInputBasic"
import { useEffect, useState } from "react";
import axiosRequest from "../../../utils/axiosConfig";
import {useLoading} from "../../../hooks/useLoading";
import { Container } from "./styled";
const EditarPremioForm = ({ prem, vendedorId, mes }) => {
  const [initial, setInitial] = useState({})
  const {
    inputValues,
    handleChange,
    handleChangeExternal,
    formErrors,
    handleValidation,
  } = useForm(null, null, initial);
  const {loading, loadingText, setLoading, changeLoadingText } = useLoading()

  useEffect(() => {
        let obj = {}
        prem.data.forEach((item, index) => {
            const keys = Object.keys(item).filter(
                (item) =>
                  item !== "id_premio" &&
                  item !== "estado" &&
                  item !== "id_vendedor_premio"
            );
            keys.forEach(key => {
                if(prem[key] === 1){
                    obj[key + '_' + index] = item[key]
                }
            })
        })
        setInitial(obj)
  }, [])

  const actualizarPremio = async () => {
    changeLoadingText("Guardando cambios...")
    setLoading(true)
    const dataToSend = prem.data.map((item, index) => {
        let obj = {}
        const values = Object.keys(inputValues).filter(item => item.includes(index))
        values.forEach(item => {
            const key = item.split(`_${index}`)[0]
            obj[key] = inputValues[key + "_" + index]
        })

        return {
            id_parametro: item.id_parametro,
            ...obj
        }
    })
    console.log(dataToSend)

        try {
          const update = await axiosRequest.put(`/premios/vendedor/${vendedorId}/${prem.id_vendedor_premio}/${mes}`, dataToSend)
          if(update.status === 200){
            changeLoadingText("¡Cambios guardados!")
            setLoading(false)
            setTimeout(() => {
              changeLoadingText(null)
            }, 1000)
          }
        } catch (error) {
          console.log(error)
        }

  };

  return (
      <Container>
        <Col desktop={12}>
          <Subtitle
            icon={<FiStar />}
            title={`Premio - ${prem.nombre}`}
            description={
              "Personalizá los valores y parámetros que conforman el premio"
            }
            disableDivider={true}
          />
        </Col>
        <Col desktop={12}>
          <Container>
          {prem.data.map((item, index) => {
            const keys = Object.keys(item).filter(
              (item) =>
                item !== "id_premio" &&
                item !== "estado" &&
                item !== "id_vendedor_premio"
            );
            return (
              <>
                <Grid colGap={21} rowGap={21} narrow={true}>
                  {keys.map((key) => {
                    if (prem[key] === 1) {
                      return (
                        <Col desktop={3}>
                          <NewInputBasic
                            type={"text"}
                            name={key + "_" + index}
                            labelName={
                              key.charAt(0).toUpperCase() +
                              key.slice(1).replaceAll("_", " ")
                            }
                            placeholder={"Operacion Desde"}
                            onChange={handleChange}
                            value={inputValues[key + "_" + index]}
                          />
                          {formErrors[key] && (
                            <ErrorInput>{formErrors[key]}</ErrorInput>
                          )}
                        </Col>
                      );
                    }
                  })}
                </Grid>
              </>
            );
          })}
          </Container>
        </Col>
        <Col desktop={12}>
          <NewButton
            backgroundColor={true}
            onClick={() => actualizarPremio(prem.data)}
            disabled={loading}
          >
            {loadingText ? loadingText : "Guardar cambios"}
          </NewButton>
        </Col>
        <hr />
      </Container>
  );
};

export default EditarPremioForm;
