import Modal from "../Modal";
import Titulo from "../Titulo";
import MessagesHistory from "../MessagesHistory";
import Button from "../Button";
import { ModalHistorialContainer } from "../InfoBoard/styled";
import SpinnerLoading from "../SpinnerLoading";
import edit from "../../assets/iconos/edit.svg";
import warning from "../../assets/iconos/warning.svg";
import Cancel from "../../assets/iconos/cancel.svg";
import CheckEmit from "../../assets/iconos/checkEmit.svg";
import Emisiones from "../../assets/iconos/emisiones.svg";
import Observacion from "../../assets/iconos/observacion.svg"
import Efectivo from "../../assets/iconos/dollar.png"
import Susurro from "../../assets/iconos/susurro.svg"
import dayjs from 'dayjs'
import { motion, AnimatePresence } from "framer-motion"
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

const ModalHistorial = ({ isModalOpen, setIsModalOpen, modalInfo, historial, destinatarioMail, setDestinatarioMail, attachments, setAttachmentsList, enviarMail, getMailAttachments }) => {
    
    return(
        <>
        {isModalOpen ?
            <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
              <ModalHistorialContainer>
              {modalInfo ? 
              <>
                <motion.div initial={{opacity:0}} transition={{duration: 0.5}} animate={{opacity: 1,}}>
                  <Titulo>Solicitud {modalInfo.cliente} - {modalInfo.compania} {modalInfo.cobertura}</Titulo>
                  <h3>Estado actual: <strong className={`${modalInfo.estado.toLowerCase()}`} >{modalInfo.estado}</strong></h3>
                </motion.div>
                <div className={'mensajesContainer'}>
                  {historial && historial.map((message, index) => {
                      let previousDate = index !== 0 ? dayjs(new Date(historial[index - 1].fecha_solicitud_historial)).format('DD/MM') + ' - ' + dayjs(new Date(historial[index - 1].fecha_solicitud_historial)).format("HH:mm") : '';
                      let currentDate = dayjs(new Date(message.fecha_solicitud_historial)).format('DD/MM') + ' - ' + dayjs(new Date(message.fecha_solicitud_historial)).format("HH:mm");
                      let date
                      let messageClassName;
                      let icon;
                      let historialEmailId;

                      console.log(message)

                      if(currentDate === previousDate && !(index == 0 || index != 0 && message.id_area != historial[index - 1].id_area)){
                        date = ''
                      } else {
                        date = currentDate
                      }

                      if(message.descripcion_solicitud_historial.includes('http')){
                        icon = Emisiones;
                      }
                      if(message.id_accion_historial === 4 && !message.descripcion_solicitud_historial.startsWith("Solicitud")){
                        icon = Observacion;
                      }
                      if(message.id_accion_historial === 6){
                        icon = Susurro;
                      }
                      if(message.id_accion_historial === 8){
                        icon = Efectivo;
                      }
                      
                      if(message.id_historial_email || message.email_solicitudes_historial === 1 || message.email_solicitudes_historial === 2){
                        historialEmailId = message.id_historial_email
                      }
                      
                      // Cambia la clase según el mensaje.
                      switch(message.descripcion_solicitud_historial){ 
                        case "Solicitud de emisión enviada.":
                          messageClassName = "enviada" 
                          break
                        case "Solicitud Rechazada.": 
                          messageClassName = "rechazado" 
                          icon = Cancel;
                          break
                        case "Solicitud Emitida.":
                          messageClassName = "emitida" 
                          icon = CheckEmit;
                          break
                        case "Solicitud Pendiente Suscripción.":
                          messageClassName = "suscripcion" 
                          break
                        case "Solicitud Emisión Provisoria.":
                          messageClassName = "provisoria" 
                          break
                        default:
                          messageClassName = "falta" 
                      }
                      
                      return(
                        <>
                          <MessagesHistory className={messageClassName} icon={icon} direction={message.id_area == '1' ? 'right' : 'left'} persona={index == 0 || index != 0 && message.id_area != historial[index - 1].id_area ? `${message.id_area == '1' ? 'Operador' : 'Vendedor'} ${message.nombre_operador} ${message.apellido_operador}` : ''} fechaHora={date} mensaje={message.descripcion_solicitud_historial} isLink={message.descripcion_solicitud_historial.includes('http') ? true : false} showReenviarButton={message.email_solicitudes_historial === 1 || message.email_solicitudes_historial === 2 || message.id_historial_email ? true : false} enviarMail={() => enviarMail(message)} getMailAttachments={()=> getMailAttachments(message)} setDestinatarioMail={setDestinatarioMail} destinatarioMail={destinatarioMail} key={index} attachments={attachments} showAttachmentsFiles={message.id_historial_email ? true : false} setAttachmentsList={setAttachmentsList} historialEmailId={historialEmailId} ></MessagesHistory>
                        </>
                      )
                  })}
                  {modalInfo.estado === "Rechazado" && 
                  <div className="editar-solicitud-btn-container">
                    <Link to={{ pathname: `/EditarSolicitudEmision/${modalInfo.id}`, state: { data: modalInfo.id, },}}><Button
                        backgroundColor={false}
                        borderColor={false}
                        textColor={true}
                        icon={edit}
                        direction={"before"}
                    >
                        
                        Editar solicitud
                    </Button></Link>
                    </div>
                  }
                </div>
                </>
                :
                <SpinnerLoading />
              }
              </ModalHistorialContainer>
            </Modal>
          : 
            null
          }
        </>
    )
}

export default ModalHistorial