import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Subtitulo,
  Titulo,
  InfoBoard,
  Grid,
  Col,
  Inner,
  InputBasic,
  InputSelect,
  InputTextarea,
  ProgressBar,
  Menu,
  Wrapper,
  Modal,
  NewProgressBar,
  BurgerMenu,
  NavMenu,
  InputCalendar,
} from "../components/Index";
import { SolEmisionTitleContainer, ModalDescargarEstadisticas } from "./styled";
import axiosRequest from "../utils/axiosConfig";
import Filters from "../components/Filters";
import { anios, meses } from "../constants/data";
import { DetailsMain } from "../components/Details/styled";
import url from "../utils/url";
import { AuthContext } from '../context/AuthContext'
import { useHistory } from 'react-router-dom';
import { MdFileDownload } from "react-icons/md";

const EstadisticasCobranzas = () => {

  const authContext = useContext(AuthContext)
  const allowedRoles = [1, 8];

  const history = useHistory(); 

  // Información del usuario logueado
  const user = JSON.parse(localStorage.getItem("userInfo"));
  // State que abre y cierra el menú de navegación
  const [open, setOpen] = useState(false);

  // Headers de la tabla
  //   const headers = ["Id", "Nombre", "Año", "Mes", "Temas"];

  // Objeto donde se almacenan los que se tipea / selecciona en los filtros de la página.
  const [filtro, setFiltro] = useState({
    desde: "",
    hasta: "",
    compania: "",
    ramo: "",
  });
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [btnLimpiar, setBtnLimpiar] = useState(false);
  const [datosTabla, setDatosTabla] = useState(null);
  const [totales, setTotales] = useState(null);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [format, setFormat] = useState("");
  const [loading, setLoading] = useState(false);
  const [limpiando, setLimpiando] = useState(false);
  const [companias, setCompanias] = useState([]);
  const [ramos, setRamos] = useState([]);

      // Chequear si el usuario está autenticado, caso contrario lo manda al login. 
      useEffect(() => {
        if(!authContext.isAuthenticated()){
          history.push("/");
        }
      }, [])
    
  
      // Chequear el rol del usuario autenticado para saber si puede acceder a la página
      useEffect(() => {
        if(user){
          if(!allowedRoles.includes(user.id_rol)){
            history.push("/");
          }
        }
      }, [])
  

  const addField = (e) => {
    const { name, value } = e.target;
    setFiltro({ ...filtro, [name]: value });
  };

  const [selectToShow, setSelectToShow] = useState({
    selectProductos: [],
    selectCampanias: [],
    selectEstados: [],
    selectVendedores: [],
  });

  ////////////////// EFECTOS //////////////////////
  const getData = async () => {
    try {
      const operacionesData = await axiosRequest.get(
        `/objetivos_cobros?desde=${filtro.desde}&hasta=${filtro.hasta}&id_compania=${filtro.compania}&id_ramo=${filtro.ramo}`
      );
      let sumaTotales = {
        ramo: "TOTALES",
        anulado: {
          importe: 0,
          operaciones: 0,
        },
        ingresado: {
          importe: 0,
          operaciones: 0,
        },
        pagado: {
          importe: 0,
          operaciones: 0,
        },
        seguimiento: {
          importe: 0,
          operaciones: 0,
        },
      };

      operacionesData.data.forEach((elem) => {
        sumaTotales.anulado.importe += elem.anulado.importe;
        sumaTotales.anulado.operaciones += elem.anulado.operaciones;
        sumaTotales.ingresado.importe += elem.ingresado.importe;
        sumaTotales.ingresado.operaciones += elem.ingresado.operaciones;
        sumaTotales.pagado.importe += elem.pagado.importe;
        sumaTotales.pagado.operaciones += elem.pagado.operaciones;
        sumaTotales.seguimiento.importe += elem.seguimiento.importe;
        sumaTotales.seguimiento.operaciones += elem.seguimiento.operaciones;
      });
      let soloConOperaciones = [];

      operacionesData.data.forEach((elem) => {
        if (elem.ingresado.operaciones) soloConOperaciones.push(elem);
      });

      setTotales([sumaTotales]);
      setDatosTabla(soloConOperaciones);
    } catch (error) {
      console.log(error);
      setDatosTabla([]);
    }
  };

  // Trae la información del backend y setea el state data.
  useEffect(() => {
    if ((!filtro.desde && !filtro.hasta && !filtro.compania) || !filtro.ramo) {
      getData();
    }
  }, [filtro]);

  useEffect(() => {
    if ((filtro.desde && filtro.hasta) || filtro.compania || filtro.ramo) {
      console.log(filtro);
      setBtnLimpiar(true);
      setBtnDisabled(false);
    } else {
      setBtnLimpiar(false);
      setBtnDisabled(true);
    }
  }, [filtro]);

  useEffect(() => {
    (async () => {
      let allCompanias = await axiosRequest.get("/objetivos_cobros/companias");
      let allRamos = await axiosRequest.get("/objetivos_cobros/secciones");
      setCompanias(allCompanias.data);
      setRamos(allRamos.data);
    })();
  }, []);

  const buscarOperaciones = async () => {
    if ((filtro.desde && filtro.hasta) || filtro.compania || filtro.ramo) {
      setLoading(true);
      await getData();
      setBtnDisabled(true);
      setLoading(false);
    }
  };

  const limpiarForm = async () => {
    setLimpiando(true);
    setFiltro({
      desde: "",
      hasta: "",
      compania: "",
      ramo: "",
    });
    await getData();
    console.log(filtro);

    setBtnLimpiar(false);
    setBtnDisabled(true);
    setLimpiando(false);
  };

  const ramoParaSelect = [];

  ramos.forEach((elem) => {
    ramoParaSelect.push({
      label: elem.seccion,
      value: elem.id,
      key: elem.id,
    });
  });

  const ciasParaSelect = [];

  companias.forEach((elem) => {
    ciasParaSelect.push({
      label: elem.nombre,
      value: elem.id,
      key: elem.id,
    });
  });

  const csvHeaders1 = [
    { header: "", key: "blanco", width: 20 },
    { header: `PAGADO`, key: `pagado`, width: 15 },
    { header: "ANULADO", key: "anulado", width: 15 },
    { header: "SEGUIMIENTO", key: "seguimiento", width: 15 },
    { header: "INGRESADO", key: "ingresado", width: 15 },
  ];

  const csvHeaders2 = [
    { header: "RAMO", key: "ramo", width: 20 },
    { header: "IMPORTE", key: "importePagado", width: 15 },
    { header: "OPERACIONES", key: "operacionesPagado", width: 15 },
    { header: "IMPORTE", key: "importeAnulado", width: 15 },
    { header: "OPERACIONES", key: "operacionesAnulado", width: 15 },
    { header: "IMPORTE", key: "importeSeguimiento", width: 15 },
    { header: "OPERACIONES", key: "operacionesSeguimiento", width: 15 },
    { header: "IMPORTE", key: "importeIngresado", width: 15 },
    { header: "OPERACIONES", key: "operacionesIngresado", width: 15 },
  ];

  const downloadCSV = async () => {
    try {
      const csvData = {
        headers1: csvHeaders1,
        headers2: csvHeaders2,
        data: datosTabla,
        totales: totales,
        formato: format,
        compania: filtro.compania ? filtro.compania : "TODAS",
        desde: filtro.desde ? filtro.desde : null,
        hasta: filtro.hasta ? filtro.hasta : null,
      };
      const csv = await axiosRequest.post(
        "/objetivos_cobros/descargar_archivo",
        csvData
      );
      if (csv.status === 200) {
        window.location.href = `${url}${csv.data.path}`;
        setTimeout(async () => {
          await axiosRequest.delete("/delete_file", {
            data: { path: "./app/public/" + csv.data.path },
          });
        }, 5000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const currency = function (number) {
    return new Intl.NumberFormat("es-ES", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  return (
    <div>
      <BurgerMenu open={open} setOpen={setOpen} />
      <NavMenu
        open={open}
        setOpen={setOpen}
        user={user}
        active={"estadisticas-cobranzas"}
      />
      {isDownloadModalOpen ? (
        <Modal
          isModalOpen={isDownloadModalOpen}
          setIsModalOpen={setIsDownloadModalOpen}
        >
          <ModalDescargarEstadisticas>
            <h2>Elegir formato para descargar las estadísticas</h2>
            <Grid colGap={21} rowGap={21} narrow={true}>
              <Col desktop={6}>
                <div
                  className={
                    format === "excel"
                      ? "estadisticas-formato selected-formato"
                      : "estadisticas-formato"
                  }
                  onClick={() => setFormat("excel")}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path
                      fill={format === "excel" ? "#3289b9" : "#bab8b8"}
                      d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z"
                    />
                  </svg>
                  <span>XLSX (Excel)</span>
                </div>
              </Col>
              <Col desktop={6}>
                <div
                  className={
                    format === "csv"
                      ? "estadisticas-formato selected-formato"
                      : "estadisticas-formato"
                  }
                  onClick={() => setFormat("csv")}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path
                      fill={format === "csv" ? "#3289b9" : "#bab8b8"}
                      d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 224H96c22.1 0 40 17.9 40 40v8c0 8.8-7.2 16-16 16s-16-7.2-16-16v-8c0-4.4-3.6-8-8-8H80c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8H96c4.4 0 8-3.6 8-8v-8c0-8.8 7.2-16 16-16s16 7.2 16 16v8c0 22.1-17.9 40-40 40H80c-22.1 0-40-17.9-40-40V264c0-22.1 17.9-40 40-40zm72 46.4c0-25.6 20.8-46.4 46.4-46.4H216c8.8 0 16 7.2 16 16s-7.2 16-16 16H198.4c-7.9 0-14.4 6.4-14.4 14.4c0 5.2 2.8 9.9 7.2 12.5l25.4 14.5c14.4 8.3 23.4 23.6 23.4 40.3c0 25.6-20.8 46.4-46.4 46.4H168c-8.8 0-16-7.2-16-16s7.2-16 16-16h25.6c7.9 0 14.4-6.4 14.4-14.4c0-5.2-2.8-9.9-7.2-12.5l-25.4-14.5C160.9 302.4 152 287 152 270.4zM280 240v31.6c0 23 5.5 45.6 16 66c10.5-20.3 16-42.9 16-66V240c0-8.8 7.2-16 16-16s16 7.2 16 16v31.6c0 34.7-10.3 68.7-29.6 97.6l-5.1 7.7c-3 4.5-8 7.1-13.3 7.1s-10.3-2.7-13.3-7.1l-5.1-7.7c-19.3-28.9-29.6-62.9-29.6-97.6V240c0-8.8 7.2-16 16-16s16 7.2 16 16z"
                    />
                  </svg>
                  <span>CSV</span>
                </div>
              </Col>
            </Grid>
            <div className={"buttonsCont"}>
              <Button
                backgroundColor={false}
                borderColor={true}
                textColor={true}
                icon={false}
                onClick={() => setIsDownloadModalOpen(false)}
              >
                {" "}
                Cancelar{" "}
              </Button>
              <Button
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                icon={false}
                onClick={downloadCSV}
              >
                {format
                  ? `Descargar como ${
                      format === "excel" ? ".xlsx (Excel)" : ".csv"
                    }`
                  : "Descargar"}
              </Button>
            </div>
          </ModalDescargarEstadisticas>
        </Modal>
      ) : null}

      <Wrapper>
        <Inner>
          <Grid colGap={21} rowGap={21} narrow={true}>
            <Col desktop={12}>
              <SolEmisionTitleContainer>
                <Titulo textColor={false}>Estadísticas de Cobranzas</Titulo>
                <Button
                  backgroundColor={true}
                  borderColor={false}
                  textColor={false}
                  icon={false}
                  onClick={() => setIsDownloadModalOpen(true)}
                >
                  <span><MdFileDownload></MdFileDownload></span>
                  Descargar Estadísticas
                </Button>
              </SolEmisionTitleContainer>
            </Col>
            <Col desktop={12}>
              <Subtitulo>Resumen del estado de todas las operaciones</Subtitulo>
            </Col>
            <Col desktop={3}>
              <InputCalendar
                type={"date"}
                name={"desde"}
                labelName={"Desde"}
                value={filtro.desde}
                onChange={addField}
              />
            </Col>
            <Col desktop={3}>
              <InputCalendar
                type={"date"}
                name={"hasta"}
                labelName={"Hasta"}
                value={filtro.hasta}
                onChange={addField}
              />
            </Col>
            <Col desktop={3}>
              <InputSelect
                name={"compania"}
                labelName={"Compañia"}
                options={ciasParaSelect}
                value={filtro.compania}
                onChange={addField}
              />
            </Col>
            <Col desktop={3}>
              <InputSelect
                name={"ramo"}
                labelName={"Ramo"}
                options={ramoParaSelect}
                value={filtro.ramo}
                onChange={addField}
              />
            </Col>
            <Col desktop={12}>
              <Button
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                icon={false}
                onClick={buscarOperaciones}
                disabled={btnDisabled}
              >
                Buscar
              </Button>
              {btnLimpiar ? (
                <Button
                  backgroundColor={true}
                  borderColor={false}
                  textColor={false}
                  icon={false}
                  onClick={limpiarForm}
                >
                  Limpiar
                </Button>
              ) : null}
            </Col>
          </Grid>
        </Inner>
        <DetailsMain>
          <div id="table-container-cobros">
            <>
              <table>
                <thead>
                  <tr className="first-tr">
                    <th>
                      Datos de{" "}
                      {filtro.compania
                        ? filtro.compania
                        : "TODAS LAS COMPAÑÍAS"}
                    </th>
                    <th>Desde:{filtro.desde ? " " + filtro.desde : " --"}</th>
                    <th>Hasta:{filtro.hasta ? " " + filtro.hasta : " --"}</th>
                  </tr>
                </thead>
              </table>

              <table>
                <thead>
                  <tr className="second-tr">
                    <th></th>
                    <th>PAGADO</th>
                    <th>ANULADO</th>
                    <th>SEGUIMIENTO</th>
                    <th>INGRESADO</th>
                    <th>OBJETIVOS</th>
                  </tr>
                </thead>
              </table>

              <table>
                <tr className="third-tr">
                  <th>RAMO</th>
                  <th>IMPORTE</th>
                  <th>OPERACIONES</th>
                  <th>IMPORTE</th>
                  <th>OPERACIONES</th>
                  <th>IMPORTE</th>
                  <th>OPERACIONES</th>
                  <th>IMPORTE</th>
                  <th>OPERACIONES</th>
                  <th>OPERACIONES</th>
                </tr>
                {loading ? (
                  <h3>CARGANDO DATOS</h3>
                ) : limpiando ? (
                  <h3>RESTAURANDO DATOS</h3>
                ) : (
                  <tbody>
                    {datosTabla?.map((dato) => {
                      return (
                        <tr>
                          <td>{dato.ramo}</td>
                          <td>${currency(dato.pagado.importe)}</td>
                          <td>{dato.pagado.operaciones}</td>
                          <td>${currency(dato.anulado.importe)}</td>
                          <td>{dato.anulado.operaciones}</td>
                          <td>${currency(dato.seguimiento.importe)}</td>
                          <td>{dato.seguimiento.operaciones}</td>
                          <td className="total">
                            ${currency(dato.ingresado.importe)}
                          </td>
                          <td className="total">
                            {dato.ingresado.operaciones}
                          </td>
                          <td className="total">
                            {dato.objetivos ? dato.objetivos : '-'}
                          </td>
                        </tr>
                      );
                    })}
                    {datosTabla
                      ? totales.map((dato) => {
                          return (
                            <tr className="totales">
                              <td>{dato.ramo}</td>
                              <td>${currency(dato.pagado.importe)}</td>
                              <td>{dato.pagado.operaciones}</td>
                              <td>${currency(dato.anulado.importe)}</td>
                              <td>{dato.anulado.operaciones}</td>
                              <td>${currency(dato.seguimiento.importe)}</td>
                              <td>{dato.seguimiento.operaciones}</td>
                              <td>${currency(dato.ingresado.importe)}</td>
                              <td>{dato.ingresado.operaciones}</td>
                              <td></td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                )}
              </table>
              <br />
            </>
          </div>
        </DetailsMain>
      </Wrapper>
    </div>
  );
};

export default EstadisticasCobranzas;
