import { useState } from "react";
// Components
import Modal from "../../../Modal";
import { Container, StatusContainer } from "./styled";
import Titulo from "../../../Titulo";
import Subtitulo from "../../../Subtitulo";
import { Grid, Col } from "../../../Grid";
import InputBasic from "../../../InputBasic";
import InputTextarea from "../../../InputTextarea";
import InputSelect from "../../../InputSelect";
import InputFileRec from "../../../InputFileRec";
import Button from "../../../Button";
import FileLoaded from "../../../FileLoaded";
import InputFile from "../../../InputFile";
import Estado from "../../../Estado";
// Hooks
import { useForm } from "../../../../hooks/useForm";
import useFiles from "../../../../hooks/useFiles";
// Utils
import { editarSiniestrosReglas } from "../../../../utils/formRules";
import { editarSiniestrosForm } from "../../../../utils/formInitialValues";
import url from "../../../../utils/url";
// Libraries
import dayjs from "dayjs";
// Icons
import { FiSave } from "react-icons/fi";
import SpinnerLoading from "../../../SpinnerLoading";
import FileLoadedNew from "../../../FileLoadedNew";
import Archivo from "../../../Archivo";

const ModalEditarSiniestro = ({
  isModalOpen,
  handleModal,
  estados,
  updateSiniestro,
  siniestroSeleccionado,
  loading,
  productos,
}) => {
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const [editButtonMessage, setEditButtonMessage] = useState("Guardar cambios");

  // Custom Hooks
  const {
    inputValues,
    handleChange,
    handleChangeExternal,
    handleValidationAsync,
    formErrors,
    resetForm,
    resetFormErrors,
  } = useForm(handleSubmit, editarSiniestrosReglas, siniestroSeleccionado);
  const { deleteFileMultiple } = useFiles(handleChangeExternal);

  const producto = productos.find(
    (item) => item.value == inputValues.producto_siniestro
  );
  const nombreProducto = producto
    ? producto.label
    : inputValues.producto_siniestro;

  async function handleSubmit() {
    try {
      await updateSiniestro({
        ...inputValues,
        estado_previo: siniestroSeleccionado.estado,
      });
      resetForm({});
      resetFormErrors();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal}>
      <Container>
        {!loading ? (
          <>
            <Titulo margin={true}>Siniestro ・ {inputValues.asegurado}</Titulo>
            <StatusContainer estado_previo={inputValues.estado_previo_siniestro ? true : false}>
            <h3>{parseInt(inputValues.tipo_carga_siniestro) === 2 ? <span>Cargado mediante el sistema de denuncias de siniestros de las landings</span> : <span>Cargado manualmente</span>}</h3>
            <h3>
              Estado actual: <Estado estado={inputValues.estado} />
            </h3>
            <h3>Prioridad: {inputValues.prioridad}</h3>
            {inputValues.fecha_cambio_estado_siniestro ? <h3>Última modificación del estado: {dayjs(inputValues.fecha_cambio_estado_siniestro).format("DD/MM/YY")} a las {dayjs(inputValues.fecha_cambio_estado_siniestro).format("hh:mm:ss")}</h3> : null}
            <h3>{inputValues.estado_previo_siniestro ? <span>Estado previo:{" "} <Estado estado={inputValues.estado_previo_siniestro} /></span> : null}</h3>
            {inputValues.fecha_suma_pagada ? <h3>Suma pagada el {dayjs(inputValues.fecha_suma_pagada).format("DD/MM/YY")} a las {dayjs(inputValues.fecha_suma_pagada).format("hh:mm:ss")}</h3> : null}
            </StatusContainer>

            <hr className="divider" />

            <Subtitulo margin={true}>Resumen</Subtitulo>
            <Grid colGap={21} rowGap={21}>
              <>
                {/*
                  <Col desktop={12}>
                    <span>Asegurado: {inputValues.asegurado}</span><span>Compañia: {inputValues.compania}</span><span>DNI: {inputValues.dni_siniestro}</span><span>Fecha Carga: {inputValues.fecha_carga_siniestro}</span>
                  </Col>
                  */}

                <Col desktop={4}>
                  <h4>Asegurado</h4>
                  <span>{inputValues.asegurado}</span>
                </Col>
                <Col desktop={4}>
                  <h4>Compañia</h4>
                  <span>{inputValues.compania}</span>
                </Col>
                <Col desktop={4}>
                  <h4>DNI</h4>
                  <span>{inputValues.dni_siniestro}</span>
                </Col>
                <Col desktop={4}>
                  <h4>Fecha Carga</h4>
                  <span>{inputValues.fecha_carga_siniestro}</span>
                </Col>
                <Col desktop={4}>
                  <h4>Fecha del Siniestro</h4>
                  <span>{dayjs(inputValues.fecha).format("DD-MM-YYYY")}</span>
                </Col>
                <Col desktop={4}>
                  <h4>Póliza</h4>
                  <span>{inputValues.poliza}</span>
                </Col>
                {nombreProducto ?
                <Col desktop={4}>
                  <h4>Producto</h4>
                  {productos.length > 0 && <span>{nombreProducto}</span>}
                </Col>
                : null}
                <Col desktop={4}>
                  <h4>Ramo</h4>
                  <span>{inputValues.ramo}</span>
                </Col>
                <Col desktop={4}>
                  <h4>Tipo</h4>
                  <span>{inputValues.tipo_siniestro}</span>
                </Col>
                {inputValues.informe_siniestro ? 
                <Col desktop={12}>
                  <h4>Informe</h4>
                  <span>{inputValues.informe_siniestro}</span>
                </Col>
                : null}

                {parseInt(inputValues.tipo_carga_siniestro) === 2 ? 
                  <>
                    <Col desktop={12}>
                      <hr />
                    </Col>
                    <Col desktop={12}>
                      <Subtitulo margin={true}>Información brindada por el asegurado</Subtitulo>
                    </Col>
                    <Col desktop={4}>
                      <h4>Email</h4>
                      <span>{inputValues.email_asegurado_siniestro}</span>
                    </Col>
                    <Col desktop={4}>
                      <h4>Código de área</h4>
                      <span>{inputValues.codigo_area_asegurado_siniestro}</span>
                    </Col>
                    <Col desktop={4}>
                      <h4>Teléfono</h4>
                      <span>{inputValues.telefono_asegurado_siniestro}</span>
                    </Col>
                    <Col desktop={4}>
                      <h4>Fecha del siniestro</h4>
                      <span>{dayjs(inputValues.fecha).format("DD-MM-YYYY")}</span>
                    </Col>
                    <Col desktop={4}>
                      <h4>Hora del siniestro</h4>
                      <span>{inputValues.hora_siniestro}</span>
                    </Col>
                    <Col desktop={4}>
                      <h4>Provincia</h4>
                      <span>{inputValues.provincia_siniestro}</span>
                    </Col>
                    <Col desktop={4}>
                      <h4>Localidad</h4>
                      <span>{inputValues.localidad_siniestro}</span>
                    </Col>
                    <Col desktop={4}>
                      <h4>Calle</h4>
                      <span>{inputValues.calle_siniestro}</span>
                    </Col>
                    <Col desktop={4}>
                      <h4>Altura</h4>
                      <span>{inputValues.altura_siniestro}</span>
                    </Col>
                    <Col desktop={12}>
                      <h4>Reseña</h4>
                      <span>{inputValues.descripcion_asegurado_siniestro}</span>
                    </Col>
                  </>
                : null}

                {inputValues?.adjuntos?.length > 0 ? (
                  <>
                    <Col desktop={12}>
                      <hr />
                    </Col>
                    <Col desktop={12}>
                      <Subtitulo margin={true}>Documentación actual</Subtitulo>
                    </Col>
                    {inputValues.adjuntos.map((file, index) => {
                      return (
                        <Col desktop={12}>
                          <FileLoadedNew
                            file={file.url_siniestro_adjunto}
                            filename={file.url_siniestro_adjunto}
                            link={file.url_siniestro_adjunto}
                            deleteFile={() =>
                              deleteFileMultiple(
                                file,
                                inputValues.adjuntos,
                                "adjuntos"
                              )
                            }
                          />
                        </Col>
                      );
                    })}
                  </>
                ) : null}

                <Col desktop={12}>
                  <hr />
                </Col>

                <Col desktop={12}>
                  <Subtitulo margin={true}>Edición del siniestro</Subtitulo>
                </Col>

                {!user.siniestros_habilitado_usuario ? (
                  <>
                    <Col desktop={4}>
                      <InputBasic
                        type={"text"}
                        name={"mail_siniestro"}
                        labelName={"Mail"}
                        placeholder={"Mail"}
                        onChange={handleChange}
                        value={inputValues.mail_siniestro}
                      />
                    </Col>
                    <Col desktop={4}>
                      <InputBasic
                        type={"text"}
                        name={"num_siniestro"}
                        labelName={"Número Siniestro"}
                        placeholder={"Número Siniestro"}
                        onChange={handleChange}
                        value={inputValues.num_siniestro}
                      />
                    </Col>
                    <Col desktop={4}>
                      <InputSelect
                        name={"estado"}
                        labelName={"Estado"}
                        options={estados}
                        onChange={handleChange}
                        value={inputValues.estado}
                      />
                      {formErrors.estado && (
                        <span className="error">{formErrors.estado}</span>
                      )}
                    </Col>
                    {inputValues.estado === "Cerrado positivo - Conforme" ? (
                      <>
                        <Col desktop={4}>
                          <InputBasic
                            type={"number"}
                            name={"suma_pagada_siniestro"}
                            labelName={"Suma Pagada"}
                            placeholder={"250000"}
                            onChange={handleChange}
                            value={inputValues.suma_pagada_siniestro}
                          />
                          {formErrors.suma_pagada_siniestro && (
                            <span className="error">
                              {formErrors.suma_pagada_siniestro}
                            </span>
                          )}
                        </Col>
                        <Col desktop={4}>
                          <InputBasic
                            type={"number"}
                            name={"gastos_liquidacion_siniestro"}
                            labelName={"Gastos Liquidación"}
                            placeholder={"250000"}
                            onChange={handleChange}
                            value={inputValues.gastos_liquidacion_siniestro}
                          />
                          {formErrors.gastos_liquidacion_siniestro && (
                            <span className="error">
                              {formErrors.gastos_liquidacion_siniestro}
                            </span>
                          )}
                        </Col>
                      </>
                    ) : null}
                    <Col desktop={4}>
                      <InputBasic
                        type={"number"}
                        name={"suma_asegurada_siniestro"}
                        labelName={"Suma Asegurada"}
                        placeholder={"250000"}
                        onChange={handleChange}
                        value={inputValues.suma_asegurada_siniestro}
                      />
                      {formErrors.suma_asegurada_siniestro && (
                        <span className="error">
                          {formErrors.suma_asegurada_siniestro}
                        </span>
                      )}
                    </Col>
                    <Col desktop={4}>
                      <InputBasic
                        type={"number"}
                        name={"ajuste"}
                        labelName={"Ajuste"}
                        placeholder={"250000"}
                        onChange={handleChange}
                        value={inputValues.ajuste}
                      />
                      {formErrors.ajuste && (
                        <span className="error">{formErrors.ajuste}</span>
                      )}
                    </Col>
                    <Col desktop={4}>
                      <InputBasic
                        type={"number"}
                        name={"franquicia"}
                        labelName={"Franquicia"}
                        placeholder={"250000"}
                        onChange={handleChange}
                        value={inputValues.franquicia}
                      />
                      {formErrors.franquicia && (
                        <span className="error">{formErrors.franquicia}</span>
                      )}
                    </Col>
                    <Col desktop={12}>
                      <InputTextarea
                        type={"text"}
                        name={"informe_siniestro"}
                        labelName={"Informe"}
                        placeholder={"Informe"}
                        maxLength={"1000"}
                        height={"100px"}
                        onChange={handleChange}
                        value={inputValues.informe_siniestro}
                      />
                      {formErrors.informe_siniestro && (
                        <span className="error">
                          {formErrors.informe_siniestro}
                        </span>
                      )}
                    </Col>

                    <Col desktop={12}>
                      <hr />
                    </Col>

                    <Col desktop={12}>
                      <Subtitulo margin={true}>
                        Adjuntar documentación
                      </Subtitulo>
                    </Col>

                    <Col desktop={12}>
                      <InputFile
                        type={"file"}
                        name={"nuevos_adjuntos"}
                        text={"Adjuntar documentación del siniestro"}
                        labelName={""}
                        required={true}
                        multiple={true}
                        onChange={handleChangeExternal}
                        files={inputValues.nuevos_adjuntos}
                      />
                    </Col>
                    <Col desktop={12}>
                      <div className="buttonsContSiniestro">
                        <Button
                          backgroundColor={false}
                          borderColor={true}
                          textColor={true}
                          icon={false}
                          onClick={handleModal}
                          disabled={user.id_rol === 8}
                        >
                          Cancelar
                        </Button>
                        <Button
                          backgroundColor={true}
                          borderColor={false}
                          textColor={false}
                          icon={false}
                          onClick={handleValidationAsync}
                          disabled={user.id_rol === 8}
                        >
                          <span className="icon">
                            <FiSave />
                          </span>
                          {editButtonMessage}
                        </Button>
                      </div>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col desktop={4}>
                      <h4>Mail</h4>
                      <span>{inputValues.mail ? inputValues.mail : "-"}</span>
                    </Col>
                    <Col desktop={4}>
                      <h4>Número de siniestro</h4>
                      <span>
                        {inputValues.num_siniestro
                          ? inputValues.num_siniestro
                          : "-"}
                      </span>
                    </Col>
                    <Col desktop={4}>
                      <h4>Estado</h4>
                      <span>
                        {inputValues.estado ? inputValues.estado : "-"}
                      </span>
                    </Col>
                    <Col desktop={4}>
                      <h4>Suma pagada</h4>
                      <span>
                        {inputValues.suma_pagada
                          ? inputValues.suma_pagada
                          : "-"}
                      </span>
                    </Col>
                    <Col desktop={4}>
                      <h4>Suma asegurada</h4>
                      <span>
                        {inputValues.suma_asegurada
                          ? inputValues.suma_asegurada
                          : "-"}
                      </span>
                    </Col>
                    <Col desktop={4}>
                      <h4>Franquicia</h4>
                      <span>
                        {inputValues.franquicia ? inputValues.franquicia : "-"}
                      </span>
                    </Col>
                    <Col desktop={4}>
                      <h4>Ajuste</h4>
                      <span>
                        {inputValues.ajuste ? inputValues.ajuste : "-"}
                      </span>
                    </Col>
                    <Col desktop={12}>
                      <h4>Informe</h4>
                      <span>
                        {inputValues.informe ? inputValues.informe : "-"}
                      </span>
                    </Col>
                  </>
                )}
              </>
            </Grid>
          </>
        ) : (
          <SpinnerLoading text="Actualizando siniestro..." />
        )}
      </Container>
    </Modal>
  );
};

export default ModalEditarSiniestro;
