import React, { useEffect, useState } from "react";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import TitlePageSection from "../../../components/TitlePageSection";
import Sidebar from "../../../components/Sidebar";
import NewButton from "../../../components/NewButton";
import { Breadcrumb, Container } from "./styled";
import axiosRequest from "../../../utils/axiosConfig";
import NewTabs from "../../../components/NewTabs";
import { useTabs } from "../../../hooks/useTabs";
import Subtitle from "../../../components/Subtitle";
import { FiArrowLeft, FiSave, FiStar, FiUser, FiFile, FiDatabase } from "react-icons/fi";
import { Col, Grid } from "../../../components/Grid";
import NewInputBasic from "../../../components/NewInputBasic";
import NewInputCalendar from "../../../components/NewInputCalendar";
import { useForm } from "../../../hooks/useForm";
import ErrorInput from "../../../components/ErrorInput";
import { crearGrupoReglas } from "../../../utils/formRules";
import { crearGrupoForm } from "../../../utils/formInitialValues";
import { useLoading } from "../../../hooks/useLoading";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useParams, useHistory } from "react-router-dom";
import NewTable from "../../../components/NewTable";
import { useTable } from "../../../hooks/useTable";
import dayjs from "dayjs";
import usePagination from "../../../hooks/usePagination";

const Premio = () => {
  const [operaciones, setOperaciones] = useState([]);
  const [tableData, setTableData] = useState([])
  const premioId = useParams();
  const location = useLocation();
  const [initial, setInitial] = useState({})
  const { order, handleTableOrder } = useTable();
  const [descripcion, setDescripcion] = useState(null)
  const history = useHistory()
  const formatter = new Intl.NumberFormat("es-ES", {});
  const userRol = JSON.parse(localStorage.getItem("userInfo"))?.id_rol;
  //const pagination = usePagination(operaciones.length)

  console.log(premioId)


  useEffect(() => {
    const getPremio = async () => {
      if(premioId){
        try {
          const operaciones = await axiosRequest.get(`/premio/${premioId.id_premio}/${premioId.id}/${parseInt(location.state.mes) + 1}`);
          if(operaciones.data.length > 0){
            const op = operaciones.data.map(item => {
              const fecha = item?.fechaucac ? "fechaucac" : "fechauc"
              const obj = item
              obj[fecha] = dayjs(item[fecha]).format("DD/MM/YYYY")
              return obj
              
            })
            setOperaciones(op)
          }
          setOperaciones(operaciones.data)
        } catch (error) {
          console.log(error);
        }
      }
    };
    getPremio();
  }, [premioId]);

  useEffect(() => {
    let obj = {}
    let descripcionKeys = {}
    let duplicates = false
    location.state.data.data.forEach((item, index) => {
        const keys = Object.keys(item).filter(
            (item) =>
              item !== "id_premio" &&
              item !== "estado" &&
              item !== "id_vendedor_premio"
        );

        keys.forEach(key => {
            if(location.state.data[key] === 1){
                duplicates = Object.keys(obj).some(item => item.includes(key))
                descripcionKeys[key] = item[key]
                obj[key + '_' + index] = item[key]
            }
        })
    })
    let desc = location.state.data.descripcion
    const keysForDescription = duplicates ? obj : descripcionKeys
    Object.keys(keysForDescription).forEach(key => desc = desc.replace(`{${key}}`, keysForDescription[key] ? keysForDescription[key].toString() : ""))

    setDescripcion(desc)
    setInitial(obj)
}, [])

  const labels = [
    {
      name: "nombre_canal",
      header: "Canal"
    },
    {
      name: "nombre",
      header: "Cliente",
      component: (item) => {
        if (item.nombre) {
          return (
            <td className="cliente" width="200px">
              <span className={"asegurado"}>
                <span className={"userIcon"}>
                  <FiUser />
                </span>
                <div className={"aseguradoInfo"}>
                  <span>{item.nombre}</span>
                </div>
              </span>
            </td>
          );
        }

        return <td>-</td>;
      },
    },
    {
      name: "numero",
      header: "Operación"
    },
    {
      name: "prima_bruta",
      header: "Prima Bruta",
      component: (item) => {
        return <td width="100px">${formatter.format(Math.round(item.prima_bruta))}</td>
      }
    },
    {
      name: "prima_neta",
      header: "Prima Neta",
      component: (item) => {
        return <td width="100px">${formatter.format(Math.round(item.prima_neta))}</td>
      }
    },
    {
      name: "forma_pago",
      header: "Medio Pago"
    },
    {
      name: "fechauc",
      header: "F. Contratado",
      component: (item) => {
        return <td width="100px">{item.fechauc}</td>
      }
    },
    {
      name: "fechaucac",
      header: "F. Emisión",
      component: (item) => {
        const fecha = item?.fechaucac?.includes("/") ? item?.fechaucac : dayjs(item?.fechaucac).format("DD/MM/YYYY")
        return <td width="100px">{fecha}</td>
      }
    },
    {
      name: "estado",
      header: "Est. Novedades"
    },
    {
      name: "estado_ac",
      header: "Est. Emisión"
    },
    {
      name: "producto",
      header: "Producto"
    },
  ]

  useEffect(() => {
    if(operaciones.length > 0){
      const headerKeys = Array.isArray(operaciones[0]) ? operaciones[1][0] : operaciones[0]
      const headers = Object.keys(headerKeys).map(item => {
        const headerData = labels.find(label => item === label.name)
        let size = null
        if(item === "numero"){
          size = "20px"
        } else if(item === "nombre"){
          size = "100px"
        } else {
          size = "80px"
        }

        let data = {
          header: labels.find(label => item === label.name)?.header ?? item,
          name: item,
          size: size,
        }

        if(headerData?.component){
          data.component = headerData.component
        }

        return data
      })
      setTableData(headers)
    }
  }, [operaciones])

  return (
    <div>
      <div>
        <Sidebar />
        <Wrapper>
          <Inner>
            <Container>
              <div>
              {userRol !== 10 && userRol !== 18 ?
              <Breadcrumb>
                <Link to="/Premios" className="back-button"><span>Premios</span></Link>
                <span>/</span>
                <span onClick={() => history.goBack()} className="vendedor-nombre">{location.state.vendedor}</span>
                <span>/</span>
                <span className="active">{location.state.premio.nombre}</span>
              </Breadcrumb>
              : 
              <Link to={`/Premio/Vendedor/${premioId.id}`} className="back-button">
              <FiArrowLeft />
                Volver a mis premios
              </Link>
              }
              <TitlePageSection
                title={`Premio - ${location.state.premio.nombre}`}
                description={descripcion}
              ></TitlePageSection>
              </div>
              <hr />
              <Subtitle
                icon={<FiStar />}
                title={"Datos del premio"}
                description={
                  "Personalizá los valores y parámetros que conforman el premio"
                }
                disableDivider={true}
              />
              <Grid colGap={21} rowGap={21} narrow={true}>
                <Col desktop={3}>
                  <div className={`premio-container total`}>
                    <span>Valor obtenido</span>
                    <h2>${location.state.premio.valor}</h2>
                  </div>
                </Col>
              </Grid>
              <hr />
              <Subtitle
                icon={<FiDatabase />}
                title={"Valores"}
                description={
                  "Lista de valores utilizados para el cálculo del premio"
                }
                disableDivider={true}
              />
              <Grid colGap={21} rowGap={21} narrow={true}>
                {location.state.data.data.map((item, index) => {
                const keys = Object.keys(item).filter(
                  (item) =>
                    item !== "id_premio" &&
                    item !== "estado" &&
                    item !== "id_vendedor_premio"
                );
                return (
                  <>
                      {keys.map((key) => {
                        if (location.state.data[key] === 1 && initial[key + "_" + index]) {
                          console.log(key)
                          console.log(initial[key + "_" + index])
                          return (
                            <Col desktop={3}>

                            <div className={`premio-container`}>
                              <span>{key.replaceAll("_", " ")}</span>
                              <h2>{key.includes("valor") ? `$${formatter.format(initial[key + "_" + index])}` : initial[key + "_" + index]}</h2>
                            </div>
                            </Col>
                          );
                        }
                      })}
              </>
            );
          })}
              </Grid>
              {operaciones.length > 0 ?
              <>
              <hr />

              {Array.isArray(operaciones[0]) ? operaciones.map((item, index) => {
                console.log(item)
                return (
                  <>
                  <Subtitle
                    icon={<FiFile />}
                    title={`Operaciones - ${index === 0 ? 'Primera parte del premio' : 'Segunda parte del premio'}`}
                    description={
                      `Lista de operaciones tomadas en cuenta para realizar el cálculo del premio (${item.length} resultados)`
                    }
                    disableDivider={true}
                  />
                <NewTable
                columns={tableData}
                data={item}
                order={handleTableOrder}
                orderParams={order}
              />    
              </>  
                )
              })
              : 
              <>
                            <Subtitle
                icon={<FiFile />}
                title={"Operaciones"}
                description={
                  `Lista de operaciones tomadas en cuenta para realizar el cálculo del premio (${operaciones.length} resultados)`
                }
                disableDivider={true}
              />
              <NewTable
              columns={tableData}
              data={operaciones}
              order={handleTableOrder}
              orderParams={order}
            />
            </>
            }
              
              </>
              : null}
            </Container>
          </Inner>
        </Wrapper>
      </div>
    </div>
  );
};

export default Premio;
