import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../../constants";

export const ModalExito = styled.div`
  background-color: #fff;
  padding: 40px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 14px;

  .poliza-icon {
    font-size: 64px;
    color: ${leerColor(colores.celeste)};
    margin: 0px 0 0px 0;
    border: 3px solid ${leerColor(colores.celeste)};
    border-radius: 100%;
    padding: 40px;
    display: flex;
  }

  .title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }

  hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    margin: 10px 0 10px 0;
  }


  .poliza-container {
    display: flex;
    width: 100%;
    margin: 10px 0 20px 0;
    justify-content: space-around;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    box-sizing: border-box;

    .poliza-data {
        padding: 20px 0;
        border-radius: 6px;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 6px;

        .icon {
            display: flex;
            gap: 10px;
        }

        :first-of-type{
            border-right: 1px solid #eaeaea;
        }
    }
  }

  h4 {
    margin: 0;
  }

  /*
  h1 {
    margin-bottom: 11px;
    padding-bottom: 0;
  }
  h2 {
    margin-top: 30px;
  }
  */
  h3 {
    margin: 0;
    font-weight: 400;
  }
  .buttonsCont {
    display: flex;
    gap: 10px;
  }
`;