import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../constants";

export const AclaracionInput = styled.span`
    font-size: 12px;
    color: ${leerColor(colores.grisEstados)};
    margin-top: 10px;
    display: flex;
    gap: 6px;
    align-items: center;
`;

export const AclaracionAutoMoto = styled.span`
    font-size: 14px;
    color: ${leerColor(colores.blanco)};
    display: inline-block;
    margin: 0;
    padding: 0;
    line-height: 1.7;
    //margin-bottom: 20px;
    background-color: ${leerColor(colores.negro)};
    box-sizing: border-box;
    padding: 20px;
    border-radius: 8px;

    .icon {
        margin-right: 10px;
    }
`;

export const EjemploAutoMoto = styled.a`
    font-size: 12px;
    color: ${leerColor(colores.azulRebranding)};
    margin-top: 10px;
    display: flex;
    gap: 6px;
    align-items: center;
    cursor: pointer;
    font-weight: 300;
    text-decoration: none;

    :hover {
        opacity: 0.7;
    }
`;

export const ComprimirVideo = styled.a`
    font-size: 12px;
    color: ${leerColor(colores.azulRebranding)};
    margin-top: 10px;
    display: flex;
    gap: 6px;
    align-items: center;
    cursor: pointer;
    font-weight: 300;
    text-decoration: none;

    strong {
        color: ${leerColor(colores.grisEstados)};
    }

    :hover {
        opacity: 0.7;
    }
`;

export const AclaracionLimiteArchivo = styled.div`
    font-size: 14px;
    color: ${leerColor(colores.grisEstados)};
    display: flex;
    margin: 0;
    padding: 0;
    line-height: 1.7;
    margin-bottom: 20px;
    background-color: ${leerColor(colores.blanco)};
    box-sizing: border-box;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #eaeaea;
    gap: 4px;

    h4 {
        color: ${leerColor(colores.negro)};
        margin: 0;
        font-weight: 500;
    }

    a {
        color: ${leerColor(colores.azulRebranding)};
        cursor: pointer;
        font-weight: 300;
        text-decoration: none;
        font-size: 14px;
    }

    .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 6px;
    }

    .icon {
        display: flex;
        align-items: center;
        display: flex;
        border: 1px solid rgb(234, 234, 234);
        font-size: 20px;
        box-sizing: border-box;
        padding: 10px;
        border-radius: 100px;
        color: rgb(26, 86, 255);
    }
`;