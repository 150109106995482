import React, { useEffect, useState } from "react";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import TitlePageSection from "../../../components/TitlePageSection";
import Sidebar from "../../../components/Sidebar";
import NewButton from "../../../components/NewButton";
import { Container } from "./styled";
import axiosRequest from "../../../utils/axiosConfig";
import NewTabs from "../../../components/NewTabs";
import { useTabs } from "../../../hooks/useTabs";
import Subtitle from "../../../components/Subtitle";
import { FiArrowLeft, FiUser } from "react-icons/fi";
import { Col, Grid } from "../../../components/Grid";
import NewInputBasic from "../../../components/NewInputBasic"
import { useForm } from "../../../hooks/useForm";
import ErrorInput from "../../../components/ErrorInput";
import { crearGrupoReglas, editarGrupoReglas } from "../../../utils/formRules";
import { crearGrupoForm, editarGrupoForm } from "../../../utils/formInitialValues";
import { useLoading } from "../../../hooks/useLoading";
import { Link, useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";

const PremiosEditarGrupo = () => { 
  const grupo = useParams();
  const location = useLocation();
  console.log(grupo)
  console.log(location.state)
  const [integrantes, setIntegrantes] = useState(location.state?.integrantes ? location.state.integrantes : [])
  const [vendedores, setVendedores] = useState([]);
  const [listaVendedores, setListaVendedores] = useState([])
  const [integrantesEliminados, setIntegrantesEliminados] = useState([])
  const [vendedoresSeleccionados, setVendedoresSeleccionados] = useState([])
    const { loading, setLoading } = useLoading()
    const history = useHistory(); // Hook de router
  const { inputValues, handleChange, handleChangeExternal, formErrors, handleValidation, resetForm } = useForm(editarGrupo, editarGrupoReglas, editarGrupoForm)

  useEffect(() => {
    const getData = async () => {
      try {
        const vendedores = await axiosRequest.get("/premios/vendedores");
        console.log(integrantes)
        const filterVendedores = vendedores.data.filter(item => {
            return !integrantes.find(integrante => integrante.id_vendedor == item.numero)
        })
        console.log(filterVendedores)
        handleChangeExternal("integrantes", integrantes)
        setVendedores(filterVendedores);
        setListaVendedores(filterVendedores);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  const seleccionarVendedor = (vendedor) => {
    const seleccionado = vendedoresSeleccionados.find(ven => ven === vendedor)
    if(seleccionado){
        const removerVendedor = vendedoresSeleccionados.filter(ven => ven !== vendedor)
        //handleChangeExternal("integrantes", removerVendedor)
        setVendedoresSeleccionados(removerVendedor)
    } else {
        //handleChangeExternal("integrantes", [...vendedoresSeleccionados, vendedor])
        setVendedoresSeleccionados(prev => [...prev, vendedor])
    }
  }

  const handleChangeVendedor = (e) => {
    const { value } = e.target
    if(value){
        const filtrarVendedores = listaVendedores.filter(vendedor => vendedor.nombre.toLowerCase().startsWith(value.toLowerCase()))
        setVendedores(filtrarVendedores)
    } else {
        setVendedores(listaVendedores)
    }
  }

  const eliminarVendedor = (id) => {
    const vendedores = integrantes.filter(item => item.id_vendedor != id)
    setIntegrantes(vendedores)
    setIntegrantesEliminados([...integrantesEliminados, id])
  }

  async function editarGrupo (){
    setLoading(true)
    try { 
        const data = {
            agregar: vendedoresSeleccionados,
            eliminar: integrantesEliminados
        }
        console.log(data)
        const editar = await axiosRequest.put(`/premios/actualizar_grupo/${grupo.id}`, data)
        if(editar.status === 200){
            setLoading(false)
            setVendedoresSeleccionados([])
            resetForm()
            history.push("/Premios")
        }
    } catch (error) {
        console.log(error)
    }
  }

  return (
    <div>
      <div>
        <Sidebar />
        <Wrapper>
          <Inner>
            <Container>
              <div>
            <Link to="/Premios" className="back-button">
                  <FiArrowLeft />
                  Volver a Premios
                </Link>
              <TitlePageSection
                title="Editar Grupo"
                description="Modificá la lista de integrantes del grupo"
              >
              </TitlePageSection>
              </div>
              <hr />
              <Subtitle
                icon={<FiUser />}
                title={"Integrantes actuales"}
                description="Lista de vendedores que forman parte del grupo"
                disableDivider={true}
              />
              <Grid colGap={21} rowGap={21} narrow={true}>
              {integrantes.length > 0
                  ? integrantes.map((vendedor) => {
                      return (
                        <Col desktop={3}>
                            <div className={`vendedor-container`}>
                                <span className="icon"><FiUser /></span>
                                <span>{vendedor.nombre}</span>
                                <NewButton backgroundColor={false} textColor={true} borderColor={true} onClick={() => eliminarVendedor(vendedor.id_vendedor)}>Eliminar</NewButton>
                            </div>
                        </Col>
                      )
                    })
                  : null}
                  {formErrors.integrantes && <Col desktop={12}><ErrorInput>{formErrors.integrantes}</ErrorInput></Col>}
              </Grid>
              <hr />
              <Subtitle
                icon={<FiUser />}
                title={"Agregar nuevos integrantes"}
                description="Seleccioná los vendedores que formarán parte del grupo"
                disableDivider={true}
              />
              <Grid colGap={21} rowGap={21} narrow={true}>
                <Col desktop={12}>
                <NewInputBasic
                    type={"text"}
                    name={"nombre"}
                    labelName={"Buscar vendedor"}
                    placeholder={"Nombre del vendedor"}
                    onChange={handleChangeVendedor}
                />
                </Col>
                {vendedores.length > 0
                  ? vendedores.map((vendedor) => {
                      const seleccionado = vendedoresSeleccionados.find(ven => ven === vendedor.numero)
                      return (
                        <Col desktop={3}>
                            <div className={`vendedor-container ${seleccionado ? 'seleccionado' : ''}`} onClick={() => seleccionarVendedor(vendedor.numero)}>
                                <span className="icon"><FiUser /></span>
                                <span>{vendedor.nombre}</span>
                            </div>
                        </Col>
                      )
                    })
                  : null}
              </Grid>
              <hr />
              <div className="buttons-container">
                <NewButton backgroundColor={true} onClick={handleValidation} disabled={loading}>{loading ? "Actualizando grupo..." : "Actualizar grupo"}</NewButton>
                <Link to="/Premios"><NewButton borderColor={true} textColor={true}><FiArrowLeft /> Volver a Premios</NewButton></Link>
              </div>
            </Container>
          </Inner>
        </Wrapper>
      </div>
    </div>
  );
};

export default PremiosEditarGrupo;
