export const breakpoints = {
  // Mobile
  mobile280: "280px",
  mobile310: "310px",
  mobile330: "330px",
  mobile340: "340px",
  mobile350: "350px",
  mobile352: "352px",
  mobile360: "360px",
  mobile375: "375px",
  mobile390: "390px",
  mobile408: "408px",
  mobile414: "414px",
  mobile450: "450px",
  mobile455: "455px",
  mobile470: "470px",
  mobile525: "525px",
  mobile550: "550px",
  mobile570: "570px",
  mobile651: "651px",
  // Tablet
  tablet670: "670px",
  tablet700: "700px",
  tablet720: "720px",
  tablet768: "768px",
  tablet850: "850px",
  tablet940: "940px",
  tablet1024: "1024px",
  // Desktop
  desktop1160: "1160px",
  desktop1200: "1200px",
  desktop1366: "1367px",
  desktop1440: "1440px",
  desktop1550: "1550px",
  desktop1600: "1600px",
  desktop1710: "1710px",
  desktop1820: "1820px",
  desktop1860: "1860px",
  desktop1920: "1920px",
};
