// Components
import Modal from "../../../Modal";
import { ModalRechazoContainer } from "../../../../pages/styled";
import Titulo from "../../../Titulo";
import { Grid, Col } from "../../../Grid";
import InputBasic from "../../../InputBasic";
import InputCalendar from "../../../InputCalendar";
import InputTextarea from "../../../InputTextarea";
import InputSelect from "../../../InputSelect";
import InputFileRec from "../../../InputFileRec";
import Button from "../../../Button";
import FileLoaded from "../../../FileLoaded";
import InputFileButton from "../../../InputFileButton";
import NewInputSelect from "../../../NewInputSelect"
import NewInputTextarea from "../../../NewInputTextarea"
// Hooks
import { useForm } from "../../../../hooks/useForm";
import useAutocomplete from "../../../../hooks/useAutocomplete";
import useButtonMessage from "../../../../hooks/useButtonMessage";
import useFiles from "../../../../hooks/useFiles";
// Utils
import { modalRechazoReglas } from "../../../../utils/formRules";
import { modalRechazoForm } from "../../../../utils/formInitialValues";
// Services
import {
  getPolizaInformation,
  getProductoSiniestroId,
} from "../../../../services/Siniestros";
import { useRechazo } from "../../../../hooks/useRechazo";
import useModal from "../../../../hooks/useModal";
import { useLoading } from "../../../../hooks/useLoading";
import SpinnerLoading from "../../../SpinnerLoading";
import ModalSuccess from "../../../ModalSuccess";
import NewButton from "../../../NewButton";
import NewTitle from "../../../NewTitle";
import { RechazoContainer } from "./styled";
import ErrorInput from "../../../ErrorInput";

const ModalRechazo = ({
  isModalOpen,
  handleModal,
  asegurado,
  seguro,
  solicitud,
  errorModal,
  getSolicitud,
}) => {
  const exitoModal = useModal();

  const { inputValues, formErrors, handleValidation, handleChange, handleChangeExternal } = useForm(
    handleSubmit,
    modalRechazoReglas,
    modalRechazoForm
  );
  const { motivos, postearRechazo, popUpMessage } = useRechazo(solicitud);
  const {loading, setLoading} = useLoading()

  async function handleSubmit() {
    setLoading(true)
    try {
      await postearRechazo(
        inputValues,
        handleModal,
        exitoModal.setIsModalOpen,
        getSolicitud
      );
      setLoading(false)
    } catch (error) {
      console.log(error);
      errorModal.setIsModalOpen(true);
      errorModal.handleModalMessage("Error al rechazar la solicitud");
      handleModal();
      setLoading(false)
    }
  }

  return (
    <>
      <ModalSuccess
        isSuccessPopUpOpen={exitoModal.isModalOpen}
        setIsSuccessPopUpOpen={exitoModal.handleModal}
        popUpMessage={popUpMessage}
      />
      <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal}>
        <RechazoContainer>
          <div className="header-container">
          <NewTitle>
            Rechazar Solicitud
          </NewTitle>
          <p>Emisión {asegurado.nombre_asegurado}{" "}
            {asegurado.apellido_asegurado} - {seguro.cia_cobertura_asegurada}{" "}
            {seguro.producto_cobertura_asegurada}</p>
          </div>
          <hr />
          <div className="data-container">
            <div className="motivos-container">
                <label>Seleccioná un motivo de rechazo (*)</label>
                {motivos.length > 0 ? 
                <>
                <Grid rowGap={10} colGap={10}>
                    {motivos.map(motivo => {
                      return (
                      <Col desktop={6}>
                        <div className={`motivo ${motivo.value === inputValues.motivo ? "motivo-selected" : ""}`} onClick={() => handleChangeExternal('motivo', motivo.value)}>{motivo.label}</div>
                      </Col>
                      )
                    })}
                </Grid>
                {formErrors.motivo ? <ErrorInput>{formErrors.motivo}</ErrorInput> : null}
                </>
                : <SpinnerLoading />}
            </div>
          <NewInputTextarea
            name={"mensaje"}
            placeholder={"Complementá el mótivo de rechazo con un comentario para el vendedor"}
            onChange={handleChange}
            labelName={"Comentario para vendedor"}
          ></NewInputTextarea>
          </div>
          <hr />
          <div className={"actionButtonsCont"}>
            <div className={"buttonsCont"}>
              <NewButton
                onClick={handleModal}
                backgroundColor={false}
                borderColor={true}
                textColor={true}
                icon={false}
              >
                Cancelar
              </NewButton>
              <NewButton
                onClick={handleValidation}
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                icon={false}
                disabled={loading}
              >
                {loading ? "Rechazando..." : "Rechazar"}
              </NewButton>
            </div>
          </div>
        </RechazoContainer>
      </Modal>
    </>
  );
};

export default ModalRechazo;
