import Modal from "../../../Modal"
import { ModalHistorialContainer } from "../../../InfoBoard/styled"
import Titulo from "../../../Titulo"
import { Grid, Col } from "../../../Grid"
import Button from "../../../Button"
import InputBasic from "../../../InputBasic"
import { ContainerBotones, Usuario, Icon } from "./styled"
import ErrorInput from "../../../ErrorInput"
// Icons
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"
import { VscLock } from 'react-icons/vsc'
import { FaUserCircle } from 'react-icons/fa'
// React
import { useState } from "react"
import Subtitulo from "../../../Subtitulo"

const ModalNuevaClave = ({ isModalOpen, setIsModalOpen, form, usuario }) => {

    const { inputValues, handleChange, formErrors, handleValidation } = form 

    const [inputTypeClave, setInputTypeClave] = useState('password')
    const [inputTypeClaveRepetida, setInputTypeRepetida] = useState('password')

    return (
      <div>
        <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
          <ModalHistorialContainer>
            <Titulo>Generar contraseña</Titulo>
            <Grid colGap={21} rowGap={21} narrow={true}>
              <Col desktop={12}>
                {usuario && <Usuario><Icon><FaUserCircle /></Icon><strong>{usuario.nombre_operador} {usuario.apellido_operador}</strong> ・ {usuario.usuario_usuario} ・ {usuario.titulo_rol}</Usuario>}
                <InputBasic
                  type={inputTypeClave}
                  name={"clave_usuario"}
                  labelName={"Nueva Contraseña"}
                  id={"clave_usuario"}
                  onChange={handleChange}
                  value={inputValues.clave_usuario}
                  color={formErrors.clave_usuario ? "error" : ""}
                  iconImage={inputTypeClave === "password" ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                  iconCallback={() => setInputTypeClave(inputTypeClave === "password" ? "text" : "password")}
                />
                {formErrors.clave_usuario && <ErrorInput>{formErrors.clave_usuario}</ErrorInput>}
              </Col>
              <Col desktop={12}>
                <InputBasic
                  type={inputTypeClaveRepetida}
                  name={"clave_usuario_repetida"}
                  labelName={"Repetir Contraseña"}
                  id={"clave_usuario_repetida"}
                  onChange={handleChange}
                  value={inputValues.clave_usuario_repetida}
                  color={formErrors.clave_usuario_repetida ? "error" : ""}
                  iconImage={inputTypeClaveRepetida === "password" ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                  iconCallback={() => setInputTypeRepetida(inputTypeClaveRepetida === "password" ? "text" : "password")}
                />
                {formErrors.clave_usuario_repetida && <ErrorInput>{formErrors.clave_usuario_repetida}</ErrorInput>}
              </Col>
              <Col desktop={12}>
                <ContainerBotones>
                    <Button
                        backgroundColor={false}
                        borderColor={true}
                        textColor={true}
                        onClick={setIsModalOpen}
                        target={"_blank"}
                    >
                        Cancelar
                    </Button>
                    <Button
                        backgroundColor={true}
                        borderColor={false}
                        textColor={false}
                        onClick={handleValidation}
                        target={"_blank"}
                    >
                        <span><VscLock /></span>Generar contraseña
                    </Button>
                </ContainerBotones>
              </Col>
            </Grid>
          </ModalHistorialContainer>
        </Modal>
      </div>
    );
}

export default ModalNuevaClave