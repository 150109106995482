import { useState, useEffect } from "react";
import axiosRequest from "../../../../utils/axiosConfig";
import { useForm } from "../../../../hooks/useForm";
import { crearPlanReglas, nuevoDetalleReglas, nuevoBeneficioReglas } from "../../../../utils/formRules";
import { crearPlanForm, nuevoDetalleForm, nuevoBeneficioForm } from "../../../../utils/formInitialValues";
import useButtonMessage from "../../../../hooks/useButtonMessage";
import { obtenerCoberturas, obtenerCompanias, obtenerProductos } from "../../../../services/Landings";

export const useCrearPlan = () => {
  const [companias, setCompanias] = useState();
  const [coberturas, setCoberturas] = useState([]);
  const [productos, setProductos] = useState([]);
  const [tieneValorCuota, setTieneValorCuota] = useState(false);
  const [logosCompanias, setLogosCompanias] = useState([]);
  const [companiaLogo, setCompaniaLogo] = useState(null);
  const [nuevaCompaniaProducto, setNuevaCompaniaProducto] = useState(false)
  const { buttonText, handleButtonText } = useButtonMessage("Crear plan");

  // Forms
  const { inputValues, handleChange, handleChangeExternal, formErrors, handleValidation } = 
  useForm(handleSubmit, crearPlanReglas, crearPlanForm, { tieneValorCuota: tieneValorCuota, nuevaCompaniaProducto });

  const detallesForm = useForm(handleSubmitDetalle, nuevoDetalleReglas, nuevoDetalleForm);

  const beneficiosForm = useForm(handleSubmitBeneficio, nuevoBeneficioReglas, nuevoBeneficioForm)

  const getCompanias = async () => {
    try {
      const { companias, companiasFormatted } = await obtenerCompanias()
      setCompanias(companiasFormatted);
      setLogosCompanias(companias);
    } catch (error) {
      console.log(error);
    }
  };

  const getProductos = async (landing) => {
    try {
      const productos = await obtenerProductos(landing)
      setProductos(productos);
    } catch (error) {
      console.log(error);
    }
  };

  const getCoberturas = async (landing) => {
    try {
      const coberturas = await obtenerCoberturas(landing)
      setCoberturas(coberturas);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCompanias();
  }, []);

  useEffect(() => {
    if (inputValues.landing) {
      getCoberturas(inputValues.landing);
      getProductos(inputValues.landing);
    }
  }, [inputValues.landing]);

  useEffect(() => {
    if (inputValues.compania) {
      const empresa = logosCompanias.find(
        (compania) => compania.numero === parseInt(inputValues.compania)
      );
      setCompaniaLogo(empresa);
    }
  }, [inputValues.compania]);

  useEffect(() => {
    const getPlanes = async () => {
      if(inputValues.producto, inputValues.compania){
        try {
          const planes = await axiosRequest.get(`/planes_producto_compania/${inputValues.compania}/${inputValues.producto}`)
          if(planes.data.length === 0){
            setNuevaCompaniaProducto(true)
          } 
          if(nuevaCompaniaProducto && planes.data.length > 0){
            setNuevaCompaniaProducto(false)
            handleChangeExternal("limite", "")
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
    getPlanes()
  }, [inputValues.producto, inputValues.compania])

  // **** Submit Forms ****  //

  async function handleSubmit() {
    handleButtonText("Creando plan...");
    const data = { ...inputValues, coberturas, tieneValorCuota };
    try {
      const crearPlan = await axiosRequest.post("/crear_plan_landings", data);
      if (crearPlan) {
        handleButtonText("¡Plan creado correctamente!");
        setTimeout(() => {
          handleButtonText("Crear plan");
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleSubmitDetalle() {
    const detalles = [...inputValues.detalles];
    detalles.push(detallesForm.inputValues.detalle);
    handleChangeExternal("detalles", detalles);
    detallesForm.handleChangeExternal("detalle", "");
  }

  function handleSubmitBeneficio() {
    const beneficios = [...inputValues.beneficios];
    beneficios.push(beneficiosForm.inputValues.beneficio);
    handleChangeExternal("beneficios", beneficios);
    beneficiosForm.handleChangeExternal("beneficio", "");
  }

  // **** Submit Forms ****  //

  const handleChangeCobertura = (e) => {
    const { name, checked } = e.target;
    const newCoberturas = [...coberturas];
    const coberturasActualizadas = newCoberturas.map((cober) => {
      if (cober.id_landing_cobertura == name) {
        cober.activo = checked ? 1 : 0;
        return cober;
      }
      return cober;
    });
    setCoberturas(coberturasActualizadas);
    const areActive = coberturasActualizadas.some(cober => cober.activo)
    if(!areActive){
      handleChangeExternal("coberturas", false)
    } else if (areActive && !inputValues.coberturas){
      handleChangeExternal("coberturas", true)
    }
  };

  const handleChangeOperador = (name, operador, handleMenu) => {
    handleChangeExternal(name, operador);
    handleMenu(false);
  };

  const handleValorCuota = () => setTieneValorCuota(!tieneValorCuota)

  const editArrayList = (e, index, type) => {
    const { value } = e.target
    const arrayList = [...inputValues[type]]
    arrayList[index] = value
    handleChangeExternal(type, arrayList)
  }

  const deleteItem = (index, type) => {
    const arrayList = [...inputValues[type]]
    arrayList.splice(index, 1)
    handleChangeExternal(type, arrayList)
  }
  
  return {
    data: {
        companias,
        productos,
        coberturas,
        logosCompanias,
        companiaLogo,
        tieneValorCuota,
        nuevaCompaniaProducto
    },
    detallesForm,
    beneficiosForm,
    planForm: {
        inputValues, handleChange, handleChangeExternal, formErrors, handleValidation
    },
    actions: {
        deleteItem,
        editArrayList,
        handleChangeOperador,
        handleChangeCobertura,
        handleValorCuota

    },
    buttonText
  }
};
