import styled from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const TituloNombre = styled.h1`
  // 
  font-family: 'DM Sans', sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: ${leerColor(colores.negro)};
  margin:  ${props => (props.margin ? "0 0 20px 0" : "0px")};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;

  strong {
    color: ${leerColor(colores.azulRebranding)};
  }
  
  img {
    width: 35px;
    margin: 0px 10px 0px 0px;
  }
`;
