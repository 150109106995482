import { useState } from "react"
import axiosRequest from "../utils/axiosConfig"
import axios from 'axios'
import dayjs from "dayjs"
import docVideo from '../assets/images/docVideo.png'
import docImage from '../assets/images/doc_cuadrado.png'

export default function useHistorial(){
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalInfo, setModalInfo] = useState('')
    const [historial, setHistorial] = useState('')
    const [attachments, setAttachments] = useState([])
    const [isModalOpenEnviarEmail, setIsModalOpenEnviarEmail] = useState(false)
    const [hideCross, setHideCross] = useState(false)
    const [isSuccessPopUpOpen, setIsSuccessPopUpOpen] = useState(false)
    const [destinatarioMail, setDestinatarioMail] = useState('')

    // Adjuntos de mail
    const [attachmentsList, setAttachmentsList] = useState({})
 
    // Texto para el pop up de success
    const [popUpMessage, setPopUpMessage] = useState('')
    const [loadingModalMessage, setLoadingModalMessage] = useState('')

    const userRol = JSON.parse(localStorage.getItem('userInfo'))  // Roles que pueden ver la opción de "Susurro"
    const allowedRolesSusurro = [1,6, 14]

    const handleModalEnviarEmail = () => {
      setHideCross(true)
      setIsModalOpenEnviarEmail(!isModalOpenEnviarEmail); 
    }

    const handleModalHistorial = async (data) => {
        setModalInfo('')
        setIsModalOpen(true)
        //setButtonsStatus(prev => ({...prev, [index]: false, }))
        try{
          const historial = await axiosRequest.get(`/solicitudes_historial/${data.id}`);
          let orderHistorial = historial.data.sort(function(a, b){return a.id_solicitud_historial-b.id_solicitud_historial});
          // Sacar susurros del historial en caso de no existir el rol adecuado
          if(!allowedRolesSusurro.includes(userRol.id_rol)){
            const filterSusurro = orderHistorial.reduce((array, item) => {
              if(item.id_accion_historial !== 6){
                array = [...array, item]
              }
              return array
            }, [])
            setHistorial(filterSusurro)
          } else {
            setHistorial(orderHistorial)
          }
        } catch (error){
          console.log(error)
        }
        setModalInfo(data)
    }

    const getMailAttachments = async (data) => {
      try{
        const getAdjuntos = await axiosRequest.get(`/emails_adjuntos/${data.id_historial_email}`)

        const config = {
          headers: { 'keystorage': 'e152f0a0-5918-5169-a04d-ae4ea1123b69' },
          responseType: 'blob',
        }
        const filesToSend = await Promise.all(getAdjuntos.data.map(async (adjunto) => {
          try {
            // obtiene el documento como un blob
            const file = await axios.get(adjunto.url_adjunto, config)
            let src;
            var isChrome = navigator.userAgent.indexOf("Chrome") != -1;
            if ( isChrome ){
                src = window.webkitURL.createObjectURL(file.data);
            } else {
                src = window.URL.createObjectURL(file.data);
            }          
            let image = null
            
            if(file.headers['content-type'].includes("video")){
              image = docVideo
            } else if(file.headers['content-type'].includes("application")){
              image = docImage
            } 

            return { link: src, image: image ? image : src }
          } catch (error) {
              console.log(error)
          }
        }))
        
        setAttachments(prev => ({...prev, [data.id_historial_email]: filesToSend }))
      } catch (err){
        console.log(err)
      }
    }

    const enviarMail = async (data) => {
      handleModalEnviarEmail();
      setLoadingModalMessage("Reenviando mail")
  
      // Obtener archivos para enviar en el mail
      const { id_solicitud, eprevio_solicitud_historial, email_solicitudes_historial, id_historial_email } = data
      const getAdjuntos = await axiosRequest.get(`/emails_adjuntos/${data.id_historial_email}`)
      const getEmailContent = await axiosRequest.get(`/historial_emails/${data.id_historial_email}`)

      const horaMailOriginal = dayjs(getEmailContent.data[0].fecha_historial_email).format("hh:mm")
      const fechaMailOriginal = dayjs(getEmailContent.data[0].fecha_historial_email).format("DD/MM")

      let filesToSend = []
      getAdjuntos.data.forEach(adjunto => {
        filesToSend.push(adjunto.url_adjunto)
      })
  
      // Información para enviar el mail
      let mailData = {
        mail: destinatarioMail,
        htmlContent: getEmailContent.data[0].contenido_historial_email ? getEmailContent.data[0].contenido_historial_email : 'Reenvio de mail.',
        filesSolicitud: filesToSend,
        subject: `Solicitud ${modalInfo.cliente} - ${modalInfo.compania} ${modalInfo.cobertura}`
      }
  
      // Enviar mail
      let mail = await axiosRequest.post('/send_email', mailData);
      if(mail){
        setIsModalOpenEnviarEmail(false);
        setPopUpMessage("Mail reenviado")
        setIsSuccessPopUpOpen(true)
        setTimeout(() => {
          setIsSuccessPopUpOpen(false)
        }, 1000);
        let date = new Date();
        let time = dayjs(date).format("HH:mm");
        // Agrega un comentario en la tabla "sw_solicitudes_historial" con el cambio de estado.
        const dataSolicitudHistorial = {
          id_solicitud: id_solicitud,
          id_area: 1,
          id_operador: 4,
          id_accion_historial: 1,
          fecha_solicitud_historial: dayjs(new Date()).format('DD/MM/YYYY hh:mm:ss'),
          descripcion_solicitud_historial: `Reenvio de mail a ${destinatarioMail} a las ${time}hs. El mail original fue enviado el ${fechaMailOriginal} a las ${horaMailOriginal}hs.`,
          eprevio_solicitud_historial: eprevio_solicitud_historial,
          estado_solicitud_historial: 1,
          email_solicitudes_historial: 69,
          id_historial_email: id_historial_email
        }
        // Request POST con el comentario
        axiosRequest({
          method: 'post',
          url: '/solicitudes_historial',
          data: dataSolicitudHistorial
        })
        .then(function (response) {
          console.log("Comentario posteado en Solicitud Historial")
        })
        .catch(function (response) {
          console.log(response);
        });
      }
    }

    return {
        isModalOpen,
        setIsModalOpen,
        handleModalHistorial,
        modalInfo,
        historial,
        getMailAttachments,
        attachments,
        setAttachments, 
        setPopUpMessage, 
        setIsSuccessPopUpOpen, 
        destinatarioMail, 
        setDestinatarioMail,
        enviarMail,
        isSuccessPopUpOpen,
        popUpMessage,
        isModalOpenEnviarEmail,
        setIsModalOpenEnviarEmail,
        hideCross,
        loadingModalMessage,
        handleModalEnviarEmail,
        setLoadingModalMessage,
        setAttachmentsList
    }
}