import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  background-color: var(--blanco);
  overflow: hidden !important;

  #max-width-container-chat {
    width: 1270px;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
  }

  & .contenedor-mensajes {
    flex-wrap: wrap;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    max-width: 100%;
    padding: 0px;
    margin: 0px;
  }




  // 1600px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1600}) {
    #max-width-container-chat {
      width: 1200px;
    }

    #boton-atras-container div {
      width: 1200px;
    }
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    #max-width-container-chat {
      width: 900px;
    }

    #boton-atras-container div {
      width: 900px;
    }
  }

  // 1024px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet1024}) {
    #max-width-container-chat {
      width: 700px;
      flex-wrap: wrap;
    }

    #boton-atras-container div {
      width: 700px;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    #max-width-container-chat {
      width: 500px;
    }

    #boton-atras-container div {
      width: 500px;
    }
  }

  // 570px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile570}) {
    #max-width-container-chat {
      width: 90%;
    }

    #boton-atras-container {
      display: none;
    }
  }
`;

export { Container };