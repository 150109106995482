import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../../constants";

export const ContainerBotones = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
`;

export const Usuario = styled.p`
    margin: 20px 0;
    //background-color: #f3fbff;
    color: ${leerColor(colores.grisModales)};
    padding: 20px;
    font-weight: 500;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    display: flex;
    gap: 10px;
`;

export const Icon = styled.span`
    font-size: 20px;
    color: ${leerColor(colores.celeste)};
`;