import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../../constants";

export const Container = styled.div`
  padding: 20px 70px 40px 70px;
  display: flex;
  flex-direction: column;
  max-height: 600px;
  max-width: 600px;
  justify-content: center;
  // 
  font-family: 'DM Sans', sans-serif;
  background: #fff;
  border-radius: 8px;

  h2{
    //text-align: center;
    
    font-weight: 600;
    color: ${leerColor(colores.azul)};
  }

  h4{
    font-weight:600;
    color: ${leerColor(colores.celeste)};
    margin:10px 0;
  }

  span{
    font-size:16px;
    font-weight: 500;
    //color: ${leerColor(colores.gris)};
    color: rgb(82, 82, 82);
  }

  .buttonsCont{
    display: flex;
    justify-content: flex-end;
    width:100%;
    margin-top:30px;
  }

  .buttonsCont a {
    margin-left: 25px;
  }

  .excepcionData{
    display: flex;
    margin:10px 0 40px 0;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;

    p{
      margin:5px;
    }
  }

  .data{
    //font-weight:600;
    //color: ${leerColor(colores.celeste)};
  }
`;