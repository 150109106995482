// Components
import Modal from "../../../Modal";
import Button from "../../../Button";
import { ModalRevertirContainer } from "../../../../pages/styled";
import ModalSuccess from "../../../ModalSuccess";
// Services
import { postMensajeHistorial, actualizarSolicitud } from "../../../../services/Emision";
import dayjs from "dayjs"
import useModal from "../../../../hooks/useModal";
import { useSocket } from "../../../../context/SocketProvider";
import axiosRequest from "../../../../utils/axiosConfig";
import { RevertirContainer } from "./styled";
import NewButton from "../../../NewButton";
import NewTitle from "../../../NewTitle";
import { useLoading } from "../../../../hooks/useLoading";

const ModalRevertir = ({ isModalOpen, handleModal, solicitud, errorModal, getSolicitud }) => {

  const socket = useSocket()
  const exitoModal = useModal()
  const userRol = JSON.parse(localStorage.getItem('userInfo'))
  const { loading, setLoading } = useLoading()

  const handleRevertir = async () => {
        setLoading(true)
        try{
            let data = solicitud;
            let prevEstado = data.id_estado
            data.id_estado = 1;
            data.id_estado_previo = prevEstado
            data.id_motivo = null;
            data.rechazo_solicitud = null;
            data.ingreso_solicitud = dayjs(data.ingreso_solicitud).isValid() ? dayjs(data.ingreso_solicitud).format('DD/MM/YYYY HH:mm:ss') : data.ingreso_solicitud
            await actualizarSolicitud(solicitud.id, data)
            
            // Agrega el cambio de estado al historial de solicitudes.
            const dataSolicitudHistorial = {
                id_solicitud: solicitud.id,
                id_area: 1,
                id_operador: userRol.id_operador,
                id_accion_historial: 3,
                fecha_solicitud_historial: dayjs(new Date()).format('DD/MM/YYYY HH:mm:ss'),
                descripcion_solicitud_historial: 'Se revirtió el rechazo.',
                eprevio_solicitud_historial: prevEstado,
                estado_solicitud_historial: 1,
                email_solicitudes_historial: 0,
                id_historial_email: null
            }
            await postMensajeHistorial(dataSolicitudHistorial)

            const operadores = await axiosRequest.get('operadores')
            const usuario = operadores.data.find(operador => operador.id_operador === solicitud.vendedor_solicitud)
            
            const notificacion = {
              url_pagina: "/HistorialSolicitudes",
              nombre_icono: "emision",
              nombre_pagina: "Historial Solicitudes",
              notificacion: `Se revirtió el rechazo de la solicitud ${solicitud.id}`,
              time: dayjs(new Date()).format("HH:mm"),
              id_usuario: usuario.id_usuario,
            }
            socket.emit('notificacion-crm-emision-revertir', notificacion)
            await axiosRequest.post('/notificacion/crear', notificacion)

            handleModal()

            exitoModal.setIsModalOpen(true)
            getSolicitud()
            setTimeout(() => {
                exitoModal.setIsModalOpen(false)
                setLoading(false)
            }, 1200);
        } catch (error) {
            console.log(error)
            errorModal.setIsModalOpen(true)
            errorModal.handleModalMessage("Error al revirtir la solicitud")
            handleModal()
            setLoading(false)
        }
    }

    return (
        <>
            <ModalSuccess isSuccessPopUpOpen={exitoModal.isModalOpen} setIsSuccessPopUpOpen={exitoModal.handleModal} popUpMessage={"¡Solicitud revertida correctamente!"} />
                <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal}>
                    <RevertirContainer>
                        <div className="header-container">
                            <NewTitle>Revertir rechazo</NewTitle>
                            <p>¿Estás seguro de querer revertir el rechazo de la solicitud?</p>
                        </div>
                        <hr />
                        <div className={"actionButtonsCont"}>
                            <div className={'buttonsCont'}>
                                <NewButton onClick={handleModal} backgroundColor={false} borderColor={true} textColor={true} icon={false}> Cancelar </NewButton>
                                <NewButton onClick={handleRevertir} backgroundColor={true} borderColor={false} textColor={false} icon={false} disabled={loading}> {loading ? "Revirtiendo..." : "Revertir"} </NewButton>
                            </div>
                        </div>
                    </RevertirContainer>
                </Modal>
        </>
    )
}

export default ModalRevertir