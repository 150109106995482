import React, { useState, useEffect } from "react";
import {
  InputBasic,
  ErrorInput,
  Subtitulo,
  Grid,
  Col,
  InputCheckbox,
  InputSelect,
  InputTextarea,
} from "../../../Index";
import InputFile from "../../../InputFile";
import { useSolicitudContext } from "../../../../context/SolicitudContext";
import { Container, StepContainer } from "../../../Step3/styled";
import NewInputBasic from "../../../NewInputBasic";
import NewInputSelect from "../../../NewInputSelect";
import NewInputTextarea from "../../../NewInputTextarea";
import Subtitle from "../../../Subtitle";

import { FiAlertCircle, FiBox, FiEye, FiFile, FiImage } from "react-icons/fi";
import NewInputCalendar from "../../../NewInputCalendar";
import { SELECT_OPTIONS_TIPO_VEHICULO } from "../../../../constants/emision";
import { AclaracionAutoMoto, AclaracionInput, AclaracionLimiteArchivo, EjemploAutoMoto } from "../styled";

const AutomotoresForm = () => {
  const { bienAsegurado } = useSolicitudContext();
  const {
    values,
    formErrors,
    handleChange,
    handleChangeExternal,
    handleChangeCheckbox,
    data,
  } = bienAsegurado;
  const { campos } = data;

  console.log("HOOOOOOLA")

  console.log(campos);

  const [usoOptions, setUsoOptions] = useState([]);
  const [fieldsDuplicate, setFieldsDuplicate] = useState(campos.campos);
  const [fields, setFields] = useState(campos.campos);

  const SELECT_TIPO_CAMION_SEMIRREMOLQUE = [
    {
      label: "Porta volquete",
      value: "Porta volquete",
      key: 1,
    },
    {
      label: "Cargas generales",
      value: "Cargas generales",
      key: 2,
    },
    {
      label: "Cargas peligrosas",
      value: "Cargas peligrosas",
      key: 3,
    },
  ];

  const SELECT_TIPO_PICKUP = [
    {
      label: "Ambulancia",
      value: "Ambulancia",
      key: 1,
    },
    {
      label: "Traslado de personas",
      value: "Traslado de personas",
      key: 2,
    },
  ];

  const imagenesEjemplo = {
    archivo_parte_delantera: "https://files.gyssrl.ar/api/v1/storage/image/1f8d23f5-9451-44a7-8c26-f96def4c5a80",
    archivo_parte_trasera: "https://files.gyssrl.ar/api/v1/storage/image/025c047d-2b0f-46bb-81eb-b1554b3b327a",
    archivo_lado_conductor: "https://files.gyssrl.ar/api/v1/storage/image/f5e334b3-31f5-43d6-a876-b550f6b852aa",
    archivo_lado_acompaniante: "https://files.gyssrl.ar/api/v1/storage/image/ff20e9c1-22a8-4acb-a975-66019393f9e5",
    archivo_parabrisas_lado_adentro: "https://files.gyssrl.ar/api/v1/storage/image/620506f3-3f46-4a16-b2c4-928f8c57adf7",
    archivo_rueda_auxilio: "https://files.gyssrl.ar/api/v1/storage/image/0939c165-aa42-4bac-9014-8e12181fd7d0"
  }

  useEffect(() => {
    if (
      values.tipo_vehiculo === "Camión" ||
      values.tipo_vehiculo === "Semirremolque"
    ) {
      const fieldsCopy = [...fieldsDuplicate];
      const fieldsWithoutGNC = fieldsCopy.filter(
        (field) => !field.label_campo.toLowerCase().includes("gnc")
      );
      setFields(fieldsWithoutGNC);
      setUsoOptions(SELECT_TIPO_CAMION_SEMIRREMOLQUE);
    } else if (values.tipo_vehiculo === "Pick-Up") {
      setUsoOptions(SELECT_TIPO_PICKUP);
      setFields(fieldsDuplicate);
    } else if (values.tipo_vehiculo === "Auto") {
      const fieldsCopy = [...fieldsDuplicate];
      const fieldsWithoutUso = fieldsCopy.filter(
        (field) => field.name_campo !== "uso"
      );
      setFields(fieldsWithoutUso);
    }
  }, [values.tipo_vehiculo]);

  return (
    <StepContainer>
      {campos.camposCheckbox.length > 0 ? (
        <>
          <Container>
            <Grid colGap={21} rowGap={21} narrow={true}>
              {campos.camposCheckbox.map((campo) => {
                return (
                  <Col desktop={4}>
                    <InputCheckbox
                      className={"inputCheckbox"}
                      type={campo.tipo_campo}
                      name={campo.name_campo}
                      labelName={campo.label_campo}
                      color={formErrors[campo.name_campo] ? "error" : "normal"}
                      placeholder={campo.placeholder_campo}
                      onChange={(event) => handleChangeCheckbox(event, campo)}
                      checked={values[campo.name_campo]}
                    />
                  </Col>
                );
              })}
            </Grid>
          </Container>

          <hr />
        </>
      ) : null}

      <Container>
        <Grid colGap={21} rowGap={21} narrow={true}>
          {fields.map((campo) => {
            switch (campo.tipo_campo) {
              case "text":
              case "number":
                return (
                  <Col desktop={4}>
                    <NewInputBasic
                      type={campo.tipo_campo}
                      name={campo.name_campo}
                      labelName={campo.label_campo}
                      color={formErrors[campo.name_campo] ? "error" : "normal"}
                      placeholder={campo.placeholder_campo}
                      onChange={handleChange}
                      value={values[campo.name_campo]}
                      disabled={false}
                    />
                    {formErrors[campo.name_campo] && (
                      <ErrorInput>{formErrors[campo.name_campo]}</ErrorInput>
                    )}
                  </Col>
                );
              case "select":
                let options = [];
                switch (campo.name_campo) {
                  case "tipo_vehiculo":
                    options = SELECT_OPTIONS_TIPO_VEHICULO;
                    break;
                  case "uso":
                    options = usoOptions;
                    break;
                  default:
                    break;
                }
                return (
                  <Col desktop={4}>
                    <NewInputSelect
                      name={campo.name_campo}
                      labelName={campo.label_campo}
                      color={formErrors[campo.name_campo] ? "error" : "normal"}
                      placeholder={campo.placeholder_campo}
                      onChange={handleChange}
                      value={values[campo.name_campo]}
                      options={options}
                      disabled={false}
                    />
                    {formErrors[campo.name_campo] && (
                      <ErrorInput>{formErrors[campo.name_campo]}</ErrorInput>
                    )}
                  </Col>
                );
              case "date":
                return (
                  <Col desktop={4}>
                    <NewInputCalendar
                      type={campo.tipo_campo}
                      name={campo.name_campo}
                      labelName={campo.label_campo}
                      color={formErrors[campo.name_campo] ? "error" : "normal"}
                      placeholder={campo.placeholder_campo}
                      onChange={handleChange}
                      value={values[campo.name_campo]}
                      disabled={false}
                    />
                    {formErrors[campo.name_campo] && (
                      <ErrorInput>{formErrors[campo.name_campo]}</ErrorInput>
                    )}
                  </Col>
                );
              default:
                break;
            }
          })}
        </Grid>
      </Container>

      <hr />
      <Container>
        <Grid colGap={21} rowGap={21} narrow={true}>
          {fields
            .filter((item) => item.tipo_campo === "textarea")
            .map((campo) => {
              return (
                <Col desktop={12}>
                  <NewInputTextarea
                    type={campo.tipo_campo}
                    name={campo.name_campo}
                    labelName={campo.label_campo}
                    color={formErrors[campo.name_campo] ? "error" : "normal"}
                    placeholder={campo.placeholder_campo}
                    onChange={handleChange}
                    value={values[campo.name_campo]}
                    disabled={false}
                  />
                  <AclaracionInput><FiAlertCircle />Agregar cada accesorio junto con su suma asegurada.</AclaracionInput>
                  {formErrors[campo.name_campo] && (
                    <ErrorInput>{formErrors[campo.name_campo]}</ErrorInput>
                  )}
                </Col>
              );
            })}
        </Grid>
      </Container>

      {campos.camposFile.length > 0 ? (
        <>
          <hr />

          <Container>
            <Grid colGap={21} rowGap={21} narrow={true}>
              <Col desktop={12}>
                <AclaracionAutoMoto><span className="icon"><FiAlertCircle /></span>Recordá que el cartel con la fecha debe estar colocado en la puerta, donde no obstruya la visión de las ventanas laterales (<strong>que deben estar cerradas y visibles para identificar sí tienen un daño o no</strong>). Lo mismo con el frente y costado del vehículo. La fecha debe estar colocada en el frente y arriba de la patente, <strong>la patente y el cartel con la fecha deben ser visibles sin que se obstruya el parabrisas o luneta del vehículo.</strong></AclaracionAutoMoto>
              </Col>
              <Col desktop={12}>
              <AclaracionLimiteArchivo>
                  <div className="icon-container">
                    <span className="icon"><FiFile /></span>
                  </div>
                  <div>
                  <h4>Subida de archivos</h4>
                  <span>El tamaño límite de subida de archivos es de 5MB. En caso de que el archivo supere el límite, <a href="https://www.freeconvert.com/es/video-compressor" target="_blank">reducí su tamaño usando este link.</a></span>
                  </div>
                </AclaracionLimiteArchivo>
              </Col>
              {campos.camposFile.map((campo) => {
                return (
                  <Col desktop={4}>
                    <InputFile
                      type={campo.tipo_campo}
                      name={campo.name_campo}
                      labelName={campo.label_campo}
                      required={false}
                      onChange={handleChangeExternal}
                      file={values[campo.name_campo]}
                      text={"Adjuntar" + " " + campo.label_campo}
                      accept={campo.extensiones_campo}
                    />
                    {formErrors[campo.name_campo] && (
                      <ErrorInput>{formErrors[campo.name_campo]}</ErrorInput>
                    )}
                    {imagenesEjemplo[campo.name_campo] && <EjemploAutoMoto href={imagenesEjemplo[campo.name_campo]} target="_blank"><FiEye />Ver ejemplo</EjemploAutoMoto>}
                  </Col>
                );
              })}
            </Grid>
          </Container>
        </>
      ) : null}
    </StepContainer>
  );
};

export default AutomotoresForm;
