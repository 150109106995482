// Styles
import { Container, Section } from "./styled";
// Components
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import { Grid, Col } from "../../../components/Grid";
import TitlePageSection from "../../../components/TitlePageSection";
import Subtitulo from "../../../components/Subtitulo";
import TabsInfo from "../../../components/TabsInfo";
import Sidebar from "../../../components/Sidebar";
import SpinnerLoading from "../../../components/SpinnerLoading";
import PlanCard from "../../../components/PlanCard";
import CotizadorForm from "../../../components/LandingsForms/CotizadorForm";
import Button from "../../../components/Button";
// Hooks
import { useTabs } from "../../../hooks/useTabs";
import { usePlanes } from "./hooks/usePlanes";
import { useDragAndDrop } from "./hooks/useDragAndDrop";
// Constants
import { landingsTabs } from "../../../constants/tabs";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
// Icons
import { FiDollarSign, FiFileText, FiBarChart2 } from "react-icons/fi";
import SectionLandings from "../../../components/SectionLandings";
import LimitesForm from "../../../components/LandingsForms/LimitesForm";

const LandingsRefactor = () => {
  const { tabSelected, handleTab } = useTabs();
  const landing = landingsTabs[tabSelected];
  const {
    planes,
    handlePlanes,
    productos,
    limites,
    productoSeleccionado,
    pruductoSeleccionadoLimites,
    handleProducto,
    handleProductoLimite,
    loading,
    loadingText,
  } = usePlanes(landing);
  const { onDragOver, handleDrop, handleDraggingPlan } = useDragAndDrop(
    planes,
    handlePlanes
  );

  return (
    <>
      <Sidebar active="landings" />
      <Container>
        <Wrapper>
          <Inner>
            <Grid colGap={21} rowGap={10} narrow={true}>
              <Col desktop={12}>
                <TitlePageSection title="Landings">
                  <Link to={"/Landings/Plan/Crear"}>
                    <Button backgroundColor={true}>Crear Plan</Button>
                  </Link>
                </TitlePageSection>
              </Col>

              <Col desktop={12}>
                <p>
                  Modificá los valores de cotización para los planes de las
                  landings de Seguro Web
                </p>
              </Col>

              <Col desktop={12}>
                <TabsInfo
                  tabs={landingsTabs}
                  tabSelected={tabSelected}
                  handleTab={handleTab}
                />
              </Col>

              {landing.name === "Seguro Bici" ? (
                <SectionLandings
                  columns={12}
                  icon={<FiDollarSign />}
                  title="Cotizador"
                  description={`Establecé el valor máximo del cotizador de ${landing.name} para cada producto`}
                  divider={false}
                >
                  <CotizadorForm productos={productos} />
                </SectionLandings>
              ) : null}

              {landing.name === "Seguro Bici" ? (
                <SectionLandings
                  columns={12}
                  icon={<FiBarChart2 />}
                  title="Limites"
                  description={`Establecé los valores límite para cada aseguradora y producto`}
                  divider={true}
                  noMargin={true}
                >
                  {productos.length > 0 && (
                    <>
                      <div className="productos-limites-container">
                        {productos.map((producto) => {
                          return (
                            <button
                              onClick={() =>
                                handleProductoLimite(producto.numero)
                              }
                              className={`${
                                pruductoSeleccionadoLimites ===
                                  producto.numero && "producto-seleccionado"
                              }`}
                            >
                              {producto.descripcion}
                            </button>
                          );
                        })}
                      </div>
                    </>
                  )}
                  <LimitesForm
                    limites={limites}
                    productoSeleccionado={pruductoSeleccionadoLimites}
                    productos={productos}
                  />
                </SectionLandings>
              ) : null}

              <SectionLandings
                columns={12}
                icon={<FiFileText />}
                title="Planes"
                description={`Establecé el valor máximo del cotizador de ${landing.name} para cada producto`}
                divider={landing.name === "Seguro Bici"}
                noMargin={true}
              >
                {landing.name === "Seguro Bici" ? (
                  <div className="productos-container">
                    {productos.length > 0 &&
                      productos.map((producto) => {
                        return (
                          <button
                            onClick={() => handleProducto(producto.numero)}
                            className={`${
                              productoSeleccionado === producto.numero &&
                              "producto-seleccionado"
                            }`}
                          >
                            {producto.descripcion}
                          </button>
                        );
                      })}
                  </div>
                ) : null}

                <div className="planes-container">
                  {planes.map((plan, index) => {
                    return (
                      <>
                        <PlanCard
                          data={plan}
                          key={plan.id}
                          active={plan.estado === 1}
                          onDrop={handleDrop}
                          onDragOver={onDragOver}
                          handleDraggingPlan={handleDraggingPlan}
                        />
                      </>
                    );
                  })}
                </div>
              </SectionLandings>

              <Col desktop={12}>
                {loading ? <SpinnerLoading text={loadingText} /> : null}
              </Col>
            </Grid>
          </Inner>
        </Wrapper>
      </Container>
    </>
  );
};

export default LandingsRefactor;
