import styled, { css } from 'styled-components';
import { breakpoints, leerColor, colores } from '../../constants';

export const Container = styled.div`
    display: flex;
    border: 1px solid #eaeaea;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    img {
        width: 50px;
    }
`