import { useEffect } from "react"
import { Item } from "./styled"

export const ItemConsulta = ({consulta, selected, setSelected}) => {

    return (
    <Item select={selected===consulta.id} onClick={()=>setSelected(consulta.id)}>
        <strong>#{consulta.id}</strong> 
        <div className="badge"> {consulta.Tipo.tipo}</div> 
        {consulta.notificacion? 
        <div className="badge notificacion"> nuevo mensaje</div>
        : null
        }
        <br />
        <div>Poliza: {consulta.poliza} - Asegurado: {consulta.id_user}</div>
        <p>{consulta.id}</p>
    </Item>
    )
}