import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { InputDiv, Input } from "./styled";
import CancelReminder from "../../assets/iconos/cancelReminder.svg";
import CalendarPicker from "../../assets/iconos/calendarPicker.svg";

function NewInputCalendar({ labelName, type, name, onChange, readOnly, color, value, disabled }) {
  return (
    <InputDiv>
      <label htmlFor={name} color={color}>{labelName}</label>
      <input
        type={type}
        name={name}
        id={name}
        value={value}
        color={color}
        onChange={onChange}
        readOnly={readOnly}
        calendarPicker={CalendarPicker}
        cancelReminder={CancelReminder}
        disabled={disabled}
      />
    </InputDiv>
  )
}

export default NewInputCalendar;
