import { useState, useEffect } from "react";
import axiosRequest from "../../../../utils/axiosConfig";
import formatSelectData from "../../../../utils/formatSelectData";
// Components
import Modal from "../../../Modal";
import { Container } from "./styled";
import Titulo from "../../../Titulo";
import { Grid, Col } from "../../../Grid";
import InputBasic from "../../../InputBasic";
import InputCalendar from "../../../InputCalendar";
import InputTextarea from "../../../InputTextarea";
import InputSelect from "../../../InputSelect";
import InputFileRec from "../../../InputFileRec";
import Button from "../../../Button";
import FileLoadedNew from "../../../FileLoadedNew";
import InputFile from "../../../InputFile";
// Hooks
import { useForm } from "../../../../hooks/useForm";
import useAutocomplete from "../../../../hooks/useAutocomplete";
import useButtonMessage from "../../../../hooks/useButtonMessage";
import useFiles from "../../../../hooks/useFiles";
// Utils
import { cargarSiniestrosReglas } from "../../../../utils/formRules";
import { cargarSiniestrosForm } from "../../../../utils/formInitialValues";
// Services
import {
  getPolizaInformation,
  getProductoSiniestroId,
} from "../../../../services/Siniestros";
import Subtitulo from "../../../Subtitulo";
import SpinnerLoading from "../../../SpinnerLoading";

const ModalCargarSiniestro = ({
  isModalOpen,
  handleModal,
  tipos,
  postSiniestro,
  siniestros,
  loading,
  allProductos
}) => {
  const user = JSON.parse(localStorage.getItem("userInfo"));

  const [productos, setProductos] = useState([]);

  // Custom Hooks
  const {
    inputValues,
    handleChange,
    handleChangeExternal,
    handleValidationAsync,
    formErrors,
    resetForm,
    resetFormErrors,
  } = useForm(handleSubmit, cargarSiniestrosReglas, cargarSiniestrosForm);
  const autocomplete = useAutocomplete(productos, handleChange);
  const { buttonText, handleButtonText } = useButtonMessage("Cargar Siniestro");

  // Modifica el texto del botón
  const changeButtonText = (text) => handleButtonText(text);

  // Validación de data
  const validateData = async (e) => {
    try {
      changeButtonText("Validando información...");
      const val = await handleValidationAsync(e);
      if (!val) {
        changeButtonText("Cargar siniestro");
      }
      console.log(val);
    } catch (error) {
      console.log("ERROR");
      changeButtonText("Cargar siniestro");
    }
  };

  // Cierra el modal y limpia el array de adjuntos
  const closePostModal = () => {
    handleModal();
    resetForm();
    resetFormErrors({});
  };

  useEffect(() => {
    const getProductos = async () => {
      if (inputValues?.dni?.length === 8) {
        try {
          const productos = await axiosRequest.get(
            `/siniestros_productos/${inputValues.dni}`
          );
          if(productos.data.length > 0){
            const productosList = formatSelectData(
              productos.data,
              "producto",
              "nombre_producto",
              "producto"
            );
            setProductos(productosList);
          } else {
            setProductos(allProductos)
          }

        } catch (error) {
          console.log(error);
          console.log(error.data);
          setProductos(allProductos)
        }
      }
    };
    getProductos();
  }, [inputValues.dni]);

  // Cargar siniestro
  async function handleSubmit() {
    console.log("submit siniestro");
    try {
      //const polizaExists = siniestros.some((siniestro) => siniestro.poliza === inputValues.poliza)
      const polizaInfo = await getPolizaInformation(
        inputValues.poliza,
        inputValues.dni
      );
      console.log(polizaInfo);
      const productoId = await getProductoSiniestroId(inputValues.producto);
      console.log("YYYY");
      console.log(productoId);
      changeButtonText("Cargando siniestro...");
      console.log("inputValues");
      await postSiniestro(inputValues, polizaInfo.data[0], productoId.data);
      changeButtonText("Cargar siniestro");
      resetForm();
      resetFormErrors({});
      /*
        if(!polizaExists){
          changeButtonText("Cargando siniestro...")
          await postSiniestro(inputValues, polizaInfo, productoId)
        } else {
          changeButtonText("Cargar siniestro");
          //setCargaSiniestroData(siniestroData);
          //setIsModalPolizaSiniestroOpen(true);
        }
        */
    } catch (error) {
      console.log(error);
      changeButtonText("Cargar siniestro");
    }
  }

  return (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal}>
      <Container>
        {!loading ? (
          <>
            <Titulo margin={true}>Cargar Siniestro</Titulo>
            <Grid colGap={21} rowGap={21}>
              <Col desktop={4}>
                <InputBasic
                  type={"number"}
                  name={"dni"}
                  labelName={"DNI"}
                  placeholder={"40955581"}
                  onChange={handleChange}
                  value={inputValues.dni}
                />
                {formErrors.dni && (
                  <span className="error">{formErrors.dni}</span>
                )}
              </Col>
              <Col desktop={4}>
                <InputCalendar
                  type={"date"}
                  name={"fecha_siniestro"}
                  labelName={"Fecha Siniestro"}
                  onChange={handleChange}
                  readOnly={false}
                  value={inputValues.fecha_siniestro}
                />
                {formErrors.fecha_siniestro && (
                  <span className="error">{formErrors.fecha_siniestro}</span>
                )}
              </Col>
              <Col desktop={4}>
                <InputBasic
                  type={"text"}
                  name={"num_siniestro"}
                  labelName={"Número Siniestro"}
                  placeholder={"Número Siniestro"}
                  onChange={handleChange}
                  value={inputValues.num_siniestro}
                />
                {formErrors.numero_siniestro && (
                  <span className="error">{formErrors.num_siniestro}</span>
                )}
              </Col>
              <Col desktop={4}>
                <InputBasic
                  type={"number"}
                  name={"poliza"}
                  labelName={"Póliza"}
                  placeholder={"900034"}
                  onChange={handleChange}
                  value={inputValues.poliza}
                />
                {formErrors.poliza && (
                  <span className="error">{formErrors.poliza}</span>
                )}
              </Col>
              <Col desktop={4}>
                <InputSelect
                    name={"producto"}
                    labelName={"Producto"}
                    options={productos}
                    onChange={handleChange}
                    value={inputValues.producto}
                />
                {formErrors.producto && (
                  <span className="error">{formErrors.producto}</span>
                )}
              </Col>
              <Col desktop={4}>
                <InputSelect
                  name={"tipo_siniestro"}
                  labelName={"Tipo"}
                  options={tipos}
                  onChange={handleChange}
                  value={inputValues.tipo_siniestro}
                />
                {formErrors.tipo_siniestro && (
                  <span className="error">{formErrors.tipo_siniestro}</span>
                )}
              </Col>
              <Col desktop={12}>
                <InputTextarea
                  type={"text"}
                  name={"informe"}
                  labelName={"Informe"}
                  placeholder={"Informe del siniestro"}
                  maxLength={"1000"}
                  height={"100px"}
                  onChange={handleChange}
                  value={inputValues.informe}
                />
                {formErrors.informe && (
                  <span className="error">{formErrors.informe}</span>
                )}
              </Col>
            </Grid>
            <Grid colGap={21} rowGap={"0px"}>
              <Col desktop={12}>
                <Subtitulo>Documentación</Subtitulo>
              </Col>
              <Col desktop={12}>
                <InputFile
                  type={"file"}
                  name={"adjuntos"}
                  text={"Adjuntar documentación del siniestro"}
                  labelName={""}
                  required={true}
                  multiple={true}
                  onChange={handleChangeExternal}
                  files={inputValues.adjuntos}
                />
              </Col>
            </Grid>

            <div className="buttonsContSiniestro">
              <Button
                backgroundColor={false}
                borderColor={true}
                textColor={true}
                icon={false}
                onClick={closePostModal}
                disabled={user.id_rol === 8}
              >
                {" "}
                Cancelar{" "}
              </Button>
              <Button
                backgroundColor={true}
                borderColor={false}
                textColor={false}
                icon={false}
                onClick={validateData}
                disabled={user.id_rol === 8}
              >
                {buttonText}
              </Button>
            </div>
          </>
        ) : (
          <SpinnerLoading text="Creando siniestro..." />
        )}
      </Container>
    </Modal>
  );
};

export default ModalCargarSiniestro;
