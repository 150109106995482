import { useState, useContext } from "react";
import { useForm } from "./useForm";
import { loginForm } from "../utils/formInitialValues";
import { datosLoginReglas } from "../utils/formRules";
import { iniciarSesion, obtenerNotificaciones } from "../services/Login";
import { AuthContext } from "../context/AuthContext";
import { useHistory } from "react-router-dom";
import { useNotificaciones } from "../context/NotificacionesProvider";

export const useLogin = () => {

  // Context
  const userAuth = useContext(AuthContext);
  const { handleLoginNotificaciones } = useNotificaciones()
  // Router
  const history = useHistory(); 
  // States
  const [passwordType, setPasswordType] = useState("password")
  // Custom Hooks
  const form = useForm(handleLogin, datosLoginReglas, loginForm)

  const handlePasswordType = () => setPasswordType(passwordType === "password" ? "text" : "password")

  async function handleLogin(){
    try {
      const login = await iniciarSesion(form.inputValues)
      if(login.status === 200){
        const userInfo = login.data.user
        const token = login.data.accessToken
        const expiresIn = login.data.expiresIn
        userAuth.setAuthState({token: token, userInfo: userInfo, expiresIn: expiresIn})
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        localStorage.setItem("expiresIn", expiresIn) 
        const notificaciones = await obtenerNotificaciones(userInfo.id_usuario)
        handleLoginNotificaciones(notificaciones)
        history.push({pathname: '/Fichajes'});     
      }
    } catch (error) {
      console.log(error)
    }
  }

  return {
    form,
    passwordType,
    handlePasswordType
  };
};
