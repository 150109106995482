import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { SelectDiv, Select } from "./styled";

function InputSelect({ labelName, name, onChange}) {

  const [items, setItems] = useState([]);

  useEffect(() => {
    async function getCharacters() {
      const response = await fetch("https://swapi.dev/api/people");
      const body = await response.json();
      setItems(body.results.map(({ name }) => (
        { 
          label: name, 
          value: name 
        }
      )));
    }
    getCharacters();
  }, []);

  return (
    <SelectDiv>
      <label
        htmlFor={name}
      >
        {labelName}
      </label>
      <Select 
        name={name}
        id={name}
        onChange={onChange}
      >
        {items.map(item => (
          <option
            key={item.value}
            value={item.value}
          >
            {item.label}
          </option>
        ))}
      </Select>
    </SelectDiv>
  );
}

// InputSelect.propTypes = {
//   children: PropTypes.string.isRequired
// };

export default InputSelect;