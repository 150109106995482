import React, { useState, useRef, useEffect } from "react";
// Iconos
import {
  HiOutlineTicket,
  HiOutlineFolder,
  HiOutlineFolderOpen,
  HiBadgeCheck,
} from "react-icons/hi";
import {
  RiShieldFill,
  RiCustomerServiceFill,
  RiCalendar2Fill,
  RiHotelFill,
  RiDiscussFill,
  RiTimeLine,
} from "react-icons/ri";
import { IoClose } from "react-icons/io5";
// Librerias
import disableScroll from "disable-scroll";
import { CSSTransition } from "react-transition-group";
// Estilos
import Container from "./styled";
// Componentes
import CardMobile from "./card-mobile";
import SpinnerLoading from "../../SpinnerLoading";

export default function Titulo({
  detalleConsulta,
  loading,
  consultas
}) {
  const [detallesStruct, setDetallesStruct] = useState({
    numero: "",
    seguro: "",
    estado: "",
    bien: "",
    poliza: "",
    sector: "",
    operador: "",
  });
  const { numero, seguro, estado, bien, poliza, sector, operador } = detallesStruct
  // Estado para determinar si esta abierto o no el dropdown en mobile
  const [isDropdownOpen, setIsDropdownOpen] = useState({ unoActivo: false });
  // Handle para cambiar el estado del dropdown
  const handleToggleDropdownMobile = () => {
    setIsDropdownOpen((prev) => ({
      ...prev,
      unoActivo: !isDropdownOpen.unoActivo,
    }));
  };
  // Estado para determinar si esta abierto el pop-up de cerrar la consulta
  const [cerrarConsulta, setCerrarConsulta] = useState(false);
  // Toggle para estado de cerrar consulta
  const toggleCerrarConsulta = () => {
    setCerrarConsulta(!cerrarConsulta);
    // Activa o desactiva el scroll si esta abierto o no el pop-up
    if (cerrarConsulta == true) {
      disableScroll.off();
    } else {
      disableScroll.on();
    }
  };
  // Setea la referencia para el CSS Transition
  const nodeRef = useRef(null);

  useEffect(() => {

    if (detalleConsulta !== null) {
      const { poliza, Tipo, id: numero, abierta, User } = detalleConsulta;
      const { tipo: sector } = Tipo;
      const { nombre, apellido } = User;
      const estado = abierta === 's' ? "Abierta" : "Cerrada";
      setDetallesStruct({
        poliza,
        sector,
        numero,
        estado,
        seguro: "SeguroBici(?)"
      })
    }

  }, [detalleConsulta])

  return (
    <Container>
      {
        !loading ?
          detalleConsulta !== null ?
            <>
              {/* Card Container */}
              <div id="card-container" className="opacidad03">
                {/* Header Container */}
                <div id="header-container">
                  {/* Título */}
                  <h3>
                    <span>
                      <HiOutlineTicket></HiOutlineTicket>
                    </span>
                    <strong>#{numero}&nbsp;-&nbsp;</strong>
                    {seguro}
                  </h3>
                  {/* Tag */}
                  <p id="estado">
                    <span>
                      {estado == "Abierta" ? (
                        <HiOutlineFolderOpen></HiOutlineFolderOpen>
                      ) : (
                        <HiOutlineFolder></HiOutlineFolder>
                      )}
                    </span>
                    {estado}
                  </p>
                </div>
                {/* Data Container */}
                <div id="data-container">
                  {/* Bien asegurado */}
                  <p>
                    <span>
                      <RiShieldFill></RiShieldFill>
                    </span>
                    <strong>Bien asegurado:</strong>&nbsp;{bien}
                  </p>
                  {/* Fecha de apertura */}
                  <p>
                    <span>
                      <RiCalendar2Fill></RiCalendar2Fill>
                    </span>
                    <strong>Poliza:</strong>&nbsp;{poliza}
                  </p>
                  {/* Área */}
                  <p>
                    <span>
                      <RiHotelFill></RiHotelFill>
                    </span>
                    <strong>Área:</strong>&nbsp;{sector}
                  </p>
                </div>
                {/* Botones Consulta Container */}
                <div id="botones-consulta-container">
                  {/* Botón Mensajes */}
                  {estado != "Cerrada" ? (
                    <a id="cerrar-consulta-boton" onClick={toggleCerrarConsulta}>
                      Cerrar consulta
                    </a>
                  ) : (
                    <></>
                  )}
                </div>
                {/* Operador Container */}
                <div id="operador-container">
                  <div id="perfil-operador">
                    <div>
                      <p>Operador</p>
                      <p>
                        {operador}
                        <span>
                          <HiBadgeCheck></HiBadgeCheck>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Card Container Mobile */}
              <CardMobile
                numero={numero}
                seguro={seguro}
                estado={estado}
                bien={bien}
                poliza={poliza}
                sector={sector}
                operador={operador}
                isDropdownOpen={isDropdownOpen.unoActivo}
                handleToggleDropdownMobile={handleToggleDropdownMobile}
                children={[
                  <div id="card-mobile-children">
                    {/* Header Container */}
                    <div id="header-container">
                      {/* Tag */}
                      <p id="estado">
                        <span>
                          {estado == "Abierta" ? (
                            <HiOutlineFolderOpen></HiOutlineFolderOpen>
                          ) : (
                            <HiOutlineFolder></HiOutlineFolder>
                          )}
                        </span>
                        {estado}
                      </p>
                    </div>
                    {/* Data Container */}
                    <div id="data-container">
                      {/* Bien asegurado */}
                      <p>
                        <span>
                          <RiShieldFill></RiShieldFill>
                        </span>
                        <strong>Bien asegurado:</strong>&nbsp;{bien}
                      </p>
                      {/* Fecha de apertura */}
                      <p>
                        <span>
                          <RiCalendar2Fill></RiCalendar2Fill>
                        </span>
                        <strong>Poliza:</strong>&nbsp;{poliza}
                      </p>
                      {/* Área */}
                      <p>
                        <span>
                          <RiHotelFill></RiHotelFill>
                        </span>
                        <strong>Área:</strong>&nbsp;{sector}
                      </p>
                    </div>
                    {/* Botones Consulta Container */}
                    <div id="botones-consulta-container">
                      {/* Botón Mensajes */}
                      {estado != "Cerrada" ? (
                        <a id="cerrar-consulta-boton" onClick={toggleCerrarConsulta}>
                          Cerrar consulta
                        </a>
                      ) : (
                        <></>
                      )}
                    </div>
                    {/* Operador Container */}
                    <div id="operador-container">
                      <div id="perfil-operador">
                        <div>
                          <p>Operador</p>
                          <p>
                            {operador}
                            <span>
                              <HiBadgeCheck></HiBadgeCheck>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>,
                ]}
              ></CardMobile>
              {/* Pop-up Cerrar Consulta, no puede estar como un componente separado para poder renderizar sos transiciones al mismo tiempo */}
              <CSSTransition
                nodeRef={nodeRef}
                in={cerrarConsulta}
                timeout={150}
                classNames="cerrar-consulta"
                unmountOnExit
              >
                {/* Fondo Popup */}
                <div id="fondo-popup" ref={nodeRef}>
                  {/* Caja Popup */}
                  <div id="caja-popup">
                    {/* Botón Cerrar Container Popup */}
                    <div id="boton-cerrar-container">
                      {/* Botón */}
                      <a onClick={toggleCerrarConsulta}>
                        <IoClose />
                      </a>
                    </div>
                    {/* Título Popup*/}
                    <h3>¿Querés cerrar esta consulta?</h3>
                    {/* Subtitulo Popup */}
                    <p>
                      Una vez cerrada, no podrás reabrir la consulta, deberás crear una
                      nueva consulta.
                    </p>
                    <div id="consulta-container-popup">
                      <h4>
                        <span>
                          <HiOutlineTicket></HiOutlineTicket>
                        </span>
                        <strong>#{numero}&nbsp;-&nbsp;</strong>
                        {seguro}
                      </h4>
                    </div>
                    {/* Botón Continuar Container */}
                    {estado == "Cerrada" ? (
                      <></>
                    ) : (
                      <div id="boton-confirmar-container">
                        {/* Botón */}
                        <button>Cerrar consulta</button>
                      </div>
                    )}
                  </div>
                </div>
              </CSSTransition>
            </>
            : <p>  {consultas && consultas.length > 0 ? "Seleccione una consulta" : ""} </p>
          :
          <div>
            <SpinnerLoading text={"Obteniendo Detalles"}></SpinnerLoading>
          </div>
      }
    </Container>
  );
}
