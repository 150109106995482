import React, { useState, useEffect, useContext } from "react";
import "../../index.css";
import GlobalStyle from "../../constants/globalStyle.js";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import {
  AllComponents,
  Login,
  CargaSolicitud,
  Rechazadas,
  Consultas,
  Emision,
  Emisiones,
  Estadisticas,
  SolicitudEmision,
  Modals,
  ModalDerivacion,
  ModalDerivacionOk,
  ModalChat,
  HistorialSolicitudes,
  Rechazos,
  Usuarios,
  Fichajes,
  FedPat,
  Siniestros,
  Campanias,
  EditarSolicitud,
  Capacitaciones,
  Landings,
  EstadisticasProductoCanal,
  StorageApi,
  Objetivos,
  Cobranzas,
  WhatsApp,
  Autogestion,
  EstadisticasCobranzas,
  ObjetivosCobranzas,
  PremiosVendedores,
  ObjetivosPorVendedor,
  Colaboradores
} from "../../pages/index";
import Siniestros2 from "../../pages/Siniestros2";
import CargarSolicitudEmision from "../../pages/new/CargaSolicitudEmision";
import EmisionRefactor from "../../pages/new/EmisionRefactor";
import EditarSolicitudEmision from "../../pages/new/EditarSolicitudEmision";
import SolicitudEmisionRefactor from "../../pages/new/SolicitudEmisionRefactor";
import EmisionesRefactor from "../../pages/new/EmisionesRefactor";
import HistorialSolicitudesRefactor from "../../pages/new/HistorialSolicitudes";
import EstadisticasProductoCanalRefactor from "../../pages/new/EstadisticasProductoCanal";
import AutogestionRefactor from "../../pages/new/AutogestionRefactor";

import { AuthContext, AuthProvider } from "../../context/AuthContext";
import UserIdle from "../UserIdle/index";
import UsuariosRefactor from "../../pages/new/UsuariosRefactor";
import LoginRefactor from "../../pages/new/LoginRefactor";
import ObjetivosLeads from "../../pages/new/ObjetivosLeads";
import SiniestrosRefactor from "../../pages/new/SiniestrosRefactor";
import LandingsRefactor from "../../pages/new/LandingsRefactor/index.js";
import LandingsPlan from "../../pages/new/LandingsPlan/index.js";
import LandingsCrearPlan from "../../pages/new/LandingsCrearPlan/index.js";

import { SocketProvider } from "../../context/SocketProvider.js";
import { NotificacionesProvider } from "../../context/NotificacionesProvider.js";
import Premios from "../../pages/new/Premios/index.jsx";
import PremiosCrearGrupo from "../../pages/new/PremiosCrearGrupo/index.jsx";
import Premio from "../../pages/new/Premio/index.jsx";
import Vendedor from "../../pages/new/Vendedor/index.js";
import GrupoVendedores from "../../pages/new/Grupo/index.jsx";
import PremiosEditarGrupo from "../../pages/new/PremiosEditarGrupo/index.js";

function App() {
  return (
    <>
      <AuthProvider>
        <SocketProvider>
          <NotificacionesProvider>
            <Router>
              <Switch>
                <Route path="/AllComponents" component={AllComponents} />
                <Route path="/Premios/EditarGrupo/:id" component={PremiosEditarGrupo} />
                <Route path="/Premio/Vendedor/:id/:id_premio" component={Premio} />
                <Route path="/Premios/Vendedores/Grupos/:id" component={GrupoVendedores} />
                <Route path="/Premios" component={Premios} />
                <Route path="/Premio/Vendedor/:id" component={Vendedor} />
                <Route path="/CrearGrupo" component={PremiosCrearGrupo} />

                <Route exact path="/Rechazadas" component={Rechazadas} />
                <Route exact path="/StorageApi" component={StorageApi} />
                <Route exact path="/Rechazos" component={Rechazos} />
                <Route exact path="/FedPat" component={FedPat} />
                <Route exact path="/Campanias" component={Campanias} />
                <Route exact path="/Cobranzas" component={Cobranzas} />
                <Route exact path="/Fichajes" component={Fichajes} />
                <Route
                  exact
                  path="/Capacitaciones"
                  component={Capacitaciones}
                />
                <Route exact path="/Objetivos" component={Objetivos} />
                <Route exact path="/Whatsapp" component={WhatsApp} />
                <Route path="/Estadisticas" component={Estadisticas} />

                <Route path="/Modals" component={Modals} />
                <Route path="/Consultas" component={Consultas} />
                <Route path="/ModalDerivacion" component={ModalDerivacion} />
                <Route
                  path="/ModalDerivacionOk"
                  component={ModalDerivacionOk}
                />
                <Route path="/ModalChat" component={ModalChat} />

                <Route
                  path="/EstadisticasCobranzas"
                  component={EstadisticasCobranzas}
                />
                <Route
                  path="/ObjetivosCobranzas"
                  component={ObjetivosCobranzas}
                />
                <Route
                  path="/PremiosVendedores"
                  component={PremiosVendedores}
                />
                <Route
                  path="/ObjetivosPorVendedor"
                  component={ObjetivosPorVendedor}
                />

                {/* Refactor Test */}
                <Route
                  path="/CargarSolicitudEmision"
                  component={CargarSolicitudEmision}
                />
                <Route
                  path="/EditarSolicitudEmision/:id"
                  component={EditarSolicitudEmision}
                />
                <Route path="/Emision/:id" component={EmisionRefactor} />
                <Route
                  path="/SolicitudEmision"
                  component={SolicitudEmisionRefactor}
                />
                <Route path="/Emisiones" component={EmisionesRefactor} />
                <Route
                  path="/HistorialSolicitudes"
                  component={HistorialSolicitudesRefactor}
                />
                <Route
                  path="/EstadisticasProductoCanal"
                  component={EstadisticasProductoCanalRefactor}
                />
                <Route path="/Usuarios" component={UsuariosRefactor} />
                <Route path="/Autogestion" component={AutogestionRefactor} />
                <Route path="/ObjetivosLeads" component={ObjetivosLeads} />
                <Route path="/Siniestros" component={SiniestrosRefactor} />
                <Route
                  path="/Landings/Plan/Crear"
                  component={LandingsCrearPlan}
                />
                <Route path="/Landings/Plan/:id" component={LandingsPlan} />
                <Route path="/Landings" component={LandingsRefactor} />
                <Route path="/Colaboradores" component={Colaboradores} />
                <Route path="/" component={LoginRefactor} />

              </Switch>
              <UserIdle></UserIdle>
            </Router>
            <GlobalStyle></GlobalStyle>
          </NotificacionesProvider>
        </SocketProvider>
      </AuthProvider>
    </>
  );
}

export default App;
