// Styles
import {
  Container,
  Cobertura,
  CoberturasContainer,
  Section,
  ActivePlan,
  CalculoContainer,
  Breadcrumb,
} from "./styled";
// Components
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import { Grid, Col } from "../../../components/Grid";
import TitlePageSection from "../../../components/TitlePageSection";
import Subtitulo from "../../../components/Subtitulo";
import Sidebar from "../../../components/Sidebar";
import SpinnerLoading from "../../../components/SpinnerLoading";
import Switch from "../../../components/Switch";
import ButtonCalc from "../../../components/ButtonCalc";
// Hooks
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosRequest from "../../../utils/axiosConfig";
import InputBasic from "../../../components/InputBasic";
import PlanCard from "../../../components/PlanCard";
import { Link } from "react-router-dom/cjs/react-router-dom";
import {
  FiDollarSign,
  FiShield,
  FiClipboard,
  FiStar,
  FiList,
  FiTrash2,
  FiArrowLeft,
} from "react-icons/fi";
import Button from "../../../components/Button";
import { useForm } from "../../../hooks/useForm";
import {
  actualizarPlanLandingReglas,
  detallesReglas,
  nuevoBeneficioReglas,
  nuevoDetalleReglas,
} from "../../../utils/formRules";
import ErrorInput from "../../../components/ErrorInput";
import { actualizarPlan } from "../../../services/Landings";
import useButtonMessage from "../../../hooks/useButtonMessage";
import IconButton from "../../../components/IconButton";
import {
  nuevoDetalleForm,
  nuevoBeneficioForm,
} from "../../../utils/formInitialValues";

const LandingsPlan = () => {
  const planId = useParams();
  const [plan, setPlan] = useState(null);
  const [coberturas, setCoberturas] = useState([]);
  const [detalles, setDetalles] = useState([]);
  const [beneficios, setBeneficios] = useState([]);
  const [tieneValorCuota, setTieneValorCuota] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState({});
  const { buttonText, handleButtonText } = useButtonMessage("Actualizar plan");
  const [beneficiosParaBorrar, setBeneficiosParaBorrar] = useState([]);
  const [detallesParaBorrar, setDetallesParaBorrar] = useState([]);
  const motosProductos = [5, 14, 15, 16, 49]

  const {
    inputValues,
    handleChange,
    handleChangeExternal,
    formErrors,
    handleValidation,
  } = useForm(handleSubmit, actualizarPlanLandingReglas, formInitialValues, {
    tieneValorCuota: tieneValorCuota,
    detalles: detalles,
    beneficios: beneficios
  });

  const detallesForm = useForm(
    handleSubmitDetail,
    nuevoDetalleReglas,
    nuevoDetalleForm
  );
  const beneficiosForm = useForm(
    handleSubmitBenefit,
    nuevoBeneficioReglas,
    nuevoBeneficioForm
  );

  const getPlan = async () => {
    try {
      const plan = await axiosRequest.get(`/planes_landings/plan/${planId.id}`);
      setPlan(plan.data);
      setCoberturas(plan.data.coberturas);
      setDetalles(plan.data.detalles);
      setBeneficios(plan.data.beneficios);
      console.log(plan.data)
      const detallesInit = {};
      const beneficiosInit = {};
      plan.data.detalles.forEach(
        (detalle) =>
          (detallesInit[`detalle_${detalle.id_detalle_plan}`] = detalle.detalle)
      );
      plan.data.beneficios.forEach(
        (beneficio) =>
          (beneficiosInit[`beneficio_${beneficio.id_beneficio}`] =
            beneficio.beneficio)
      );
      if (plan.data.valor_uno) {
        setTieneValorCuota(true);
      }
      setFormInitialValues({
        plan: plan.data.plan,
        id_plan_producto: plan.data.id_plan_producto,
        cuotas: "",
        vigencia: "",
        coberturas: true,
        valor_uno: plan.data.valor_uno,
        valor_dos: plan.data.valor_dos,
        operador_uno:
          plan.data.operador_uno === "*" ? "X" : plan.data.operador_uno,
        operador_dos: plan.data.operador_dos,
        estado: plan.data.estado,
        ...detallesInit,
        ...beneficiosInit,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeCobertura = (e) => {
    const { name, checked } = e.target;
    const newCoberturas = [...coberturas];
    const coberturasActualizadas = newCoberturas.map((cober) => {
      if (cober.id_plan_cobertura == name) {
        cober.activo = checked ? 1 : 0;
        return cober;
      }
      return cober;
    });
    setCoberturas(coberturasActualizadas);
    const areActive = coberturasActualizadas.some((cober) => cober.activo);
    if (!areActive) {
      setFormInitialValues((prev) => ({ ...prev, coberturas: false}))
      handleChangeExternal("coberturas", false);
    } else if (areActive && !inputValues.coberturas) {
      setFormInitialValues((prev) => ({ ...prev, coberturas: true}))
      handleChangeExternal("coberturas", true);
    }
  };

  useEffect(() => {
    getPlan();
  }, [planId]);

  async function handleSubmitDetail() {
    const details = [...detalles];
    details.push({ detalle: detallesForm.inputValues.detalle });
    console.log(formInitialValues);
    setFormInitialValues((prev) => ({
      ...prev,
      [`nuevo_detalle_${detallesForm.inputValues.detalle
        .toLowerCase()
        .replace(" ", "_")}`]: detallesForm.inputValues.detalle,
    }));
    setDetalles(details);
    detallesForm.handleChangeExternal("detalle", "");
  }

  async function handleSubmitBenefit() {
    const benefits = [...beneficios];
    benefits.push({ beneficio: beneficiosForm.inputValues.beneficio });
    setFormInitialValues((prev) => ({
      ...prev,
      [`nuevo_beneficio_${beneficiosForm.inputValues.beneficio
        .toLowerCase()
        .replace(" ", "_")}`]: beneficiosForm.inputValues.beneficio,
    }));
    setBeneficios(benefits);
    beneficiosForm.handleChangeExternal("beneficio", "");
  }

  async function handleSubmit() {
    handleButtonText("Actualizando...");
    try {
      const data = await actualizarPlan(
        plan.id,
        inputValues,
        coberturas,
        detalles,
        beneficios,
        beneficiosParaBorrar,
        detallesParaBorrar,
        tieneValorCuota
      );
      if (data) {
        handleButtonText("¡Plan actualizado correctamente!");
        setTimeout(() => {
          handleButtonText("Actualizar plan");
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const borrarBeneficio = (beneficio) => {
    if (Object.keys(beneficio).length > 1) {
      const benefitsToDelete = [...beneficiosParaBorrar];
      benefitsToDelete.push(beneficio);
      setBeneficiosParaBorrar(benefitsToDelete);
      setBeneficios((prev) =>
        prev.filter((ben) => ben.id_beneficio !== beneficio.id_beneficio)
      );
    } else {
      setBeneficios((prev) =>
        prev.filter((ben) => ben.beneficio !== beneficio.beneficio)
      );
    }
  };

  const borrarDetalle = (detalle) => {
    if (Object.keys(detalle).length > 1) {
      const detailsToDelete = [...detallesParaBorrar];
      detailsToDelete.push(detalle);
      setDetallesParaBorrar(detailsToDelete);
      setDetalles((prev) =>
        prev.filter((det) => det.id_detalle_plan !== detalle.id_detalle_plan)
      );
    } else {
      setDetalles((prev) =>
        prev.filter((det) => det.detalle !== detalle.detalle)
      );
    }
  };

  const handleChangeEstado = async (e) => {
    const { name, checked } = e.target;
    const estado = checked ? 1 : 0;
    handleChangeExternal(name, estado);
    setFormInitialValues((prev) => ({ ...prev, estado: estado }));
    const data = { estado: estado };
    try {
      const actualizarEstado = await axiosRequest.put(
        `actualizar_estado_plan_landings/${planId.id}`,
        data
      );
      console.log(actualizarEstado);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeOperador = (name, operador, handleMenu) => {
    handleChangeExternal(name, operador);
    handleMenu(false);
  };

  const handleValorCuota = () => {
    setFormInitialValues((prev) => ({
      ...prev,
      valor_uno: null,
      valor_dos: null,
      operador_uno: null,
      operador_dos: null,
    }));
    setTieneValorCuota(!tieneValorCuota);
  };

  return (
    <>
      <Sidebar active="landings" />
      <Container>
        <Wrapper>
          <Inner>
            <Grid colGap={21} rowGap={10} narrow={true}>
              <Col desktop={12}>
                <TitlePageSection title="Landings" />
              </Col>

              <Col desktop={12}>
                <p>
                  Modificá los valores de cotización para los planes de las
                  landings de Seguro Web
                </p>
              </Col>

              {plan ? (
                <>
                  <Col desktop={12}>
                    <Section>
                      <Breadcrumb>
                        <span className="icon">
                          <FiArrowLeft color="#3289b9" />
                        </span>
                        <Link to="/Landings">Volver a lista de planes</Link>
                      </Breadcrumb>
                      <PlanCard
                        data={plan}
                        single={true}
                        active={inputValues.estado}
                      />
                      <ActivePlan>
                        <Switch
                          checked={inputValues.estado === 1 ? true : false}
                          name="estado"
                          onChange={handleChangeEstado}
                        />
                        <div className="container">
                          {inputValues.estado === 1 ? (
                            <div className="blob"></div>
                          ) : null}
                          <span
                            className={`${
                              inputValues.estado === 0 && "plan-inactivo"
                            }`}
                          >
                            {inputValues.estado === 1
                              ? "El plan está activo"
                              : "El plan está inactivo"}
                          </span>
                        </div>
                      </ActivePlan>
                    </Section>
                  </Col>

                  <Col desktop={12}>
                    <hr />
                    <Section>
                      <div className="section-title">
                        <Subtitulo>
                          <span className="icon">
                            <FiClipboard />
                          </span>
                          Plan
                        </Subtitulo>
                        <p>
                          Conocé y modificá algunos aspectos generales del plan
                        </p>
                      </div>{" "}
                      <Grid colGap={21} rowGap={10} narrow={true}>
                        <Col desktop={3}>
                          <div className="plan-info">
                            <h3>Compañia</h3>
                            <span>{plan.nombre_compania}</span>
                          </div>
                        </Col>
                        <Col desktop={3}>
                          <div className="plan-info">
                            <h3>Producto</h3>
                            <span>{plan.descripcion}</span>
                          </div>
                        </Col>
                      </Grid>
                      <Grid colGap={21} rowGap={10} narrow={true}>
                        <Col desktop={3}>
                          <InputBasic
                            type="text"
                            value={inputValues.plan}
                            labelName="Plan"
                            placeholder="Plan Standard"
                            name="plan"
                            onChange={handleChange}
                          />
                          {formErrors.plan && (
                            <ErrorInput>{formErrors.plan}</ErrorInput>
                          )}
                        </Col>
                      </Grid>
                    </Section>
                  </Col>

                  <Col desktop={12}>
                    <hr />
                    <Section>
                      <div className="section-title">
                        <Subtitulo>
                          <span className="icon">
                            <FiShield />
                          </span>
                          Coberturas
                        </Subtitulo>
                        <p>Cambiá el estado de las coberturas del plan</p>
                      </div>
                      <CoberturasContainer>
                        {coberturas &&
                          coberturas.map((cobertura) => {
                            return (
                              <Cobertura>
                                <span>{cobertura.nombre_completo}</span>
                                <Switch
                                  checked={
                                    cobertura.activo === 1 ? true : false
                                  }
                                  name={cobertura.id_plan_cobertura}
                                  onChange={handleChangeCobertura}
                                />
                              </Cobertura>
                            );
                          })}
                      </CoberturasContainer>
                      {formErrors.coberturas && (
                        <ErrorInput>{formErrors.coberturas}</ErrorInput>
                      )}
                    </Section>
                  </Col>

                  {!motosProductos.includes(plan.id_producto) ?
                  <Col desktop={12}>
                    <hr />
                    <Section>
                      <div className="section-title">
                        <Subtitulo>
                          <span className="icon">
                            <FiDollarSign />
                          </span>
                          Valor de la cuota
                        </Subtitulo>
                        <p>
                          Modificá el cálculo para el valor de la cuota del plan
                        </p>
                      </div>
                      <div className="switch-calculo">
                        <span>
                          ¿El plan tiene cálculo para el valor de la cuota?
                        </span>
                        <Switch
                          checked={tieneValorCuota}
                          onChange={handleValorCuota}
                        />
                      </div>
                      {tieneValorCuota ? (
                        <>
                          <CalculoContainer>
                            <div className="plan-cotizacion">
                              <div className="plan-calculo">
                                <span className="plan-parentesis">(</span>
                                <span className="plan-valor-producto">
                                  Valor producto
                                </span>
                                {/* Primer valor */}
                                <ButtonCalc
                                  name="operador_uno"
                                  value={inputValues.operador_uno}
                                  handleChange={handleChangeOperador}
                                />
                                <div className="plan-input-container">
                                  <InputBasic
                                    type={"number"}
                                    value={inputValues.valor_uno}
                                    name="valor_uno"
                                    onChange={handleChange}
                                    labelName="Primer valor"
                                    placeholder="3"
                                  />
                                </div>
                                <>
                                  <span className="plan-parentesis">)</span>
                                  {/* Segundo valor */}
                                  <ButtonCalc
                                    name="operador_dos"
                                    value={inputValues.operador_dos}
                                    handleChange={handleChangeOperador}
                                  />
                                  <div className="plan-input-container">
                                    <InputBasic
                                      type={"number"}
                                      value={inputValues.valor_dos}
                                      name="valor_dos"
                                      onChange={handleChange}
                                      labelName="Segundo valor"
                                      placeholder="2"
                                    />
                                  </div>
                                </>
                              </div>
                            </div>
                            {/*
                          <div className="plan-ejemplo">
                            {plan.valor_dos ? (
                              <span className="plan-calculo-ejemplo">
                                Ejemplo: ($90.000 <span>{inputValues.operador_uno}</span>
                                {inputValues.valor_uno})
                                <span>
                                  {inputValues.operador_dos}
                                </span>
                                {inputValues.valor_dos} = Valor de la cuota -
                                $100
                              </span>
                            ) : (
                              <span>
                                Ejemplo: $90.000{" "}
                                <span>
                                  {inputValues.operador_uno}
                                </span>{" "}
                                {inputValues.valor_uno} = Valor de la cuota - $
                                {(90000 * inputValues.valor_uno).toFixed(2)}
                              </span>
                            )}
                          </div>
                          */}
                          </CalculoContainer>
                          {formErrors.operador_uno && (
                            <ErrorInput>{formErrors.operador_uno}</ErrorInput>
                          )}
                          {formErrors.operador_dos && (
                            <ErrorInput>{formErrors.operador_dos}</ErrorInput>
                          )}
                          {formErrors.valor_uno && (
                            <ErrorInput>{formErrors.valor_uno}</ErrorInput>
                          )}
                          {formErrors.valor_dos && (
                            <ErrorInput>{formErrors.valor_dos}</ErrorInput>
                          )}
                        </>
                      ) : null}
                    </Section>
                  </Col>
                  : null}

                  <Col desktop={12}>
                    <hr />

                    <Grid colGap={60} rowGap={10} narrow={true}>
                      <Col desktop={6}>
                        <Section>
                          <div className="section-title">
                            <Subtitulo>
                              <span className="icon">
                                <FiList />
                              </span>
                              Detalles
                            </Subtitulo>
                            <p>Lista de detalles del plan</p>
                          </div>
                          <div className="agregar-container">
                            <div className="input-agregar-container">
                              <InputBasic
                                type="text"
                                placeholder="Detalle"
                                name="detalle"
                                labelName="Nuevo Detalle"
                                onChange={detallesForm.handleChange}
                                value={detallesForm.inputValues.detalle}
                              />
                              <Button
                                backgroundColor={true}
                                onClick={detallesForm.handleValidation}
                              >
                                Agregar
                              </Button>
                            </div>
                            {detallesForm.formErrors.detalle && (
                              <ErrorInput>
                                {detallesForm.formErrors.detalle}
                              </ErrorInput>
                            )}
                          </div>
                          <hr />
                          {detalles.length > 0 ? (
                            <>
                              <h4 className="lista-titulo">
                                Lista de detalles del plan
                              </h4>
                              <ul>
                                {detalles.map((detalle) => {
                                  return (
                                    <li>
                                      <div className="input-agregar-container">
                                        <InputBasic
                                          type="text"
                                          value={
                                            detalle.id_detalle_plan
                                              ? inputValues[
                                                  `detalle_${detalle.id_detalle_plan}`
                                                ]
                                              : inputValues[
                                                  `nuevo_detalle_${detalle.detalle
                                                    .toLowerCase()
                                                    .replace(" ", "_")}`
                                                ]
                                          }
                                          name={
                                            detalle.id_detalle_plan
                                              ? `detalle_${detalle.id_detalle_plan}`
                                              : `nuevo_detalle_${detalle.detalle
                                                  .toLowerCase()
                                                  .replace(" ", "_")}`
                                          }
                                          onChange={handleChange}
                                        />
                                        <IconButton
                                          icon={<FiTrash2 />}
                                          action={() => borrarDetalle(detalle)}
                                          tooltipText="Borrar detalle"
                                        />
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </>
                          ) : (
                            <span className="empty-state">
                              <span className="icon">
                                <FiList />
                              </span>
                              El plan no tiene detalles
                            </span>
                          )}
                          {formErrors.detalles && (
                            <ErrorInput>{formErrors.detalles}</ErrorInput>
                          )}
                        </Section>
                      </Col>
                      <Col desktop={6}>
                        <Section>
                          <div className="section-title">
                            <Subtitulo>
                              <span className="icon">
                                <FiStar />
                              </span>
                              Beneficios
                            </Subtitulo>
                            <p>Lista de beneficios del plan</p>
                          </div>
                          <div className="agregar-container">
                            <div className="input-agregar-container">
                              <InputBasic
                                type="text"
                                placeholder="Beneficio"
                                name="beneficio"
                                labelName="Nuevo Beneficio"
                                onChange={beneficiosForm.handleChange}
                                value={beneficiosForm.inputValues.beneficio}
                              />
                              <Button
                                backgroundColor={true}
                                onClick={beneficiosForm.handleValidation}
                              >
                                Agregar
                              </Button>
                            </div>
                            {beneficiosForm.formErrors.beneficio && (
                              <ErrorInput>
                                {beneficiosForm.formErrors.beneficio}
                              </ErrorInput>
                            )}
                          </div>
                          <hr />
                          {beneficios.length > 0 ? (
                            <>
                              <h4 className="lista-titulo">
                                Lista de beneficios del plan
                              </h4>
                              <ul>
                                {beneficios.map((beneficio) => {
                                  return (
                                    <li>
                                      <div className="input-agregar-container">
                                        <InputBasic
                                          type="text"
                                          value={
                                            beneficio.id_beneficio
                                              ? inputValues[
                                                  `beneficio_${beneficio.id_beneficio}`
                                                ]
                                              : inputValues[
                                                  `nuevo_beneficio_${beneficio.beneficio
                                                    .toLowerCase()
                                                    .replace(" ", "_")}`
                                                ]
                                          }
                                          name={
                                            beneficio.id_beneficio
                                              ? `beneficio_${beneficio.id_beneficio}`
                                              : `nuevo_beneficio_${beneficio.beneficio
                                                  .toLowerCase()
                                                  .replace(" ", "_")}`
                                          }
                                          onChange={handleChange}
                                        />
                                        <IconButton
                                          icon={<FiTrash2 />}
                                          action={() =>
                                            borrarBeneficio(beneficio)
                                          }
                                          tooltipText="Borrar beneficio"
                                        />
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </>
                          ) : (
                            <span className="empty-state">
                              <span className="icon">
                                <FiStar />
                              </span>
                              El plan no tiene beneficios
                            </span>
                          )}
                          {formErrors.beneficios && (
                            <ErrorInput>{formErrors.beneficios}</ErrorInput>
                          )}
                        </Section>
                      </Col>
                    </Grid>
                  </Col>

                  <Col desktop={12}>
                    <hr />
                    <Section>
                      <div className="botones-container">
                        <Button
                          backgroundColor={true}
                          onClick={handleValidation}
                        >
                          {buttonText}
                        </Button>
                        <Link to="/Landings">
                          <Button
                            backgroundColor={false}
                            borderColor={true}
                            textColor={true}
                          >
                            Volver a lista de planes
                          </Button>
                        </Link>
                      </div>
                    </Section>
                  </Col>
                </>
              ) : (
                <Col desktop={12}>
                  <div className="loading-container">
                    <SpinnerLoading text="Cargando plan" />
                  </div>
                </Col>
              )}
            </Grid>
          </Inner>
        </Wrapper>
      </Container>
    </>
  );
};

export default LandingsPlan;
