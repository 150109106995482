import styled from "styled-components";
import { breakpoints } from "../../../../constants/media";

const Container = styled.div`
  width: 100% !important;
  margin: 0px 0px 0px 0px;
  display: none;
  justify-content: center;
  align-items: center;
  align-content: center;

  // Card Container Mobile
  #card-container-mobile {
    width: calc(100% - 32px);
    margin: 0px 0px 0px 0px;
    padding: 32px 32px 32px 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    border: 1px solid var(--grisClaro);
    border-radius: 16px;
    background-color: var(--blanco);
    box-shadow: 0px 5px 30px rgba(9, 30, 66, 0.05);
  }

  // Card Container Mobile Dropdown
  #dropdown-abierto,
  #dropdown-cerrado {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    pointer-events: none;
    border: 0;
    outline: 0;
    background-color: var(--blanco);
    transition: 0.2s all;
  }

  // Titulo
  h3 {
    width: 80%;
    margin: 0px 16px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 18px;
    color: var(--negro);
  }

  // Titulo Ícono
  h3 span {
    margin: 0px 8px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 24px;
    color: var(--azul60);
  }

  // Titulo Strong
  h3 strong {
    color: var(--gris);
    font-size: 16px;
    font-weight: 500;
  }

  // Botón Ver Más
  #ver-mas-boton-abierto,
  #ver-mas-boton-cerrado {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    font-size: 30px;
    color: var(--azul100);
    background-color: var(--blanco);
    border: 0;
    outline: 0;
    pointer-events: all;
    cursor: pointer;
    transition: 0.2s all;
  }

  // Botón Ver Más Abierto
  #ver-mas-boton-cerrado {
    transform: rotate(180deg);
  }

  // Botón Ver Más Cerrado y Abierto Hover
  #ver-mas-boton-abierto:hover,
  #ver-mas-boton-cerrado:hover {
    color: var(--negro);
  }

  // Tiempo de transicion cuando se abre y se cierra el dropdown
  .ReactCollapse--collapse {
    transition: height 250ms;
  }

  // 1024px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet1024}) {
    display: flex;
  }

  // 570px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile570}) {
    // Card Container Mobile
    #card-container-mobile {
      padding: 16px 16px 16px 16px;
    }
  }
`;

export default Container;
