import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 30% !important;
  margin: 30.5px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  // Card Container
  #card-container {
    width: calc(100% - 64px);
    margin: 0px 0px 0px 0px;
    padding: 32px 32px 32px 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    border: 1px solid var(--grisClaro);
    border-radius: 16px;
    background-color: var(--blanco);
    box-shadow: 0px 5px 30px rgba(9, 30, 66, 0.05);
  }

  // Header Container
  #header-container {
    width: 100%;
    margin: 16px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }

  // Header Container Título
  #header-container h3 {
    width: 100%;
    margin: 0px 16px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 18px;
    color: var(--negro);
  }

  // Header Container Título Ícono
  #header-container h3 span {
    margin: 0px 8px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 24px;
    color: var(--azul60);
  }

  // Header Container Titulo Strong
  #header-container h3 strong {
    color: var(--gris);
    font-size: 16px;
    font-weight: 500;
  }

  // Header Container Tags
  #header-container p {
    width: auto;
    margin: 24px 0px 0px 0px;
    padding: 0px 18px 0px 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: var(--cielo);
    border-radius: 300px;
    font-size: 12px;
    font-weight: 600;
    color: var(--negro);
    line-height: normal;
  }

  // Header Container Tags Ícono
  #header-container p span {
    margin: 3.5px 8px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-size: 19px;
    color: var(--azul60);
  }

  // Header Container Tag Estado Ícono
  #header-container #estado span {
    color: var(--naranja100);
  }

  // Data Container
  #data-container {
    width: 100%;
    margin: 16px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }

  // Data Container Texto
  #data-container p {
    width: 100%;
    margin: 16px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 13.5px;
    color: var(--gris);
    line-height: normal;
  }

  // Data Container Texto Ícono
  #data-container p span {
    margin: 3px 4px 0px 0px;
  }

  // Data Container Texto Strong
  #data-container p strong {
    font-weight: 500;
  }

  // Botones Consulta Container
  #botones-consulta-container {
    width: 100%;
    margin: 32px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Botón Cerrar Consulta
  #cerrar-consulta-boton {
    width: auto;
    margin: 0px 0px 0px 0px;
    padding: 8px 24px 8px 24px;
    background-color: var(--grisClaro);
    color: var(--rojo);
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.2s all;
  }

  // Botón Cerrrar Consulta Hover
  #cerrar-consulta-boton:hover {
    background-color: var(--cielo);
  }

  // Última vez Container
  #operador-container {
    width: 100%;
    margin: 32px 0px 0px 0px;
    padding: 24px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border-top: 1px solid var(--grisClaro);
  }

  #perfil-operador {
    width: fit-content;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  // Operador Container Imagen
  #perfil-operador img {
    width: 35px;
    height: 35px;
    margin: 0px 16px 0px 0px;
    padding: 0px 0px 0px 0px;
    object-fit: cover;
    border-radius: 300px;
  }

  // Operador Container Texto Container
  #perfil-operador div {
    width: fit-content;
    margin: 8px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Operador Container Texto
  #perfil-operador div p {
    width: auto;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    text-align: left;
    line-height: normal;
    font-weight: 600;
  }

  // Operador Container Primer Texto
  #perfil-operador div p:first-child {
    margin: 0px 0px -4px 0px;
    font-weight: 400;
    font-size: 12px;
    color: var(--gris);
  }

  // Operador Container Texto Ícono
  #perfil-operador div p span {
    font-size: 20px;
    margin: 5px 0px 0px 4px;
    color: var(--azul60);
  }

  #horario {
    width: fit-content;
    font-size: 13.5px;
    color: var(--gris);
    font-weight: 500;
  }

  /* --------- ACÁ ARRANCA POP-UP CERRAR CONSULTA --------- */

  // Animación Pop-up Cerrar Consulta
  .cerrar-consulta-enter {
    opacity: 0;
  }

  .cerrar-consulta-enter-active {
    opacity: 1;
    transition: opacity 150ms;
  }

  .cerrar-consulta-exit {
    opacity: 1;
  }

  .cerrar-consulta-exit-active {
    opacity: 0;
    transition: opacity 150ms;
  }

  // Fondo Cerrar Consulta
  #fondo-popup {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    background: #00000010;
    backdrop-filter: blur(7px);
    z-index: 100;
  }

  // Caja Cerrar Consulta
  #caja-popup {
    width: 600px;
    height: auto;
    min-height: 364px;
    top: 100px;
    left: 0px;
    right: 0px;
    position: fixed;
    padding: 17px;
    margin-top: calc(100vh - 85vh - 100px);
    margin: 0 auto;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
    background: var(--blanco);
    border: 1px solid var(--grisClaro);
    border-radius: 16px;
    animation-name: zoom;
    animation-duration: 0.2s;
    animation-iteration-count: initial;
    animation-timing-function: ease-out;
  }

  // Animación Zoom
  @keyframes zoom {
    from {
      transform: scale(0.8);
    }
    50% {
    }
    to {
      transform: scale(1);
    }
  }

  // Caja Cerrar Consulta Botón Cerrar Container
  #caja-popup #boton-cerrar-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  // Caja Cerrar Consulta Botón Cerrar
  #caja-popup #boton-cerrar-container a {
    font-size: 1.5em;
    color: var(--negro);
    cursor: pointer;
    transition: 0.1s all;
  }

  // Caja Cerrar Consulta Botón Cerrar Hover
  #caja-popup #boton-cerrar-container a:hover {
    color: var(--azul60) !important;
  }

  // Caja Cerrar Consulta Titulo
  #caja-popup h3 {
    width: 100%;
    margin: 24px 0px 0px 0px;
    text-align: center;
    font-size: 22px;
  }

  // Caja Cerrar Consulta Texto
  #caja-popup p {
    width: 60%;
    margin: 24px 0px 0px 0px;
    text-align: center;
    font-size: 15px;
    color: var(--gris);
    line-height: 32px;
  }

  // Caja Cerrar Consulta Consulta Container
  #caja-popup #consulta-container-popup {
    width: 80%;
    margin: 32px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  // Caja Cerrar Consulta Consulta Container Título
  #caja-popup #consulta-container-popup h4 {
    width: fit-content;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 18px;
    color: var(--negro);
  }

  // Caja Cerrar Consulta Consulta Container Título Ícono
  #caja-popup #consulta-container-popup h4 span {
    margin: 0px 8px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 24px;
    color: var(--azul60);
  }

  // Caja Cerrar Consulta Consulta Container Título Strong
  #caja-popup #consulta-container-popup h4 strong {
    color: var(--gris);
    font-size: 16px;
    font-weight: 500;
  }

  // Caja Cerrar Consulta Botón Confirmar Container
  #boton-confirmar-container {
    width: 100%;
    margin: 40px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Caja Cerrar Consulta Botón Confirmar
  #boton-confirmar-container button {
    padding: 7px 24px 7px 24px;
    border-radius: 8px;
    border: 0px solid;
    color: var(--rojo);
    background-color: var(--grisClaro);
    cursor: pointer;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    transition: 0.2s all;
  }

  // Caja Cerrar Consulta Botón Confirmar Hover
  #boton-confirmar-container button:hover {
    background-color: var(--cielo);
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    width: 40% !important;
  }

  // 1024px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet1024}) {
    width: 100% !important;

    // Card Container Mobile
    #card-container {
      display: none;
    }

    // Caja Cerrar Consulta
    #caja-popup {
      width: 400px;
    }
  }

  // 570px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile570}) {
    margin: 80px 0px 0px 0px;

    // Caja Cerrar Consulta
    #caja-popup {
      width: 80%;
      padding: 17px 17px 32px 17px;
    }

    // Caja Cerrar Consulta Texto
    #caja-popup p {
      width: 80%;
    }
  }
`;

export default Container;
