import React, { useEffect, useState } from "react";
import { FiPaperclip } from "react-icons/fi";
import { AiOutlineFile } from "react-icons/ai";
import { RiDeleteBin7Line } from "react-icons/ri";
import { IoSend } from "react-icons/io5";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import "tippy.js/animations/scale-subtle.css";
import "tippy.js/animations/scale-extreme.css";
import Mensaje from "./mensaje";
import Container from "./styled";
import ConsultasIco from "../../../assets/iconos/consultas.svg";
import socket from "../../../config/socket_client";
import axiosRequest from "../../../utils/axiosConfig";
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import axios from "axios";
import urlPortal from "../../../constants/urlPortal";

export default function Mensajes({ detalle, setDetalle, operador, cliente, mensajes, loading, consultas, setConsultas }) {
  const [formValues, setFormValues] = useState({});
  const [estado, setEstado] = useState('Abierta');
  const [mensajesVista, setMensajesVista] = useState([])


    // mensaje actual en el input (texto)
    const [mensajePorEnviar, setMensajePorEnviar] = useState("");
    // loading del mensaje a enviarse
    const [loadingMsg, setLoadingMsg] = useState(false);


  // Cargar archivo
  function onFileUpload(event) {
    event.preventDefault();
    event.persist();
    if (event.target.files[0]) {
      const { name } = event.target;
      setFormValues((prev) => ({
        ...prev,
        [name]: {
          image: event.target.files[0],
          name: event.target.files[0].name,
        },
      }));
    }
  }

  // Borrar archivo
  const deleteFile = () => {
    setFormValues((prev) => ({ ...prev, "archivo": "" }));
  };

  const sendMsg = async (e) => {
    e.preventDefault();
    setLoadingMsg(true)
    let respMensaje;
    // valida si es solo texto o tiene un archivo
    try {
      if( formValues.archivo  && formValues.archivo !== ''){
        let bodyFormData = new FormData();

        // Se agrega información al formData en base a la cantidad de propiedades que existan en el state 'files'. 
        bodyFormData.append('recfile', formValues.archivo.image, formValues.archivo.name);
        bodyFormData.append('id_consulta', detalle.id);
        bodyFormData.append('mensajeAnterior', JSON.stringify(mensajesVista[0] || {}))
        const config = {
            headers: {
                'keystorage': 'e152f0a0-5918-5169-a04d-ae4ea1123b69',
                'project': 'portal_clientes',
                'scope': 'public'
            }
        }
        respMensaje = await axios.post(`${urlPortal}/crm/chat/files`, bodyFormData, config)
      } else {
        respMensaje = await axiosRequest.post(`${urlPortal}/crm/chat?tipo_consulta=${detalle.Tipo.tipo}`,{
          mensajeAnterior: mensajesVista[0] || null,
          mensajeNuevo: {
            id_consulta: detalle.id,
            mensaje: mensajePorEnviar
          }
        })
      }
      

      setMensajesVista( prev => ([ respMensaje.data , ...prev])) // carga el mensaje al tope de la pila de mensajes
      setMensajePorEnviar(''); // limpia el input
      setFormValues({})
    } catch (error) {
      // manejar el error
      console.log(error)
    } finally {
      setLoadingMsg(false)
    }
  }


  useEffect(()=>{
     if (detalle !== null){
      setDetalle( prev => ({...prev, notificacion:false}))
     }
  },[])


  useEffect(()=>{
    if ( mensajes !== null){
      setMensajesVista(mensajes)
    }
  },[mensajes])

  useEffect(()=>{
    if(formValues.archivo)
    console.log('form', formValues.archivo.image)
  },[formValues])


  // socket para emision y escucha de mensajes
  socket.off('new_mensaje_from_portal')
  socket.on('new_mensaje_from_portal', (data) => {
    // si el mensaje corresponde a la consulta actual lo agrega al chat
     if (detalle && data.id_consulta === detalle.id){
      setMensajesVista( prev => [data, ...prev])
     }else{
      // si no corresponde lo agrega como notificacion a la consulta que corresponda
      const consultaNotificada = consultas.map( c => c.id === data.id_consulta? {...c, notificacion :true} : c)
      setConsultas( consultaNotificada)
     }
  })

  useEffect(()=>{
    if ( detalle !== null){
      detalle.notificacion = false;
    }
  },[detalle])

  return (
    <Container estado={estado}>
      {
        mensajes !== null ?
          <div id="mensajes-input-container" className="opacidad03">
            <div id="mensajes-container">
              {
                mensajesVista.map( mensaje => {
                dayjs.extend(relativeTime);
                const hora = dayjs(mensaje.createdAt).fromNow();
                return (<Mensaje
                  enviado={mensaje.remitente==="crm" || mensaje.remitente==="bot"}
                  mensaje={mensaje.mensaje}
                  archivo={mensaje.url}
                  hora={hora}
                  nombre={operador}
                ></Mensaje>)})
              }

            </div>
            {estado == "Cerrada" ? (
              <></>
            ) : (
              <div id="input-mensaje-container" className="opacidad03" >
                <form id="input-mensaje" onSubmit={sendMsg}>
                  {formValues.archivo ? (
                    <div id="archivo-cargado">
                      <p>
                        <span>
                          <AiOutlineFile></AiOutlineFile>
                        </span>
                        {formValues.archivo.name}
                      </p>
                      <a onClick={deleteFile}>
                        <span>
                          <RiDeleteBin7Line></RiDeleteBin7Line>
                        </span>
                        Eliminar
                      </a>
                    </div>
                  ) : (
                    <input 
                      placeholder="Escribí tu mensaje" 
                      type="text" value={mensajePorEnviar} 
                      onChange={e=> setMensajePorEnviar(e.target.value)}
                      onKeyDown={ e => {
                        if(e.key === 'enter'){
                          console.log('press enter')
                        }
                      }}
                    ></input>
                  )}
                  <div id="input-mensaje-botones">
                    {formValues.archivo ? (
                      <></>
                    ) : (
                      <Tippy content="PDF, imagen o video" animation="scale">
                        <label for="input-archivo" id="boton-archivo">
                          <FiPaperclip></FiPaperclip>
                        </label>
                      </Tippy>
                    )}
                    <input
                      type="file"
                      name="archivo"
                      accept="application/pdf, video/*, image/*"
                      id="input-archivo"
                      onChange={(e) => onFileUpload(e)}
                    ></input>
                    <a id="enviar-boton" onClick={sendMsg}><IoSend></IoSend></a>
                  </div>
                </form>
              </div>
            )}
          </div>
          :
          <div id="mensajes-input-container" className="opacidad03">
            <figure>
              <img src={ConsultasIco} alt={'mensajes'} className="invertido" />
              <img src={ConsultasIco} alt={'mensajes'} />
            </figure>
          </div>
      }
    </Container>
  );
}
