import React, { useState, useEffect, useContext } from "react";
import { Button, Subtitulo, Titulo, Grid, Col, Inner, Menu, InputBasic, InfoBoard, Wrapper, NavMenu, BurgerMenu} from '../components/Index';
import { useHistory } from 'react-router-dom';
import axiosRequest from '../utils/axiosConfig'
import { AuthContext } from '../context/AuthContext'
import IconButton from "../components/IconButton";
import see from "../assets/iconos/see.svg";
import edit from "../assets/iconos/edit.svg";
import dayjs from "dayjs"
import useHistorial from "../hooks/useHistorial";
import ModalHistorial from "../components/ModalHistorial";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { rolesRechazadas } from "../constants/roles";

const Rechazadas = (props) => {

  // Context
  const authContext = useContext(AuthContext)
  const user = JSON.parse(localStorage.getItem('userInfo'))
  const allowedRoles = rolesRechazadas;

  const [hayBusquedas, setHayBusquedas] = useState(true)
  const history = useHistory(); // Hook de router
  const handleClick = () => {
      history.push("/CargarSolicitudEmision");
  }
  const [data, setData] = useState('');
  const headers = ["Id", "F. Ingreso", "F. Rechazo", "Cliente", "Producto", "Motivo", "Acciones"]

  const { modalInfo, historial, handleModalHistorial, getMailAttachments, attachments, setAttachments, setPopUpMessage, setIsSuccessPopUpOpen, destinatarioMail, setDestinatarioMail, enviarMail, isSuccessPopUpOpen, popUpMessage, isModalOpenEnviarEmail, setIsModalOpenEnviarEmail, hideCross, loadingModalMessage, handleModalEnviarEmail, setLoadingModalMessage, setAttachmentsList, isModalOpen, setIsModalOpen } = useHistorial()

  // *** AUTENTICACIÓN & ROLES *** // 

  // Chequear si el usuario está autenticado, caso contrario lo manda al login. 
  useEffect(() => {
    if(!authContext.isAuthenticated()){
      history.push("/");
    }
  }, [])

  // Chequear el rol del usuario autenticado para saber si puede acceder a la página
  useEffect(() => {
    if(user){
      if(!allowedRoles.includes(user.id_rol) && user.id_rol != 8){
        history.push("/SolicitudEmision");
      } else if(!allowedRoles.includes(user.id_rol) && user.id_rol == 8) {
        history.push("/Estadisticas"); // CAMBIAR POR LA RUTA DE /RECHAZOS CUANDO EXISTA
      }
    }
  }, [])

  // *** *** // 

  useEffect(() => {
    const getData = async () => {
        try{
          let solicitudes = await axiosRequest.get("/estadosolicitudes/3");
          const filterBySeller = solicitudes.data.filter(solicitud => { return solicitud.id_vendedor === user.id_operador })
          if(user.id_operador === 1){
            solicitudes = solicitudes.data
          } else {
            solicitudes = filterBySeller
          }
          solicitudes.forEach(solicitud => solicitud.acciones = <div className="actionsContainer"><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={see} tooltipText="Ver Historial" action={() => handleModalHistorial(solicitud)}/><Link to={{ pathname: `/EditarSolicitudEmision/${solicitud.id}`, state: { data: solicitud.id, },}}><IconButton backgroundColor={false} borderColor={false} textColor={true} icon={edit} tooltipText="Editar Solicitud" /></Link></div>)
          setData(solicitudes);
          setHayBusquedas(true)
        } catch (error) {
          setHayBusquedas(false)
          console.log(error)
        }
    } 
    getData();
  }, []);

  const [open, setOpen] = useState(false)

  return (
    <div className="App">
      <ModalHistorial isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} modalInfo={modalInfo} historial={historial} destinatarioMail={destinatarioMail} setDestinatarioMail={setDestinatarioMail} attachments={attachments} setAttachmentsList={setAttachmentsList} enviarMail={enviarMail} getMailAttachments={getMailAttachments} />

      <Wrapper>
        <Inner>
          <BurgerMenu open={open} setOpen={setOpen}/>
          <NavMenu open={open} setOpen={setOpen} user={user} />
            <Grid colGap={21} rowGap={21}>
              <Col desktop={12} spaced={true}>
                <Titulo textColor={false}>Solicitudes Rechazadas</Titulo>
                <Button backgroundColor={true} borderColor={false} textColor={false} icon={false} onClick={handleClick}>
                  Cargar nueva solicitud
                </Button>
              </Col>
              <Col desktop={12}>
                <InfoBoard borderTop={false} invisible={true} loading={hayBusquedas} data={data} headers={headers} page="Rechazadas" />
              </Col>
            </Grid>
        </Inner>
      </Wrapper>
    </div>
  );
};

export default Rechazadas;